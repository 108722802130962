import Airdome from '../../../base/notifications/airdome';

const airdome: Airdome = {
  stateChanged: {
    title: 'Notifikace',
    messageFormat: {
      alive: 'Hala {airdomeName} je aktivní',
      halfdead: 'Hala {airdomeName} neodpovídá',
      dead: 'Hala {airdomeName} není aktivní',
      plcunavailable: 'PLC haly {airdomeName} je nedostupné',
      notinflated: 'Hala {airdomeName} není nafouknutá'
    },
  },
  created: {
    title: '{airdomeName}',
    message: 'Hala byla vytvořena'
  }
};

export default airdome;
