export default class BacnetObjectDefinition {
  public readonly bacnetId: number;
  public readonly bacnetType: number;
  public readonly bacnetPropertyType: number;

  constructor(bacnetId: number, bacnetType: number, bacnetPropertyType: number) {
    this.bacnetId = bacnetId;
    this.bacnetType = bacnetType;
    this.bacnetPropertyType = bacnetPropertyType;
  }
}
