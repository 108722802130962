import { AxiosInstance } from 'axios';
import { TemplateDto } from '../../dto/Container';
import format from 'string-format';
import { getBackendException } from 'helpers/ErrorHelper';
import { getJoinedEndpoint } from './../../utils/Settings';

export default class TemplateService {
  private axios: AxiosInstance;
  private getTemplatesUrl: string;
  private updateTemplateUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getTemplatesUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.templates.getAll);
    this.updateTemplateUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.templates.update)
  }

  public async getTemplates(trackingId: string): Promise<TemplateDto[]> {
    try {
      const response = await this.axios.get<TemplateDto[]>(this.getTemplatesUrl);
      return response.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async updateTemplate(trackingId: string, templateId: number): Promise<void> {
    try {
      const url = format(this.updateTemplateUrl, templateId.toString());
      const response = await this.axios.put(url);
      return response.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
