import Notifications from '../../base/notifications';
import airdome from './airdome';
import changeRequest from './ChangeRequest';

const notifications: Notifications = {
  airdome,
  changeRequest,
  notificationChannelTimedOut: {
    title: 'Warnung',
    message: 'Benutzer wurde erstellt, aber Benachrichtigungen konnten nicht festgelegt werden'
  }
};

export default notifications;
