import ArtificialIntelligence from '../../../../base/forms/airdome/artificialIntelligence';

const artificialIntelligence: ArtificialIntelligence = {
  constantsHeader: 'Default AI Heating Constants',
  mainHeader: 'Artificial intelligence',
  intakeMultiplier: 'Intake multiplier',
  intakeIntegration: 'Intake integration',
  spaceMultiplier: 'Space multiplier',
  spaceIntegration: 'Space integration'
};

export default artificialIntelligence;
