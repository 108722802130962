import * as React from 'react';

import { inject, observer } from 'mobx-react';

import { AirdomeModel } from 'models';
import AirdomeStore from '../stores/AirdomeStore';
import ButtonLoading from '../shared/components/ButtonLoading';
import { ErrorPopup } from '.';
import ErrorStore from '../stores/errors/ErrorStore';
import MaterialTable from './basic/material-table/MaterialTable';
import { TableColumn } from './basic/material-table/table-header/TableHeader';
import Translate from '../localization/Translate';

interface UserAirdomesProps {
  translate: Translate;
  setSelectedAirdomeIds: (airdomeIds: number[]) => void;
  selectedAirdomeIds: number[];
  onSubmit?: () => Promise<void>;
  search?: string;
  airdomeStore?: AirdomeStore;
  errorStore?: ErrorStore;
}

@inject('errorStore', 'airdomeStore')
@observer
export default class UserAirdomes extends React.Component<UserAirdomesProps> {
  private columns: TableColumn<AirdomeModel>[] = [
    {
      id: 'name',
      disablePadding: false,
      label: this.props.translate(x => x.general.airdome.name),
      getValue: (x: AirdomeModel) => x.name
    }
  ];

  private onAirdomeSelectionChanged = (selectedItems: AirdomeModel[]) => {
    const { setSelectedAirdomeIds } = this.props;

    if (!selectedItems)
      return;

    setSelectedAirdomeIds(selectedItems.map(x => x.id));
  }

  private dismissError = () =>
    this.props.errorStore!.clearError();

  public render() {
    const { translate } = this.props;
    const { airdomes } = this.props.airdomeStore!;
    const error = this.props.errorStore!.ErrorModel;

    const usersCurrentAirdomes = this.props.selectedAirdomeIds;

    const selected = usersCurrentAirdomes
      ? airdomes.filter(x => usersCurrentAirdomes.includes(x.id))
      : [];

    const onSubmit = async () => {
      this.props.onSubmit && await this.props.onSubmit();
    };

    return (
      <>
        <ErrorPopup
          translate={translate}
          error={error}
          close={this.dismissError}
        />
        <form onSubmit={() => onSubmit()}>
          <MaterialTable<AirdomeModel>
            {...{
              selected,
              showPagination: true,
              multiselect: true,
              search: this.props.search,
              searchIn: this.columns,
              data: airdomes,
              clickRowToSelect: true,
              showCheckboxColumn: true,
              rowsPerPageOptions: [10, 25, 30, 50],
              columns: this.columns,
              handleSelectionChanged: this.onAirdomeSelectionChanged
            }}
          />
          {this.props.onSubmit
            ? <ButtonLoading
              className="col-md-2 col-12"
              color="primary"
              onClick={async () => await onSubmit()}
              content={translate(x => x.forms.user.airdomeAccess.submit)}
            />
            : null}
        </form>
      </>
    );
  }
}
