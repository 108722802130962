enum SupportedLocale {
  enGB = 'en-GB',
  csCZ = 'cs-CZ',
  deDE = 'de-DE'
}

export default SupportedLocale;

export const supportedLocales = [
  SupportedLocale.enGB,
  SupportedLocale.csCZ,
  SupportedLocale.deDE
];