import './SwitchboxHeader.scss';

import { Card, CardBody, Col, Row } from 'reactstrap';

import AirdomeStateExpand from 'components/airdome/AirdomeStateExpand';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const SwitchboxHeader = () => {
  const translate = useLocalization();
  const airdome = useStore(x => x.airdomeStore).SelectedAirdome;
  const store = useStore(x => x.switchBoxStore);

  return <div className="SwitchboxHeader w-100">
    <Card>
      <CardBody>
      {airdome
        ? <>
        <Row>
            <h2 className="d-inline-block">
              <span className="lnr lnr-tablet" />{airdome.name}: {translate(x => x.pages.switchbox.header)}
            </h2>
            <AirdomeStateExpand
                onClick={async () => await store.requestSwitchBox()}
                enabledTitle={translate(x => x.pages.switchbox.refresh)}/>
          </Row>
          <div className="last-update">
            {store.lastUpdate
              ? <p>{translate(x => x.pages.switchbox.lastUpdate)}: {store.lastUpdate.format("L LT")}</p>
              : null}
          </div>
        </>
        : null}
      </CardBody>
    </Card>
  </div>
};

export default observer(SwitchboxHeader);
