import { defaultLogLevelId, getJoinedEndpoint } from './../../utils/Settings';

import { AxiosInstance } from 'axios';
import LogLevel from 'enums/LogLevel';
import LogMessageCommand from '../../dto/logger/commands/LogMessageCommand';
import moment from 'moment';
import requestConfigBuilder from '../../utils/axiosRequestConfigBuilder';
import stringFormat from 'string-format';

export default class LoggerService {
  private axios: AxiosInstance;
  private logMessageUrl: string;
  private logLevelUrl: string;
  private getAirdomeLogLevelUrl: string;
  private setAirdomeLogLevelUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.logMessageUrl = getJoinedEndpoint(
      x => x.logger.baseUri,
      x => x.logger.log.add
    );
    this.logLevelUrl = getJoinedEndpoint(
      x => x.logger.baseUri,
      x => x.logger.logLevel.get
    );
    this.getAirdomeLogLevelUrl = getJoinedEndpoint(
      x => x.logger.baseUri,
      x => x.logger.airdomes.getLogLevel
    );
    this.setAirdomeLogLevelUrl = getJoinedEndpoint(
      x => x.logger.baseUri,
      x => x.logger.airdomes.setLogLevel
    );
  }

  public getMinimumLogLevel = async () => {
    //todo -- API Endpoint needs to be created, for now return default value
    return defaultLogLevelId;
  }

  public getAirdomeLogLevel = async (airdomeId: number, trackingId: string) => {
    const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
    const url = stringFormat(this.getAirdomeLogLevelUrl, airdomeId.toString());

    return await this.axios.get<number>(url, requestConfig)
      .then(x => x.data);
  }

  public setAirdomeLogLevel = async (airdomeId: number, logLevelId: number, trackingId: string) => {
    const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
    const url = stringFormat(this.setAirdomeLogLevelUrl, airdomeId.toString());
    const dto = {
      logLevelId
    };

    return await this.axios.post(url, dto, requestConfig);
  }

  public log = async (
    message: string,
    logLevel: LogLevel,
    trackindId: string,
    airdomeId?: number,
    data?: { [ket: string]: any },
    timeStamp?: moment.Moment
  ): Promise<any> => {
    const command = new LogMessageCommand({
      airdomeId,
      logLevel,
      message,
      data,
      source: 'Frontend',
      timeStamp: timeStamp ? timeStamp : moment()
    });
    const result = await this.sendLog(command, trackindId);
    return result;
  }

  private async sendLog(dto: LogMessageCommand, trackingId: string): Promise<number> {
    const result = await this.axios.post(this.logMessageUrl, dto, {
      headers: { trackingId }
    });
    return result.status;
  }
}
