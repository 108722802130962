import './IntervalsForm.scss';

import * as React from 'react';

import {
  AIIntervalField,
  ConsumptionsIntervalField,
  PlcReadingIntervalField,
  StatusIntervalField
} from './fields';
import { Card, CardBody, Col, Fade, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import AirdomeStateExpand from 'components/airdome/AirdomeStateExpand';
import AwaitingDataSourceComponent from '../../basic/AwaitingDataSourceComponent';
import IntervalsFormProps from './IntervalsFormProps';

@inject(({ settingsStore }) => ({ settingsStore }))
@observer
export default class IntervalsForm extends AwaitingDataSourceComponent<IntervalsFormProps> {
  protected fetchData = () =>
    this.props.settingsStore!.fetchCurrentAirdomeIntervals()

  protected renderContent = () => {
    const { children, intervalModel, processingRequest, translate, disabled } = this.props;

    return <>
      {children}
      <form
        className="intervalsForm"
        onSubmit={(event) => {
          event && event.preventDefault();
          return false;
        }}>
        <fieldset disabled={processingRequest}>
          <Row>
            <AIIntervalField {...this.props} />
            <StatusIntervalField {...this.props} />
            <PlcReadingIntervalField {...this.props} />
            <ConsumptionsIntervalField {...this.props} />
          </Row>
          <div className="intervalsEditButton">
            <AirdomeStateExpand
              className="px-3"
              disabled={disabled || !intervalModel.areDataValid}
              onClick={async () => await this.onIntervalUpdate()}
              enabledTitle={translate(x => x.general.edit)}/>
          </div>
        </fieldset>
      </form >
    </>;
  }

  public render() {
    const { translate } = this.props;

    return <Col sm={12}>
      <Fade>
        <Card>
          <CardBody>
            <h1>{translate(x => x.general.airdome.intervalHeader)}</h1>
            {super.render()}
          </CardBody>
        </Card>
      </Fade>
    </Col >;
  }

  private onIntervalUpdate = async (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();
    const { settingsStore } = this.props;

    settingsStore && await settingsStore.updateIntervals();
  }
}
