import * as axios from 'axios';

import { ApiError, ErrorModel } from '../models';

export function createErrorModel(trackingId: string, apiError?: ApiError): ErrorModel {
  const localizationData = (apiError && apiError.data)
    ? {
      trackingId,
      ...apiError.data
    } : {
      trackingId
    };

  return new ErrorModel({
    localizationData,
    localizationKey: (apiError && apiError.messageKey) || 'GeneralError'
  });
}

export function getBackendException(exception: axios.AxiosError): Error {
  if (exception.response && exception.response.data && exception.response.data.localizationKey)
    return new ApiError({
      message: exception.message,
      localizationKey: exception.response.data.localizationKey
    });

  return new Error(exception.message);
}

export function getErrorsFromIdentityResponse(
  error: axios.AxiosError
): string[] {
  if (error.response && error.response.data)
    return Object.keys(error.response.data);

  return [];
}

export function getIdentityException(
  exception: axios.AxiosError,
  expectedErrors: string[] = []
): Error | ApiError {
  const errorCodes = getErrorsFromIdentityResponse(exception);
  if (errorCodes) {
    const errorCode = errorCodes.find(x => !!expectedErrors.find(y => y === x));
    if (errorCode) {
      const error = new ErrorModel({ localizationKey: errorCode });
      return new ApiError({ data: error });
    }
  }

  return new Error();
}

export const getFirstOrDefaultErrorFromConditions = (
  error: axios.AxiosError,
  ...condition: ((e: axios.AxiosError) => ApiError | false)[]
): ApiError => {
  if (!error || !error.request)
    return new ApiError();

  const resultError = condition.reduce<ApiError | false>(
    (firstError, condition) => firstError || condition(error),
    false);

  return resultError || new ApiError();
}