import moment from 'moment';
import BaseChart, { RenderLineProps } from './base/BaseChart';

interface WindSpeedNode {
  timestamp: moment.Moment;
  windSpeed: number;
}

class WindSpeedChart extends BaseChart<WindSpeedNode> {
  protected getTitle = () =>
    this.props.translate(x => x.pages.status.charts.windSpeedChartTitle)

  protected getLines = (): RenderLineProps<WindSpeedNode>[] => [{
    dataKey: x => x.windSpeed,
    stroke: 'green',
    name: this.props.translate(x => x.pages.status.windSpeed),
    unit: 'm/s'
  }]
}

export default WindSpeedChart;
