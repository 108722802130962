import * as React from 'react';
import { ErrorModel } from '../../models';
import format from 'string-format';

const errorBox = (props: { errorModel: ErrorModel, errorText: string }) => {
  const {
    errorText,
    errorModel: {
      localizationData
    }
  } = props;

  return <div className="alert alert-danger" role="alert">
    {format(errorText, localizationData)}
  </div>;
};

export default React.memo(errorBox);
