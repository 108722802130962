import BaseTemperatureField from '../BaseTemperatureField';
import React from 'react';
import TemperatureProps from '../../TemperatureProps';
import { observer } from 'mobx-react';

const MaximumValueField = ({ translate, model, readonly }: TemperatureProps) =>
  <BaseTemperatureField {...{
    readonly,
    numberValidationSchema: model.cutbackTemperatureMaxSchema.label(
      translate(x => x.forms.airdome.restrictions.temperature.cutbackTemperatureMax)
    ),
    inputId: 'UpperBound_TemperatureCutback_Field',
    label: translate(x => x.forms.airdome.restrictions.temperature.cutbackTemperatureMax),
    value: model.cutbackTemperatureMax.Value,
    setValue: x => model.cutbackTemperatureMax.setValue(x),
    isEnabled: model.isCutbackTemperatureMaxEnabled.Value ?? false,
    setIsEnabled: x => model.isCutbackTemperatureMaxEnabled.setValue(x)
  }} />;

export default observer(MaximumValueField);
