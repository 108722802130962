import * as validations from '../validations/validations';

import { NotificationsService, UserService } from "services";
import { action, computed, observable } from "mobx";

import InputModel from "models/InputModel";
import { NotificationOptionModel } from "models";
import RootStore from './RootStore';
import { SecurityStore } from 'stores';
import { catchError } from "./decorators";

export default class UserProfileStore {
  private readonly userService: UserService;
  private readonly notificationsService: NotificationsService;
  private readonly securityStore: SecurityStore;
  public params: RootStore; // Used by @catchError decorator

  @observable
  private originalNotificationChannelId: number | undefined;

  @observable
  public username = new InputModel<string>();

  @observable
  public email = new InputModel<string>();

  @observable
  public password = new InputModel<string>();

  @observable
  public confirmPassword = new InputModel<string>();

  @observable
  public selectedNotificationChannelId = new InputModel<number>();

  @observable
  public notificationChannelOptions: NotificationOptionModel[] = [];

  constructor(securityStore: SecurityStore, userService: UserService, notificationsService: NotificationsService, params: RootStore) {
    this.userService = userService;
    this.notificationsService = notificationsService;
    this.securityStore = securityStore;
    this.params = params;

    this.username.setValue(securityStore.userName, false);
    this.email.setValue(securityStore.email, false);
  }

  @computed
  public get arePasswordsDifferent(): boolean {
    return this.password.Value !== this.confirmPassword.Value;
  }

  @computed
  public get hasChanges(): boolean {
    return this.userInfoChanged || this.notificationChannelChanged;
  }

  @computed
  public get userInfoChanged(): boolean {
    return this.username.ValueChanged || this.password.ValueChanged || this.email.ValueChanged
  }

  @computed
  public get notificationChannelChanged(): boolean {
    return this.selectedNotificationChannelId.Value !== this.originalNotificationChannelId
  }

  @computed
  public get isValid(): boolean {
    return (
      !this.userInfoChanged || (
        validations.getUsernameValidationSchema().isValidSync(this.username.Value) &&
        validations.getOptionalPasswordValidationSchema().isValidSync(this.password.Value) &&
        validations.getEmailValidationSchema().isValidSync(this.email.Value) &&
        !this.arePasswordsDifferent
      )) &&
      (
        !this.notificationChannelChanged ||
        this.notificationChannelOptions.some(x => x.id === this.selectedNotificationChannelId.Value)
      );
  }

  @catchError
  @action
  public fetchNotificationsOptions = async (trackingId: string = '') => {
    const notificationsDto = await this.userService.getNotificationOptions(trackingId);
    this.setNotificationsOptions(notificationsDto);
  }

  @catchError
  @action
  public fetchNotificationChannel = async (trackingId: string = '') => {
    const notificationsDto = await this.notificationsService
      .getCurrentUserNotificationChannel(trackingId);
    this.setOriginalNotificationChannelId(notificationsDto.id);
    this.selectedNotificationChannelId.setValue(notificationsDto.id);
  }

  @catchError
  @action
  public submit = async (trackingId: string = ''): Promise<void> => {
    await Promise.all([
      this.updateUserInfo(trackingId),
      this.updateNotificationChannel(trackingId)
    ]);
  }

  @action
  private setNotificationsOptions = (value: NotificationOptionModel[]) =>
    this.notificationChannelOptions = value;

  @action
  private setOriginalNotificationChannelId = (notificationChannelId: number) =>
    this.originalNotificationChannelId = notificationChannelId;

  private updateUserInfo = async (trackingId: string = '') => {
    if (this.userInfoChanged) {
      await this.userService.updateCurrentUser(
        this.username.Value || '',
        this.password.Value || '',
        this.email.Value || '',
        trackingId);
      this.securityStore.setUserName(this.username.Value!);
      this.securityStore.setEmail(this.email.Value!);
      this.username.resetHasChange();
      this.password.resetHasChange();
      this.email.resetHasChange();
    }
  }

  private updateNotificationChannel = async (trackingId: string = '') => {
    if (this.notificationChannelChanged) {
      const selectedNotificationChannelId = this.selectedNotificationChannelId.Value!
      await this.notificationsService
        .updateCurrentUserNotificationChannel(selectedNotificationChannelId, trackingId);
      this.setOriginalNotificationChannelId(selectedNotificationChannelId);
    }
  }
}