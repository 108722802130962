import NotificationChannel from '../../base/enums/NotificationChannel';

const notificationChannel: NotificationChannel = {
  both: 'Both',
  emails: 'Email',
  mobilenotifications: 'Push notifications',
  none: 'None'
};

export default notificationChannel;
