import { NotificationOptionModel, SelectOptionModel } from '../../../../models';

import { FormSelect } from '../../..';
import Translate from '../../../../localization/Translate';
import { observer } from 'mobx-react';

interface NotificationsSelectFieldProps {
  translate: Translate;
  notificationsOptions: NotificationOptionModel[];
  setNotifications: (notifications: string) => void;
  notifications?: string;
  readOnly: boolean;
}

const NotificationsSelectField = ({
  translate, notificationsOptions, setNotifications, notifications, readOnly
}: NotificationsSelectFieldProps) =>
  <FormSelect {...{
    translate,
    readOnly,
    label: translate(x => x.general.user.notifications),
    selectId: 'UserNotifications',
    options: notificationsOptions.map(x =>
      new SelectOptionModel({
        displayName: translate(tr => tr.enums.notificationChannel[x.name.toLowerCase()]),
        value: x.id.toString()
      })
    ),
    setValue: setNotifications,
    selectedValue: notifications
  }} />;

export default observer(NotificationsSelectField);
