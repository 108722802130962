import React from 'react';
import { FormSelect } from '../../..';
import { SelectOptionModel, NotificationOptionModel } from '../../../../models';
import Translate from '../../../../localization/Translate';

interface NotificationsSelectFieldProps {
  translate: Translate;
  notificationsOptions: NotificationOptionModel[];
  setNotifications: (notifications: string) => void;
  notifications?: string;
}

const NotificationsSelectField = ({
  translate, notificationsOptions, setNotifications, notifications
}: NotificationsSelectFieldProps) =>
  <FormSelect {...{
    translate,
    label: translate(x => x.general.user.notifications),
    selectId: 'UserNotifications',
    options: notificationsOptions.map(x =>
      new SelectOptionModel({
        displayName: translate(tr => tr.enums.notificationChannel[x.name.toLowerCase()]),
        value: x.id.toString()
      })
    ),
    setValue: setNotifications,
    selectedValue: notifications
  }} />;

export default React.memo(NotificationsSelectField);
