import * as React from 'react';

import { Card, CardBody, Col, Fade, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import AirdomeStore from '../../../stores/AirdomeStore';
import ButtonLoading from '../../../shared/components/ButtonLoading';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import ErrorBox from '../../basic/ErrorBox';
import ErrorModel from '../../../models/error/ErrorModel';
import { MacAddressField } from '../../airdome/create/fields';
import { MacAddressModel } from '../../../models';
import MaterialTable from '../../basic/material-table/MaterialTable';
import SettingsStore from '../../../stores/SettingsStore';
import { TableColumn } from '../../basic/material-table/table-header';
import Translate from '../../../localization/Translate';

interface MacAddressesFormProps {
  translate: Translate;
  settingsStore?: SettingsStore;
  airdomeStore?: AirdomeStore;
}

interface MacAddressesFormState {
  addInProgress: boolean;
  removeInProgress: boolean;
}

@inject(({ settingsStore, airdomeStore }) => ({ settingsStore, airdomeStore }))
@observer
export default class MacAddressesForm extends React.Component<MacAddressesFormProps, MacAddressesFormState> {
  constructor(props: MacAddressesFormProps) {
    super(props);

    this.state = {
      addInProgress: false,
      removeInProgress: false
    };
  }

  public componentDidMount() {
    const airdome = this.props.airdomeStore!.SelectedAirdome;

    if (!airdome)
      return;

    this.props.settingsStore!.macAddressesModel.import(airdome.macAddresses);
  }

  public componentWillUnmount() {
    this.props.settingsStore!.macAddressesModel.clearMacAddressToAdd();
  }

  public render() {
    const translate = this.props.translate;
    const { macAddressesModel } = this.props.settingsStore as SettingsStore;

    return <Col sm={6}>
      <Fade>
        <Card>
          <CardBody>
            <h3>{translate(x => x.general.airdome.macAddresses)}</h3>
            {this.renderAddErrorBox()}
            {this.renderRemoveErrorBox()}
            <form>
              <MaterialTable<MacAddressModel>
                {...{
                  columns: this.getColumns(),
                  selected: [],
                  showPagination: false,
                  multiselect: false,
                  data: macAddressesModel.macAddresses,
                  clickRowToSelect: false,
                  showCheckboxColumn: false
                }}
              />
              <Row>
                <Col>
                  <MacAddressField {...{
                    translate,
                    readonly: this.state.addInProgress,
                    macAddress: macAddressesModel.macAddressToAdd.value,
                    getValidationSchema: macAddressesModel.getValidationSchema,
                    setMacAddress: macAddress => macAddressesModel.setMacAddressToAdd(macAddress || '')
                  }} />
                </Col>
                <Col style={{ paddingTop: 20 }} >
                  <ButtonLoading
                    disabled={!macAddressesModel.macAddressToAdd.areDataValid(true)}
                    color="secondary"
                    onClick={this.onAdd}
                    content={translate(x => x.general.add)}
                  />
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Fade>
    </Col >;
  }

  private onAdd = async () => {
    const settingsStore = this.props.settingsStore as SettingsStore;

    this.setState({ addInProgress: true });

    await settingsStore.addMacAddress();

    this.setState({ addInProgress: false });
  }

  private getColumns = (): TableColumn<MacAddressModel>[] => {
    const translate = this.props.translate;

    const columns: TableColumn<MacAddressModel>[] = [
      {
        label: '#',
        id: 'index',
        disablePadding: true,
        width: 10,
        getValue: (item, index) => ((index || 0) + 1).toString()
      },
      {
        label: translate(x => x.general.airdome.macAddress),
        id: 'macAddress',
        disablePadding: true,
        getValue: (x: MacAddressModel) => x.value,
        width: 88
      },
      {
        renderCell: (macAddress: MacAddressModel) => {
          if (this.state.removeInProgress)
            return <div className="waiting">
              <CloseCircleIcon />
            </div>;

          return <div className="clickable" onClick={async () => this.onRemove(macAddress.id)}>
            < CloseCircleIcon />
          </div >;
        },
        label: '',
        id: 'controls',
        disablePadding: true,
        getValue: () => '',
        width: 2
      }
    ];

    return columns;
  }

  private onRemove = (macAddressId?: number) => {
    const settingsStore = this.props.settingsStore as SettingsStore;

    settingsStore.removeMacAddress(macAddressId)
      .then(() => this.setState({ removeInProgress: false }));

    this.setState({ removeInProgress: true });
  }

  private renderAddErrorBox = () => {
    const { addMacAddress } = this.props.settingsStore as SettingsStore;
    const { translate } = this.props;

    if (!addMacAddress.getLastExecutionFailed!())
      return null;

    return <ErrorBox
      {...{
        errorText: translate(x => x.errors.airdome.macAddress.unableToAdd),
        errorModel: new ErrorModel({ localizationKey: '' })
      }}
    />;
  }

  private renderRemoveErrorBox = () => {
    const { removeMacAddress } = this.props.settingsStore as SettingsStore;
    const { translate } = this.props;

    if (!removeMacAddress.getLastExecutionFailed!())
      return null;

    return <ErrorBox
      {...{
        errorText: translate(x => x.errors.airdome.macAddress.unableToRemove),
        errorModel: new ErrorModel({ localizationKey: '' })
      }}
    />;
  }
}
