import '../../scss/component/form-input.scss';

import * as React from 'react';

import { FormInputModel } from '../../models';
import { TextField } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

type FormInputProps = {
  model: FormInputModel;
  className?: string;
  children?: any;
  errors?: string[]
};

interface FormInputState {
  errors: string[];
}

class FormInput extends React.Component<FormInputProps, FormInputState> {
  constructor(props: FormInputProps) {
    super(props);
    this.state = { errors: [] };
  }

  private onChange = (event: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
    const { handleValueModel } = this.props.model;

    const value = event.target.value
      ? handleValueModel.InputType === 'number'
        ? Number(event.target.value)
        : event.target.value
      : '';

    handleValueModel.SetValue(value!);
  }

  public static getDerivedStateFromProps(props: FormInputProps, state: FormInputState) {
    const { validationSchema, handleValueModel, value } = props.model;
    const convertedValue = handleValueModel.InputType === 'number'
      ? value
        ? Number(value)
        : undefined
      : value;

    if (validationSchema)
      try {
        validationSchema.validateSync(convertedValue);
        state.errors = [];
      } catch (x) {
        if ((x as any).errors)
          state.errors = (x as any).errors;
      }
    else
      state.errors = [];

    if (props.errors)
      state.errors = [...props.errors, ...state.errors];

    return state;
  }

  public render() {
    const { errors } = this.state;
    const {
      className,
      model: {
        label,
        placeholder,
        inputId,
        handleValueModel,
        autoComplete,
        maxLength,
        minLength,
        value,
        readonly
      }
    } = this.props;

    const errorList = errors.length > 0
      ? errors.map((error, index) => (
        <div key={index} className="element-error">
          {error}
        </div>
      ))
      : null;

    return <div className={`form-group${className ? ` ${className}` : ''}`}>
      <TextField className="form-control text-field" {...{
        minLength,
        maxLength,
        autoComplete,
        label,
        placeholder,
        value: value || '',
        disabled: readonly,
        error: errors.length > 0,
        onChange: this.onChange,
        type: handleValueModel.InputType,
        id: `${inputId}Input`,
        name: inputId
      }} />
      {errorList}
      {this.props.children}
    </div>;
  }
}

export default withTheme(FormInput);
