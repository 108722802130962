import SwitchBoxTablePropertyName from 'enums/SwitchBoxTablePropertyName';
import Switchbox from '../../../base/pages/switchbox';

const switchbox: Switchbox = {
  header: 'Schaltanlage',
  refresh: 'Aktualisieren',
  lastUpdate: 'Letzte aktualisierung',

  operationFailure: 'OPERATION/VERSAGEN',
  
  engineUsage: 'Motornutzung',
  engineUsageNotAvailable: 'Motornutzung ist nicht verfügbar',

  flapUsage: 'Klappe geöffnet um',
  flapUsageNotAvailable: 'Klappenschließung ist nicht verfügbar',
  
  burnerPercentage: 'Brennernutzung',
  burnerPercentageNotAvailable: 'Brennernutzung ist nicht verfügbar',

  notAvailable: 'nicht verfügbar',
  valueNotAvailable: 'Wert ist nicht verfügbar',

  temperatureMode: {
    label: 'Aktueller Temperaturmodus',
    day: 'Tag',
    night: 'Nacht'
  },
  
  labels: {
    dieselFailure: 'VERSAGEN',
    engine: 'VENT MOTOR',
    flap: 'KLAPPE',
    heating: 'HEIZUNG',
    burnerSwitch: 'BRENNER',
    alarmReset: 'ALARM ZURÜCKSETZEN',
    powerOn: 'STROM EIN',
    aggregate: 'AGGREGAT'
  },

  switches: {
    hand0auto: 'HAND 0 AUTO',
    '0auto': '0 AUTO',
    daynightauto: 'TAG NACHT AUTO',
    '0autotest': '0 AUTO TEST'
  },

  table: {
    [SwitchBoxTablePropertyName.DomeHeightUpperBound]: 'Hallenhöhe - obere Grenze',
    [SwitchBoxTablePropertyName.DomeHeightLowerBound]: 'Hallenhöhe - untere Grenze',
    [SwitchBoxTablePropertyName.DomeHeightAlarmHysteresis]: 'Hallenhöhenhysterese-Alarm',
    [SwitchBoxTablePropertyName.WindSpeedLowerBound]: 'Wind - untere Grenze',
    [SwitchBoxTablePropertyName.WindSpeedUpperBound]: 'Wind - obere Grenze',
    [SwitchBoxTablePropertyName.WindSpeedAlarmHysteresis]: 'Windhysterese-Alarm',
    [SwitchBoxTablePropertyName.ComputedPressureValue]: 'Berechneter Druckwert',
    [SwitchBoxTablePropertyName.ZesileniRegulaceP]: 'Multiplikatorregelung - Druck',
    [SwitchBoxTablePropertyName.IntegracniRegulaceP]: 'Integrationsregelung - Druck',
    [SwitchBoxTablePropertyName.MaximalEmergencyPressure]: 'Maximaler Notfalldruck',
    [SwitchBoxTablePropertyName.LastPreheatingDurationDeviation]: 'Abweichung letzte Vorwärmdauer',
    [SwitchBoxTablePropertyName.PredictedPreheatingDuration]: 'Voraussichtliche Vorheizdauer'
  }
};

export default switchbox;
