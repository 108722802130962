import { AirdomeStore, ErrorStore } from "stores";
import { IReactionDisposer, action, observable, reaction } from "mobx";

import PreheatingSettingsDto from "dto/settings/PreheatingSettingsDto";
import PreheatingSettingsService from "services/settings/PreheatingSettingsService";
import PagingModel from "models/store/PagingModel";
import UserService from "services/identity/UserService";
import { asTracked } from "stores/decorators/TrackedDecorator";

const PanelUserId = "Panel";

export type PreheatingSettingsHistoryItem = Omit<PreheatingSettingsDto & { userName: string }, 'userId'>;

export default class PreheatingSettingsHistoryModel {
  private readonly errorStore: ErrorStore;
  private readonly getTrackingId: () => string;
  private readonly preheatingSettingsService: PreheatingSettingsService;
  private readonly airdomeStore: AirdomeStore;
  private readonly userService: UserService;

  public readonly pagingModel = new PagingModel();

  private fetchReactionDisposer: IReactionDisposer | undefined;

  public constructor(
    params: {
      errorStore: ErrorStore,
      getTrackingId: () => string,
      preheatingSettingsService: PreheatingSettingsService,
      airdomeStore: AirdomeStore,
      userService: UserService
    }) {
    this.errorStore = params.errorStore;
    this.getTrackingId = params.getTrackingId;
    this.preheatingSettingsService = params.preheatingSettingsService;
    this.airdomeStore = params.airdomeStore;
    this.userService = params.userService;
  }

  @observable
  public totalCount = 0;

  @observable
  public preheatingSettingsEntries: PreheatingSettingsHistoryItem[] = [];

  @action
  public clear = () => {
    this.preheatingSettingsEntries = [];
    this.totalCount = 0;
    this.pagingModel.clear();

    if(this.fetchReactionDisposer) {
      this.fetchReactionDisposer();
      this.fetchReactionDisposer = undefined;
    }
  }

  public setup = asTracked(action(() => {
    const airdomeId = this.airdomeStore.SelectedAirdomeId;
    if(!airdomeId) {
      this.errorStore.handleErrorModel(
        this.getTrackingId(),
        new Error('No airdome selected!')
      );
      return;
    }

    if(this.fetchReactionDisposer)
      return;

    this.fetchReactionDisposer = reaction(
      () => ({
        skip: this.pagingModel.Skip,
        take: this.pagingModel.Take
      }),
      ({ skip, take }) => this.fetch(airdomeId, skip, take),
      {
        fireImmediately: true
      });
  }));

  public readonly fetch = asTracked(action(async (airdomeId: number, skip: number, take: number) => {
    const trackingId = this.getTrackingId();

    const preheatingSettingsHistoryPaged = await this.preheatingSettingsService.fetchPreheatingSettingsHistory(
      airdomeId,
      skip,
      take,
      trackingId);

    const userIds = preheatingSettingsHistoryPaged.entities
      .map(x => x.userId)
      .filter(x => x !== PanelUserId);

    const userNames = userIds.length > 0 
      ? await this.userService.getUserNamesByIds(userIds, trackingId)
      : {};

    this.preheatingSettingsEntries = preheatingSettingsHistoryPaged.entities.map(preheatingSettings => ({
      ...preheatingSettings,
      userName: preheatingSettings.userId === PanelUserId
        ? preheatingSettings.userId
        : userNames[preheatingSettings.userId] ?? " - "
    }));

    this.totalCount = preheatingSettingsHistoryPaged.totalCount;
  }))
}