import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { ValuesUnits, chartStyle, iconSize, isTranslateSame, wrapGauge } from './Shared';

import ArrowExpandVerticalIcon from 'mdi-react/ArrowExpandVerticalIcon';
import React from 'react';
import StatusGauge from '../StatusGauge';

const AirdomeHeight = React.memo((props: TranslateProps & ColClassName & {
  height?: number;
}) => wrapGauge(
  <StatusGauge {...{
    chartStyle,
    title: props.translate(x => x.pages.status.height),
    value: props.height,
    minValue: 0,
    maxValue: 15,
    lineForeground: '#000000',
    unit: ValuesUnits.height,
    icon: <ArrowExpandVerticalIcon size={iconSize} color="#000000" />
  }} />, props.colClassName),
  (a, b) => a.height === b.height
    && isTranslateSame(a, b, x => x.pages.status.height));

export default AirdomeHeight;
