import React from 'react';
import { FormInput } from '../../../..';
import { FormInputModel } from '../../../../../models';
import { HandleValueModel } from '../../../../../models/component/HandleValueModel';
import Translate from '../../../../../localization/Translate';
import { StringSchema } from 'yup';
import { observer } from 'mobx-react';

interface ProjectNumberFieldProps {
  translate: Translate;
  projectNumber?: string;
  validationSchema: StringSchema;
  setProjectNumber: (projectNumber?: string) => void;
}

const ProjectNumberField =
  ({ translate, validationSchema, projectNumber, setProjectNumber }: ProjectNumberFieldProps) =>
    <FormInput {...{
      model: new FormInputModel({
        inputId: 'AirdomeProjectNumber',
        value: projectNumber,
        label: translate(x => x.general.airdome.projectNumber),
        placeholder: translate(x => x.general.airdome.projectNumber),
        handleValueModel: new HandleValueModel({
          inputType: 'text',
          setValue: setProjectNumber
        }),
        validationSchema: validationSchema.label(translate(x => x.general.airdome.projectNumber))
      })
    }} />;

export default observer(ProjectNumberField);
