import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { chartStyle, getColorIfDefined, iconSize, wrapGauge } from './Shared';

import LightbulbOutlineIcon from 'mdi-react/LightbulbOutlineIcon';
import React from 'react';
import StatusGauge from '../StatusGauge';
import { observer } from 'mobx-react';

const Lighting = (props: TranslateProps & ColClassName & {
  lights?: {
    total: number;
    active: number;
  };
  onClick: () => void;
}) => wrapGauge(
  <StatusGauge {...{
    chartStyle,
    title: props.translate(x => x.pages.status.lighting),
    value: props.lights ? props.lights.active : undefined,
    minValue: 0,
    maxValue: props.lights ? props.lights.total : 0,
    lineForeground: getColorIfDefined(props.lights, '#ffc61c'),
    valueTextFormat: props.lights ? '{0}/{1}' : '-',
    unit: '',
    icon: <LightbulbOutlineIcon size={iconSize} color={getColorIfDefined(props.lights, '#ffc61c')} />
  }} />, props.colClassName, props.onClick);

export default observer(Lighting);
