import * as validations from '../validations/validations';

import { Card, CardBody, Col } from "reactstrap";
import { ConfirmPasswordField, EmailField, NotificationsSelectField, PasswordField, UsernameField } from "components/user/edit/fields";

import ButtonLoading from "shared/components/ButtonLoading";
import HeaderCard from 'components/basic/HeaderCard';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocalization } from "localization/LocalizationProvider";
import { useStore } from "stores/MobXTypedStoreHook";

const UserProfilePage = () => {
  const translate = useLocalization();
  const store = useStore(x => x.userProfileStore);

  useEffect(
    () => {
      store.params.errorStore.clearError();
      store.fetchNotificationsOptions();
      store.fetchNotificationChannel();
      return () => store.params.errorStore.clearError()
    }, [])

  return (
    <>
      <Col xl={6} lg={12} className="clearfix">
        <HeaderCard title={translate(x => x.menu.user.edit)} />
      </Col>
      <Col xl={6} lg={12}>
        <Card>
          <CardBody>
            <span className="element-error">
              {store.params.errorStore.hasRequestFailed ? translate(x => x.pages.userProfile.requestFailed) : null}
            </span>
            <UsernameField {...{
              translate,
              validationSchema: validations.getUsernameValidationSchema(),
              username: store.username.Value ?? '',
              setUsername: x => store.username.setValue(x)
            }} />
            <PasswordField {...{
              translate,
              validationSchema: validations.getOptionalPasswordValidationSchema(),
              password: store.password.Value ?? '',
              setPassword: x => store.password.setValue(x)
            }} />
            <ConfirmPasswordField {...{
              translate,
              confirmPassword: store.confirmPassword.Value ?? '',
              setConfirmPassword: x => store.confirmPassword.setValue(x),
              arePasswordsDifferent: store.arePasswordsDifferent
            }} />
            <EmailField {...{
              translate,
              validationSchema: validations.getEmailValidationSchema(),
              currentEmail: store.email.Value ?? '',
              setEmail: x => store.email.setValue(x)
            }} />
            <NotificationsSelectField {...{
              translate,
              notificationsOptions: store.notificationChannelOptions,
              notifications: store.selectedNotificationChannelId.Value?.toString() ?? '',
              setNotifications: x => store.selectedNotificationChannelId.setValue(+x),
              readOnly: false
            }} />
            <ButtonLoading
              disabled={!store.hasChanges || !store.isValid}
              className="col-md-2 col-12 mb-0 float-right"
              onClick={async () => await store.submit()}
              content={translate(x => x.general.edit)}
              color="primary" />
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default observer(UserProfilePage);