import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from 'mdi-react/FilterListIcon';

export interface MatTableMenuItem {
  column: string;
  name: string;
}

export default class TableFilterButton extends React.Component<{
  menuItems: MatTableMenuItem[],
  onRequestSort: (event: React.MouseEvent<HTMLElement, MouseEvent>, property: string) => void
}, { anchorEl?: HTMLElement & EventTarget }> {
  public state = {
    anchorEl: undefined,
  };

  private handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  private handleClose = () => {
    this.setState({ anchorEl: undefined });
  }

  private handleSort = (property: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.props.onRequestSort(event, property);
    this.handleClose();
  }

  public render() {
    const { anchorEl } = this.state;
    const { menuItems } = this.props;

    return (
      <div>
        <IconButton
          className="material-table__toolbar-button"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <FilterListIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          {menuItems.map(x =>
            <MenuItem onClick={this.handleSort(x.column)} className="material-table__filter-menu-item">
              {x.name}
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}
