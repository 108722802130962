import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import React from 'react';
import SettingsFormProps from '../SettingsFormProps';
import { observer } from 'mobx-react';
import useSelectedAirdomeTime from 'common/AirdomeTimeHook';

const LastUpdateField = ({ translate, locale, settingsModel }: SettingsFormProps) => {
  const {
    toAirdomeTime
  } = useSelectedAirdomeTime();

  return <FormInput {...{
    model: new FormInputModel({
      placeholder: translate(x => x.forms.airdome.settings.lastUpdate),
      handleValueModel: new HandleValueModel({
        setValue: () => { },
        inputType: 'text'
      }),
      readonly: true,
      label: translate(x => x.forms.airdome.settings.lastUpdate),
      inputId: 'LastUpdate',
      value: settingsModel.lastUpdate
        ? toAirdomeTime(settingsModel.lastUpdate).locale(locale).format('l LT')
        : ''
    })
  }} />
};

export default observer(LastUpdateField);
