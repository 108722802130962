import { UserListItemModel } from '.';
import { observable, action, computed } from 'mobx';

export default class {
  public readonly user: UserListItemModel;

  @observable
  private selectedAirdomeIds: number[] = [];

  constructor(user: UserListItemModel) {
    this.user = user;
  }

  @action
  public setSelectedAirdomeIds = (selectedAirdomeIds: number[]) => {
    this.selectedAirdomeIds = selectedAirdomeIds;
  }

  @computed
  public get SelectedAirdomeIds(): number[] {
    return this.selectedAirdomeIds;
  }
}
