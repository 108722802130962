import SwitchBoxTablePropertyName from 'enums/SwitchBoxTablePropertyName';
import Switchbox from '../../../base/pages/switchbox';

const switchbox: Switchbox = {
  header: 'Rozvaděč',
  refresh: 'Obnovit',
  lastUpdate: 'Naposledy aktualizováno',
  
  operationFailure: 'CHOD/PORUCHA',
  
  engineUsage: 'Vytížení motoru',
  engineUsageNotAvailable: 'Vytížení motoru není dostupné',
  
  flapUsage: 'Otevření klapky',
  flapUsageNotAvailable: 'Zavření klapky není dostupné',

  burnerPercentage: 'Využití hořáku',
  burnerPercentageNotAvailable: 'Využití hořáku není dostupné',

  notAvailable: 'nedostupné',
  valueNotAvailable: 'Hodnota není dostupná',

  temperatureMode: {
    label: 'Aktuální režim topení',
    day: 'Komfort',
    night: 'Útlum'
  },

  labels: {
    dieselFailure: 'PORUCHA',
    engine: 'MOTOR',
    flap: 'KLAPKA',
    heating: 'TOPENÍ',
    alarmReset: 'ODST. ALARMU',
    powerOn: 'POD NAPĚTÍM',
    burnerSwitch: 'HOŘÁK',
    aggregate: 'AGREGÁT'
  },

  switches: {
    hand0auto: 'RUČ 0 AUTO',
    '0auto': '0 AUTO',
    daynightauto: 'DEN NOC AUTO',
    '0autotest': '0 AUTO TEST'
  },

  table: {
    [SwitchBoxTablePropertyName.DomeHeightUpperBound]: 'Výška haly - horní mez',
    [SwitchBoxTablePropertyName.DomeHeightLowerBound]: 'Výška haly - dolní mez',
    [SwitchBoxTablePropertyName.DomeHeightAlarmHysteresis]: 'Hystereze alarmu výšky haly',
    [SwitchBoxTablePropertyName.WindSpeedLowerBound]: 'Vítr - dolní mez',
    [SwitchBoxTablePropertyName.WindSpeedUpperBound]: 'Vítr - horní mez',
    [SwitchBoxTablePropertyName.WindSpeedAlarmHysteresis]: 'Hystereze alarmu rychlosti větru',
    [SwitchBoxTablePropertyName.ComputedPressureValue]: 'Vypočítaná požadovaná hodnota tlaku',
    [SwitchBoxTablePropertyName.ZesileniRegulaceP]: 'Zesílení regulace - tlak',
    [SwitchBoxTablePropertyName.IntegracniRegulaceP]: 'Integrační regulace - tlak',
    [SwitchBoxTablePropertyName.MaximalEmergencyPressure]: 'Maximální nouzový tlak',
    [SwitchBoxTablePropertyName.LastPreheatingDurationDeviation]: 'Odchylka posledního předzátopu',
    [SwitchBoxTablePropertyName.PredictedPreheatingDuration]: 'Odhadovaná doba předzátopu'
  }
};

export default switchbox;
