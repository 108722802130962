import Charts from '../../../../base/pages/status/charts';
import dateSelect from './DateSelect';

const charts: Charts = {
  dateSelect,

  temperatureChartTitle: 'Teplota',
  pressureChartTitle: 'Tlak',
  windSpeedChartTitle: 'Rychlost větru',
  airdomeHeightChartTitle: 'Výška haly',
  noData: 'Vybrané rozmezí neobsahuje žádná data',
  dataWarningTitle: 'Vybrané rozmezí obsahuje větší množství dat a jejich načtení nám chvíli zabere',
  dataWarningMessage: 'Přejete si pokračovat?'
};

export default charts;
