import React from 'react';
import { FormInput } from '../../../..';
import { FormInputModel } from '../../../../../models';
import { HandleValueModel } from '../../../../../models/component/HandleValueModel';
import { getBasicNumberSchema } from '../../../../../validations/validations';
import Translate from '../../../../../localization/Translate';
import objectHelper from '../../../../../utils/ObjectHelper';

interface MainLightsFieldProps {
  translate: Translate;
  lightsAdditionalCount?: number;
  setLightsAdditionalCount: (mainCount?: number) => void;
}

const MainLightsField = ({ translate, lightsAdditionalCount, setLightsAdditionalCount }: MainLightsFieldProps) =>
  <FormInput {...{
    model: new FormInputModel({
      inputId: 'AirdomeNumberOfAdditionalLights',
      handleValueModel: new HandleValueModel({
        inputType: 'number',
        setValue: setLightsAdditionalCount
      }),
      value: objectHelper.executeIfNumber(lightsAdditionalCount, x => x.toString()),
      label: translate(x => x.forms.airdome.create.numberOfAdditionalLights),
      placeholder: translate(x => x.forms.airdome.create.numberOfAdditionalLights),
      validationSchema: getBasicNumberSchema({
        max: 4,
        min: 0,
        required: false,
        strict: true
      }).label(translate(x => x.forms.airdome.create.numberOfAdditionalLights))
    })
  }} />;

export default React.memo(MainLightsField);
