export default [
  {
    id: 'Dateline Standard Time',
    displayName: '(UTC-12:00) International Date Line West'
  },
  { id: 'UTC-11', displayName: '(UTC-11:00) Coordinated Universal Time-11' },
  { id: 'Aleutian Standard Time', displayName: '(UTC-10:00) Aleutian Islands' },
  { id: 'Hawaiian Standard Time', displayName: '(UTC-10:00) Hawaii' },
  {
    id: 'Marquesas Standard Time',
    displayName: '(UTC-09:30) Marquesas Islands'
  },
  { id: 'Alaskan Standard Time', displayName: '(UTC-09:00) Alaska' },
  { id: 'UTC-09', displayName: '(UTC-09:00) Coordinated Universal Time-09' },
  {
    id: 'Pacific Standard Time (Mexico)',
    displayName: '(UTC-08:00) Baja California'
  },
  { id: 'UTC-08', displayName: '(UTC-08:00) Coordinated Universal Time-08' },
  {
    id: 'Pacific Standard Time',
    displayName: '(UTC-08:00) Pacific Time (US & Canada)'
  },
  { id: 'US Mountain Standard Time', displayName: '(UTC-07:00) Arizona' },
  {
    id: 'Mountain Standard Time (Mexico)',
    displayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan'
  },
  {
    id: 'Mountain Standard Time',
    displayName: '(UTC-07:00) Mountain Time (US & Canada)'
  },
  {
    id: 'Central America Standard Time',
    displayName: '(UTC-06:00) Central America'
  },
  {
    id: 'Central Standard Time',
    displayName: '(UTC-06:00) Central Time (US & Canada)'
  },
  {
    id: 'Easter Island Standard Time',
    displayName: '(UTC-06:00) Easter Island'
  },
  {
    id: 'Central Standard Time (Mexico)',
    displayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'
  },
  {
    id: 'Canada Central Standard Time',
    displayName: '(UTC-06:00) Saskatchewan'
  },
  {
    id: 'SA Pacific Standard Time',
    displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco'
  },
  { id: 'Eastern Standard Time (Mexico)', displayName: '(UTC-05:00) Chetumal' },
  {
    id: 'Eastern Standard Time',
    displayName: '(UTC-05:00) Eastern Time (US & Canada)'
  },
  { id: 'Haiti Standard Time', displayName: '(UTC-05:00) Haiti' },
  { id: 'Cuba Standard Time', displayName: '(UTC-05:00) Havana' },
  { id: 'US Eastern Standard Time', displayName: '(UTC-05:00) Indiana (East)' },
  {
    id: 'Turks And Caicos Standard Time',
    displayName: '(UTC-05:00) Turks and Caicos'
  },
  { id: 'Paraguay Standard Time', displayName: '(UTC-04:00) Asuncion' },
  {
    id: 'Atlantic Standard Time',
    displayName: '(UTC-04:00) Atlantic Time (Canada)'
  },
  { id: 'Venezuela Standard Time', displayName: '(UTC-04:00) Caracas' },
  { id: 'Central Brazilian Standard Time', displayName: '(UTC-04:00) Cuiaba' },
  {
    id: 'SA Western Standard Time',
    displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
  },
  { id: 'Pacific SA Standard Time', displayName: '(UTC-04:00) Santiago' },
  { id: 'Newfoundland Standard Time', displayName: '(UTC-03:30) Newfoundland' },
  { id: 'Tocantins Standard Time', displayName: '(UTC-03:00) Araguaina' },
  { id: 'E. South America Standard Time', displayName: '(UTC-03:00) Brasilia' },
  {
    id: 'SA Eastern Standard Time',
    displayName: '(UTC-03:00) Cayenne, Fortaleza'
  },
  {
    id: 'Argentina Standard Time',
    displayName: '(UTC-03:00) City of Buenos Aires'
  },
  { id: 'Greenland Standard Time', displayName: '(UTC-03:00) Greenland' },
  { id: 'Montevideo Standard Time', displayName: '(UTC-03:00) Montevideo' },
  { id: 'Magallanes Standard Time', displayName: '(UTC-03:00) Punta Arenas' },
  {
    id: 'Saint Pierre Standard Time',
    displayName: '(UTC-03:00) Saint Pierre and Miquelon'
  },
  { id: 'Bahia Standard Time', displayName: '(UTC-03:00) Salvador' },
  { id: 'UTC-02', displayName: '(UTC-02:00) Coordinated Universal Time-02' },
  {
    id: 'Mid-Atlantic Standard Time',
    displayName: '(UTC-02:00) Mid-Atlantic - Old'
  },
  { id: 'Azores Standard Time', displayName: '(UTC-01:00) Azores' },
  { id: 'Cape Verde Standard Time', displayName: '(UTC-01:00) Cabo Verde Is.' },
  { id: 'UTC', displayName: '(UTC) Coordinated Universal Time' },
  { id: 'Morocco Standard Time', displayName: '(UTC+00:00) Casablanca' },
  {
    id: 'GMT Standard Time',
    displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London'
  },
  {
    id: 'Greenwich Standard Time',
    displayName: '(UTC+00:00) Monrovia, Reykjavik'
  },
  {
    id: 'W. Europe Standard Time',
    displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  },
  {
    id: 'Central Europe Standard Time',
    displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  },
  {
    id: 'Romance Standard Time',
    displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'
  },
  { id: 'Sao Tome Standard Time', displayName: '(UTC+01:00) Sao Tome' },
  {
    id: 'Central European Standard Time',
    displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
  },
  {
    id: 'W. Central Africa Standard Time',
    displayName: '(UTC+01:00) West Central Africa'
  },
  { id: 'Jordan Standard Time', displayName: '(UTC+02:00) Amman' },
  { id: 'GTB Standard Time', displayName: '(UTC+02:00) Athens, Bucharest' },
  { id: 'Middle East Standard Time', displayName: '(UTC+02:00) Beirut' },
  { id: 'Egypt Standard Time', displayName: '(UTC+02:00) Cairo' },
  { id: 'E. Europe Standard Time', displayName: '(UTC+02:00) Chisinau' },
  { id: 'Syria Standard Time', displayName: '(UTC+02:00) Damascus' },
  { id: 'West Bank Standard Time', displayName: '(UTC+02:00) Gaza, Hebron' },
  {
    id: 'South Africa Standard Time',
    displayName: '(UTC+02:00) Harare, Pretoria'
  },
  {
    id: 'FLE Standard Time',
    displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
  },
  { id: 'Israel Standard Time', displayName: '(UTC+02:00) Jerusalem' },
  { id: 'Kaliningrad Standard Time', displayName: '(UTC+02:00) Kaliningrad' },
  { id: 'Sudan Standard Time', displayName: '(UTC+02:00) Khartoum' },
  { id: 'Libya Standard Time', displayName: '(UTC+02:00) Tripoli' },
  { id: 'Namibia Standard Time', displayName: '(UTC+02:00) Windhoek' },
  { id: 'Arabic Standard Time', displayName: '(UTC+03:00) Baghdad' },
  { id: 'Turkey Standard Time', displayName: '(UTC+03:00) Istanbul' },
  { id: 'Arab Standard Time', displayName: '(UTC+03:00) Kuwait, Riyadh' },
  { id: 'Belarus Standard Time', displayName: '(UTC+03:00) Minsk' },
  {
    id: 'Russian Standard Time',
    displayName: '(UTC+03:00) Moscow, St. Petersburg, Volgograd'
  },
  { id: 'E. Africa Standard Time', displayName: '(UTC+03:00) Nairobi' },
  { id: 'Iran Standard Time', displayName: '(UTC+03:30) Tehran' },
  { id: 'Arabian Standard Time', displayName: '(UTC+04:00) Abu Dhabi, Muscat' },
  {
    id: 'Astrakhan Standard Time',
    displayName: '(UTC+04:00) Astrakhan, Ulyanovsk'
  },
  { id: 'Azerbaijan Standard Time', displayName: '(UTC+04:00) Baku' },
  { id: 'Russia Time Zone 3', displayName: '(UTC+04:00) Izhevsk, Samara' },
  { id: 'Mauritius Standard Time', displayName: '(UTC+04:00) Port Louis' },
  { id: 'Saratov Standard Time', displayName: '(UTC+04:00) Saratov' },
  { id: 'Georgian Standard Time', displayName: '(UTC+04:00) Tbilisi' },
  { id: 'Caucasus Standard Time', displayName: '(UTC+04:00) Yerevan' },
  { id: 'Afghanistan Standard Time', displayName: '(UTC+04:30) Kabul' },
  {
    id: 'West Asia Standard Time',
    displayName: '(UTC+05:00) Ashgabat, Tashkent'
  },
  { id: 'Ekaterinburg Standard Time', displayName: '(UTC+05:00) Ekaterinburg' },
  {
    id: 'Pakistan Standard Time',
    displayName: '(UTC+05:00) Islamabad, Karachi'
  },
  {
    id: 'India Standard Time',
    displayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'
  },
  {
    id: 'Sri Lanka Standard Time',
    displayName: '(UTC+05:30) Sri Jayawardenepura'
  },
  { id: 'Nepal Standard Time', displayName: '(UTC+05:45) Kathmandu' },
  { id: 'Central Asia Standard Time', displayName: '(UTC+06:00) Astana' },
  { id: 'Bangladesh Standard Time', displayName: '(UTC+06:00) Dhaka' },
  { id: 'Omsk Standard Time', displayName: '(UTC+06:00) Omsk' },
  { id: 'Myanmar Standard Time', displayName: '(UTC+06:30) Yangon (Rangoon)' },
  {
    id: 'SE Asia Standard Time',
    displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta'
  },
  {
    id: 'Altai Standard Time',
    displayName: '(UTC+07:00) Barnaul, Gorno-Altaysk'
  },
  { id: 'W. Mongolia Standard Time', displayName: '(UTC+07:00) Hovd' },
  { id: 'North Asia Standard Time', displayName: '(UTC+07:00) Krasnoyarsk' },
  {
    id: 'N. Central Asia Standard Time',
    displayName: '(UTC+07:00) Novosibirsk'
  },
  { id: 'Tomsk Standard Time', displayName: '(UTC+07:00) Tomsk' },
  {
    id: 'China Standard Time',
    displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
  },
  { id: 'North Asia East Standard Time', displayName: '(UTC+08:00) Irkutsk' },
  {
    id: 'Singapore Standard Time',
    displayName: '(UTC+08:00) Kuala Lumpur, Singapore'
  },
  { id: 'W. Australia Standard Time', displayName: '(UTC+08:00) Perth' },
  { id: 'Taipei Standard Time', displayName: '(UTC+08:00) Taipei' },
  { id: 'Ulaanbaatar Standard Time', displayName: '(UTC+08:00) Ulaanbaatar' },
  { id: 'Aus Central W. Standard Time', displayName: '(UTC+08:45) Eucla' },
  { id: 'Transbaikal Standard Time', displayName: '(UTC+09:00) Chita' },
  {
    id: 'Tokyo Standard Time',
    displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo'
  },
  { id: 'North Korea Standard Time', displayName: '(UTC+09:00) Pyongyang' },
  { id: 'Korea Standard Time', displayName: '(UTC+09:00) Seoul' },
  { id: 'Yakutsk Standard Time', displayName: '(UTC+09:00) Yakutsk' },
  { id: 'Cen. Australia Standard Time', displayName: '(UTC+09:30) Adelaide' },
  { id: 'AUS Central Standard Time', displayName: '(UTC+09:30) Darwin' },
  { id: 'E. Australia Standard Time', displayName: '(UTC+10:00) Brisbane' },
  {
    id: 'AUS Eastern Standard Time',
    displayName: '(UTC+10:00) Canberra, Melbourne, Sydney'
  },
  {
    id: 'West Pacific Standard Time',
    displayName: '(UTC+10:00) Guam, Port Moresby'
  },
  { id: 'Tasmania Standard Time', displayName: '(UTC+10:00) Hobart' },
  { id: 'Vladivostok Standard Time', displayName: '(UTC+10:00) Vladivostok' },
  {
    id: 'Lord Howe Standard Time',
    displayName: '(UTC+10:30) Lord Howe Island'
  },
  {
    id: 'Bougainville Standard Time',
    displayName: '(UTC+11:00) Bougainville Island'
  },
  { id: 'Russia Time Zone 10', displayName: '(UTC+11:00) Chokurdakh' },
  { id: 'Magadan Standard Time', displayName: '(UTC+11:00) Magadan' },
  { id: 'Norfolk Standard Time', displayName: '(UTC+11:00) Norfolk Island' },
  { id: 'Sakhalin Standard Time', displayName: '(UTC+11:00) Sakhalin' },
  {
    id: 'Central Pacific Standard Time',
    displayName: '(UTC+11:00) Solomon Is., New Caledonia'
  },
  {
    id: 'Russia Time Zone 11',
    displayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky'
  },
  {
    id: 'New Zealand Standard Time',
    displayName: '(UTC+12:00) Auckland, Wellington'
  },
  { id: 'UTC+12', displayName: '(UTC+12:00) Coordinated Universal Time+12' },
  { id: 'Fiji Standard Time', displayName: '(UTC+12:00) Fiji' },
  {
    id: 'Kamchatka Standard Time',
    displayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'
  },
  {
    id: 'Chatham Islands Standard Time',
    displayName: '(UTC+12:45) Chatham Islands'
  },
  { id: 'UTC+13', displayName: '(UTC+13:00) Coordinated Universal Time+13' },
  { id: 'Tonga Standard Time', displayName: '(UTC+13:00) Nuku\'alofa' },
  { id: 'Samoa Standard Time', displayName: '(UTC+13:00) Samoa' },
  {
    id: 'Line Islands Standard Time',
    displayName: '(UTC+14:00) Kiritimati Island'
  }
];
