import AirdomeState from 'localization/base/enums/AirdomeState';
import { AxiosInstance } from 'axios';
import { Moment } from 'moment';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from './../../utils/Settings';
import stringFormat from 'string-format';

export interface AirdomeStateDto {
  airdomeId: number;
  lastContact?: Moment;
  state?: keyof AirdomeState;
}

export default class HeartbeatAirdomeService {
  private axios: AxiosInstance;
  private getAirdomeHeartbeatUrl: string;
  private getAllAirdomeHeartbeatsUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getAirdomeHeartbeatUrl = getJoinedEndpoint(x => x.heartbeat.baseUri, x => x.heartbeat.airdomes.getSingle);
    this.getAllAirdomeHeartbeatsUrl = getJoinedEndpoint(x => x.heartbeat.baseUri, x => x.heartbeat.airdomes.getAll);
  }

  public async getAllAirdomeStates(trackingId: string): Promise<AirdomeStateDto[]> {
    try {
      const response = await this.axios.get<AirdomeStateDto[]>(
        this.getAllAirdomeHeartbeatsUrl, { headers: { trackingId } });

      return response.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }

  public async getStateOfAirdome(airdomeId: number, trackingId: string): Promise<AirdomeStateDto> {
    try {
      const url = stringFormat(this.getAirdomeHeartbeatUrl, airdomeId.toString());
      const response = await this.axios.get<AirdomeStateDto>(url, { headers: { trackingId } });

      return response.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }
}
