import Temperature from '../../../../base/forms/airdome/restrictions/Temperature';

const temperature: Temperature = {
  header: 'Restrict range of temperature settings for air dome',
  comfortTemperatureMin: 'Minimal comfort temperature (°C)',
  comfortTemperatureMax: 'Maximal comfort temperature (°C)',
  cutbackTemperatureMin: 'Minimal cutback temperature (°C)',
  cutbackTemperatureMax: 'Maximal cutback temperature (°C)'
};

export default temperature;
