import Create from '../../../base/errors/airdome/Create';

const create: Create = {
  GeneralError:
    'Error occurred while processing the request, please try again. If the problem persists contact Calypso group with error code <{trackingId}>.',
  AirdomeAlreadyPresent: 'Air dome with same name already exists.',
  MacAddressAlreadyPresent: 'MAC address is already assigned to another air dome.'
};

export default create;
