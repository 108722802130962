import ObservedNotifications from '../../../base/pages/status/ObservedNotifications';

const observedNotifications: ObservedNotifications = {
  level: {
    info: 'Information:',
    danger: 'Vorsicht!',
    warning: 'Achtung!'
  },
  internetOutage: 'Häufige Internetausfälle.',
  wrongTemplate: 'Falsche Vorlage.',
  plcConnectivity: 'Häufige SPS-Verbindungsausfälle.'
};

export default observedNotifications;
