import React from 'react';
import AlertComponent from '../../shared/components/Alert';
import { ObservedNotificationsState } from '../../stores/NotificationStore';
import Translate from '../../localization/Translate';

interface ObservedNotificationsProps {
  translate: Translate;
  observedNotifications: ObservedNotificationsState;
}

const AlertText = React.memo((props: { alertLevel: string, alertContent: string }) =>
  <p><b>{props.alertLevel}</b>{props.alertContent}</p>);

const Alert = React.memo((props: { color: 'info' | 'warning' | 'danger', alertLevel: string, alertContent: string }) =>
  <div className="col-md-12 col-lg-6 col-xl-4 d-inline-block p-2">
    <AlertComponent className="alert--bordered" color={props.color} icon={true}>
      <AlertText {...props} />
    </AlertComponent>
  </div>);

const InternetOutageAlert = React.memo((props: ObservedNotificationsProps) =>
  props.observedNotifications.internetOutage
    ? <Alert color="info"
      alertLevel={props.translate(x => x.pages.status.observedNotifications.level.info)}
      alertContent={props.translate(x => x.pages.status.observedNotifications.internetOutage)}
    />
    : null);

const WrongTemplateAlert = React.memo((props: ObservedNotificationsProps) =>
  props.observedNotifications.wrongTemplate
    ? <Alert color="danger"
      alertLevel={props.translate(x => x.pages.status.observedNotifications.level.danger)}
      alertContent={props.translate(x => x.pages.status.observedNotifications.wrongTemplate)}
    />
    : null);

const PLCConnectivityAlert = React.memo((props: ObservedNotificationsProps) =>
  props.observedNotifications.plcConnectivity
    ? <Alert color="warning"
      alertLevel={props.translate(x => x.pages.status.observedNotifications.level.warning)}
      alertContent={props.translate(x => x.pages.status.observedNotifications.plcConnectivity)}
    />
    : null);

const ObservedNotifications = (props: ObservedNotificationsProps) => <>
  <InternetOutageAlert {...props} />
  <WrongTemplateAlert {...props} />
  <PLCConnectivityAlert {...props} />
</>;

export default React.memo(ObservedNotifications);
