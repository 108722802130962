import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import React from 'react';
import Translate from '../../../../localization/Translate';

interface ConfirmPasswordFieldProps {
  translate: Translate;
  confirmPassword?: string;
  setConfirmPassword: (password?: string) => void;
  arePasswordsDifferent: boolean;
}

const ConfirmPasswordField =
  ({ translate, confirmPassword, setConfirmPassword, arePasswordsDifferent }: ConfirmPasswordFieldProps) =>
    <FormInput {...{
      translate,
      errors: arePasswordsDifferent
        ? [translate(x => x.general.user.passwordsNotSame)]
        : undefined,
      model: new FormInputModel({
        value: confirmPassword,
        handleValueModel: new HandleValueModel({
          setValue: setConfirmPassword,
          inputType: 'password'
        }),
        placeholder: translate(x => x.general.user.password),
        label: translate(x => x.general.user.passwordAgain),
        inputId: 'ConfirmPassword'
      })
    }} />;

export default React.memo(ConfirmPasswordField);
