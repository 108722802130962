import { BaseSchema } from 'yup';
import { HandleValueModel } from './HandleValueModel';

export default class FormInputModel {
  public handleValueModel: HandleValueModel;

  public validationSchema?: BaseSchema<any> = undefined;
  public inputId: string;
  public label?: string;
  public placeholder?: string = undefined;
  public minLength?: number = undefined;
  public maxLength?: number = undefined;
  public autoComplete?: string = undefined;
  public error?: string = undefined;
  public value?: string = undefined;
  public readonly: boolean;

  public constructor(model: {
    handleValueModel: HandleValueModel;
    inputId: string;
    label?: string;
    validationSchema?: BaseSchema<any>;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    autoComplete?: string;
    error?: string;
    value?: string;
    readonly?: boolean;
  }) {
    this.handleValueModel = model.handleValueModel;
    this.inputId = model.inputId;
    this.label = model.label;
    this.validationSchema = model.validationSchema;
    this.placeholder = model.placeholder;
    this.maxLength = model.maxLength;
    this.minLength = model.minLength;
    this.autoComplete = model.autoComplete;
    this.error = model.error;
    this.value = model.value;
    this.readonly = !!model.readonly;
  }
}
