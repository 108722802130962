import BaseTemperatureField from '../BaseTemperatureField';
import React from 'react';
import TemperatureProps from '../../TemperatureProps';
import { observer } from 'mobx-react';

const MaximumValueField = ({ translate, model, readonly }: TemperatureProps) => <BaseTemperatureField {...{
  readonly,
  translate,
  numberValidationSchema: model.comfortTemperatureMaxSchema.label(
    translate(x => x.forms.airdome.restrictions.temperature.comfortTemperatureMax)
  ),
  inputId: 'UpperBound_TemperatureComfort_Field',
  label: translate(x => x.forms.airdome.restrictions.temperature.comfortTemperatureMax),
  value: model.comfortTemperatureMax.Value,
  setValue: x => model.comfortTemperatureMax.setValue(x),
  isEnabled: model.isComfortTemperatureMaxEnabled.Value ?? false,
  setIsEnabled: x => model.isComfortTemperatureMaxEnabled.setValue(x)
}} />;

export default observer(MaximumValueField);
