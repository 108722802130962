import React from 'react';
import { FormSelect } from '../../../..';
import { SelectOptionModel } from '../../../../../models';
import { TemplateDto } from '../../../../../dto/Container';
import Translate from '../../../../../localization/Translate';

interface CountryFieldProps {
  translate: Translate;
  setTemplate: (id: string) => void;
  templates: SelectOptionModel[];
  currentTemplate?: TemplateDto;
}

const CountryField = ({ translate, setTemplate, templates, currentTemplate }: CountryFieldProps) =>
  <FormSelect {...{
    label: translate(x => x.general.airdome.template),
    options: templates,
    selectId: 'AirdomeTemplate',
    setValue: setTemplate,
    selectedValue: currentTemplate &&
      currentTemplate.id.toString()
  }} />;

export default React.memo(CountryField);
