import Intervals from '../../../../base/forms/airdome/intervals';

const intervals: Intervals = {
  statusInterval: 'Statusintervall',
  plcReadingInterval: 'PLC leseintervall',
  aiInterval: 'AI intervall',
  consumptionsInterval: 'Verbrauchsintervall'
};

export default intervals;
