import ChangeRequest from '../../base/notifications/ChangeRequest';

const changeRequest: ChangeRequest = {
  created: {
    title: '{airdomeName}',
    message: 'Požadavek od {author} na změnu {type} byl vytvořen.'
  },
  finished: {
    title: '{airdomeName}',
    message: 'Požadavek od {author} na změnu {type} byl dokončen.'
  },
  failed: {
    title: '{airdomeName}',
    message: 'Požadavek od {author} na změnu {type} selhal.'
  }
};

export default changeRequest;
