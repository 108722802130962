import './UserAccessForm.scss';

import * as React from 'react';

import { Card, CardBody, Col, Fade } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import AccessDefinitions from 'security/AccessDefinitions';
import AddUserField from './fields/AddUserField';
import AwaitingDataSourceComponent from '../../basic/AwaitingDataSourceComponent';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import MaterialTable from '../../basic/material-table/MaterialTable';
import { TableColumn } from '../../basic/material-table/table-header';
import Translate from '../../../localization/Translate';
import UserAccessFormProps from './UserAccessFormProps';
import { UserWithRoleDto } from '../../../dto/Container';

@inject(({ settingsStore, userStore, securityStore }) => ({ settingsStore, userStore, securityStore }))
@observer
export default class UserAccessForm extends AwaitingDataSourceComponent<UserAccessFormProps> {
  private roleColors: { [key: string]: string } = {
    admin: '#ff5757',
    electrician: '#fff757',
    airdome_admin: '#576dff',
    advanced: '#ff9d57',
    basic: '#94ff57'
  };

  protected fetchData = async () => {
    const { settingsStore, userStore, securityStore } = this.props;
    
    if(securityStore!.isAccessAllowed(AccessDefinitions.Settings.AirdomeUsersManage))
      await Promise.all([
        settingsStore!.fetchCurrentAirdomeUserAccess(),
        userStore!.fetchUsers()
      ]);
    else 
      await settingsStore!.fetchCurrentAirdomeUserAccess();
  }

  protected clearModel = () =>
    this.props.settingsStore!.usersWithAccessModel.addUserAccessModel.clear()

  protected renderContent(): React.ReactNode {
    const { readonly, translate, onRemove, onAdd, users, model, error } = this.props;

    return <>
      {error}
      <form className="userAccessForm">
        <MaterialTable<UserWithRoleDto>
          {...{
            columns: this.getColumns(translate, onRemove, readonly),
            selected: [],
            showPagination: false,
            multiselect: false,
            data: users,
            clickRowToSelect: true,
            showCheckboxColumn: false,
            rowsPerPageOptions: [10, 25, 30, 50],
            handleSelectionChanged: () => { }
          }}
        />
        {
          !readonly &&
          <AddUserField {...{
            readonly,
            translate,
            model,
            submit: async x => onAdd(x)
          }} />
        }
      </form>
    </>;
  }

  public render() {
    const { translate } = this.props;

    return <Col sm={6}>
      <Fade>
        <Card>
          <CardBody>
            <h3>{translate(x => x.pages.settings.usersWithAccess)}</h3>
            {super.render()}
          </CardBody>
        </Card>
      </Fade>
    </Col>;
  }

  private renderRoleCell = (x: UserWithRoleDto, translate: Translate) => <div>
    <p className="accessCell" style={{ backgroundColor: this.roleColors[x.role] }}>
      {translate(tr => tr.enums.user.roles[x.role.toLowerCase()])}
    </p>
  </div>

  private getColumns = (translate: Translate, onRemove: (id: string) => Promise<void>, readonly: boolean)
    : TableColumn<UserWithRoleDto>[] => {
    const columns: TableColumn<UserWithRoleDto>[] = [
      {
        label: '#',
        id: 'index',
        disablePadding: true,
        width: 8,
        getValue: (item, index) => ((index || 0) + 1).toString()
      },
      {
        label: translate(x => x.general.user.username),
        id: 'username',
        disablePadding: true,
        getValue: (x: UserWithRoleDto) => x.username,
        width: 20
      },
      {
        renderCell: (x: UserWithRoleDto) => this.renderRoleCell(x, translate),
        label: translate(x => x.general.user.role),
        id: 'role',
        disablePadding: true,
        getValue: (x: UserWithRoleDto) => x.role,
        width: 72
      }
    ];

    if (!readonly)
      columns.push({
        renderCell: (x: UserWithRoleDto) =>
          <div className="clickable" onClick={async () => onRemove(x.id)}>
            <CloseCircleIcon />
          </div>,
        label: '',
        id: 'controls',
        disablePadding: true,
        getValue: () => '',
        width: 72
      });

    return columns;
  }
}
