import * as validations from '../../validations/validations';

import { action, computed, observable } from 'mobx';

import InputModel from 'models/InputModel';
import { NumberSchema } from 'yup';
import PressureRestrictionsDto from '../../dto/settings/PressureRestrictionsDto';

const defaultMinPressure = 0;
const defaultMaxPressure = 350;

export default class PressureRestrictionsModel {
  public get normalPressureMinSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      min: defaultMinPressure,
      max: this.normalPressureMax.Value ?? defaultMaxPressure,
      required: true,
      strict: true
    });
  }

  public get normalPressureMaxSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      min: this.normalPressureMin.Value ?? defaultMinPressure,
      max: defaultMaxPressure,
      required: true,
      strict: true
    });
  }

  public get maximalPressureMinSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      min: defaultMinPressure,
      max: this.maximalPressureMax.Value ?? defaultMaxPressure,
      required: true,
      strict: true
    });
  }

  public get maximalPressureMaxSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      min: this.maximalPressureMin.Value ?? defaultMinPressure,
      max: defaultMaxPressure,
      required: true,
      strict: true
    });
  }

  private originalValues?: PressureRestrictionsDto;

  @observable public normalPressureMin = new InputModel<number>();
  @observable public normalPressureMax = new InputModel<number>();
  @observable public maximalPressureMin = new InputModel<number>();
  @observable public maximalPressureMax = new InputModel<number>();

  @action public clear(): void {
    this.normalPressureMin.clear();
    this.normalPressureMax.clear();
    this.maximalPressureMin.clear();
    this.maximalPressureMax.clear();
  }

  @action public resetToOriginalValues(): void {
    this.clear();
    if (this.originalValues)
      this.importFromDto(this.originalValues);
  }

  public importFromDto = (dto: PressureRestrictionsDto) => {
    this.originalValues = { ...dto };

    this.normalPressureMin.setValue(dto.normalPressureMin, false);
    this.normalPressureMax.setValue(dto.normalPressureMax, false);
    this.maximalPressureMin.setValue(dto.maximalPressureMin, false);
    this.maximalPressureMax.setValue(dto.maximalPressureMax, false);
  }

  public getDto = (): PressureRestrictionsDto | undefined =>
    this.areDataValid
      ? {
        normalPressureMin: this.normalPressureMin.Value!,
        normalPressureMax: this.normalPressureMax.Value!,
        maximalPressureMin: this.maximalPressureMin.Value!,
        maximalPressureMax: this.maximalPressureMax.Value!
      }
      : undefined

  @computed public get anyValueChanged(): boolean {
    return this.normalPressureMin.ValueChanged
      || this.normalPressureMax.ValueChanged
      || this.maximalPressureMin.ValueChanged
      || this.maximalPressureMax.ValueChanged;
  }

  @computed public get areDataValid(): boolean {
    return (
      this.anyValueChanged &&
      this.normalPressureMinSchema.isValidSync(this.normalPressureMin.Value) &&
      this.normalPressureMaxSchema.isValidSync(this.normalPressureMax.Value) &&
      this.maximalPressureMinSchema.isValidSync(this.maximalPressureMin.Value) &&
      this.maximalPressureMaxSchema.isValidSync(this.maximalPressureMax.Value)
    );
  }
}
