import { Card, CardBody, Col, Row } from "reactstrap";

interface Props {
  title: string;
}

const HeaderCard = ({ title }: Props) => (
  <Card>
    <CardBody>
      <Row>
        <Col>
          <h1>
            {title}
          </h1>
        </Col>
      </Row>
    </CardBody>
  </Card>
)

export default HeaderCard;