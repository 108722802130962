import RDevNotifications from '../../../base/pages/rdevNotifications';
import message from './Message';
import type from './Type';

const rdevNotifications: RDevNotifications = {
  header: 'RDev Notifizierung',
  timestampHeader: 'Aufgetreten',
  typeHeader: 'Benachrichtigungstyp',
  messageHeader: 'Nachricht',
  
  type,
  message
};

export default rdevNotifications;
