import PersistedHubConnection from './base/PersistedHubConnection';
import { SecurityStore } from 'stores';
import { getJoinedEndpoint } from '../../utils/Settings';

type NotificationsChannelMethods = '';

type NotificationsChannelEvents = 'NotificationChannelUpdateTimedOut';

export default class PlcNotificationsHubConnection
  extends PersistedHubConnection<NotificationsChannelEvents, NotificationsChannelMethods> {

  constructor(securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.notificationsChannel
    ), securityStore);
  }

  public onNotificationChannelUpdateTimedOut = (callback: (userId: string) => void) => {
    super.on('NotificationChannelUpdateTimedOut', callback);
  }
}
