export default class IntervalsDto {
  public statusInterval: number;
  public plcReadingInterval: number;
  public artificialIntelligenceInterval: number;
  public consumptionsInterval: number;

  constructor(dto: {
    statusInterval: number,
    plcReadingInterval: number,
    artificialIntelligenceInterval: number,
    consumptionsInterval: number
  }) {
    this.statusInterval = dto.statusInterval;
    this.plcReadingInterval = dto.plcReadingInterval;
    this.artificialIntelligenceInterval = dto.artificialIntelligenceInterval;
    this.consumptionsInterval = dto.consumptionsInterval;
  }
}
