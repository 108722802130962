import { AirdomeStore, SecurityStore } from 'stores';

import AirdomeHubConnection from './base/AirdomeHubConnection';
import DoorLockSettingsSignalRDto from './dto/DoorLockSettingsSignalRDto';
import LightsSignalRDto from './dto/LightsSignalRDto';
import PreheatingSignalRDto from './dto/PreheatingSettingsSignalRDto';
import SchedulesDto from './dto/SchedulesDto';
import SettingsSignalRDto from './dto/SettingsSignalRDto';
import { getJoinedEndpoint } from '../../utils/Settings';
import moment from 'moment';

type SettingsEvents = 'SettingsUpdated' | 'SchedulesUpdated' | 'DoorLockSettingsUpdated' | 'LightsUpdated' | 'PreheatingSettingsUpdated';
export default class SettingsHubConnection extends AirdomeHubConnection<SettingsEvents, ''> {
  constructor(airdomeStore: AirdomeStore, securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.airdome.settings
    ), airdomeStore, securityStore);
  }

  public onSettingsUpdated = (callback: (settings: SettingsSignalRDto) => void) =>
    super.on('SettingsUpdated', settings =>
      callback({
        ...settings,
        timestamp: moment.utc(settings.timestamp)
      })
    );
  
  public onSchedulesUpdated = (callback: (schedules: SchedulesDto) => void) =>
    super.on('SchedulesUpdated', schedules =>
      callback({
        ...schedules,
        timestamp: moment.utc(schedules.timestamp)
      })
    );

  public onDoorLockSettingsUpdated = (callback: (settings: DoorLockSettingsSignalRDto) => void) =>
    super.on('DoorLockSettingsUpdated', settings =>
      callback({
        ...settings,
        timestamp: moment.utc(settings.timestamp)
      })
    );

  public onPreheatingSettingsUpdated = (callback: (settings: PreheatingSignalRDto) => void) =>
    super.on('PreheatingSettingsUpdated', settings =>
      callback({
        ...settings,
        timestamp: moment.utc(settings.timestamp)
    })
  );
  
  public onLightsUpdated = (callback: (lights: LightsSignalRDto) => void) =>
    super.on('LightsUpdated', callback);
}