import { AirdomeModel } from 'models';
import ConditionalRendering from '../../../security/ConditionalRendering';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';
import Translate from '../../../localization/Translate';
import { observer } from 'mobx-react';

export interface SidebarAirdomeMenuProps {
  selectedAirdome: AirdomeModel;
  onClick: () => void;
  translate: Translate;
}

const SidebarAirdomeMenu = ({ selectedAirdome, onClick, translate: tr }: SidebarAirdomeMenuProps) => (
  <>
    <ConditionalRendering getAccessDefinition={d => d.Status.Read}>
      <SidebarLink
        title={tr(x => x.menu.airdome.status)}
        icon="chart-bars"
        route={`/airdome/${selectedAirdome.id}/status`}
        onClick={onClick} />
    </ConditionalRendering>

    <ConditionalRendering getAccessDefinition={d => d.Settings.Read}>
      <SidebarCategory
        navLinkProps={{
          title: tr(x => x.menu.airdome.settings.categoryName),
          route: `/airdome/${selectedAirdome.id}/settings`,
          icon: 'cog',
          onClick
        }}>
        <SidebarLink
          title={tr(x => x.menu.airdome.history)}
          route={`/airdome/${selectedAirdome.id}/settings/history`}
          onClick={onClick} />
      </SidebarCategory>
    </ConditionalRendering>

    <ConditionalRendering getAccessDefinition={d => d.Restrictions.Read}>
      <SidebarLink
        title={tr(x => x.menu.airdome.restrictions)}
        icon="hand"
        route={`/airdome/${selectedAirdome.id}/restrictions`}
        onClick={onClick} />
    </ConditionalRendering>

    {selectedAirdome.hasLighting
      ? <ConditionalRendering getAccessDefinition={d => d.Lights.FullAccess}>
        <SidebarCategory
          navLinkProps={{
            title: tr(x => x.menu.airdome.lighting.categoryName),
            route: `/airdome/${selectedAirdome.id}/lighting`,
            icon: 'sun',
            onClick
          }}>
          <SidebarLink
            title={tr(x => x.menu.airdome.history)}
            route={`/airdome/${selectedAirdome.id}/lighting/history`}
            onClick={onClick} />
        </SidebarCategory>
      </ConditionalRendering>
      : null}

    {selectedAirdome.hasPreheating
      ? <ConditionalRendering getAccessDefinition={d => d.PreheatingSettings.FullAccess}>
        <SidebarCategory
            navLinkProps={{
              title: tr(x => x.menu.airdome.preheatingSettings),
              icon: 'lnr lnr-lighter',
              route: `/airdome/${selectedAirdome.id}/preheating`,
              onClick
            }}>
          <SidebarLink
            title={tr(x => x.menu.airdome.history)}
            route={`/airdome/${selectedAirdome.id}/preheating/history`}
            onClick={onClick}/>
          </SidebarCategory>
        </ConditionalRendering>
      : null}
   
    {selectedAirdome.hasDoorLocking
      ? <ConditionalRendering getAccessDefinition={d => d.DoorLocking.FullAccess}>
        <SidebarCategory
          navLinkProps={{
            title: tr(x => x.menu.airdome.doorLockSettings),
            icon: "enter",
            route: `/airdome/${selectedAirdome.id}/door-locking`,
            onClick
          }}>
          <SidebarLink
            title={tr(x => x.menu.airdome.history)}
            route={`/airdome/${selectedAirdome.id}/door-locking/history`}
            onClick={onClick} />
        </SidebarCategory>
      </ConditionalRendering>
      : null}

    <ConditionalRendering getAccessDefinition={d => d.Notifications.Plc.Read}>
      <SidebarCategory
        navLinkProps={{
          title: tr(x => x.menu.airdome.plcNotifications.categoryName),
          icon: "warning",
          route: `/airdome/${selectedAirdome.id}/notifications/plc`,
          onClick
        }}>
        <SidebarLink
          title={tr(x => x.menu.airdome.history)}
          route={`/airdome/${selectedAirdome.id}/notifications/plc/history`}
          onClick={onClick} />
      </SidebarCategory>
    </ConditionalRendering>

    <ConditionalRendering getAccessDefinition={d => d.Notifications.RDev}>
      <SidebarLink
        title={tr(x => x.menu.airdome.rdevNotifications)}
        icon="alarm"
        route={`/airdome/${selectedAirdome.id}/notifications/rdev`}
        onClick={onClick} />
    </ConditionalRendering>

    <ConditionalRendering getAccessDefinition={d => d.Schedule.FullAccess}>
      <SidebarLink
        title={tr(x => x.menu.airdome.calendar)}
        icon="calendar-full"
        route={`/airdome/${selectedAirdome.id}/calendar`}
        onClick={onClick} />
    </ConditionalRendering>

    <ConditionalRendering getAccessDefinition={d => d.Bacnet.FullAccess}>
      <SidebarCategory
        navLinkProps={{
          title: tr(x => x.menu.airdome.bacnetObjects.categoryName),
          icon: "rocket",
          route: `/airdome/${selectedAirdome.id}/bacnetObjects/read`,
          onClick
        }}>
        <SidebarLink
          title={tr(x => x.menu.airdome.bacnetObjects.view)}
          route={`/airdome/${selectedAirdome.id}/bacnetObjects/read`}
          onClick={onClick} />
        <SidebarLink
          title={tr(x => x.menu.airdome.bacnetObjects.edit)}
          route={`/airdome/${selectedAirdome.id}/bacnetObjects/edit`}
          onClick={onClick} />
      </SidebarCategory>
    </ConditionalRendering>

    {selectedAirdome.hasElectricityConsumption || selectedAirdome.hasGasConsumption
      ? <ConditionalRendering getAccessDefinition={d => d.Consumptions.Read}>
        <SidebarLink
          title={tr(x => x.menu.airdome.consumption)}
          icon="leaf"
          route={`/airdome/${selectedAirdome.id}/consumption`}
          onClick={onClick} />
      </ConditionalRendering>
      : null}

    <ConditionalRendering getAccessDefinition={d => d.SwitchBox.Read}>
      <SidebarLink title={tr(x => x.menu.airdome.switchbox)} icon="tablet" route={`/airdome/${selectedAirdome.id}/switchbox`} onClick={onClick} />
    </ConditionalRendering>
  </>
);

export default observer(SidebarAirdomeMenu);
