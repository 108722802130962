import React, { PureComponent } from 'react';

interface ToggleButtonFieldProps {
  onChange?: (value: boolean) => void;
  name: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  value: boolean;
}

export default class ToggleButtonField extends PureComponent<ToggleButtonFieldProps> {
  public render() {
    const {
      name, disabled, value, onChange, className
    } = this.props;

    return (
      <div className={`toggle-btn ${className || ''}`}>
        <input
          className="toggle-btn__input"
          type="checkbox"
          name={name}
          onChange={event => onChange && onChange(event.target.value === 'true')}
          checked={value}
          disabled={disabled}
          readOnly={disabled}
        />
        <button
          disabled={disabled}
          className="toggle-btn__input-label d-inline-block"
          onClick={() => onChange && onChange(!value)}
        >Toggle
        </button>
      </div>
    );
  }
}
