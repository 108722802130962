import Airdome from '../../../base/notifications/airdome';

const airdome: Airdome = {
  stateChanged: {
    title: 'Notification',
    messageFormat: {
      alive: 'Air dome {airdomeName} is active',
      halfdead: 'Air dome {airdomeName} not responding',
      dead: 'Air dome {airdomeName} is not active',
      plcunavailable: 'PLC from air dome {airdomeName} is unavailable',
      notinflated: 'Air dome {airdomeName} is not inflated'
    }
  },
  created: {
    title: '{airdomeName}',
    message: 'Air dome has been created'
  }
};

export default airdome;
