import { AxiosInstance } from 'axios';
import ConsumptionDto from '../../dto/status/ConsumptionDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from './../../utils/Settings';
import moment from 'moment';
import stringFormat from 'string-format';

interface ConsumptionApiResponse {
  airdomeId: number;
  gas: number;
  electricity: number;
  trackingId: string;
}

export default class ConsumptionService {
  private axios: AxiosInstance;
  private getConsumptionIntervalUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getConsumptionIntervalUrl = getJoinedEndpoint(
      x => x.status.baseUri,
      x => x.status.consumptions.getSummary);
  }

  public async getConsumptionWithinInterval(
    airdomeId: number,
    trackingId: string,
    startDate: moment.Moment,
    endDate: moment.Moment): Promise<ConsumptionDto> {
    try {
      const total = await this.getConsumptionsSummaryFromApi(airdomeId, trackingId);
      const interval = await this.getConsumptionsSummaryFromApi(airdomeId, trackingId, startDate, endDate);

      return new ConsumptionDto({
        airdomeId,
        trackingId,
        gasSelection: interval ? interval.gas : undefined,
        gasTotal: total ? total.gas : undefined,
        electricitySelection: interval ? interval.electricity : undefined,
        electricityTotal: total ? total.electricity : undefined,
      });

    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }

  private async getConsumptionsSummaryFromApi(
    airdomeId: number, 
    trackingId: string,
    startDate?: moment.Moment, 
    endDate?: moment.Moment
  ): Promise<ConsumptionApiResponse | undefined> {

    const url = stringFormat(
      this.getConsumptionIntervalUrl,
      airdomeId.toString()
    );

    const response = await this.axios.get<ConsumptionApiResponse>(
      url, 
      { 
        headers: { 
          trackingId 
        }, 
        params: { 
          start: startDate?.toISOString(),
          end: endDate?.toISOString()
        } 
      });
      
    return response.data;
  }
}
