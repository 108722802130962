import { DoorLockState, PressureMode, TemperatureMode } from 'dto/settings/enums';

import Enums from '../../base/enums';
import airdomeState from './AirdomeState';
import changeRequest from './ChangeRequest';
import lightState from './LightState';
import logLevel from './LogLevel';
import notificationChannel from './NotificationChannel';
import user from './user';

const enums: Enums = {
  user,
  notificationChannel,
  airdomeState,
  temperatureModes : {
    [TemperatureMode.Auto]: 'Auto',
    [TemperatureMode.Comfort]: 'Comfort',
    [TemperatureMode.Cutback]: 'Cutback',    
    disabled: 'Disabled'
  },
  pressureModes: {
    [PressureMode.Maximum]: 'Maximal',
    [PressureMode.Normal]: 'Normal',
  },
  logLevel,
  lightState,
  changeRequest,
  doorLockState: {
    [DoorLockState.Auto]: {
      short: 'Auto',
      full: 'Auto'
    },
    [DoorLockState.Unlocked]: {
      short: 'Unl.',
      full: 'Unlocked'
    },
    [DoorLockState.Locked]: {
      short: 'Loc.',
      full: 'Locked'
    }
  }
};

export default enums;
