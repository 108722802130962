import * as validations from '../validations/validations';

import { AirdomeService, ChangeRequestHubConnection, SettingsHubConnection, UINotificationService, UserService } from '../services';
import BaseStore, { Params } from './base/BaseStore';
import { action, computed, observable, reaction } from 'mobx';

import AirdomeStore from './AirdomeStore';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';
import ErrorStore from './errors/ErrorStore';
import FormNewChangesModel from '../models/FormNewChangesModel';
import InputModel from 'models/InputModel';
import { NumberSchema } from 'yup';
import PreheatingService from 'services/settings/PreheatingSettingsService';
import PreheatingSettingsHistoryModel from 'models/preheatingsettings/PreheatingSettingsHistoryModel';
import PreheatingSettingsSignalRDto from 'services/signalr/dto/PreheatingSettingsSignalRDto';
import { asTracked } from './decorators/TrackedDecorator';
import { catchError } from './decorators';

export interface StoreParams {
  preheatingSettingsService: PreheatingService;
  airdomeStore: AirdomeStore;
  airdomeService: AirdomeService;
  errorStore: ErrorStore;
  userService: UserService;
  settingsHubConnection: SettingsHubConnection;
  changeRequestHubConnection: ChangeRequestHubConnection;
  uiNotificationService: UINotificationService;
  getTrackingId: () => string;
}
export default class PreheatingSettingsStore extends BaseStore<StoreParams> {
  @observable
  public isPreheatingEnabledModel = new InputModel<boolean>();

  @observable
  public maximalPreheatingMinutes = new InputModel<number>();
  
  @observable
  public minimumPreheatingMinutes = new InputModel<number>();

  @observable
  public preheatingSettingsHistoryModel: PreheatingSettingsHistoryModel;

  @observable
  public newChangesModel: FormNewChangesModel<PreheatingSettingsSignalRDto>;

  constructor(params: Params<StoreParams>) {
    super(params);

    this.newChangesModel = new FormNewChangesModel((preheatingSettings) => this.setPreheating(preheatingSettings));
    this.preheatingSettingsHistoryModel = new PreheatingSettingsHistoryModel(params);

    reaction(
      () => params.airdomeStore.SelectedAirdome,
      airdome => {
        this.clear();
        if (!airdome)
          return;

        if (airdome.hasPreheating)
          this.fetchPreheatingSettings()
      });

    params.settingsHubConnection.onPreheatingSettingsUpdated(preheatingSettings => {
      this.newChangesModel.updateForm(preheatingSettings, !preheatingSettings.userId, this.hasAnyChanges);
    });

    params.changeRequestHubConnection.onFailed((airdomeId, _, chRType) => {
      const selectedAirdomeId = params.airdomeStore.SelectedAirdomeId;

      if (!selectedAirdomeId || selectedAirdomeId !== airdomeId)
        return;

      if(chRType === ChangeRequestTypeEnum.PreheatingSettings)
        this.newChangesModel.isUpdateExecutor = false;
    });
  }

  @catchError
  public fetchPreheatingSettings = asTracked(action(async () => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    if (!airdomeId)
      return;

    const trackingId = this.params.getTrackingId();
    const preheating = await this.params.preheatingSettingsService.fetchPreheatingSettings(airdomeId, trackingId);
    this.isPreheatingEnabledModel.setValue(preheating.isPreheatingEnabled, false);
    this.maximalPreheatingMinutes.setValue(Math.round(preheating.maximalPreheatingMinutes * 10) / 10, false);
    this.minimumPreheatingMinutes.setValue(Math.round(preheating.minimumPreheatingMinutes * 10) / 10, false);
  }));

  @catchError
  public updatePreheatingSettings = asTracked(action(async () => {
    const trackingId = this.params.getTrackingId();
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    if (!airdomeId 
      || this.isPreheatingEnabledModel.Value === undefined
      || this.maximalPreheatingMinutes.Value === undefined
      || this.minimumPreheatingMinutes.Value === undefined)
      return;

    await this.params.preheatingSettingsService.updatePreheatingSettings({
      isPreheatingEnabled: this.isPreheatingEnabledModel.Value,
      maximalPreheatingMinutes: this.maximalPreheatingMinutes.Value,
      minimumPreheatingMinutes: this.minimumPreheatingMinutes.Value
    }, airdomeId, trackingId);

    this.newChangesModel.isUpdateExecutor = true;
  }));

  public clear = asTracked(action(() => {
    this.isPreheatingEnabledModel.clear();
    this.maximalPreheatingMinutes.clear();
    this.minimumPreheatingMinutes.clear();
  }));

  public setPreheating = asTracked(action((preheatingSettings: PreheatingSettingsSignalRDto) => {
      this.isPreheatingEnabledModel.setValue(preheatingSettings.isPreheatingEnabled, false);
      this.maximalPreheatingMinutes.setValue(Math.round(preheatingSettings.maximalPreheatingMinutes * 10) / 10, false);
      this.minimumPreheatingMinutes.setValue(Math.round(preheatingSettings.minimumPreheatingMinutes * 10) / 10, false);
  }));

  public get maximalPreheatingMinutesValidationSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: true,
      min: this.minimumPreheatingMinutes.Value
    });
  }
  
  public get minimalPreheatingMinutesValidationSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: true,
      min: 0,
      max: this.maximalPreheatingMinutes.Value
    });
  }

  @computed
  public get hasAnyChanges(): boolean {
    return this.isPreheatingEnabledModel.ValueChanged 
      || this.maximalPreheatingMinutes.ValueChanged
      || this.maximalPreheatingMinutes.ValueChanged
  }
}