import * as uuid from 'uuid';

import AirdomeStore from 'stores/AirdomeStore';
import BaseHubConnection from './BaseHubConnection';
import SecurityStore from 'stores/SecurityStore';
import format from 'string-format';
import { reaction } from 'mobx';

export default
  class AirdomeHubConnection<TEvents extends string, TMethods extends string> extends BaseHubConnection<TEvents, TMethods> {
  constructor(connectionUrl: string, airdomeStore?: AirdomeStore, securityStore?: SecurityStore) {
    super();

    reaction(
      () => ({
        airdomeId: airdomeStore?.SelectedAirdomeId,
        accessToken: securityStore?.AccessToken
      }),
      async ({ airdomeId, accessToken }) => {
        if (this.connection) {
          await this.connection.stop();
          this.connection = undefined;
        }

        if (airdomeId && accessToken)
          await this.createConnection(format(connectionUrl, airdomeId.toString(), uuid.v4(), encodeURIComponent(accessToken)));
      },
      {
        fireImmediately: true
      });
  }
}