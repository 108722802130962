import { FormSelect } from '../../..';
import InfoFormProps from '../InfoFormProps';
import React from 'react';
import objectHelper from '../../../../utils/ObjectHelper';
import { observer } from 'mobx-react';

const LogLevelField = ({ translate, logLevelOptions, infoModel, disabled }: InfoFormProps) => <FormSelect {...{
  readOnly: disabled,
  options: logLevelOptions,
  setValue: x => infoModel.logLevel.setValue(Number(x)),
  selectedValue: objectHelper.executeIfNumber(infoModel.logLevel.Value, x => x.toString()),
  label: translate(x => x.general.airdome.logLevel),
  selectId: 'LogLevelField'
}} />;

export default observer(LogLevelField);
