import React from 'react';
import { FormSelect } from '../../../..';
import { SelectOptionModel } from '../../../../../models';
import Translate from '../../../../../localization/Translate';

interface TimeZoneFieldProps {
  translate: Translate;
  setTimeZone: (timeZone: string) => void;
  timezones: SelectOptionModel[];
  currentTimeZone?: string;
}

const TimeZoneField = ({ translate, setTimeZone, timezones, currentTimeZone }: TimeZoneFieldProps) =>
  <FormSelect {...{
    label: translate(x => x.general.airdome.timeZone),
    options: timezones,
    selectId: 'AirdomeTimeZone',
    setValue: setTimeZone,
    selectedValue: currentTimeZone
  }} />;

export default React.memo(TimeZoneField);
