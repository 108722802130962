import LogLevel from 'enums/LogLevel';
import LoggerService from '../services/logger/LoggerService';
import { defaultLogLevelId } from '../utils/Settings';
import moment from 'moment';

export interface LoggerStoreParams {
  loggerService: LoggerService;
}

export default class LoggerStore {
  private minimalLogLevel: LogLevel;
  private loggerService: LoggerService;

  constructor(loggerService: LoggerService) {
    this.loggerService = loggerService;
    this.minimalLogLevel = defaultLogLevelId;
    this.initLogLevel();
  }

  private initLogLevel = async () => {
    this.minimalLogLevel = await this.loggerService.getMinimumLogLevel();
  }

  public log = async (message: string, logLevel: LogLevel, trackingId: string,
    airdomeId?: number, data?: { [key: string]: any }, timeStamp?: moment.Moment) => {
    if (logLevel >= this.minimalLogLevel)
      await this.loggerService.log(message, logLevel, trackingId, airdomeId, data, timeStamp);
  }

  public logInfo = async (message: string, trackingId: string,
    airdomeId?: number, data?: { [key: string]: any }, timeStamp?: moment.Moment) => {
    await this.log(message, LogLevel.Info, trackingId, airdomeId, data, timeStamp);
  }

  public logWarn = async (message: string, trackingId: string,
    airdomeId?: number, data?: { [key: string]: any }, timeStamp?: moment.Moment) => {
    await this.log(message, LogLevel.Warn, trackingId, airdomeId, data, timeStamp);
  }

  public logError = async (message: string, trackingId: string,
    airdomeId?: number, data?: { [key: string]: any }, timeStamp?: moment.Moment) => {
    await this.log(message, LogLevel.Error, trackingId, airdomeId, data, timeStamp);
  }
}
