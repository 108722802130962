import './Lighting.scss';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import ButtonLoading from 'shared/components/ButtonLoading';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';
import FormNewChangesDialog from 'components/FormNewChangesDialog';
import { LightCard } from '../../components/lighting';
import LightModel from '../../models/lighting/LightModel';
import { LinearProgress } from '@material-ui/core';
import { Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const Lighting = () => {
  const lightingStore = useStore(x => x.lightingStore);
  const airdomeStore = useStore(x => x.airdomeStore);
  const changeRequestStore = useStore(x => x.changeRequestStore);
  const translate = useLocalization();

  const airdome = airdomeStore.SelectedAirdome;
  const history = useHistory();

  if(!airdome)
    return null;
    
  if(!airdome.hasLighting)
    history.push('/');

  const chRInProgress = changeRequestStore.ChangeRequestStates[ChangeRequestTypeEnum.Lights];

  const renderLightCard = (lightModel?: LightModel) => lightModel &&
    <LightCard {...{
      translate: translate,
      name: lightModel.name.Value ?? '',
      setName: lightModel.name.setValue,
      lightState: lightModel.lightState.Value!,
      setLightState: lightModel.setLightState,
      disabled: chRInProgress,
    }} />

  return <>
    <AirdomePageHeader
      icon={<span className="lnr lnr-sun" />}
      title={airdome ? airdome.name : ''}
      additionalContent={<ButtonLoading
        onClick={async () => await lightingStore.updateLights()}
        content={translate(x => x.general.update)}
        disabled={chRInProgress || !lightingStore.lights.hasAnyChanges || !lightingStore.lights.areNamesValid}
        color="primary" />}/>
        
    <Row>
      {lightingStore.lights.hasData
        ? <>
          {renderLightCard(lightingStore.lights.lightsMain1)}
          {renderLightCard(lightingStore.lights.lightsMain2)}
          {renderLightCard(lightingStore.lights.lightsMain3)}
          {renderLightCard(lightingStore.lights.lightsMain4)}
          {renderLightCard(lightingStore.lights.lightsAdditional1)}
          {renderLightCard(lightingStore.lights.lightsAdditional2)}
          {renderLightCard(lightingStore.lights.lightsAdditional3)}
          {renderLightCard(lightingStore.lights.lightsAdditional4)}
        </>
        : <LinearProgress />}
    </Row>

    <FormNewChangesDialog
        newChangesModel={lightingStore.newChangesModel}
        onConfirm={async () => await lightingStore.fetchLights()}
        changeRequestType={ChangeRequestTypeEnum.Lights}
      />
  </>
}

export default observer(Lighting);