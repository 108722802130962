import Airdome from '../../../base/errors/airdome';

import create from './Create';
import intervals from './Intervals';
import userAccess from './UserAccess';
import macAddress from './MacAddress';

const airdome: Airdome = {
  create,
  intervals,
  userAccess,
  macAddress
};

export default airdome;
