import React from 'react';
import { FormInput } from '../../..';
import { FormInputModel, HandleValueModel } from '../../../../models';
import { Col } from 'reactstrap';
import CheckBox from '../../../../shared/components/form/CheckBox';
import { NumberSchema } from 'yup';

export interface BaseTemperatureProps {
  label: string;
  inputId: string;
  isEnabled: boolean;
  value: number | undefined;
  setIsEnabled: (x: boolean) => void;
  setValue: (x?: number) => void;
  numberValidationSchema: NumberSchema;
  readonly: boolean;
}

const BaseTemperatureField = ({
  numberValidationSchema, label, inputId, isEnabled, setIsEnabled, value, setValue, readonly
}: BaseTemperatureProps) =>
  <Col className="temperatureField">
    <div>
      <CheckBox {...{
        value: isEnabled,
        onChange: x => setIsEnabled(!isEnabled),
        name: `${inputId}_isEnabled`,
        color: 'primary',
        className: 'colored-click',
        disabled: readonly
      }} />
    </div>
    <FormInput {...{
      model: new FormInputModel({
        label,
        inputId,
        validationSchema: isEnabled
          ? numberValidationSchema
          : undefined,
        readonly: readonly || !isEnabled,
        value: (typeof value === 'number' && !Number.isNaN(value)) ? value.toString() : '',
        handleValueModel: new HandleValueModel({
          setValue,
          inputType: 'number'
        })
      })
    }} />
  </Col>;

export default BaseTemperatureField;
