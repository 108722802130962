import {
  HasDoorLockingField,
  HasElectricityConsumptionField,
  HasGasConsumptionField,
  HasHeatingField,
  HasPreheatingField
} from '../fields';

import { CreateAirdomeModel } from 'models';
import React from 'react';
import Translate from '../../../../localization/Translate';
import { observer } from 'mobx-react';

interface AirdomeFlagsFieldsetProps {
  translate: Translate;
  supportsHeating: boolean;
  supportsPreheating: boolean;
  supportsDoorLocking: boolean;
  createAirdomeModel: CreateAirdomeModel;
}

const AirdomeFlagsFieldset = (props: AirdomeFlagsFieldsetProps) => <fieldset>
  {props.supportsHeating
    ? <HasHeatingField {...{
      setHasHeating: props.createAirdomeModel.setHasHeating,
      hasHeating: props.createAirdomeModel.hasHeating ?? false
    }} />
    : null}
  {props.supportsPreheating
    ? <HasPreheatingField {...{
      setHasPreheating: props.createAirdomeModel.setHasPreheating,
      hasPreheating: props.createAirdomeModel.hasPreheating ?? false
    }}/>
  : null}
  {props.supportsDoorLocking
    ? <HasDoorLockingField {...{
      setHasDoorLocking: props.createAirdomeModel.setHasDoorLocking,
      hasDoorLocking: props.createAirdomeModel.hasDoorLocking ?? false
    }}/>
    : null}
  <HasElectricityConsumptionField {...{
    setHasElectricityConsumption: props.createAirdomeModel.setHasElectricityConsumption,
    hasElectricityConsumption: props.createAirdomeModel.hasElectricityConsumption ?? false
  }}/>
  <HasGasConsumptionField {...{
    setHasGasConsumption: props.createAirdomeModel.setHasGasConsumption,
    hasGasConsumption: props.createAirdomeModel.hasGasConsumption ?? false
  }}/>
</fieldset>;

export default observer(AirdomeFlagsFieldset);
