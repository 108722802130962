import './LightingHistory.scss';

import { Card, CardBody, Col, Container } from 'reactstrap';
import React, { useEffect } from 'react';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import AirdomeTime from 'components/airdome/AirdomeTime';
import { LightHistoryItemModel } from 'models/lighting/LightsHistoryModel';
import { LightState } from 'dto/settings/enums';
import { MaterialTable } from 'components';
import { TableColumn } from 'components/basic/material-table/table-header';
import { TablePagination } from '@material-ui/core';
import { observer } from "mobx-react";
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const LightStateCell = observer((props: { item: LightHistoryItemModel, getState: (x: LightHistoryItemModel) => LightState | undefined }) => {
  const translate = useLocalization();

  const lightState = props.getState(props.item);
  return <p className="text-center">
    {lightState 
      ? translate(x => x.enums.lightState[lightState].full)
      : ' - '}
  </p>
});

const LightingHistory = () => {
  const {
    lights,
    lightsHistoryModel
  } = useStore(x => x.lightingStore);

  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  useEffect(() => {
    lightsHistoryModel.setup();

    return lightsHistoryModel.clear;
  }, [lightsHistoryModel, airdome]);

  const translate = useLocalization();
  const history = useHistory();

  if(!airdome)
    return null;
    
  if(!airdome.hasLighting)
    history.push('/');
  
  const getRenderCell = (getState: (item: LightHistoryItemModel) => LightState | undefined) => 
    (item: LightHistoryItemModel) => <LightStateCell {...{ item, getState }}/>

  return <>
    <AirdomePageHeader
      icon={<span className="lnr lnr-sun" />}
      title={airdome 
        ? `${airdome.name}: ${translate(x => x.pages.lighting.history.subHeader)}`
        : ''}/>

    <Card className="LightingHistoryCard">
      <CardBody>
        <MaterialTable<LightHistoryItemModel>
          disableSorting
          showPagination={false}
          showCheckboxColumn={false}
          clickRowToSelect={false}
          multiselect={false}
          data={lightsHistoryModel.lightsEntries}
          columns={[
            lights.lightsMain1 
              ? { id: 'lightsMain1', disablePadding: false, label: lights.lightsMain1.name.Value, renderCell: getRenderCell(x => x.lightsMain1) }
              : undefined,
            lights.lightsMain2
              ? { id: 'lightsMain2', disablePadding: false, label: lights.lightsMain2.name.Value, renderCell: getRenderCell(x => x.lightsMain2) }
              : undefined,
            lights.lightsMain3 
              ? { id: 'lightsMain3', disablePadding: false, label: lights.lightsMain3.name.Value, renderCell: getRenderCell(x => x.lightsMain3) }
              : undefined,
            lights.lightsMain4 
              ? { id: 'lightsMain4', disablePadding: false, label: lights.lightsMain4.name.Value, renderCell: getRenderCell(x => x.lightsMain4) }
              : undefined,
            lights.lightsAdditional1 
              ? { id: 'lightsAdditional1', disablePadding: false, label: lights.lightsAdditional1.name.Value, renderCell: getRenderCell(x => x.lightsAdditional1) }
              : undefined,
            lights.lightsAdditional2
              ? { id: 'lightsAdditional2', disablePadding: false, label: lights.lightsAdditional2.name.Value, renderCell: getRenderCell(x => x.lightsAdditional2) }
              : undefined,
            lights.lightsAdditional3 
              ? { id: 'lightsAdditional3', disablePadding: false, label: lights.lightsAdditional3.name.Value, renderCell: getRenderCell(x => x.lightsAdditional3) }
              : undefined,
            lights.lightsAdditional4 
              ? { id: 'lightsAdditional4', disablePadding: false, label: lights.lightsAdditional4.name.Value, renderCell: getRenderCell(x => x.lightsAdditional4) }
              : undefined,
            { 
              id: 'timestamp', 
              disablePadding: false, 
              label: translate(x => x.pages.lighting.history.timestampHeader), 
              renderCell: (item: LightHistoryItemModel) => <p className="text-center"><AirdomeTime time={item.timestamp}/></p>
            },
            { 
              id: 'updatedBy', 
              disablePadding: false, 
              label: translate(x => x.pages.lighting.history.updatedByHeader), 
              renderCell: (item: LightHistoryItemModel) => <p className="text-center">{item.userName}</p>
            }
          ].filter(x => !!x) as TableColumn<LightHistoryItemModel>[]}/>
        {lightsHistoryModel.totalLightsCount > 0 && <TablePagination
          component="div"
          className="material-table__pagination mt-2"
          count={lightsHistoryModel.totalLightsCount}
          rowsPerPage={lightsHistoryModel.pagingModel.rowsPerPage}
          page={lightsHistoryModel.pagingModel.currentPage}
          backIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.previousPage) }}
          nextIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.nextPage) }}
          onChangePage={(_, page) => lightsHistoryModel.pagingModel.setCurrentPage(page)}
          onChangeRowsPerPage={(event) => lightsHistoryModel.pagingModel.setRowsPerPage(Number(event.target.value))}
          rowsPerPageOptions={[
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 50, label: "50" }
          ]}
          labelRowsPerPage={translate(x => x.general.datatables.numberOfRowsShown)}
          labelDisplayedRows={(paginationInfo): React.ReactNode => translate(x => x.general.datatables.displayedRows,
            {
              from: paginationInfo.from,
              to: paginationInfo.to,
              page: paginationInfo.page + 1,
              count: paginationInfo.count
            })
          }
        />}
      </CardBody>
    </Card>
  </>;
}

export default observer(LightingHistory);