import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { observer } from "mobx-react"
import { useStore } from 'stores/MobXTypedStoreHook';

const SingleAirdomeRouteWrapper = (props: { children: any }) => {
  const airdomeStore = useStore(x => x.airdomeStore);
  const history = useHistory();
  const match = useRouteMatch<{ airdomeId: string | undefined }>();

  useEffect(() => {
    const airdomeId = match && Number(match.params.airdomeId);

    if (!airdomeId) {
      history.push('/');
      return;
    }

    if (airdomeStore.SelectedAirdomeId !== airdomeId)
      airdomeStore.setSelectedAirdomeId(airdomeId);

    if (airdomeStore.loadPromise)
      airdomeStore.loadPromise.then(() => {
        if (!airdomeStore.SelectedAirdome)
          history.push('/');
      });
  }, [airdomeStore, history, match]);
  
  return airdomeStore.SelectedAirdome === undefined
    ? <div className="load">
      <div className="load__icon-wrap">
        <svg className="load__icon">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
    : <>{props.children}</>;
}

export default observer(SingleAirdomeRouteWrapper);