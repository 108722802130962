/* eslint-disable no-extend-native */
// see ../types/global.d.ts

Array.prototype.groupBy = function groupBy<TItem, TKey>(this: TItem[], getKey: (item: TItem) => TKey,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  compare: (a: TKey, b: TKey) => boolean, updateKeyWhenGrouping: boolean = false): { key: TKey; items: TItem[] }[] {
  return this.reduce(
    (aggregate: Aggregate<TKey, TItem>[], item: TItem) => {
      const key = getKey(item);
      const group = aggregate.find(x => compare(x.key, key));

      if (!group)
        aggregate.push({
          key,
          items: [item]
        });
      else {
        if (updateKeyWhenGrouping)
          group.key = key;
        group.items.push(item);
      }

      return aggregate;
    },
    []);
};

Array.prototype.sortBy = function sortBy<T>(this: T[], getKeys: GetKey<T>[], splitKeyChar = '~') {
  const getSortValue = (item: T) =>
    getKeys
      .map(getKey => getKey(item))
      .join(splitKeyChar)

  return this.sort((itemOne, itemTwo) =>
    getSortValue(itemOne).localeCompare(getSortValue(itemTwo)));
}

Array.prototype.min = function min<TItem>(getItem?: (item: TItem) => number): number {
  const lookup = getItem ? this.map(getItem) : this;
  return lookup.sort((a, b) => a - b)[0];
}

Array.prototype.max = function max<TItem>(getItem?: (item: TItem) => number): number {
  const lookup = getItem ? this.map(getItem) : this;
  return lookup.sort((a, b) => b - a)[0];
}

Array.prototype.distinct = function distinct<TItem>(this: TItem[], getKey?: (item: TItem) => string | number | undefined | null): Array<TItem> {
  if (!getKey)
    return Array.from(new Set(this));

  return this.filter((item, index) => index === this.findIndex(firstItem => getKey(firstItem) === getKey(item)));
}

Array.prototype.sliceBy = function sliceBy<TItem>(this: TItem[], sliceSize: number): Array<Array<TItem>> {
  const result = [];

  for (let sliceStart = 0; sliceStart < this.length; sliceStart += sliceSize)
    result.push(this.slice(sliceStart, sliceStart + sliceStart));

  return result;
}

export { };