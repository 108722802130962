import * as validations from '../../validations/validations';

import { action, computed, observable } from 'mobx';

import { StringSchema } from 'yup';
import { UpdateUserDto } from '../../dto/Container';
import UserStore from '../../stores/UserStore';

export default class EditUserModel {
  @observable
  public id?: string;

  @observable
  public username?: string;

  @observable
  public password?: string = '';

  @observable
  public confirmPassword?: string = '';

  @observable
  public email?: string;

  @observable
  public role?: string;

  @observable
  public notifications?: string;

  private readonly usernameValidationSchema: StringSchema;
  private readonly passwordValidationSchema: StringSchema;
  private readonly emailValidationSchema: StringSchema;

  private readonly store: UserStore;

  public constructor(store: UserStore) {
    this.store = store;

    this.usernameValidationSchema = validations.getUsernameValidationSchema();
    this.passwordValidationSchema = validations.getOptionalPasswordValidationSchema();
    this.emailValidationSchema = validations.getEmailValidationSchema();
  }

  @action
  public importNotificationChannel = (notificationChannel: string) => {
    this.notifications = notificationChannel;
  }

  @action
  public importFromDto = (dto: {
    id: string;
    username: string;
    email: string;
    role: string;
  }) => {
    this.id = dto.id;
    this.username = dto.username;
    this.email = dto.email;
    this.role = dto.role;
  }

  @action
  public clear = () => {
    this.id = undefined;
    this.username = undefined;
    this.email = undefined;
    this.role = undefined;
    this.notifications = undefined;
  }

  public getDto = (): UpdateUserDto | undefined =>
    this.areDataValid
      // @ts-ignore
      ? new UpdateUserDto(this)
      : undefined

  @action
  public setUsername = (value?: string) =>
    this.username = value

  @action
  public setEmail = (value?: string) =>
    this.email = value

  @action
  public setPassword = (value?: string) =>
    this.password = value

  @action
  public setConfirmPassword = (value?: string) =>
    this.confirmPassword = value

  @action
  public setRole = (value?: string) =>
    this.role = value

  @action
  public setNotifications = (value?: string) =>
    this.notifications = value

  @computed
  public get arePasswordsDifferent(): boolean {
    return this.password !== this.confirmPassword;
  }

  @computed
  public get areDataValid(): boolean {
    return !!(
      this.username && this.usernameValidationSchema.isValidSync(this.username) &&
      ((this.passwordValidationSchema.isValidSync(this.password) && !this.arePasswordsDifferent)) &&
      this.email && this.emailValidationSchema.isValidSync(this.email) &&
      (this.role
        && this.store.roleOptions.includes(this.role)) &&
      (this.notifications
        && this.store.notificationsOptions.find(x => x.id.toString() === this.notifications))
    );
  }
}
