const countries = {
  cz: 'Tschechien',
  de: 'Deutschland',
  at: 'Österreich',
  sk: 'Slowakei',
  tr: 'Türkei',
  gb: 'Vereinigtes Königreich',
  ie: 'Irland',
  pl: 'Polen',
  bg: 'Bulgarien',
  lu: 'Luxemburg',
  gr: 'Griechenland'
};

export default countries;
