import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { chartStyle, getColorIfDefined, iconSize, isTranslateSame, wrapGauge } from './Shared';

import React from 'react';
import StatusGauge from '../StatusGauge';
import ThermometerIcon from 'mdi-react/ThermometerIcon';

const TemperatureOutside = React.memo((props: TranslateProps & ColClassName & { temperatureOutside?: number }) => {
  const foregroundColor = getColorIfDefined(props.temperatureOutside, {
    hotLowerBound: 10,
    warmLowerBound: 0
  });

  return wrapGauge(
    <StatusGauge {...{
      chartStyle,
      title: props.translate(x => x.pages.status.temperatureOutside),
      value: props.temperatureOutside,
      minValue: -10,
      maxValue: 30,
      lineForeground: foregroundColor,
      unit: '°C',
      icon: <ThermometerIcon size={iconSize} color={foregroundColor} />
    }} />, props.colClassName);
}, (a, b) => a.temperatureOutside === b.temperatureOutside
  && isTranslateSame(a, b, x => x.pages.status.temperatureOutside));

export default TemperatureOutside;
