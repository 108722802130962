import './ArtificialIntelligenceConstantsFieldset.scss';

import { Col, Row } from 'reactstrap';
import { IntakeIntegrationField, IntakeMultiplierField, SpaceIntegrationField, SpaceMultiplierField } from '../fields';

import ButtonLoading from '../../../../shared/components/ButtonLoading';
import ConditionalRendering from 'security/ConditionalRendering';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const ArtificialIntelligenceConstantsFieldset = () => {
  const translate = useLocalization();
  const { artificialIntelligenceModel } = useStore(x => x.settingsStore)
  return <Col sm={12} md={12} lg={8} xl={9} className="p-0 ArtificialIntelligenceConstants">
  <h4>{translate(x => x.forms.airdome.artificialIntelligence.constantsHeader)}</h4>
  <fieldset>
    <Row>
      <Col>
        <IntakeMultiplierField />
      </Col>
      <Col>
        <IntakeIntegrationField />
      </Col>
      <Col>
        <SpaceMultiplierField />
      </Col>
      <Col>
        <SpaceIntegrationField />
      </Col>
    </Row>
    
    <ConditionalRendering getAccessDefinition={x => x.AI.Edit}>
      <div className="artificialIntelligenceEditButton">
        <ButtonLoading
          disabled={!artificialIntelligenceModel.areDataValid}
          onClick={async () => await artificialIntelligenceModel.updateDefaultAIConstants()}
          content={translate(x => x.general.edit)}
          color="primary" />
      </div>
    </ConditionalRendering>
  </fieldset>
</Col>;
};

export default observer(ArtificialIntelligenceConstantsFieldset);
