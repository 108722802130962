import Lighting from 'localization/base/pages/lighting';

const lighting: Lighting = {
  history: {
    timestampHeader: 'Letzte Aktualisierung',
    updatedByHeader: 'Aktualisiert von',
    subHeader: 'Verlauf der Lichteinstellungen'
  }
}

export default lighting;