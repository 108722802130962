import React from 'react';
import { FormInput } from '../../../..';
import { FormInputModel } from '../../../../../models';
import { HandleValueModel } from '../../../../../models/component/HandleValueModel';
import { getBasicNumberSchema } from '../../../../../validations/validations';
import Translate from '../../../../../localization/Translate';
import objectHelper from '../../../../../utils/ObjectHelper';

interface MainLightsFieldProps {
  translate: Translate;
  lightsMainCount?: number;
  setLightsMainCount: (mainCount?: number) => void;
}

const MainLightsField = ({ translate, lightsMainCount, setLightsMainCount }: MainLightsFieldProps) =>
  <FormInput {...{
    translate,
    model: new FormInputModel({
      inputId: 'AirdomeNumberOfMainLights',
      handleValueModel: new HandleValueModel({
        inputType: 'number',
        setValue: setLightsMainCount
      }),
      value: objectHelper.executeIfNumber(lightsMainCount, x => x.toString()),
      label: translate(x => x.forms.airdome.create.numberOfMainLights),
      placeholder: translate(x => x.forms.airdome.create.numberOfMainLights),
      validationSchema: getBasicNumberSchema({
        max: 4,
        min: 0,
        required: false,
        strict: true
      }).label(translate(x => x.forms.airdome.create.numberOfMainLights)),
    })
  }} />;

export default React.memo(MainLightsField);
