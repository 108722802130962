import * as React from 'react';

import BasePage from './BasePage';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import Col from 'reactstrap/lib/Col';
import { CreateUserForm } from '../components/user';
import { ErrorBox } from '../components';
import ErrorStore from '../stores/errors/ErrorStore';
import LanguageStore from '../stores/LanguageStore';
import { Row } from 'reactstrap';
import { SelectedUserModel } from 'models';
import UserAirdomes from 'components/UserAirdomes';
import UserStore from '../stores/UserStore';
import { basePageInject } from './BasePageInject';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useState } from 'react';
import { useStore } from 'stores/MobXTypedStoreHook';

const CreateUser = () => {
  const translate = useLocalization();
  const history = useHistory();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [selectedAirdomeIds, setSelectedAirdomeIds] = useState<number[]>([]);
  const errorStore = useStore(x => x.errorStore);
  const userStore = useStore(x => x.userStore);
  const error = errorStore.ErrorModel;

  return <Row>
    <Col xl={6} lg={12}>
      <Card>
        <CardBody>
          <h1 className="register-box-msg">
            {translate(x => x.forms.user.create.header)}
          </h1>
          {error && <ErrorBox
            key={error.localizationKey}
            {...{
              errorModel: error,
              errorText: translate(x => x.errors.user.create[error.localizationKey])
            }}
            />}
          <div className="Register register-box-body">
            <CreateUserForm {...{
              translate,
              submitText: translate(x => x.forms.user.create.submit),
              onSubmit: async userId => {
                await userStore.sendUsersAirdomes(userId, selectedAirdomeIds);
                if(!userStore.sendUsersAirdomes.getLastExecutionFailed!())
                  history.push('/users');
              }
            }} />
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col xl={6} lg={12}>
      <Card>
        <CardBody>
          <UserAirdomes {...{ 
            translate, 
            search,
            selectedAirdomeIds,
            setSelectedAirdomeIds
          }} />
        </CardBody>
      </Card>
    </Col>
  </Row>;
}

export default observer(CreateUser);