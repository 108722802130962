import { useEffect, useRef, useState } from 'react';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import SupportedLocale from 'enums/SupportedLocale';
import { observer } from 'mobx-react';
import { useStore } from 'stores/MobXTypedStoreHook';

const getIconForLocale = (locale: SupportedLocale, className?: string) => (
  <img className={`w-auto${className ? ` ${className}` : ''}`} src={`${process.env.PUBLIC_URL}/img/language/${locale.substr(0, 2)}.svg`} alt={locale} />
)

const LanguageItem = observer(({ locale, text, onClick }: {
  locale: SupportedLocale;
  text: string;
  onClick: () => void;
}) => {
  const languageStore = useStore(x => x.languageStore);

  return (
    <span className="topbar__link" onClick={() => {
      languageStore.setLocale(locale);
      onClick();
    }}>
      {getIconForLocale(locale, "pr-2")}
      <p className="topbar__link-title">{text}</p>
    </span>
  );
})

const TopbarLanguage = () => {
  const languageStore = useStore(x => x.languageStore);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const topbarRef = useRef<HTMLElement>(null) as any;

  const onDocumentClick = (ev: MouseEvent) => {
    const topBar = topbarRef.current;
    const target = ev.target as Element;

    if (!topBar || !ev.target)
      return;

    if (ev.target !== topBar && !topBar?.contains(target))
      setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen)
      document.addEventListener('click', onDocumentClick, {
        capture: true
      });

    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen])

  return (
    <div className="topbar__collapse topbar__collapse--language" ref={topbarRef}>
      <button
        className="topbar__btn"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="topbar__language-btn-title mr-0">
          {getIconForLocale(languageStore.Locale, "ml-2")}
        </span>
        <ChevronDownIcon className="topbar__icon" />
      </button>
      <Collapse
        isOpen={isOpen}
        className="topbar__menu-wrap">
        <div className="topbar__menu">
          <LanguageItem locale={SupportedLocale.csCZ} text="CS" onClick={() => setIsOpen(false)} />
          <LanguageItem locale={SupportedLocale.enGB} text="EN" onClick={() => setIsOpen(false)} />
          <LanguageItem locale={SupportedLocale.deDE} text="DE" onClick={() => setIsOpen(false)} />
        </div>
      </Collapse>
    </div>
  )
}

export default observer(TopbarLanguage);
