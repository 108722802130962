import { CheckboxModel } from '../../../../../models';
import { FormCheckbox } from '../../../..';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface HasHeatingFieldProps {
  setHasHeating: (hasHeating: boolean) => void;
  hasHeating: boolean;
}

const HasHeatingField = ({ setHasHeating, hasHeating }: HasHeatingFieldProps) =>
  {
    const translate = useLocalization();
    return <FormCheckbox {...{
      translate,
      model: new CheckboxModel({
        inputId: 'AirdomeHeating',
        label: translate(x => x.forms.airdome.create.hasHeating),
        setValue: setHasHeating,
        value: hasHeating
      })
    }} />;
  };

export default observer(HasHeatingField);
