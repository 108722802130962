import { AirdomeService, ChangeRequestHubConnection, SettingsHubConnection, UINotificationService, UserService } from '../services';
import BaseStore, { Params } from './base/BaseStore';
import { action, computed, observable, reaction } from 'mobx';

import AirdomeStore from './AirdomeStore';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';
import DoorLockSettingsHistoryModel from 'models/doorlocksettings/DoorLockSettingsHistoryModel';
import DoorLockSettingsService from 'services/settings/DoorLockSettingsService';
import { DoorLockState } from '../dto/settings/enums';
import ErrorStore from './errors/ErrorStore';
import InputModel from 'models/InputModel';
import { asTracked } from './decorators/TrackedDecorator';
import { catchError } from './decorators';
import objectHelper from 'utils/ObjectHelper';
import FormNewChangesModel from 'models/FormNewChangesModel';
import DoorLockSettingsSignalRDto from 'services/signalr/dto/DoorLockSettingsSignalRDto';

export interface StoreParams {
  doorLockSettingsService: DoorLockSettingsService;
  airdomeStore: AirdomeStore;
  airdomeService: AirdomeService;
  errorStore: ErrorStore;
  userService: UserService;
  settingsHubConnection: SettingsHubConnection;
  changeRequestHubConnection: ChangeRequestHubConnection;
  uiNotificationService: UINotificationService;
  getTrackingId: () => string;
}

interface DoorModel {
  propertyDefinitionId?: number,
  name: InputModel<string>,
  value: InputModel<DoorLockState>
}

export default class DoorLockSettingsStore extends BaseStore<StoreParams> {
  @observable
  public mainDoorsModel: DoorModel = {
    name: new InputModel<string>(),
    value: new InputModel<DoorLockState>()
  };

  @observable
  public doorLockSettingsHistoryModel: DoorLockSettingsHistoryModel;

  @observable
  public newChangesModel: FormNewChangesModel<DoorLockSettingsSignalRDto>;

  constructor(params: Params<StoreParams>) {
    super(params);

    this.newChangesModel = new FormNewChangesModel<DoorLockSettingsSignalRDto>(async (doorLockSettings) => this.mainDoorsModel?.value?.setValue(doorLockSettings.mainDoorLock, false))
    this.doorLockSettingsHistoryModel = new DoorLockSettingsHistoryModel(params);

    reaction(
      () => params.airdomeStore.SelectedAirdome,
      airdome => {
        this.clear();
        if (!airdome)
          return;

        if(airdome.hasDoorLocking)
          this.fetchDoorLockSettings();
      });

    params.settingsHubConnection.onDoorLockSettingsUpdated(doorLockSettings => {
      this.newChangesModel.updateForm(doorLockSettings, doorLockSettings.isDataFromPlcDirectly, this.hasAnyChanges);
    });

    params.changeRequestHubConnection.onFailed((airdomeId, _, chRType) => {
      const selectedAirdomeId = params.airdomeStore.SelectedAirdomeId;

      if (!selectedAirdomeId || selectedAirdomeId !== airdomeId)
        return;

      if(chRType === ChangeRequestTypeEnum.DoorLockSettings) {
        this.newChangesModel.isUpdateExecutor = false;
        return;
      }
      
      return;
    });
  }
  
  @catchError
  public fetchDoorLockSettings = asTracked(action(async () => {
    const trackingId = this.params.getTrackingId();
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    if(!airdomeId)
      return

    const doorLockSettings = await this.params.doorLockSettingsService.fetchDoorLockSettings(airdomeId, trackingId);
    const doorLockDefinitions = await this.params.airdomeService.getDoorLocks(airdomeId, trackingId);
    
    const doorLockSettingsProperties = objectHelper.getTypedEntries(doorLockSettings).filter(x => x.key.match(/[dD]oor/));
    
    this.mainDoorsModel.propertyDefinitionId = doorLockDefinitions[0].propertyDefinitionId;
    this.mainDoorsModel.name.setValue(doorLockDefinitions[0].name, false);
    this.mainDoorsModel.value.setValue(doorLockSettingsProperties[0].value as DoorLockState, false);
  }));

  @catchError
  public updateDoorLockSettings = asTracked(action(async ()=> {
    const trackingId = this.params.getTrackingId();
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    if (!airdomeId)
      return;

    if(this.hasAnyValueChanges && this.mainDoorsModel.value.Value !== undefined){
      await this.params.doorLockSettingsService.updateDoorLockSettings({
        mainDoorLock: this.mainDoorsModel.value.Value
        }, airdomeId, trackingId);

      this.newChangesModel.isUpdateExecutor = true;
    }

    if(this.hasAnyNameChanges 
      && !!this.mainDoorsModel.name.Value
      && this.mainDoorsModel.propertyDefinitionId){
      await this.params.airdomeService.updateDoorLocks(airdomeId, {
        [this.mainDoorsModel.propertyDefinitionId]: this.mainDoorsModel.name.Value
      }, trackingId);
      
      this.mainDoorsModel.name.resetHasChange();
    }
  }));

  public clear = asTracked(action(() => {
    this.mainDoorsModel?.value?.clear();
  }));

  @computed
  public get areNamesValid(): boolean {
    return !!this.mainDoorsModel.name.Value;
  }

  @computed
  public get hasAnyChanges(): boolean {
    return this.hasAnyValueChanges || this.hasAnyNameChanges;
  }
  
  @computed
  public get hasAnyValueChanges(): boolean {
    return this.mainDoorsModel?.value?.ValueChanged ?? false;
  }

  @computed
  public get hasAnyNameChanges(): boolean {
    return this.mainDoorsModel?.name?.ValueChanged ?? false;
  }
}
