import { action, observable } from 'mobx';

import AirdomeState from '../localization/base/enums/AirdomeState';
import { Moment } from 'moment-timezone';

interface Country {
  name: string;
  shortcut: string;
}

export default class AirdomeListItemModel {
  @observable public id: number;
  @observable public name: string;
  @observable public availability?: keyof AirdomeState;
  @observable public lastContact?: Moment;
  @observable public notifications: number;
  @observable public country: Country;
  @observable public projectNumber?: string;
  @observable public templateName?: string;

  constructor(dto: {
    id: number;
    name: string;
    availability?: keyof AirdomeState;
    lastContact?: Moment;
    notifications: number;
    country: Country;
    projectNumber?: string;
    templateName?: string;
  }) {
    this.id = dto.id;
    this.name = dto.name;
    this.availability = dto.availability;
    this.lastContact = dto.lastContact;
    this.notifications = dto.notifications;
    this.country = dto.country;
    this.projectNumber = dto.projectNumber;
    this.templateName = dto.templateName;
  }

  @action
  public updateAvailability = (availability: keyof AirdomeState) =>
    this.availability = availability
}
