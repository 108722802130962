import {
  AirdomeModel,
  ArtificialIntelligenceModel,
  InfoModel,
  IntervalModel,
  MacAddressesModel,
  SettingsModel,
  UsersWithAccessModel
} from '../models';
import {
  AirdomeService,
  ChangeRequestHubConnection,
  IdentityAirdomeService,
  IntervalsService,
  RegistrySingleAirdomeHubConnection,
  SettingsService,
  UserService
} from '../services';
import BaseStore, { Params } from './base/BaseStore';
import { action, observable, reaction, runInAction } from 'mobx';
import { catchError, processingState } from './decorators';

import AIService from 'services/ai/AIService';
import AirdomeStore from './AirdomeStore';
import { ChangeRequestTypeEnum } from '../dto/changeRequest/ChangeRequestTypeEnum';
import ErrorStore from './errors/ErrorStore';
import FormNewChangesModel from 'models/FormNewChangesModel';
import FullSettingsDto from '../dto/settings/FullSettingsDto';
import IntervalsSignalRDto from 'services/signalr/dto/IntervalsDto';
import LanguageStore from './LanguageStore';
import LoggerService from '../services/logger/LoggerService';
import RestrictionsStore from './RestrictionsStore';
import { SecurityStore } from 'stores';
import SettingsHistoryModel from '../models/settings/SettingsHistoryModel';
import SettingsHubConnection from 'services/signalr/SettingsHubConnection';
import SettingsSignalRDto from 'services/signalr/dto/SettingsSignalRDto';
import { StatefulFunction } from './shared/StatefulFunction';
import StatusBoxStore from './status/StatusBoxStore';
import TemplateStatusModel from 'models/settings/TemplateStatusModel';
import UserStore from './UserStore';
import moment from 'moment';

const PanelUserId = "Panel";

export interface SettingsParams {
  identityAirdomeService: IdentityAirdomeService;
  airdomeService: AirdomeService;
  intervalsService: IntervalsService;
  settingsService: SettingsService;
  loggerService: LoggerService;
  userService: UserService;
  aiService: AIService;
  restrictionsStore: RestrictionsStore;
  languageStore: LanguageStore;
  airdomeStore: AirdomeStore;
  statusBoxStore: StatusBoxStore;
  securityStore: SecurityStore;
  errorStore: ErrorStore;
  userStore: UserStore;
  registrySingleAirdomeHubConnection: RegistrySingleAirdomeHubConnection;
  changeRequestHubConnection: ChangeRequestHubConnection;
  settingsHubConnection: SettingsHubConnection;
  getTrackingId: () => string;
}

export default class SettingsStore extends BaseStore<SettingsParams> {
  newSettings?: SettingsSignalRDto;

  @observable
  public intervalModel = new IntervalModel();

  @observable
  public infoModel = new InfoModel();

  @observable
  public artificialIntelligenceModel: ArtificialIntelligenceModel;

  @observable
  public usersWithAccessModel = new UsersWithAccessModel();

  @observable
  public macAddressesModel = new MacAddressesModel();

  @observable
  public settingsModel: SettingsModel;

  @observable
  public settingsHistoryModel: SettingsHistoryModel;

  @observable
  public readonly templateStatusModel: TemplateStatusModel;

  @observable
  public intervalsNewChangesModel: FormNewChangesModel<IntervalsSignalRDto>;

  @observable 
  public settingsNewChangesModel: FormNewChangesModel<SettingsSignalRDto>;

  constructor(params: Params<SettingsParams>) {
    super(params);

    this.intervalsNewChangesModel = new FormNewChangesModel<IntervalsSignalRDto>((dto) => this.intervalModel.importFromDto(dto))
    this.settingsNewChangesModel = new FormNewChangesModel<SettingsSignalRDto>((dto) => this.bindSettings(dto))
    this.settingsModel = new SettingsModel(params);

    this.settingsHistoryModel = new SettingsHistoryModel(
      params,
      params.settingsService,
      params.airdomeStore,
      params.userService
    );

    this.templateStatusModel = new TemplateStatusModel(
      params.loggerStore,
      params.errorStore,
      params.getTrackingId,
      params.airdomeStore,
      params.airdomeService);

    this.artificialIntelligenceModel = new ArtificialIntelligenceModel(params);

    params.registrySingleAirdomeHubConnection.onAirdomeIsInitializedChanged(this.setSettingIsInitialized);
    params.registrySingleAirdomeHubConnection.onAirdomeIsInflatedChanged(this.setSettingIsInflated);

    reaction(() => params.userStore.users,
      (users) => {
        this.usersWithAccessModel.importOptions(users.map(x => x.username));
      });
      
    params.settingsHubConnection.onSettingsUpdated(settingsDto => {
      this.settingsNewChangesModel.updateForm(settingsDto, !settingsDto.userId, this.settingsModel.hasAnyValueChanged);
    });

    params.registrySingleAirdomeHubConnection.onIntervalsUpdated(intervalsDto => {
      this.intervalsNewChangesModel.updateForm(intervalsDto, false, this.intervalModel.anyValueChanged);
    })

    params.changeRequestHubConnection.onFailed((airdomeId, createdBy, chRType) => {
      const selectedAirdomeId = params.airdomeStore.SelectedAirdomeId;

      if (!selectedAirdomeId || selectedAirdomeId !== airdomeId)
        return;

      switch (chRType) {
        case ChangeRequestTypeEnum.Settings:
          this.settingsNewChangesModel.isUpdateExecutor = false;
          return;
        case ChangeRequestTypeEnum.Intervals:
          this.intervalsNewChangesModel.isUpdateExecutor = false;
          return;
        default:
          return;
      }
    });
  }

  private getAirdomeId = (): number | undefined => this.params.airdomeStore.SelectedAirdomeId;

  @action
  public setSettingIsInflated = (airdomeId: number, isInflated: boolean) => {
    if (this.getAirdomeId() === airdomeId)
      this.infoModel.isInflated.setValue(isInflated, false);
  }

  @action
  public setSettingIsInitialized = (airdomeId: number, isInitialized: boolean) => {
    if (this.getAirdomeId() === airdomeId)
      this.infoModel.isInitialized.setValue(isInitialized, false);
  }

  @action
  public renderNewSettings = async () => {
    if (this.newSettings) {
      this.settingsModel.importfromSignalRDto({
        ...this.newSettings,
        userName: await this.getUserName(this.newSettings.userId)
      });
      this.newSettings = undefined;

      this.settingsNewChangesModel.hideModals();
      this.settingsNewChangesModel.isUpdateExecutor = false;
    }
    else
      await this.fetchSettings();
  }

  @action
  public bindSettings = (dto: SettingsSignalRDto) => {
    this.newSettings = dto;
    this.settingsModel.resetHasChanges();
    this.renderNewSettings();
  }

  @catchError
  @action
  public fetchSettings = async (trackingId: string = ''): Promise<void> => {
    const settings = await this.params.settingsService.fetchSettings(this.params.airdomeStore.SelectedAirdomeId!, trackingId);

    settings.timestamp = moment(settings.timestamp).locale(this.params.languageStore.Locale);
    
    const extendedSettings: Omit<FullSettingsDto & { userName: string}, 'userId'> = {
      ...settings,
      userName: await this.getUserName(settings.userId, trackingId)
    };

    this.settingsModel.importfromFullDto(extendedSettings);

    this.settingsNewChangesModel.hideModals();
    this.settingsNewChangesModel.isUpdateExecutor = false;
  }

  private getUserName = async (userId: string | undefined, trackingId?: string) => {
    trackingId = trackingId ?? this.params.getTrackingId();

    if(!userId || userId === PanelUserId)
      return PanelUserId;

    const userNames = await this.params.userService.getUserNamesByIds([userId], trackingId);
    
    return userNames[userId];
  }

  @catchError
  @action
  public removeUser: StatefulFunction = async (userId: string, trackingId: string = '') => {
    const airdomeId = this.getAirdomeId();
    if (!airdomeId)
      return;

    await this.params.identityAirdomeService.removeUserAccessForAirdome({ userId, airdomeId, trackingId });

    this.usersWithAccessModel.removeUser(userId);
  }

  @catchError
  @action
  public addUser: StatefulFunction = async (userName: string, trackingId: string = '') => {
    const airdomeId = this.getAirdomeId();
    if (!airdomeId)
      return;

    if (this.usersWithAccessModel.users.some(x => x.username === userName)) {
      this.usersWithAccessModel.addUserAccessModel.setUserName('');
      return;
    }

    const user = this.params.userStore.users.find(x => x.username === userName);
    if (!user)
      return;

    const addedUser =
      await this.params.identityAirdomeService.addUserAccessForAirdome({ airdomeId, trackingId, userId: user.id });

    this.usersWithAccessModel.addUser(addedUser);
    this.usersWithAccessModel.addUserAccessModel.setUserName('');
  }

  @catchError
  @action
  public fetchUserAccess = async (airdomeId: number, trackingId: string = ''): Promise<void> => {
    const userDtos = await this.params.identityAirdomeService.getUsersForAirdome({ airdomeId, trackingId });

    this.usersWithAccessModel.importUsers(userDtos);
  }

  @catchError
  @action
  public fetchCurrentAirdomeUserAccess = async (trackingId: string = ''): Promise<void> => {
    const { airdomeStore, identityAirdomeService } = this.params;

    const airdomeId = airdomeStore.SelectedAirdomeId;

    if (!airdomeId)
      return;

    const userDtos = await identityAirdomeService.getUsersForAirdome({ airdomeId, trackingId });

    this.usersWithAccessModel.importUsers(userDtos);
  }

  @catchError
  @action
  public fetchIntervals = async (trackingId: string = ''): Promise<void> => {
    const intervalDto = await this.params.intervalsService
      .fetchAirdomeIntervals(this.params.airdomeStore.SelectedAirdomeId!, trackingId);

      this.intervalModel.importFromDto(intervalDto);
  }

  @catchError
  @action
  public fetchCurrentAirdomeIntervals = async (trackingId: string = ''): Promise<void> => {
    const { airdomeStore, intervalsService } = this.params;

    const airdomeId = airdomeStore.SelectedAirdomeId;

    if (!airdomeId)
      return;

    const intervalDto = await intervalsService.fetchAirdomeIntervals(airdomeId, trackingId);

    this.intervalModel.importFromDto(intervalDto);

    this.intervalsNewChangesModel.hideModals();
    this.intervalsNewChangesModel.isUpdateExecutor = false;
  }

  @catchError
  @action
  public fetchInfo = async (airdome: AirdomeModel, trackingId: string = ''): Promise<void> => {
    const logLevel = await this.params.loggerService.getAirdomeLogLevel(airdome.id, trackingId);

    this.infoModel.importFromDto({
      logLevel,
      countryId: airdome.country.id,
      name: airdome.name,
      projectNumber: airdome.projectNumber!,
      templateId: airdome.templateId!,
      timeZone: airdome.timeZone.windows,
      isInflated: airdome.isInflated,
      isInitialized: airdome.isInitialized
    });
  }

  @processingState
  @catchError
  @action
  public updateAirdome: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.getAirdomeId();

    if (!airdomeId || !this.infoModel || !this.infoModel.areDataValid)
      return;

    const requests: Promise<any>[] = [];
    const logLevelChange = this.infoModel.getLogLevelChange();

    if (logLevelChange)
      requests.push(this.params.loggerService.setAirdomeLogLevel(airdomeId, logLevelChange, trackingId));

    const initializationChange = this.infoModel.getInitializationChange();

    if (initializationChange === 'initialize')
      requests.push(this.params.airdomeService.initializeAirdome(airdomeId, trackingId));
    else if (initializationChange === 'deinitialize')
      requests.push(this.params.airdomeService.deinitializeAirdome(airdomeId, trackingId));

    const airdomeUpdateDto = this.infoModel.getAirdomeUpdateDto();

    if (airdomeUpdateDto)
      requests.push(
        this.params.airdomeService
          .updateAirdome(airdomeId, airdomeUpdateDto, trackingId)
          .then(airdomeDto => 
            runInAction(() => {
              const airdome = this.params.airdomeStore.airdomes.find(x =>x.id === airdomeDto.id);
              if(!airdome)
                return;

              airdome.name = airdomeDto.name;
              airdome.country = airdomeDto.country;
              airdome.projectNumber = airdomeDto.projectNumber;
              airdome.timeZone = airdomeDto.timeZone;
              airdome.templateId = airdomeDto.templateId;
              airdome.templateName = airdomeDto.templateName;
              airdome.isInitialized = airdomeDto.isInitialized;
              airdome.isInflated = airdomeDto.isInflated;
            })));

    await Promise.all(requests);

    this.infoModel.resetHasChanges();
  }

  @processingState
  @catchError
  @action
  public removeMacAddress: StatefulFunction = async (macAddressId: number, trackingId: string = ''): Promise<void> => {
    const airdomeId = this.getAirdomeId();

    if (!airdomeId)
      return;

    const result = await this.params.airdomeService.removeMacAddress(airdomeId, macAddressId, trackingId);

    this.macAddressesModel.removeMacAddress(macAddressId);
    this.params.airdomeStore.updateAirdomesMacAddresses(airdomeId, result.macAddresses);
  }

  @processingState
  @catchError
  @action
  public addMacAddress: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.getAirdomeId();

    if (!airdomeId)
      return;

    if (!this.macAddressesModel.macAddressToAdd.areDataValid(true))
      throw new Error('Invalid MAC Address.');

    const result = await this.params.airdomeService.addMacAddress(
      airdomeId,
      this.macAddressesModel.macAddressToAdd.value,
      trackingId);

    this.macAddressesModel.import(result.macAddresses);
    this.params.airdomeStore.updateAirdomesMacAddresses(airdomeId, result.macAddresses);

    this.macAddressesModel.clearMacAddressToAdd();
  }

  @processingState
  @catchError
  @action
  public updateIntervals: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.getAirdomeId();
    const dto = this.intervalModel.getDto();
    if (!dto
      || !airdomeId)
      return;

    await this.params.intervalsService
      .updateAirdomeIntervals(dto, airdomeId, trackingId);

    this.intervalsNewChangesModel.isUpdateExecutor = true;
  }

  @processingState
  @catchError
  @action
  public updateSettings: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.getAirdomeId();
    const settingsDto = this.settingsModel.getDto();
    if (!settingsDto
      || !airdomeId)
      return;

    await this.params.settingsService
      .updateSettings(settingsDto, airdomeId, trackingId);

    this.settingsNewChangesModel.isUpdateExecutor = true;
  }

  @catchError
  @action
  public restartRDev = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!airdomeId)
      return;

    await this.params.settingsService.restartRDev(airdomeId, trackingId);
  }
}
