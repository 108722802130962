import Notifications from '../../base/notifications';
import airdome from './airdome';
import changeRequest from './ChangeRequest';

const notifications: Notifications = {
  airdome,
  changeRequest,
  notificationChannelTimedOut: {
    title: 'Warning',
    message: 'Notifications couldn\'t be set for the newly created user'
  }
};

export default notifications;
