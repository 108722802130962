import Pressure from '../../../../base/forms/airdome/restrictions/Pressure';

const pressure: Pressure = {
  header: 'Omezte rozsah nastavení tlaku pro halu',
  normalPressureMin: 'Minimální hodnota normálního tlaku (Pa)',
  normalPressureMax: 'Maximální hodnota normálního tlaku (Pa)',
  maximalPressureMin: 'Minimální hodnota maximálního tlaku (Pa)',
  maximalPressureMax: 'Maximální hodnota maximálního tlaku (Pa)'
};

export default pressure;
