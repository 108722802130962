import User from '../../base/menu/User';

const user: User = {
  categoryName: 'Benutzer',
  create: 'Nutzer hinzufügen',
  edit: 'Mein Profil',
  logout: 'Ausloggen'
};

export default user;
