import { LocationDescriptor, LocationState, Path } from 'history';
import React, { ReactNode } from 'react';

import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

interface SidebarLinkProps {
  title: ReactNode | ReactNode[];
  badges?: ReactNode | ReactNode[];
  className?: string;
  icon?: string;
  newLink?: boolean;
  route?: LocationDescriptor<LocationState> | Path;
  onClick?: (...args: any[]) => any;
}

const SidebarLink = ({ route, onClick, newLink, icon, title, badges, className }: SidebarLinkProps) =>
  <NavLink
    className={className}
    to={route ? route as LocationDescriptor : '/'}
    onClick={() => onClick && onClick()}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">
        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
      {badges}
    </li>
  </NavLink>;

export default SidebarLink;
