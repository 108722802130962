export default class CreateAirdomeCommand {
  public name: string;
  public projectNumber: string;
  public countryId: number;
  public templateId: number;
  public timeZone: string;
  public lightsMainCount?: number = undefined;
  public lightsAdditionalCount?: number = undefined;
  public hasHeating: boolean;
  public hasPreheating: boolean;
  public hasDoorLocking: boolean;
  public hasElectricityConsumption: boolean;
  public hasGasConsumption: boolean;
  public macAddress?: string = undefined;

  constructor(command: {
    name: string;
    projectNumber: string;
    countryId: number;
    templateId: number;
    timeZone: string;
    lightsMainCount?: number;
    lightsAdditionalCount?: number;
    hasHeating: boolean;
    hasPreheating: boolean;
    hasDoorLocking: boolean;
    hasElectricityConsumption: boolean;
    hasGasConsumption: boolean;
    macAddress?: string;
  }) {
    this.name = command.name;
    this.projectNumber = command.projectNumber;
    this.countryId = command.countryId;
    this.templateId = command.templateId;
    this.timeZone = command.timeZone;

    if (command.lightsMainCount) 
    this.lightsMainCount = command.lightsMainCount;

    if (command.lightsAdditionalCount)
      this.lightsAdditionalCount = command.lightsAdditionalCount;

    this.hasHeating = command.hasHeating;
    this.hasPreheating = command.hasPreheating;
    this.hasDoorLocking = command.hasDoorLocking;
    this.hasElectricityConsumption = command.hasElectricityConsumption;
    this.hasGasConsumption = command.hasGasConsumption;
      
    if (command.macAddress)
      this.macAddress = command.macAddress;
  }
}
