import Settings from '../../../base/pages/settings';

const settings: Settings = {
  header: 'Nastavení',
  usersWithAccess: 'Uživatelé s přístupem',
  restartDevice: 'Restartovat zařízení',
  templateStatus: {
    header: 'Status šablony',
    refresh: 'Aktualizovat',
    reported: {
      header: 'Aktuální',
      template: 'Šablona',
      revision: 'Revize'
    },
    desired: {
      header: 'Požadovaná',
      template: 'Šablona',
      revision: 'Revize'
    }
  },

  history: {
    subheader: 'Historie nastavení',
    temperatureMode: 'Mód teploty',
    temperatureComfort: 'Teplota komfort (°C)',
    temperatureCutback: 'Teplota útlum (°C)',
    pressureMode: 'Mód tlaku',
    pressureMax: 'Maximální tlak (Pa)',
    pressureNormal: 'Normální tlak (Pa)',
    lastUpdate: 'Naposledy aktualizováno',
    updatedBy: 'Upravil'
  }
};

export default settings;
