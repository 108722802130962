import General from '../../base/general';
import airdome from './airdome';
import datatables from './datatables';
import user from './user';

const general: General = {
  user,
  airdome,
  datatables,

  cancel: 'Zrušit',
  ok: 'Potvrdit',
  requestFailed: 'Nepodařilo se načíst data.',
  submit: 'Odeslat',
  yes: 'Ano',
  no: 'Ne',
  add: 'Přidat',
  edit: 'Upravit',
  update: 'Aktualizovat',
  delete: 'Smazat',
  name: 'název'
};

export default general;
