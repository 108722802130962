import React from 'react';
import { FormInput } from '../../..';
import { FormInputModel, HandleValueModel } from '../../../../models';
import { Col } from 'reactstrap';
import { NumberSchema } from 'yup';
import { observer } from 'mobx-react';

export interface BasePressureProps {
  label: string;
  inputId: string;
  value?: number;
  setValue: (x?: number) => void;
  numberValidationSchema: NumberSchema;
  readonly: boolean;
}

const BasePressureField =
  ({ label, inputId, value, setValue, numberValidationSchema, readonly }: BasePressureProps) =>
    <Col className="pressureField">
      <FormInput {...{
        model: new FormInputModel({
          label,
          inputId,
          readonly,
          validationSchema: numberValidationSchema,
          value: (typeof value === 'number' && !Number.isNaN(value)) ? value.toString() : '',
          handleValueModel: new HandleValueModel({
            setValue,
            inputType: 'number'
          })
        })
      }} />
    </Col>;

export default observer(BasePressureField);
