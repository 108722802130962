import React from 'react';
import Translate from '../../localization/Translate';

const AirdomeCountryFlag = (props: {
  className?: string;
  translate: Translate;
  languageCode?: string;
}) => <img
    className={props.className}
    src={`${process.env.PUBLIC_URL}/img/language/${props.languageCode}.svg`}
    onError={e => e.currentTarget.src = `${process.env.PUBLIC_URL}/img/language/unknown.svg`}
    alt={props.languageCode}
    title={props.languageCode ?
      props.translate(x => x.countries[props.languageCode!])
      : undefined}
  />;

export default React.memo(AirdomeCountryFlag);
