import React, { PureComponent } from 'react';

interface BasicNotificationProps {
  message: string;
  color?: 'primary' | 'success' | 'danger' | 'warning' | 'black';
  title?: string;
}

export class BasicNotification extends PureComponent<BasicNotificationProps> {
  public static defaultProps = {
    color: '',
    title: '',
  };

  public render() {
    const { color, title, message } = this.props;

    return (
      <div className={`notification notification--${color}`}>
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message">{message}</p>
      </div>
    );
  }
}

interface ImageNotificationProps extends BasicNotificationProps {
  image: React.ReactNode;
}

export class ImageNotification extends PureComponent<ImageNotificationProps> {
  public static defaultProps = {
    title: '',
  };

  public render() {
    const { image, title, message, color } = this.props;

    return (
      <div className={`notification notification--image notification--${color}`}>
        <div className="notification__image">
          {image}
        </div>
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message">{message}</p>
      </div>
    );
  }
}

interface FullWideNotificationProps {
  message: string;
  color?: string;
}

export class FullWideNotification extends PureComponent<FullWideNotificationProps> {
  public static defaultProps = {
    color: '',
  };

  public render() {
    const { color, message } = this.props;

    return (
      <div className={`notification notification--full-wide notification--${color}`}>
        <p className="notification__message">{message}</p>
      </div>
    );
  }
}
