import Validations from '../../base/validations';

const validations: Validations = {
  mixed: {
    required: 'Field ${label} is required'
  },
  string: {
    min: '${label} cannot be shorter than ${min} characters.',
    max: '${label} cannot be longer than ${max} characters.',
    email: 'Not a valid Email format.',
    noWhitespace: '${label} cannot contain spaces.',
    macAddress: '\'${value}\' is not a valid MAC address.',
    username: '${label} can contain only characters [a-zA-Z0-9-._@+.]'
  },
  number: {
    min: '${label} must be greater than or equal to ${min}',
    max: '${label} must be less than or equal to ${max}'
  }
};

export default validations;
