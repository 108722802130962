import React from 'react';
import { describeArc, describeArcRegion } from './util/SVGHelper';
import { RadialProps } from './Radial';

export interface ItemState {
  g: {
    key: number;
    id: string;
    style: React.CSSProperties,
  };
  region: {
    d: string,
    style: React.CSSProperties,
  };
  arc: {
    d: string;
    style: React.CSSProperties;
    id: string;
  };
  text: React.CSSProperties;
  textPath: any;
}

export const renderItem = (props: RadialProps, text: string, i: number): ItemState => {
  const {
    cx,
    cy,
    innerRadius: radius,
    fill,
    strokeWidth,
    outerRadius,
    stroke,
    data
  } = props;

  const radiusDiff = outerRadius - radius;
  const singleItemDegrees = 360 / data.length;
  const angleStart = singleItemDegrees * i;
  const angleEnd = angleStart + singleItemDegrees;

  return {
    g: {
      key: i,
      id: `arc${text}`,
      style: {
        transform: `translate(${strokeWidth}px,${strokeWidth}px)`
      },
    },
    region: {
      d: describeArcRegion(cx, cy, radius, radius + radiusDiff, angleStart, angleEnd),
      style: {
        fill,
        strokeWidth,
        stroke,
      },
    },
    arc: {
      d: describeArc(cx, cy, radius + radiusDiff * .8, angleStart, angleEnd),
      style: { opacity: 1e-6 },
      id: angleStart.toString()
    },
    text: {
      textAnchor: 'middle',
      fontFamily: 'sans-serif',
    },
    textPath: {
      fontWeight: 100,
      fill: stroke,
      startOffset: '50%',
      xlinkHref: `#${angleStart}`
    }
  };
};

export default renderItem;
