import '../../scss/component/status/notifications.scss';

import { Card, CardBody, Col } from 'reactstrap';
import { NotificationIcon, Notifications } from './Notifications';

import NotificationDto from '../../dto/notification/NotificationDto';
import ObservedNotifications from './ObservedNotifications';
import { ObservedNotificationsState } from '../../stores/NotificationStore';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

interface NotificationsBoxProps {
  observedNotifications: ObservedNotificationsState;
  notifications: NotificationDto[];
  removeNotification: (notification: NotificationDto) => void;
}

const NotificationsBox = (props: NotificationsBoxProps) => {
  const { observedNotifications, notifications, removeNotification } = props;
  const translate = useLocalization();
  const locale = useStore(x => x.languageStore).Locale;

  const anyNotifications = Object.values(observedNotifications).reduce((sum, next) => sum || next)
    || notifications.length > 0;

  return <Col sm={12}>
    <Card>
      <CardBody>
        <div className="d-flex flex-wrap notification-box">
          <Col xl={1} lg={2} sm={12} className="d-inline-block mb-3 mt-3">           
            <NotificationIcon anyNotifications={anyNotifications} count={notifications.length} />           
          </Col>
          <Col xl={11} lg={10} sm={12} className="d-inline-block">
            <ObservedNotifications {...{ observedNotifications, translate }} />
            {notifications.length > 0
              && <div className="border-top mt-2 pt-2 notification-border-box">
                <Notifications {...{ notifications, locale, onDismiss: removeNotification }} />
              </div>}
            {!anyNotifications && <h3>{translate(x => x.pages.status.noNotifications)}</h3>}
          </Col>
        </div>
      </CardBody>
    </Card>
  </Col>;
};

export default observer(NotificationsBox);
