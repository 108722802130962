import Columns from '../../../base/pages/airdomelist/Columns';

const columns: Columns = {
  name: 'Name',
  availability: 'Verfügbarkeit',
  notifications: 'Notifizierung',
  country: 'Land',
  projectNumber: 'Projektnummer',
  template: 'Vorlage'
};

export default columns;
