import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import React from 'react';
import { StringSchema } from 'yup';
import Translate from '../../../../localization/Translate';
import { observer } from 'mobx-react';

interface UsernameFieldProps {
  validationSchema: StringSchema;
  translate: Translate;
  setUsername: (username?: string) => void;
  username?: string;
}

const UsernameField = ({ validationSchema, translate, setUsername, username }: UsernameFieldProps) => <FormInput {...{
  translate,
  model: new FormInputModel({
    validationSchema,
    placeholder: translate(x => x.general.user.username),
    handleValueModel: new HandleValueModel({
      setValue: setUsername,
      inputType: 'text'
    }),
    label: translate(x => x.general.user.username),
    inputId: 'Username',
    value: username
  })
}} />;

export default observer(UsernameField);
