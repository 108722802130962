import * as yup from 'yup';

yup.addMethod(yup.string, 'noWhitespace', function (label: string = '') {
  return this.test('noWhitespace', label, (value: string | undefined) => {
    return !value || !/[\s]/.exec(value);
  });
});

yup.addMethod(yup.string, 'macAddress', function (label: string = '') {
  return this.test('macAddress', label, (value: string | undefined) => {
    return !value || !!/^(([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})){0,1}$/.exec(value);
  });
});

yup.addMethod(yup.string, 'username', function (label: string = ''){
  return this.test('username', label, (value: string | undefined) => {
    return !value || !!/^[a-zA-Z0-9\-._@+]*$/.exec(value);
  });
});