import { action, computed, observable } from 'mobx';
import * as errorHelper from '../../helpers/ErrorHelper';
import { ErrorModel, ApiError } from '../../models';

export default class ErrorStore {
  @observable
  private errorModel?: ErrorModel;

  @observable
  public requestSuccessful?: boolean = undefined;

  @computed
  public get hasRequestFailed(): boolean {
    return this.requestSuccessful !== undefined && !this.requestSuccessful;
  }

  @computed
  public get ErrorModel(): ErrorModel | undefined {
    return this.errorModel;
  }

  @action
  private setErrorModel = (value?: ErrorModel) => {
    this.errorModel = value;
  }

  @action
  public handleErrorModel(
    trackingId: string,
    error?: Error
  ) {
    this.requestSuccessful = false;
    const errorModel = errorHelper.createErrorModel(trackingId, <ApiError>error);
    this.setErrorModel(errorModel);
  }

  @action
  public requestSucceeded = () => {
    this.setErrorModel();
    this.requestSuccessful = true;
  }

  @action
  public clearError = () => {
    this.setErrorModel();
    this.requestSuccessful = undefined;
  }
}
