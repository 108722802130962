import Template from '../../base/menu/Template';

const template: Template = {
  categoryName: 'Manage templates',
  create: 'Hinzufügen',
  edit: 'Bearbeitung',
  list: 'Schablonen'
};

export default template;
