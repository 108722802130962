import * as React from 'react';

export interface BacnetTemplateEditProps {
    children?: React.ReactNode
}

export interface BacnetTemplateEditState {
}

export default class BacnetTemplateEdit extends React.Component<BacnetTemplateEditProps, BacnetTemplateEditState> {

    constructor(props: BacnetTemplateEditProps) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
                BacnetTemplateEdit
                { this.props.children }
            </div>
        )
    }
}
