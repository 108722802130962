import { AirdomeModel } from 'models';
import { Scrollbar } from 'smooth-scrollbar-react';
import SidebarContent from './SidebarContent';
import Translate from '../../../localization/Translate';
import classNames from 'classnames';
import { observer } from 'mobx-react';

interface SidebarProps {
  sidebarShow: boolean;
  sidebarCollapse: boolean;
  changeMobileSidebarVisibility: () => void;
  selectedAirdome?: AirdomeModel;
  translate: Translate;
}

const Sidebar = ({
  sidebarShow,
  sidebarCollapse,
  changeMobileSidebarVisibility,
  selectedAirdome,
  translate
}: SidebarProps) =>
  <div className={classNames({
    sidebar: true,
    'sidebar--show': sidebarShow,
    'sidebar--collapse': sidebarCollapse,
  })}>
    <button className="sidebar__back" onClick={() => changeMobileSidebarVisibility()} />
    <Scrollbar className="sidebar__scroll scroll">
      <div className="sidebar__wrapper sidebar__wrapper--desktop">
        <SidebarContent {...{
          selectedAirdome,
          translate,
          onClick: () => { }
        }} />
      </div>
      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SidebarContent {...{
          selectedAirdome,
          translate,
          onClick: () => changeMobileSidebarVisibility()
        }} />
      </div>
    </Scrollbar>
  </div>;

export default observer(Sidebar);
