import Consumption from '../../../base/pages/consumption';
import modal from './Modal';

const consumption: Consumption = {
  modal,

  gas: 'GAS',
  electricity: 'ELEKTRIZITÄT',
  header: 'Strom- und Gasverbrauch',
  selectRange: 'Bereich auswählen',
  descriptionTotal: 'Gesamtverbrauch',
  descriptionSelection: 'Summe für Reichweite',
  updateButton: 'Aktualisieren'
};

export default consumption;
