import React from 'react';

interface AwaitingDataSourceComponentState {
  isLoading: boolean;
}

export default abstract class AwaitingDataSourceComponent<TProps>
  extends React.Component<TProps, AwaitingDataSourceComponentState> {

  constructor(props: TProps) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  public componentDidMount() {
    this.fetchData()
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  public componentWillUnmount() {
    this.clearModel();
  }

  protected fetchData = () => Promise.resolve();
  protected clearModel = () => { };
  protected abstract renderContent(): React.ReactNode;

  protected renderLoading = () =>
    <div className="load__icon-component">
      <svg className="load__icon">
        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    </div>

  public render() {
    const { isLoading } = this.state;

    return isLoading
      ? this.renderLoading()
      : this.renderContent();
  }
}
