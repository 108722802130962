import Settings from '../../../base/pages/settings';

const settings: Settings = {
  header: 'Einstellung',
  usersWithAccess: 'Benutzer mit Zugriff',
  restartDevice: 'Gerät neustarten',
  templateStatus: {
    header: 'Schablonestatus',
    refresh: 'Aktualisieren',
    reported: {
      header: 'Aktuallen',
      template: 'Schablone',
      revision: 'Revision'
    },
    desired: {
      header: 'Gewünschter',
      template: 'Schablone',
      revision: 'Revision'
    }
  },

  history: {
    subheader: 'Einstellungen Historie',
    temperatureMode: 'Temperatur modus',
    temperatureComfort: 'Komfort temperatur (°C)',
    temperatureCutback: 'Absenktemperatur (°C)',
    pressureMode: 'Druckmodus',
    pressureMax: 'Maximaler druck (Pa)',
    pressureNormal: 'Normaler druck (Pa)',
    lastUpdate: 'Letzte aktualisierung',
    updatedBy: 'Aktualisiert von'
  }
};

export default settings;
