import { action, computed, observable } from 'mobx';

import { UsersWithAccessModel } from '..';

export default class AddUserAccessModel {
  @observable 
  public userNameOptions: string[] = [];

  @observable 
  public userName?: string;

  private model: UsersWithAccessModel;

  constructor(model: UsersWithAccessModel) {
    this.model = model;
  }

  @action
  public import = (userNameOptions: string[]) => {
    this.userNameOptions = userNameOptions;
  }

  @action
  public setUserName = (userName: string | undefined) =>
    this.userName = userName

  @action
  public clear = () => {
    this.userName = undefined;
  }

  @computed
  public get Suggestions(): string[] {
    const userName = this.userName;
    if (!userName)
      return this.NotAddedUsers;

    return this.NotAddedUsers.filter(x => x.toLowerCase().includes(userName.toLowerCase()));
  }

  get NotAddedUsers(): string[] {
    return this.userNameOptions.filter(option =>
      !this.model.users.some(existingUser =>
        option === existingUser.username));
  }
}
