import { FormSelect } from '../../..';
import InfoFormProps from '../InfoFormProps';
import React from 'react';
import { observer } from 'mobx-react';

const TimeZoneField = ({ translate, timeZoneOptions, infoModel, disabled }: InfoFormProps) =>
  <FormSelect {...{
    readOnly: disabled,
    label: translate(x => x.general.airdome.timeZone),
    options: timeZoneOptions,
    selectId: 'AirdomeTimeZone',
    setValue: x => infoModel.timeZone.setValue(x),
    selectedValue: infoModel.timeZone.Value
  }} />;

export default observer(TimeZoneField);
