import Col from 'reactstrap/lib/Col';
import GaugeValueBox from '../basic/GaugeValueBox';
import React from 'react';

interface ConsumptionCellProps {
  title: string;
  icon: JSX.Element;
  valueSelection?: number;
  valueTotal?: number;
  unit: string;
  minValue?: number;
  maxValue?: number;
  color: string;
  selectionDescription: string;
  totalDescription: string;
}

const staticData = {
  lineFill: {
    foreground: '#03A9F4',
    background: '#ECEFF1',
  },
  anglesStatic: {
    start: -140,
    end: -400
  }
};

const chartStyleStatic = {
  outerRadius: 110,
  innerRadius: 100
};

const ConsumptionCell = (props: ConsumptionCellProps) => <div>
  <div className="col-xl-12 mb-2">
    <span className="consumption-cell-title">{props.title.toUpperCase()}</span>
  </div>
  <Col lg={6} xl={6} md={12} className="d-inline-block">
    <GaugeValueBox  {...{
      angles: staticData.anglesStatic,
      chartStyle: chartStyleStatic,
      lineFill: { foreground: props.color, background: staticData.lineFill.background },
      titles: { bottom: props.totalDescription },
      title: props.title,
      icon: props.icon,
      value: props.valueTotal,
      unit: props.unit,
      minValue: props.minValue || 0,
      maxValue: props.maxValue || 0
    }} ></GaugeValueBox>
  </Col>
  <Col lg={6} xl={6} md={12} className="d-inline-block">
    <GaugeValueBox  {...{
      angles: staticData.anglesStatic,
      chartStyle: chartStyleStatic,
      lineFill: { foreground: props.color, background: staticData.lineFill.background },
      titles: { bottom: props.selectionDescription },
      title: props.title,
      icon: props.icon,
      value: props.valueSelection,
      unit: props.unit,
      minValue: props.minValue || 0,
      maxValue: props.maxValue || 0
    }} ></GaugeValueBox>
  </Col>
</div>;

export default ConsumptionCell;
