import { observable, action } from 'mobx';
import ConsumptionDto from '../../dto/status/ConsumptionDto';

export default class ConsumptionModel {
  @observable public gasTotal?: number;
  @observable public gasSelection?: number;
  @observable public electricityTotal?: number;
  @observable public electricitySelection?: number;

  @action public importFromDto = (dto: ConsumptionDto) => {
    this.gasTotal = dto.gasTotal;
    this.gasSelection = dto.gasSelection;
    this.electricityTotal = dto.electricityTotal;
    this.electricitySelection = dto.electricitySelection;
  }
}
