import Create from '../../../base/errors/airdome/Create';

const create: Create = {
  GeneralError:
    'Došlo k chybě při zpracování, zkuste prosím akci opakovat. Při přetrvávajících problémech kontaktujte Calypso group s kódem <{trackingId}>.',
  AirdomeAlreadyPresent: 'Hala se stejným názvem již existuje.',
  MacAddressAlreadyPresent: 'MAC adresa je již přiřazena k jiné hale.'
};

export default create;
