import { AirdomeStore, ErrorStore } from "stores";
import { IReactionDisposer, action, observable, reaction } from "mobx";

import LightsDto from "dto/settings/LightsDto";
import LightsService from "services/settings/LightsService";
import PagingModel from "models/store/PagingModel";
import UserService from "services/identity/UserService";
import { asTracked } from "stores/decorators/TrackedDecorator";
import { catchError } from 'stores/decorators';

const PanelUserId = "Panel";

export type LightHistoryItemModel = Omit<LightsDto & { userName: string }, 'userId'>;

export default class LightsHistoryModel {
  private readonly errorStore: ErrorStore;
  private readonly getTrackingId: () => string;
  private readonly lightsService: LightsService;
  private readonly airdomeStore: AirdomeStore;
  private readonly userService: UserService;

  public readonly pagingModel = new PagingModel();

  private fetchReactionDisposer: IReactionDisposer | undefined;

  public constructor(
    errorStore: ErrorStore,
    getTrackingId: () => string,
    lightsService: LightsService,
    airdomeStore: AirdomeStore,
    userService: UserService) {
    this.errorStore = errorStore;
    this.getTrackingId = getTrackingId;
    this.lightsService = lightsService;
    this.airdomeStore = airdomeStore;
    this.userService = userService;
  }

  @observable
  public totalLightsCount: number = 0;

  @observable
  public lightsEntries: LightHistoryItemModel[] = [];

  @action
  public clear = () => {
    this.lightsEntries = [];
    this.totalLightsCount = 0;
    this.pagingModel.clear();
    
    if(this.fetchReactionDisposer)
    {
      this.fetchReactionDisposer();
      this.fetchReactionDisposer = undefined;
    }
  }

  public setup = asTracked(action(async () => {
    const airdomeId = this.airdomeStore.SelectedAirdomeId;
    if(!airdomeId) {
      this.errorStore.handleErrorModel(
        this.getTrackingId(),
        new Error('No airdome selected!')
      );
      return;
    }

    if(this.fetchReactionDisposer)
      return;

    this.fetchReactionDisposer = reaction(
      () => ({
        skip: this.pagingModel.Skip,
        take: this.pagingModel.Take
      }),
      ({ skip, take }) => this.fetch(airdomeId, skip, take),
      {
        fireImmediately: true
      });
  }));

  public readonly fetch = asTracked(action(async (airdomeId: number, skip: number, take: number) => {
    const trackingId = this.getTrackingId();
      
    const lightsHistoryPaged = await this.lightsService.fetchLightsHistory(
      airdomeId, 
      skip, 
      take,
      trackingId);

    const userIds = lightsHistoryPaged.entities
      .reduce((array, item) => {
        if(array.find(x => x === item.userId)
        || item.userId === PanelUserId)
          return array;

        array.push(item.userId);
        return array;
      }, [] as string[]);

    const userNames = await this.userService.getUserNamesByIds(userIds, trackingId);

    this.lightsEntries = lightsHistoryPaged.entities.map(light => ({
      ...light,
      userName: light.userId === PanelUserId
        ? light.userId
        : userNames[light.userId] ?? " - "
    }));
    this.totalLightsCount = lightsHistoryPaged.totalCount;
  }))
}