enum LogLevel {
  Trace = 1,
  Debug,
  Info,
  Warn,
  Error,
  Fatal,
  Off
}

export const LogLevels = [
  LogLevel.Trace,
  LogLevel.Debug,
  LogLevel.Info,
  LogLevel.Warn,
  LogLevel.Error,
  LogLevel.Fatal
];

export default LogLevel;