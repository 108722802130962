import { action, observable, reaction } from 'mobx';

import AirdomeStore from 'stores/AirdomeStore';
import ErrorStore from 'stores/errors/ErrorStore';
import FullSettingsDto from '../../dto/settings/FullSettingsDto';
import PagingModel from 'models/store/PagingModel';
import SettingsService from 'services/settings/SettingsService';
import UserService from 'services/identity/UserService';
import { asTracked } from 'stores/decorators/TrackedDecorator';

const PanelUserId = "Panel";

export type SettingsHistoryItemModel = Omit<FullSettingsDto & { userName: string }, 'userId'>;

export default class SettingsHistoryModel {
  private readonly params: { errorStore: ErrorStore, getTrackingId: () => string };
  private readonly settingsService: SettingsService;
  private readonly airdomeStore: AirdomeStore;
  private readonly userService: UserService;

  public readonly pagingModel = new PagingModel();

  public constructor(
    params: { errorStore: ErrorStore, getTrackingId: () => string },
    settingsService: SettingsService,
    airdomeStore: AirdomeStore,
    userService: UserService) {
    this.params = params;
    this.settingsService = settingsService;
    this.airdomeStore = airdomeStore;
    this.userService = userService;

    reaction(() => ({
      _:this.pagingModel.currentPage,
      __: this.pagingModel.rowsPerPage}), 
      async (_, __) => {
        await this.fetch();
    });
  }

  @observable
  public totalSettingsCount: number = 0;

  @observable
  public settingsEntries: SettingsHistoryItemModel[] = [];

  @action
  public clear = () => {
    this.settingsEntries = [];
    this.totalSettingsCount = 0;
    this.pagingModel.clear();
  }

  
  public fetch = asTracked(action(async () => {
    const airdomeId = this.airdomeStore.SelectedAirdomeId;
    if(!airdomeId)
      throw new Error('No airdome selected');
    
    const trackingId = this.params.getTrackingId();
      
    const settingsHistoryPaged = await this.settingsService.fetchSettingsHistory(
      airdomeId, 
      this.pagingModel.Skip, 
      this.pagingModel.Take,
      trackingId);

    const userIds = settingsHistoryPaged.entities
      .reduce((array, item) => {
        if(array.find(x => x === item.userId)
        || item.userId === PanelUserId)
          return array;

        array.push(item.userId);
        return array;
      }, [] as string[]);

    const userNames = await this.userService.getUserNamesByIds(userIds, trackingId);

    this.settingsEntries = settingsHistoryPaged.entities.map(setting => ({
      ...setting,
      userName: setting.userId === PanelUserId
        ? setting.userId
        : userNames[setting.userId] ?? " - "
    }));
    this.totalSettingsCount = settingsHistoryPaged.totalCount;
  }));  
}