import BasePage, { BasePageProps } from './BasePage';

import { AirdomeModel } from '../models';

abstract class BaseAirdomePage<TProps> extends BasePage<TProps & BasePageProps> {
  protected get Airdome(): AirdomeModel | undefined {
    const airdomeStore = this.props.airdomeStore;
    if (!airdomeStore)
      throw new Error('Airdome store is missing in props');

    return airdomeStore.SelectedAirdome;
  }
}

export default BaseAirdomePage;
