import AirdomeList from '../../../base/pages/airdomelist';
import columns from './Columns';

const airdomeList: AirdomeList = {
  columns,

  header: 'Hallenverzeichnis'
};

export default airdomeList;
