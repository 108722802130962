import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { ValuesUnits, chartStyle, getColorIfDefined, iconSize, isTranslateSame, wrapGauge } from './Shared';

import FlashIcon from 'mdi-react/FlashIcon';
import React from 'react';
import StatusGauge from '../StatusGauge';

const Voltage = React.memo((props: TranslateProps & ColClassName & {
  voltage?: number;
}) => wrapGauge(
  <StatusGauge {...{
    chartStyle,
    title: props.translate(x => x.pages.status.voltage),
    value: props.voltage,
    minValue: 0,
    maxValue: 15,
    lineForeground: getColorIfDefined(props.voltage, '#FF751C'),
    unit: ValuesUnits.voltage,
    icon: <FlashIcon size={iconSize} color={getColorIfDefined(props.voltage, '#FF751C')} />
  }} />, props.colClassName),
  (a, b) => a.voltage === b.voltage
    && isTranslateSame(a, b, x => x.pages.status.voltage));

export default Voltage;
