import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import CustomJsonHubProtocol from './CustomJsonHubProtocol';

const maximumRetrySec = 30;
const defaultRetrySec = 2;

export default abstract class BaseHubConnection<TEvents extends string, TMethods extends string> {
  protected connection?: HubConnection;
  protected handlers: {
    event: TEvents,
    callback: (...args: any[]) => void
  }[] = [];
  private exponentialFalloffSec = defaultRetrySec;

  public isConnected = (): boolean => {
    return !!this.connection && this.connection.state === HubConnectionState.Connected;
  }

  public connect = async () => {
    if(!this.connection)
      return;

    await this.connection
      .start()
      .then(() => this.exponentialFalloffSec = defaultRetrySec)
      .catch(this.onConnectionFailure);
  }

  private onConnectionFailure = (error?: Error) => {
    if(this.connection?.state === HubConnectionState.Disconnecting)
    {
      setTimeout(() => this.onConnectionFailure(error), 500);
      return;
    }

    if(!error || (this.connection && this.connection.state !== HubConnectionState.Disconnected))
      return;
      
    const retryTime = this.exponentialFalloffSec <= maximumRetrySec
      ? (this.exponentialFalloffSec **= 1.1)
      : maximumRetrySec;

    setTimeout(() => {
      this.connect();
    }, retryTime * 1000);
  }

  protected on(event: TEvents, callback: (...args: any[]) => void) {
    this.handlers.push({ event, callback });

    this.connection?.on(event, callback);
  }

  protected send(method: TMethods, ...args: any[]) {
    if (this.isConnected())
      this.connection?.send(method, args);
  }
 
  protected createConnection = async (connectionString: string) => {
    const connection = new HubConnectionBuilder()
      .withUrl(connectionString)
      .withHubProtocol(new CustomJsonHubProtocol())
      .build();
 
    if (this.handlers && this.handlers.length)
      this.handlers.forEach(x => connection.on(x.event, x.callback));

    connection.onclose(this.onConnectionFailure);
 
    this.connection = connection;
    await this.connect();
  }
}
