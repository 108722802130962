import React from 'react';
import { FormInput } from '../../../..';
import { FormInputModel } from '../../../../../models';
import { HandleValueModel } from '../../../../../models/component/HandleValueModel';
import Translate from '../../../../../localization/Translate';
import { StringSchema } from 'yup';
import { observer } from 'mobx-react';

interface MacAddressFieldProps {
  translate: Translate;
  macAddress?: string;
  readonly?: boolean;
  getValidationSchema: (macAddress: String, label: String) => StringSchema;
  setMacAddress: (macAddress?: string) => void;
}

const MacAddressField = ({
  translate, macAddress, getValidationSchema, setMacAddress, readonly
}: MacAddressFieldProps) =>
  <FormInput {...{
    translate,
    model: new FormInputModel({
      readonly,
      inputId: 'AirdomeMacAddress',
      value: macAddress,
      label: translate(x => x.general.airdome.macAddress),
      placeholder: translate(x => x.general.airdome.macAddress),
      handleValueModel: new HandleValueModel({
        inputType: 'text',
        setValue: setMacAddress
      }),
      validationSchema: getValidationSchema(
        translate(x => x.validations.string.macAddress),
        translate(x => x.general.airdome.macAddress)
      )
    })
  }} />;

export default observer(MacAddressField);
