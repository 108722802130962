import './SettingsForm.scss';

import * as React from 'react';

import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  InfoFieldset,
  PressureFieldset,
  TemperatureFieldset
} from './fieldsets';
import { inject, observer } from 'mobx-react';

import { AwaitingDataSourceComponent } from '../..';
import SettingsFormProps from './SettingsFormProps';
import useAirdomeFlags from 'common/AirdomeFlagsHook';

const SettingsCard = (props: { children: React.ReactNode }) =>
  <Col sm={12} xl={6} className="col-xxl-4">
    <Card>
      <CardBody>
        {props.children}
      </CardBody>
    </Card>
  </Col>;

const TemperatureCard = (props: SettingsFormProps) => {
  const {
    hasHeating
  } = useAirdomeFlags();

  return hasHeating
    ? <SettingsCard>
      <TemperatureFieldset {...props} />
    </SettingsCard>
    : null;
}

@inject(({ settingsStore, airdomeStore }) => ({ settingsStore, airdomeStore }))
@observer
export default class SettingsForm extends AwaitingDataSourceComponent<SettingsFormProps> {
  protected fetchData = async () => {
    const { settingsStore } = this.props;

    await settingsStore!.fetchSettings();
  }

  protected clearModel = () =>
    this.props.settingsStore!.settingsModel.clear()

  protected renderContent = () => <>
    {this.props.children}
    <form className="settings-form"
      onSubmit={(event) => {
        event && event.preventDefault();
        return false;
      }}>
      <Row>
        <TemperatureCard {...this.props} />
        <SettingsCard>
          <PressureFieldset {...this.props} />
        </SettingsCard>
        <SettingsCard>
          <InfoFieldset {...this.props} />
        </SettingsCard>
      </Row>
    </form>
  </>
}
