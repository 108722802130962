import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const HeaderCellLabel = (props: {
  column: { id: string, label: string };
  orderBy?: string;
  order?: 'asc' | 'desc';
  onRequestSort: (event: React.MouseEvent<HTMLElement, MouseEvent>, property: string) => void;
  disableSorting?: boolean;
}) => !props.disableSorting
  ? <TableSortLabel
    active={props.orderBy === props.column.id}
    direction={props.order}
    onClick={event => props.onRequestSort(event, props.column.id)}
    className="material-table__sort-label"
  >
    {props.column.label}
  </TableSortLabel>
  : <span className="MuiButtonBase-root material-table__sort-label text-center w-100">
    {props.column.label}
  </span>;

export default HeaderCellLabel;
