import * as React from 'react';

import { Button, Card, CardBody, Col, Fade, Row, Spinner } from 'reactstrap';
import { ErrorPopup, MaterialTable, SearchBar } from '../components';
import { SelectedUserModel, UserListItemModel } from '../models';

import Alert from '../shared/components/Alert';
import BasePage from './BasePage';
import Dialog from '../shared/components/Dialog';
import { EditUserForm } from '../components/user';
import LanguageStore from '../stores/LanguageStore';
import LockIcon from 'mdi-react/LockIcon';
import LockOpenIcon from 'mdi-react/LockOpenIcon';
import RootStore from '../stores/RootStore';
import { StatefulFunction } from '../stores/shared/StatefulFunction';
import { TableColumn } from '../components/basic/material-table/table-header/TableHeader';
import Translate from '../localization/Translate';
import UserAirdomes from '../components/UserAirdomes';
import UserListStore from '../stores/UserListStore';
import UserStore from '../stores/UserStore';
import { basePageInject } from './BasePageInject';

interface UserListProps {
  languageStore: LanguageStore;
  userStore: UserStore;
  getUsersUrl: string;
  createInstancedStore: <TStore>(createStore: (x: RootStore) => TStore) => TStore;
}

@basePageInject('userStore', 'createInstancedStore', 'languageStore')
class UserList extends BasePage<UserListProps> {
  private userListStore = this.props.createInstancedStore(x => new UserListStore(x));

  private getColumns = (): TableColumn<UserListItemModel>[] => [
    {
      id: 'lockIcon',
      label: this.props.languageStore.translate(x => x.general.user.userIsLocked),
      getValue: () => '',
      renderCell: (item: UserListItemModel) => item.lockIcon,
      disablePadding: false
    },
    {
      id: 'username',
      label: this.props.languageStore.translate(x => x.general.user.username),
      getValue: (item: UserListItemModel) => item.username,
      disablePadding: false
    },
  ]

  public componentDidMount = () => {
    this.userListStore.fetchUsers();
  }

  private onUserDelete = async () => {
    const { userStore } = this.props;
    await userStore.deleteUser()
      .then(() => this.userListStore.setIsDeleteModalShown(false));
  }

  private onUserUnlock = async () => {
    const { userStore } = this.props;
    await userStore.unlockUser()
      .then(() => this.userListStore.setIsUnlockModalShown(false));
  }

  private onUserLock = async () => {
    const { userStore } = this.props;
    await userStore.lockUser()
      .then(() => this.userListStore.setIsLockModalShown(false));
  }

  private onUserUpdate = async (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();

    const { userStore } = this.props;

    await userStore.updateUser();
  }

  private onUserSelected = (selectedObject: UserListItemModel) => {
    const { userStore } = this.props;

    userStore.setSelectedUserModel(new SelectedUserModel(selectedObject));
    userStore.clearEditUserModelPasswords();

    this.userListStore.fetchUserDetail();
    this.userListStore.fetchUsersAirdomes();
  }

  public componentWillUnmount = () => {
    this.props.userStore.setSelectedUserModel();
  }

  public render() {
    const { userStore, languageStore: { translate } } = this.props;

    const columns = this.getColumns();
    return <>
      {this.renderDeleteModal(translate)}
      {this.renderUnlockModal(translate)}
      {this.renderLockModal(translate)}
      <Row>
        <Col xl={6} lg={12}>
          <div>
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap">
                  <Col xl={6} lg={12}>
                    <h1>{translate(x => x.pages.userList.header)}</h1>
                  </Col>
                  <ErrorPopup
                    translate={translate}
                    close={this.props.errorStore.clearError}
                    error={this.props.errorStore.ErrorModel}
                  />
                  <SearchBar {...{
                    translate,
                    onChange: this.userListStore.setUserSearch,
                    className: 'col-md-12 col-xl-6'
                  }} />
                </div>
                {this.renderLoadedElement(
                  translate,
                  this.userListStore.fetchUsers,
                  <>
                    <MaterialTable<UserListItemModel>
                      {... {
                        columns,
                        multiselect: false,
                        showPagination: true,
                        search: this.userListStore.userSearch,
                        searchIn: columns,
                        data: userStore.users,
                        rowsPerPageOptions: [10, 25, 30, 50],
                        clickRowToSelect: true,
                        showCheckboxColumn: false,
                        handleItemClick: this.onUserSelected
                      }}
                    />
                    {userStore.selectedUserModel && this.renderDeleteButton(translate)}
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
        {this.getUserDetail()}
      </Row>
    </>;
  }

  private renderDeleteModal = (translate: Translate): JSX.Element => <Dialog
    title={translate(x => x.actions.user.delete.title)}
    message={this.props.userStore.selectedUserModel && translate(
      x => x.actions.user.delete.message,
      this.props.userStore.selectedUserModel.user
    )}
    cancelButtonLabel={translate(x => x.general.cancel)}
    confirmButtonLabel={translate(x => x.general.ok)}
    isOpen={this.userListStore.isDeleteModalShown}
    close={() => this.userListStore.setIsDeleteModalShown(false)}
    confirm={() => this.onUserDelete()}
    color="danger" />

  private renderDeleteButton = (translate: Translate): JSX.Element => <Fade>
    <Button size="lg" color="danger" onClick={() => this.userListStore.setIsDeleteModalShown(true)}>
      {translate(x => x.actions.user.delete.submit)}
    </Button>
  </Fade>

  private renderUnlockModal = (translate: Translate): JSX.Element => <Dialog
    title={translate(x => x.actions.user.unlock.title)}
    message={this.props.userStore.selectedUserModel && translate(
      x => x.actions.user.unlock.message,
      this.props.userStore.selectedUserModel.user as any
    )}
    cancelButtonLabel={translate(x => x.general.cancel)}
    confirmButtonLabel={translate(x => x.general.ok)}
    isOpen={this.userListStore.isUnlockModalShown}
    close={() => this.userListStore.setIsUnlockModalShown(false)}
    confirm={() => this.onUserUnlock()}
    color="warning" />

  private renderLockModal = (translate: Translate): JSX.Element => <Dialog
    title={translate(x => x.actions.user.lock.title)}
    message={this.props.userStore.selectedUserModel && translate(
      x => x.actions.user.lock.message,
      this.props.userStore.selectedUserModel.user as any
    )}
    cancelButtonLabel={translate(x => x.general.cancel)}
    confirmButtonLabel={translate(x => x.general.ok)}
    isOpen={this.userListStore.isLockModalShown}
    close={() => this.userListStore.setIsLockModalShown(false)}
    confirm={() => this.onUserLock()}
    color="warning" />

  private renderUnlockButton = (translate: Translate): JSX.Element =>
    <Button className="icon float-right" onClick={() => this.userListStore.setIsUnlockModalShown(true)} >
      <p>
        <LockOpenIcon />
        {translate(x => x.actions.user.unlock.submit)}
      </p>
    </Button>

  private renderLockButton = (translate: Translate): JSX.Element =>
    <Button className="icon float-right" onClick={() => this.userListStore.setIsLockModalShown(true)} >
      <p>
        <LockIcon />
        {translate(x => x.actions.user.lock.submit)}
      </p>
    </Button>

  private getUserDetail = (): JSX.Element | undefined => {
    const { userStore, languageStore: { translate } } = this.props;
    return userStore.selectedUserModel && <Col xl={6} lg={12} className="d-flex flex-wrap pl-0 pr-0">
      <Col xs={12}>
        <Fade>
          <Card>
            <CardBody>
              <h1>{translate(x => x.forms.user.edit.header)}</h1>
              {this.renderLoadedElement(
                translate,
                this.userListStore.fetchUserDetail,
                userStore.editUserModel && <EditUserForm {...{
                  translate,
                  notificationLoadError: userStore.fetchUserDetail.alerts
                    ? userStore.fetchUserDetail.alerts.find(x => x.key === 'NotificationChanneUnableToLoad')
                    : undefined,
                  roleOptions: userStore.roleOptions,
                  notificationsOptions: userStore.notificationsOptions,
                  submitText: translate(x => x.forms.user.edit.submit),
                  isProcessing: userStore.updateUser.getProcessing!(),
                  userModel: userStore.editUserModel,
                  onSubmit: this.onUserUpdate
                }}>
                  {userStore.selectedUserModel.user.isLocked
                    ? this.renderUnlockButton(translate)
                    : this.renderLockButton(translate)}
                </EditUserForm>
              )}
            </CardBody>
          </Card>
        </Fade>
      </Col>
      <Col xs={12}>
        <Fade>
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <Col xl={6} lg={12}>
                  <h1 className="register-box-msg">
                    {translate(x => x.forms.user.airdomeAccess.header)}
                  </h1>
                </Col>
                <SearchBar {...{
                  translate,
                  onChange: this.userListStore.setAirdomeSearch,
                  className: 'col-md-12 col-xl-6'
                }} />
              </div>
              {this.renderLoadedElement(
                translate,
                this.userListStore.fetchUsersAirdomes,
                <UserAirdomes {...{ 
                  translate,
                  search: this.userListStore.airdomeSearch,
                  selectedAirdomeIds: this.props.userStore.selectedUserModel?.SelectedAirdomeIds ?? [],
                  setSelectedAirdomeIds: airdomeIds => this.props.userStore.selectedUserModel?.setSelectedAirdomeIds(airdomeIds),
                  onSubmit: async () => await this.props.userStore.sendSelectedUsersAirdomes()
                }}/>
              )}
            </CardBody>
          </Card>
        </Fade>
      </Col>
    </Col>;
  }

  private renderLoadedElement = (
    translate: Translate,
    action: StatefulFunction,
    element?: JSX.Element): JSX.Element | undefined => {
    if (action.getLastExecutionFailed!() && !action.getProcessing!())
      return <Alert icon={true} color="danger" className="alert--bordered mt-4">
        <p>{translate(x => x.general.requestFailed)}</p>
      </Alert>;

    if (action.getProcessing!())
      return <div style={{ margin: 'calc(50% - 1rem)' }}>
        <Spinner color="primary" />
      </div>;

    return element;
  }
}

export default UserList;
