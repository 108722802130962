import '../fields/ToggleButtonRow.scss';
import './InfoModelSubmitButton.scss';

import ButtonLoading from '../../../../shared/components/ButtonLoading';
import { InflatedField } from '../fields';
import InfoFormProps from '../InfoFormProps';
import { Row } from 'reactstrap';
import { observer } from 'mobx-react';

const InflatedSubmitFieldset = (props: InfoFormProps) =>
  <div className="form-group">
    <Row className="toggleButtonRow">
      <InflatedField {...props} />
      <ButtonLoading
        disabled={!props.infoModel.areDataValid}
        className="float-right infoModelSubmitButton"
        color="primary"
        onClick={async () => await props.updateInfo()}
        content={props.translate(x => x.general.submit)} />
    </Row>
  </div>;

export default observer(InflatedSubmitFieldset);
