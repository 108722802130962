import './ToggleButtonRow.scss';
import './ToggleButtonGroup.scss';

import InfoFormProps from '../InfoFormProps';
import React from 'react';
import { Row } from 'reactstrap';
import ToggleButton from '../../../../shared/components/form/ToggleButton';
import { observer } from 'mobx-react';

const InitializedField = ({ translate, infoModel, disabled }: InfoFormProps) =>
  <div className="form-group">
    <Row className="toggleButtonRow">
      <div className="toggleButtonGroup">
        <label>{translate(x => x.pages.status.initialized)}</label>
        <div>
          <ToggleButton
            disabled={disabled}
            defaultChecked={infoModel.isInitialized.Value || false}
            value={infoModel.isInitialized.Value || false}
            name="InitializedField"
            onChange={x => infoModel.isInitialized.setValue(x)} />
        </div>
      </div>
    </Row>
  </div>;

export default observer(InitializedField);
