import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect } from 'react';

import ButtonLoading from 'shared/components/ButtonLoading';
import CircleIcon from 'mdi-react/CircleIcon';
import Fade from 'reactstrap/lib/Fade';
import FormInput from 'components/basic/FormInput';
import FormInputModel from 'models/component/FormInputModel';
import { HandleValueModel } from 'models/component/HandleValueModel';
import { State } from 'stores/shared/NewStatefulFunction';
import { TemplateStatus } from 'models/settings/TemplateStatusModel';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const StatusIcon = () => {
  const {
    templateStatusModel
  } = useStore(x => x.settingsStore);

  const getColorForAvailability = () => {
    switch (templateStatusModel.TemplateStatus) {
      case TemplateStatus.UpToDate:
        return 'circle-icon-green';
      case TemplateStatus.Pending:
        return 'circle-icon-orange';
      case TemplateStatus.Unavailable:
        return 'circle-icon-red';
    }
  }

  return <CircleIcon size={24} className={`ml-1 mb-1 ${getColorForAvailability()}`} />;
};

const TemplateStatusComponent = () => {
  const {
    templateStatusModel
  } = useStore(x => x.settingsStore);
  const translate = useLocalization();

  useEffect(() => {
    templateStatusModel.fetch();
  }, [templateStatusModel]);

  const isLoading = templateStatusModel.fetch.state === State.Processing;

  return <Col sm={12}>
    <Fade>
      <Card>
        <CardBody>
          {isLoading
            ? <>
              <h3>{translate(x => x.pages.settings.templateStatus.header)}</h3>
              <div className="load__icon-component">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </>
            : <>
              <Row>
                <Col xs={8} lg={10}>
                  <h3 className="pb-2">
                    {translate(x => x.pages.settings.templateStatus.header)}
                    <StatusIcon />
                  </h3>
                </Col>
                <Col xs={4} lg={2} className="ml-auto">
                  <ButtonLoading className="float-right" color="primary" onClick={async () => await templateStatusModel.fetch()} content={translate(x => x.pages.settings.templateStatus.refresh)} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6} className="mb-4 mb-lg-0">
                  <Card>
                    <CardBody>
                      <h3>{translate(x => x.pages.settings.templateStatus.reported.header)}</h3>
                      <FormInput {...{
                        model: new FormInputModel({
                          handleValueModel: new HandleValueModel({
                            setValue: () => { },
                            inputType: 'text'
                          }),
                          readonly: true,
                          label: translate(x => x.pages.settings.templateStatus.reported.template),
                          inputId: 'ReportedTemplate',
                          value: templateStatusModel.Reported.templateName ?? ''
                        })
                      }} />
                      <FormInput {...{
                        model: new FormInputModel({
                          handleValueModel: new HandleValueModel({
                            setValue: () => { },
                            inputType: 'text'
                          }),
                          readonly: true,
                          label: translate(x => x.pages.settings.templateStatus.reported.revision),
                          inputId: 'ReportedRevision',
                          value: templateStatusModel.Reported.revision?.toString() ?? ''
                        })
                      }} />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} lg={6}>
                  <Card>
                    <CardBody>
                      <h3>{translate(x => x.pages.settings.templateStatus.desired.header)}</h3>
                      <FormInput {...{
                        model: new FormInputModel({
                          handleValueModel: new HandleValueModel({
                            setValue: () => { },
                            inputType: 'text'
                          }),
                          readonly: true,
                          label: translate(x => x.pages.settings.templateStatus.desired.template),
                          inputId: 'DesiredTemplate',
                          value: templateStatusModel.Desired.templateName ?? ''
                        })
                      }} />
                      <FormInput {...{
                        model: new FormInputModel({
                          handleValueModel: new HandleValueModel({
                            setValue: () => { },
                            inputType: 'text'
                          }),
                          readonly: true,
                          label: translate(x => x.pages.settings.templateStatus.desired.revision),
                          inputId: 'DesiredRevision',
                          value: templateStatusModel.Desired.revision?.toString() ?? ''
                        })
                      }} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>}
        </CardBody>
      </Card>
    </Fade>
  </Col>
}

export default observer(TemplateStatusComponent);