const countries = {
  cz: 'Česká republika',
  de: 'Německo',
  at: 'Rakousko',
  sk: 'Slovensko',
  tr: 'Turecko',
  gb: 'Velká Británie',
  ie: 'Irsko',
  pl: 'Polsko',
  bg: 'Bulharsko',
  lu: 'Lucembursko',
  gr: 'Řecko'
};

export default countries;
