import React from 'react';
import {
  PressureModeField,
  PressureNormalField,
  PressureMaxField
} from '../fields';
import SettingsFormProps from '../SettingsFormProps';
import { observer } from 'mobx-react';

const PressureFieldset = (props: SettingsFormProps) => <>
  <PressureModeField {...props} />
  <fieldset>
    <PressureNormalField {...props} />
    <PressureMaxField {...props} />
  </fieldset>
</>;

export default observer(PressureFieldset);
