import HeaderCell from './HeaderCell';
import HeaderCheckboxCell from './HeaderCheckboxCell';
import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export interface TableColumn<TItem> {
  id: string;
  disablePadding: boolean;
  label: string;
  width?: number;

  getValue: ((item: TItem, index?: number) => string) | ((item: TItem) => string);
  renderCell?: ((item: TItem, index?: number) => JSX.Element) | ((item: TItem) => JSX.Element);
}

interface TableHeadProps<TItem> {
  rowCount: number;
  numSelected: number;
  onSelectAllClick: () => void;
  showCheckboxColumn: boolean;
  onRequestSort: (event: React.MouseEvent<HTMLElement, MouseEvent>, property: string) => void;
  order?: 'desc' | 'asc';
  orderBy?: string;
  columns: TableColumn<TItem>[];
  disableSorting?: boolean;
}

export const TableHeader = <TItem extends {}>(props: TableHeadProps<TItem>) =>
  <TableHead>
    <TableRow>
      {props.showCheckboxColumn && <HeaderCheckboxCell {...props} />}
      {props.columns.map((column, key) => <HeaderCell {...{
        column,
        key,
        order: props.order,
        orderBy: props.orderBy,
        onRequestSort: props.onRequestSort,
        disableSorting: props.disableSorting
      }} />)}
    </TableRow>
  </TableHead>;
