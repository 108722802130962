import { AxiosInstance } from 'axios';
import { UserWithRoleDto } from '../../dto/Container';
import stringFormat from 'string-format';
import { getJoinedEndpoint } from '../../utils/Settings';
import { getBackendException } from './../../helpers/ErrorHelper';

export default class {
  private axios: AxiosInstance;
  private getUsersForAirdomeUrl: string;
  private removeUserForAirdomeUrl: string;
  private addUserForAirdomeUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getUsersForAirdomeUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.airdomes.getUsers);
    this.removeUserForAirdomeUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.airdomes.removeUser);
    this.addUserForAirdomeUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.airdomes.addUser);
  }

  public async getUsersForAirdome({ airdomeId, trackingId }: { airdomeId: number; trackingId: string; })
    : Promise<UserWithRoleDto[]> {
    try {
      const url = stringFormat(this.getUsersForAirdomeUrl, airdomeId.toString());
      const axiosResponse = await this.axios.get<UserWithRoleDto[]>(url, { headers: { trackingId } });
      return axiosResponse.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 3log something
    }
  }

  public removeUserAccessForAirdome = async (
    { airdomeId, userId, trackingId }: { airdomeId: number; userId: string; trackingId: string; }
  ): Promise<void> => {
    try {
      const url = stringFormat(this.removeUserForAirdomeUrl, airdomeId.toString());
      const axiosResponse = await this.axios.put(url, { userId }, { headers: { trackingId } });
      return axiosResponse.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 3log something
    }
  }

  public addUserAccessForAirdome = async (
    { airdomeId, userId, trackingId }: { airdomeId: number; userId: string; trackingId: string; }
  ): Promise<UserWithRoleDto> => {
    try {
      const url = stringFormat(this.addUserForAirdomeUrl, airdomeId.toString());
      const axiosResponse = await this.axios.put(url, { userId }, { headers: { trackingId } });
      return axiosResponse.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 3log something
    }
  }
}
