import DateSelect from '../../../../base/pages/status/charts/DateSelect';

const dateSelect: DateSelect = {
  title: 'Zvolte datum',
  from: 'Datum a čas začátku:',
  to: 'Datum a čas konce:',
  update: 'Aktualizovat'
};

export default dateSelect;
