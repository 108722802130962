import Roles from '../../../base/enums/user/Roles';

const roles: Roles = {
  admin: 'Admin',
  airdome_admin: 'Traglufthalle Administrator',
  basic: 'Grundlegend',
  advanced: 'Fortgeschritten',
  electrician: 'Elektriker'
};

export default roles;
