import Roles from '../../../base/enums/user/Roles';

const roles: Roles = {
  admin: 'Admin',
  airdome_admin: 'Air dome admin',
  basic: 'Basic',
  advanced: 'Advanced',
  electrician: 'Electrician'
};

export default roles;
