import Lighting from 'localization/base/pages/lighting';

const lighting: Lighting = {
  history: {
    timestampHeader: 'Naposledy aktualizováno',
    updatedByHeader: 'Upravil',
    subHeader: 'Historie nastavení světel'
  }
}

export default lighting;