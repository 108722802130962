import LogLevel from 'enums/LogLevel';
import LogLevelLocalization from '../../base/enums/LogLevel';

const logLevel: LogLevelLocalization = {
  [LogLevel.Trace]: 'Trace',
  [LogLevel.Debug]: 'Debug',
  [LogLevel.Info]: 'Info',
  [LogLevel.Warn]: 'Warning',
  [LogLevel.Error]: 'Error',
  [LogLevel.Fatal]: 'Fatal',
  [LogLevel.Off]: 'Off'
};

export default logLevel;
