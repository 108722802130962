import BaseTemperatureField from '../BaseTemperatureField';
import React from 'react';
import TemperatureProps from '../../TemperatureProps';
import { observer } from 'mobx-react';

const MinimumValueField = ({ translate, model, readonly }: TemperatureProps) =>
  <BaseTemperatureField {...{
    readonly,
    numberValidationSchema: model.cutbackTemperatureMinSchema.label(
      translate(x => x.forms.airdome.restrictions.temperature.cutbackTemperatureMin)
    ),
    inputId: 'LowerBound_TemperatureCutback_Field',
    label: translate(x => x.forms.airdome.restrictions.temperature.cutbackTemperatureMin),
    value: model.cutbackTemperatureMin.Value,
    setValue: x => model.cutbackTemperatureMin.setValue(x),
    isEnabled: model.isCutbackTemperatureMinEnabled.Value ?? false,
    setIsEnabled: x => model.isCutbackTemperatureMinEnabled.setValue(x)
  }} />;

export default observer(MinimumValueField);
