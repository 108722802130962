import BacnetObjectDefinition from "./BacnetObjectDefinition";

export default class BacnetObjectValue extends BacnetObjectDefinition {
  public readonly value: string;

  constructor(bacnetId: number, bacnetType: number, bacnetPropertyType: number, value: string) {
    super(bacnetId, bacnetType, bacnetPropertyType);

    this.value = value;
  }
}
