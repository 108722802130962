import TimeSpan from "../dto/TimeSpan";
import moment from "moment-timezone";
import objectHelper from "./ObjectHelper";

export const dateRegex = /^(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;
export const timeSpanRegex = /^((\d+)\.)?(\d\d):(\d\d)(:(\d\d))?(\.(\d\d\d)\d*)?$/;

export const JSONParser: JSON = {
  ...JSON,
  stringify: (value: any) => {
    if (!value)
      return JSON.stringify(value);

    if (value.isTimeSpan)
      value = (value as TimeSpan).toJSON();

    if (typeof value.getDate === 'function' && typeof value.getDate() === 'number')
      value = objectHelper.dateToISOString(value as Date);

    return JSON.stringify(value);
  },
  parse: val => JSON.parse(val, (_, value) => {
    if (typeof value !== 'string')
      return value;

    if (dateRegex.test(value))
      return moment.utc(value);

    if (timeSpanRegex.test(value))
      return TimeSpan.parse(value);

    return value;
  })
};