import Intervals from '../../../../base/forms/airdome/intervals';

const intervals: Intervals = {
  statusInterval: 'Status interval',
  plcReadingInterval: 'PLC Reading interval',
  aiInterval: 'AI interval',
  consumptionsInterval: 'Consumptions interval'
};

export default intervals;
