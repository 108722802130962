import * as React from 'react';

import { observer } from 'mobx-react';

const SwitchboxSwitch = <TValue,>({
  currentValue,
  leftValue,
  rightValue,
  topLabel,
  bottomLabel
}: {
  currentValue: TValue,
  leftValue?: TValue,
  rightValue: TValue,
  topLabel?: string, 
  bottomLabel?: string
}) => <div className="switchbox-control">
  {topLabel 
    ? <div className="txt-label" style={{ 
      paddingLeft: leftValue ? undefined : 25
    }}>{topLabel}</div> 
    : null}
  <div className={`btn-switchbox${
    currentValue === leftValue 
      ? ' left' 
      : currentValue === rightValue 
        ? ' right' 
        : ''}`}/>
  {bottomLabel ? <div className="txt-label">{bottomLabel}</div> : null}
</div>;

export default observer(SwitchboxSwitch);