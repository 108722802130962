import AirdomeState from '../../base/enums/AirdomeState';

const airdomeState: AirdomeState = {
  Alive: 'Air dome is currently active',
  HalfDead: 'Air dome is not responding',
  Dead: 'Air dome is not available',
  NotInflated: 'Air dome is not inflated',
  PlcUnavailable: 'PLC of air dome is unavailable',
};

export default airdomeState;
