import './DoorLockSettings.scss';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import ButtonLoading from 'shared/components/ButtonLoading';
import DoorLockCard from 'components/doorLockSettings/DoorLockCard';
import { LinearProgress } from '@material-ui/core';
import { Row } from 'reactstrap';
import { State } from 'stores/shared/NewStatefulFunction';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';
import FormNewChangesDialog from 'components/FormNewChangesDialog';
import { ChangeRequestTypeEnum } from "dto/changeRequest/ChangeRequestTypeEnum";

const DoorLockSettings = () => {
  const translate = useLocalization();
  const doorLockSettingsStore = useStore(x => x.doorLockSettingsStore);

  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  const {
    ChangeRequestStates: {
      DoorLockSettings: isDoorLockSettingsChangeRequestPending
    }
  } = useStore(x => x.changeRequestStore);

  const history = useHistory();

  if (!airdome)
    return null;

  if (!airdome.hasDoorLocking)
    history.push('/');

  const isAnyUpdatePending = isDoorLockSettingsChangeRequestPending || doorLockSettingsStore.newChangesModel.isUpdatePending;

  return (
    <>
      <AirdomePageHeader
        icon={<span className="lnr lnr-enter" />}
        title={`${airdome.name}: ${translate(x => x.pages.doorLockSettings.header)}`}
        additionalContent={<ButtonLoading
          onClick={async () => await doorLockSettingsStore.updateDoorLockSettings()}
          content={translate(x => x.general.update)}
          disabled={isAnyUpdatePending || !doorLockSettingsStore.hasAnyChanges || !doorLockSettingsStore.areNamesValid}
          color="primary" />} />

      <Row>
        {doorLockSettingsStore.fetchDoorLockSettings.state !== State.Processing
          ? doorLockSettingsStore.mainDoorsModel.value.Value
            ? <DoorLockCard {...{
              name: doorLockSettingsStore.mainDoorsModel.name.Value ?? '',
              setName: doorLockSettingsStore.mainDoorsModel.name.setValue,
              doorLockState: doorLockSettingsStore.mainDoorsModel.value.Value,
              setDoorLockState: doorLockSettingsStore.mainDoorsModel.value.setValue,
              disabled: isAnyUpdatePending
            }} />
            : null
          : <LinearProgress />}
      </Row>

      <FormNewChangesDialog
        newChangesModel={doorLockSettingsStore.newChangesModel}
        onConfirm={async () => await doorLockSettingsStore.fetchDoorLockSettings()}
        changeRequestType={ChangeRequestTypeEnum.DoorLockSettings}
      />
    </>
  )
}

export default observer(DoorLockSettings);