import { AxiosInstance } from 'axios';
import LightsDto from 'dto/settings/LightsDto';
import PagingDto from 'services/shared/dtos/PagingDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class LightsService {
  private axios: AxiosInstance;
  private updateLightsUrl: string;
  private getLightsUrl: string;
  private getActiveLightsUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.updateLightsUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.lights.update);
    this.getLightsUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.lights.get);
    this.getActiveLightsUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.lights.getActive);
  }

  public fetchLights = async (airdomeId: number, trackingId: string)
    : Promise<LightsDto> => {
    try {
      const url = stringFormat(this.getLightsUrl, airdomeId.toString());
      const result = await this.axios.get<LightsDto>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public getActiveLights = async (airdomeId: number, trackingId: string): Promise<number> => {
    try {
      const url = stringFormat(this.getActiveLightsUrl, airdomeId.toString());
      const result = await this.axios.get<number>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public fetchLightsHistory = async (airdomeId: number, skip: number, take: number, trackingId: string)
    : Promise<PagingDto<LightsDto>> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.settings.baseUri,
          x => x.settings.lights.getHistory),
        airdomeId.toString(),
        skip.toString(),
        take.toString());

      const result = await this.axios.get<PagingDto<LightsDto>>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public updateLights = async (updatedLights: Omit<LightsDto, 'userId' | 'timestamp'>, airdomeId: number, trackingId: string)
    : Promise<void> => {
    try {
      const url = stringFormat(this.updateLightsUrl, airdomeId.toString());
      await this.axios.put(url, updatedLights, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
