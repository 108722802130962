import stringFormat from 'string-format';
import { AxiosInstance } from 'axios';
import { ChangeRequestTypeEnum } from '../../dto/changeRequest/ChangeRequestTypeEnum';
import { getJoinedEndpoint } from '../../utils/Settings';
import { getBackendException } from '../../helpers/ErrorHelper';

export default class ChangeRequestService {
  private axios: AxiosInstance;
  private isInProgressUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.isInProgressUrl = getJoinedEndpoint(s => s.changeRequest.baseUri, x => x.changeRequest.isInProgress);
  }

  public isChangeRequestInProgress = async (airdomeId: number, changeRequestType: ChangeRequestTypeEnum, trackingId: string)
    : Promise<boolean> => {
    try {
      const url = stringFormat(this.isInProgressUrl, changeRequestType.toString(), airdomeId.toString());
      const result = await this.axios.get<boolean>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
