export default class {
  public localizationKey: string;
  public localizationData: { [key: string]: string | number | boolean | JSX.Element | Date } = {};

  constructor(model: {
    localizationKey: string,
    localizationData?: { [key: string]: string | number | boolean | JSX.Element | Date }
  }) {
    this.localizationKey = model.localizationKey;
    if (model.localizationData)
      this.localizationData = model.localizationData;
  }
}
