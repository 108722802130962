import Airdome from '../../../base/menu/airdome';
import bacnetObjects from './BacnetObjects';
import lighting from './Lighting';
import plcNotifications from './PlcNotifications';
import settings from './Settings';

const airdome: Airdome = {
  lighting,
  settings,
  bacnetObjects,
  plcNotifications,

  create: 'Add New Air Dome',
  list: 'Air domes',
  status: 'Status',
  calendar: 'Calendar',
  consumption: 'Consumption',
  restrictions: 'Restrictions',
  switchbox: 'Switchbox',
  systemLog: 'System Log',
  rdevNotifications: 'RDev Notifications',
  doorLockSettings: 'Door locking',
  preheatingSettings: 'Preheating',

  history: 'History'
};

export default airdome;
