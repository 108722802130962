import { AirdomeModel } from 'models';
import ConditionalRendering from '../../../security/ConditionalRendering';
import SidebarAirdomeMenu from './SidebarAirdomeMenu';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';
import Translate from '../../../localization/Translate';
import { observer } from 'mobx-react';

interface SidebarContentProps {
  selectedAirdome?: AirdomeModel;
  onClick: () => void;
  translate: Translate;
}

const SidebarContent = ({ selectedAirdome, onClick, translate: tr }: SidebarContentProps) =>
  <div className="sidebar__content">
    <ul className="sidebar__block">

      <ConditionalRendering getAccessDefinition={d => d.Users.FullAccess}>
        <SidebarCategory 
          navLinkProps={{
            title: tr(x => x.menu.user.categoryName),
            icon: "users",
            route: "/users",
            onClick,
          }}>
          <SidebarLink title={tr(x => x.menu.user.create)} route="/users/register" onClick={onClick} />
        </SidebarCategory>
      </ConditionalRendering>

      <ConditionalRendering getAccessDefinition={d => d.Airdomes.Read}>
        <SidebarLink title={tr(x => x.menu.airdome.list)} icon="list" route="/airdomes" onClick={onClick} />
      </ConditionalRendering>
      
      <ConditionalRendering getAccessDefinition={d => d.Templates.FullAccess}>
        <SidebarLink title={tr(x => x.menu.template.list)} icon="list" route="/templates" onClick={onClick} />
      </ConditionalRendering>

      <ConditionalRendering getAccessDefinition={d => d.Airdomes.Manage}>
        <SidebarLink
          title={tr(x => x.menu.airdome.create)}
          icon="plus-circle"
          route="/airdome/create"
          onClick={onClick} />
      </ConditionalRendering>

      {/*<SidebarCategory title={tr(x => x.menu.template.categoryName)} icon="layers">
        <SidebarLink title={tr(x => x.menu.template.create)} route="/bacnettemplates/index" onClick={onClick} />
        <SidebarLink title={tr(x => x.menu.template.edit)} route="/bacnettemplates/edit" onClick={onClick} />
      </SidebarCategory>*/}
    </ul>
    <ul className="sidebar__block">
      {selectedAirdome
        ? <SidebarAirdomeMenu
          translate={tr}
          selectedAirdome={selectedAirdome}
          onClick={onClick} />
        : null}
    </ul>
  </div>;

export default observer(SidebarContent);
