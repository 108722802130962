import React from 'react';
import { FormSelect } from '../../..';
import { SelectOptionModel } from '../../../../models';
import Translate from '../../../../localization/Translate';

interface RoleSelectFieldProps {
  translate: Translate;
  roleOptions: string[];
  setRole: (role: string) => void;
  currentRole?: string;
}

const RoleSelectField = ({ translate, roleOptions, setRole, currentRole }: RoleSelectFieldProps) => <FormSelect {...{
  label: translate(x => x.general.user.role),
  selectId: 'UserRole',
  options: roleOptions.map(x =>
    new SelectOptionModel({
      displayName: translate(tr => tr.enums.user.roles[x.toLowerCase()]),
      value: x
    })
  ),
  setValue: setRole,
  selectedValue: currentRole
}} />;

export default React.memo(RoleSelectField);
