import React from 'react';
import InfoFormProps from '../InfoFormProps';
import { TemplateField, LogLevelField, InitializedField } from '../fields';
import { InflatedSubmitFieldset } from '.';

const RightColumnFieldset = (props: InfoFormProps) => <>
  <TemplateField {...props} />
  <LogLevelField {...props} />
  <InitializedField {...props} />
  <InflatedSubmitFieldset {...props} />
</>;

export default RightColumnFieldset;
