import './SettingHistory.scss';

import { Card, CardBody } from 'reactstrap';
import { LinearProgress, TablePagination } from '@material-ui/core';
import React, { useEffect } from 'react';

import AirdomeTime from 'components/airdome/AirdomeTime';
import { MaterialTable } from 'components';
import { SettingsHistoryItemModel } from '../../models/settings/SettingsHistoryModel';
import { State } from 'stores/shared/NewStatefulFunction';
import { TableColumn } from 'components/basic/material-table/table-header';
import { observer } from "mobx-react";
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const SettingsHistory = () => {
  const {
    settingsHistoryModel
  } = useStore(x => x.settingsStore);

  const airdomeStore = useStore(x => x.airdomeStore);
  const hasHeatingEnabled = !!airdomeStore.SelectedAirdome?.hasHeating;

  useEffect(() => {
    settingsHistoryModel.fetch();

    return settingsHistoryModel.clear;
  }, [settingsHistoryModel, airdomeStore.SelectedAirdomeId]);

  const translate = useLocalization();

  const heatingColumns = () : TableColumn<SettingsHistoryItemModel>[] => 
    hasHeatingEnabled 
    ? [
        { 
          id: 'tempMode', 
          disablePadding: false,
          label: translate(x => x.pages.settings.history.temperatureMode),
          renderCell: (item: SettingsHistoryItemModel) => <p className='text-center'>{
            item.temperatureMode !== undefined
              ? translate(x => x.enums.temperatureModes[item.temperatureMode!])
              : translate(x => x.enums.temperatureModes.disabled)
          }</p> 
        }, 
        { 
          id: 'tempComfort', 
          disablePadding: false,
          label: translate(x => x.pages.settings.history.temperatureComfort),
          renderCell: (item: SettingsHistoryItemModel) => <p className='text-center'>{item.temperatureComfort}</p> 
        },                     
        { 
          id: 'tempCutback', 
          disablePadding: false,
          label: translate(x => x.pages.settings.history.temperatureCutback),
          renderCell: (item: SettingsHistoryItemModel) => <p className='text-center'>{item.temperatureCutback ?? '-'}</p> 
        }
      ] as TableColumn<SettingsHistoryItemModel>[]
    : [] as TableColumn<SettingsHistoryItemModel>[];

  return <>
    <Card className="LightingHistoryHeader">
      <CardBody>
        <h2 className="d-inline-block">
          {airdomeStore.SelectedAirdome?.name}: {translate(x => x.pages.settings.history.subheader)}
        </h2>
      </CardBody>
    </Card>
    <Card className="LightingHistoryCard">
      <CardBody>
        {
          settingsHistoryModel.fetch.state === State.Processing 
          ? <LinearProgress />
          : null
        }
        <MaterialTable<SettingsHistoryItemModel>
          disableSorting
          showPagination={false}
          showCheckboxColumn={false}
          clickRowToSelect={false}
          multiselect={false}
          data={settingsHistoryModel.settingsEntries}
          columns={[  
            ...heatingColumns(),
            { 
              id: 'pressureMode', 
              disablePadding: false,
              label: translate(x => x.pages.settings.history.pressureMode),
              renderCell: (item: SettingsHistoryItemModel) => <p className='text-center'>
                { translate(x => x.enums.pressureModes[item.pressureMode]) }
              </p> 
            },  
            { 
              id: 'pressureMax', 
              disablePadding: false,
              label: translate(x => x.pages.settings.history.pressureMax),
              renderCell: (item: SettingsHistoryItemModel) => <p className='text-center'>{item.pressureMax}</p> 
            },
            { 
              id: 'pressureNormal', 
              disablePadding: false,
              label: translate(x => x.pages.settings.history.pressureNormal),
              renderCell: (item: SettingsHistoryItemModel) => <p className='text-center'>{item.pressureNormal}</p> 
            },     
            { 
              id: 'timestamp', 
              disablePadding: false, 
              label: translate(x => x.pages.settings.history.lastUpdate), 
              renderCell: (item: SettingsHistoryItemModel) => <p className="text-center"><AirdomeTime time={item.timestamp}/></p>
            },
            { 
              id: 'updatedBy', 
              disablePadding: false, 
              label: translate(x => x.pages.settings.history.updatedBy), 
              renderCell: (item: SettingsHistoryItemModel) => <p className="text-center">{item.userName}</p>
            }
          ].filter(x => !!x) as TableColumn<SettingsHistoryItemModel>[]}/>
        {settingsHistoryModel.totalSettingsCount > 0 && <TablePagination
          component="div"
          className="material-table__pagination mt-2"
          count={settingsHistoryModel.totalSettingsCount}
          rowsPerPage={settingsHistoryModel.pagingModel.rowsPerPage}
          page={settingsHistoryModel.pagingModel.currentPage}
          backIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.previousPage) }}
          nextIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.nextPage) }}
          onChangePage={(_, page) => settingsHistoryModel.pagingModel.setCurrentPage(page)}
          onChangeRowsPerPage={(event) => settingsHistoryModel.pagingModel.setRowsPerPage(Number(event.target.value))}
          rowsPerPageOptions={[
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 50, label: "50" }
          ]}
          labelRowsPerPage={translate(x => x.general.datatables.numberOfRowsShown)}
          labelDisplayedRows={(paginationInfo): React.ReactNode => translate(x => x.general.datatables.displayedRows,
            {
              from: paginationInfo.from,
              to: paginationInfo.to,
              page: paginationInfo.page + 1,
              count: paginationInfo.count
            })
          }
        />}
      </CardBody>
    </Card>
  </>;
}

export default observer(SettingsHistory);