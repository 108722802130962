import React from 'react';
import ReactChildrenProp from 'common/ReactChildrenProp';
import SupportedLocale from 'enums/SupportedLocale';
import Translation from 'localization/base';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useStore } from 'stores/MobXTypedStoreHook';

export type GetTranslation = (translation: Translation) => string;

export type Translate = (getTranslation: GetTranslation, data?: any, locale?: SupportedLocale) => string;

export type GetTranslate = () => Translate;

const LocalizationContext = React.createContext<GetTranslate>(() => () => "");

export const useLocalization = (): Translate =>
  React.useContext(LocalizationContext)();

export const LocalizationProvider = observer(
  (props: { children: ReactChildrenProp }) => {
    const languageStore = useStore(x => x.languageStore);
    moment.locale(languageStore.Locale);

    return (
      <LocalizationContext.Provider value={() => languageStore.translate}>
        {props.children}
      </LocalizationContext.Provider>
    )
  });
