import * as validations from '../../validations/validations';

import { action, computed, observable } from 'mobx';

import InputModel from 'models/InputModel';
import { NumberSchema } from 'yup';
import TemperatureRestrictionsDto from '../../dto/settings/TemperatureRestrictionsDto';

const defaultMinTemperature = -100;
const defaultMaxTemperature = 100;

const isNumber = (value: any) =>
  (typeof value === 'number' && !Number.isNaN(value))
  || typeof value === 'bigint';

export default class TemperatureRestrictionsModel {
  @computed public get comfortTemperatureMinSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: this.isComfortTemperatureMinEnabled.Value,
      min: defaultMinTemperature,
      max: this.isComfortTemperatureMaxEnabled
        ? this.comfortTemperatureMax.Value || defaultMaxTemperature
        : defaultMaxTemperature,
      strict: true
    });
  }

  @computed public get comfortTemperatureMaxSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: this.isComfortTemperatureMaxEnabled.Value,
      min: this.isComfortTemperatureMinEnabled
        ? this.comfortTemperatureMin.Value || defaultMinTemperature
        : defaultMinTemperature,
      max: defaultMaxTemperature,
      strict: true
    });
  }

  @computed public get cutbackTemperatureMinSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: this.isCutbackTemperatureMinEnabled.Value,
      min: defaultMinTemperature,
      max: this.isCutbackTemperatureMaxEnabled
        ? this.cutbackTemperatureMax.Value || defaultMaxTemperature
        : defaultMaxTemperature,
      strict: true
    });
  }

  @computed public get cutbackTemperatureMaxSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: this.isCutbackTemperatureMaxEnabled.Value,
      min: this.isCutbackTemperatureMinEnabled
        ? this.cutbackTemperatureMin.Value || defaultMinTemperature
        : defaultMinTemperature,
      max: defaultMaxTemperature,
      strict: true
    });
  }

  private originalValues?: TemperatureRestrictionsDto;

  @observable public isComfortTemperatureMinEnabled = new InputModel<boolean>();
  @observable public isComfortTemperatureMaxEnabled = new InputModel<boolean>();
  @observable public isCutbackTemperatureMinEnabled = new InputModel<boolean>();
  @observable public isCutbackTemperatureMaxEnabled = new InputModel<boolean>();
  @observable public comfortTemperatureMin = new InputModel<number>();
  @observable public comfortTemperatureMax = new InputModel<number>();
  @observable public cutbackTemperatureMin = new InputModel<number>();
  @observable public cutbackTemperatureMax = new InputModel<number>();

  @action public clear(): void {
    this.isComfortTemperatureMinEnabled.clear();
    this.isComfortTemperatureMaxEnabled.clear();
    this.isCutbackTemperatureMinEnabled.clear();
    this.isCutbackTemperatureMaxEnabled.clear();
    this.comfortTemperatureMin.clear();
    this.comfortTemperatureMax.clear();
    this.cutbackTemperatureMin.clear();
    this.cutbackTemperatureMax.clear();
  }

  @action public resetToOriginalValues(): void {
    this.clear();
    if (this.originalValues)
      this.importFromDto(this.originalValues);
  }

  public importFromDto = (dto: TemperatureRestrictionsDto) => {
    this.originalValues = { ...dto };

    this.isComfortTemperatureMinEnabled.setValue(isNumber(dto.comfortTemperatureMin), false);
    this.isComfortTemperatureMaxEnabled.setValue(isNumber(dto.comfortTemperatureMax), false);
    this.isCutbackTemperatureMinEnabled.setValue(isNumber(dto.cutbackTemperatureMin), false);
    this.isCutbackTemperatureMaxEnabled.setValue(isNumber(dto.cutbackTemperatureMax), false);

    this.comfortTemperatureMin.setValue(isNumber(dto.comfortTemperatureMin) ? Number(dto.comfortTemperatureMin) : undefined, false);
    this.comfortTemperatureMax.setValue(isNumber(dto.comfortTemperatureMax) ? Number(dto.comfortTemperatureMax) : undefined, false);
    this.cutbackTemperatureMin.setValue(isNumber(dto.cutbackTemperatureMin) ? Number(dto.cutbackTemperatureMin) : undefined, false);
    this.cutbackTemperatureMax.setValue(isNumber(dto.cutbackTemperatureMax) ? Number(dto.cutbackTemperatureMax) : undefined, false);
  }

  public getDto = (): TemperatureRestrictionsDto | undefined =>
    this.areDataValid
      ? ({
        comfortTemperatureMax: this.isComfortTemperatureMaxEnabled.Value
          ? this.comfortTemperatureMax.Value
          : undefined,
        comfortTemperatureMin: this.isComfortTemperatureMinEnabled.Value
          ? this.comfortTemperatureMin.Value
          : undefined,
        cutbackTemperatureMax: this.isCutbackTemperatureMaxEnabled.Value
          ? this.cutbackTemperatureMax.Value
          : undefined,
        cutbackTemperatureMin: this.isCutbackTemperatureMinEnabled.Value
          ? this.cutbackTemperatureMin.Value
          : undefined
      })
      : undefined

  @computed public get anyValueChanged(): boolean {
    return this.comfortTemperatureMin.ValueChanged
      || this.comfortTemperatureMax.ValueChanged
      || this.cutbackTemperatureMin.ValueChanged
      || this.cutbackTemperatureMax.ValueChanged
      || this.isComfortTemperatureMinEnabled.ValueChanged
      || this.isComfortTemperatureMaxEnabled.ValueChanged
      || this.isCutbackTemperatureMinEnabled.ValueChanged
      || this.isCutbackTemperatureMaxEnabled.ValueChanged;
  }

  @computed public get areDataValid(): boolean {
    return (
      this.anyValueChanged &&
      this.comfortTemperatureMinSchema.isValidSync(this.comfortTemperatureMin.Value) &&
      this.comfortTemperatureMaxSchema.isValidSync(this.comfortTemperatureMax.Value) &&
      this.cutbackTemperatureMinSchema.isValidSync(this.cutbackTemperatureMin.Value) &&
      this.cutbackTemperatureMaxSchema.isValidSync(this.cutbackTemperatureMax.Value)
    );
  }
}
