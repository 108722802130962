import moment from 'moment';
import BaseChart, { RenderLineProps } from './base/BaseChart';

interface AirdomeHeightNode {
  timestamp: moment.Moment;
  airdomeHeight: number;
}

class AirdomeHeightChart extends BaseChart<AirdomeHeightNode> {
  protected getTitle = () =>
    this.props.translate(x => x.pages.status.charts.airdomeHeightChartTitle)

  protected getLines = (): RenderLineProps<AirdomeHeightNode>[] => [{
    dataKey: x => x.airdomeHeight,
    stroke: 'orange',
    name: this.props.translate(x => x.pages.status.height),
    unit: 'm'
  }]
}

export default AirdomeHeightChart;
