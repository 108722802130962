import Temperature from '../../../../base/forms/airdome/restrictions/Temperature';

const temperature: Temperature = {
  header: 'Beschränken Sie den Bereich der Temperatureinstellungen für den Airdome',
  comfortTemperatureMin: 'Minimale Wohlfühltemperatur (°C)',
  comfortTemperatureMax: 'Maximale Wohlfühltemperatur (°C)',
  cutbackTemperatureMin: 'Minimale Absenktemperatur (°C)',
  cutbackTemperatureMax: 'Maximale Absenktemperatur (°C)'
};

export default temperature;
