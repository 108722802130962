import Create from '../../../../base/forms/airdome/create';

const create: Create = {
  header: 'Add new Air dome',
  submit: 'Add',
  supportAi: 'Enable artificial intelligence',
  cannotHaveAi:
    'Selected bacnet template does not support AI functionality.',
  numberOfMainLights: 'Number of main lights',
  numberOfAdditionalLights: 'Number of additional lights',
  cannotHaveLighting: 'Selected bacnet template does not support lighting.',
  cannotHaveHeating: 'Selected bacnet template does not support heating.',
  
  hasHeating: 'Heating',
  hasPreheating: 'Preheating',
  hasDoorLocking: 'Door locking',
  hasElectricityConsumption: 'Electricity consumption',
  hasGasConsumption: 'Gas consumption'
};

export default create;
