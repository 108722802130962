import BaseStore, { Params } from './base/BaseStore';
import { action, observable, reaction } from 'mobx';

import AirdomeStore from './AirdomeStore';
import NotificationsHistoryItemDto from 'dto/notification/NotificationsHistoryItemDto';
import { NotificationsService } from '../services';
import PagingModel from '../models/store/PagingModel';
import { asTracked } from './decorators/TrackedDecorator';
import moment from 'moment';

interface NotificationsHistoryStoreParams {
  airdomeStore: AirdomeStore;
  notificationsService: NotificationsService;
}

export default class NotificationsHistoryStore extends BaseStore<NotificationsHistoryStoreParams> {
  readonly pagingModel = new PagingModel();

  public constructor(params: Params<NotificationsHistoryStoreParams>) {
    super(params);

    this.start = this.roundNextHour().add(-1, 'month');
    this.end = this.roundNextHour();

    reaction(() => ({
      _: this.pagingModel.currentPage,
      __: this.pagingModel.rowsPerPage
    }),
      async (_, __) => {
        await this.fetch();
      });
  }

  @observable
  public totalCount: number = 0;

  @observable
  public items: NotificationsHistoryItemDto[] = [];

  @observable
  public start: moment.Moment;

  @observable
  public end: moment.Moment;

  public get endMax() {
    return this.roundNextHour();
  }

  @action
  public setStart = (value: moment.Moment) => {
    return this.start = value;
  };

  @action
  public setEnd = (value: moment.Moment) => {
    return this.end = value;
  };

  @action
  public clear = () => {
    this.totalCount = 0;
    this.items = [];

    this.pagingModel.clear();
  }

  private roundNextHour = (): moment.Moment => {
    const m = moment();
    return m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
  }

  public fetch = asTracked(action(async () => {
    if (!this.params.airdomeStore.SelectedAirdomeId)
      throw new Error('No airdome selected');

    const trackingId = this.params.getTrackingId();

    const result = await this.params.notificationsService.getNotificationsHistory(
      this.params.airdomeStore.SelectedAirdomeId,
      this.start.toDate(),
      this.end.toDate(),
      this.pagingModel.Skip,
      this.pagingModel.Take,
      trackingId);

    this.totalCount = result.count;
    this.items = result.notifications;
  }));
}