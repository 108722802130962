import AirdomeHubConnection from './base/AirdomeHubConnection';
import AirdomeStore from 'stores/AirdomeStore';
import BacnetObjectValue from './dto/BacnetObjectValue';
import SecurityStore from 'stores/SecurityStore';
import { getJoinedEndpoint } from '../../utils/Settings';

type Events = 'BacnetObjectValuesRead';
type Methods = '';

export default class BacnetObjectsHubConnection extends AirdomeHubConnection<Events, Methods> {
  constructor(airdomeStore?: AirdomeStore, securityStore?: SecurityStore) {
    super(
      getJoinedEndpoint(x => x.signalr.baseUri, x => x.signalr.airdome.bacnetObjects),
      airdomeStore,
      securityStore);
  }

  public onBacnetObjectValuesRead = (callback: (values: BacnetObjectValue[]) => void) => {
    super.on('BacnetObjectValuesRead', callback);
  }
}
