export enum ChangeRequestTypeEnum {
  BacnetObjectWrite = 'BacnetObjectWrite',
  Settings = 'Settings',
  Lights = 'Lights',
  Schedule = 'Schedule',
  Intervals = 'Intervals',
  LogLevel = 'LogLevel',
  ResolveNotifications = 'ResolveNotifications',
  DoorLockSettings = 'DoorLockSettings',
  PreheatingSettings = 'PreheatingSettings'
}

export const changeRequestTypes = [
  ChangeRequestTypeEnum.BacnetObjectWrite,
  ChangeRequestTypeEnum.Settings,
  ChangeRequestTypeEnum.Lights,
  ChangeRequestTypeEnum.Schedule,
  ChangeRequestTypeEnum.Intervals,
  ChangeRequestTypeEnum.LogLevel,
  ChangeRequestTypeEnum.ResolveNotifications,
  ChangeRequestTypeEnum.DoorLockSettings,
  ChangeRequestTypeEnum.PreheatingSettings
]
