import { AxiosInstance } from "axios";
import { StatusAirdomeDto } from "../../dto/status";
import { getBackendException } from "../../helpers/ErrorHelper";
import { getJoinedEndpoint } from "./../../utils/Settings";

export default class StatusAirdomeService {
  private axios: AxiosInstance;
  private getAirdomesUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getAirdomesUrl = getJoinedEndpoint(
      (x) => x.status.baseUri,
      (x) => x.status.airdomes.getAll
    );
  }

  public async getAirdomes(trackingId: string): Promise<StatusAirdomeDto[]> {
    try {
      const response = await this.axios.get<StatusAirdomeDto[]>(
        this.getAirdomesUrl,
        {
          headers: {
            trackingId,
          },
          params: {
            excludeHeartbeatData: true,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }
}
