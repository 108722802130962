import LightState from '../../base/enums/LightState';

const lightState: LightState = {
  1: {
    short: 'Auto',
    full: 'Auto'
  },
  2: {
    short: 'Vyp.',
    full: 'Vypnuto'
  },
  3: {
    short: 'Zap.',
    full: 'Zapnuto'
  }
};

export default lightState;
