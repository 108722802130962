import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import AirdomeStateExpand from 'components/airdome/AirdomeStateExpand';
import ButtonLoading from 'shared/components/ButtonLoading';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import ConditionalRendering from 'security/ConditionalRendering';
import NotificationAlert from 'components/status/NotificationAlert';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const PlcNotificationsPage = () => {
  const airdomeStore = useStore(x => x.airdomeStore);
  const notificationStore = useStore(x => x.notificationStore);
  const changeRequestStore = useStore(x => x.changeRequestStore);
  const translate = useLocalization();

  const hasNotifications = notificationStore.notifications.length > 0;
  const isResolvingDisabled =
    changeRequestStore.ChangeRequestStates[ChangeRequestTypeEnum.ResolveNotifications]
    ?? true;

  return <>
    <AirdomePageHeader
      icon={<span className="lnr lnr-warning" />}
      title={translate(x => x.pages.plcNotifications.header, { name: airdomeStore.SelectedAirdome?.name })}
      additionalContent={<div className="d-flex flex-wrap">
        <span className="mx-md-3 ml-auto my-auto bold-text">
          {translate(
            x => x.pages.plcNotifications.activeNotifications,
            { count: notificationStore.notifications.length }
          )}
        </span>
        <span className="mx-md-3 ml-auto my-auto">
          {translate(
            x => x.pages.plcNotifications.resolvedNotifications,
            { count: notificationStore.resolvedNotificationsCount }
          )}
        </span>
        <ConditionalRendering getAccessDefinition={x => x.Notifications.Plc.Resolve.All}>
          <AirdomeStateExpand
            disabled={isResolvingDisabled}
            className="ml-md-3 ml-auto my-auto"
            onClick={async () => await notificationStore.resolveAllNotifications()}
            enabledTitle={translate(x => x.pages.plcNotifications.resolveAllNotifications)}/>
        </ConditionalRendering>
      </div>}
    />
    <Card>
      <CardBody>
        {
          hasNotifications
            ? notificationStore.notifications
              .sort((a, b) => b.occurred.valueOf() - a.occurred.valueOf())
              .map((x, i) =>
                <NotificationAlert
                  key={`${x.bacnetObjectId}-${x.bacnetType}-${x.state}`}
                  notification={x}
                  onDismiss={{
                    isDisabled: isResolvingDisabled,
                    execute: () => notificationStore.resolveNotification(x)
                  }} />
              )
            : <div className="d-flex flex-column align-items-center">
              <CheckCircleIcon size={60} className="d-block" color="#4caf50" />
              <h3>{translate(x => x.pages.status.noNotifications)}</h3>
            </div>
        }
      </CardBody>
    </Card>
  </>;
}

export default observer(PlcNotificationsPage);