import PreheatingSettings from 'localization/base/pages/PreheatingSettings';

const preheatingSettings: PreheatingSettings = {
  header: 'Preheating',
  historyHeader: 'Preheating history',
  maximalPreheatingMinutes: 'Maximum pre-heating time (min.)',
  minimumPreheatingMinutes: 'Minimum pre-heating time (min.)',
  isPreheatingEnabled: "Preheating enabled",
  timestamp: 'Last update',
  updatedBy: 'Updated by',
};

export default preheatingSettings;
