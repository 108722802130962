import {
  ProcessingObservable,
  StatefulFunction,
  createStatefulFunction,
  defineProcessingOnStatefulFunction,
  processingObservableProperty
} from '../shared/NewStatefulFunction';

import { IObservableObject } from 'mobx';

export interface Descriptor {
  value?: Function;
  initializer?: () => Function;
}

export type FunctionType<TResult> = ((...args: any[]) => TResult | Promise<TResult>);

export default abstract class DecoratorBase<TOwner> {
  protected abstract implementation<TResult>(wrapper: StatefulFunction, method: FunctionType<TResult>): Promise<TResult>;

  private wrapMethod = (wrapper: StatefulFunction, method: Function) =>
    async (store: TOwner, ...args: any[]) =>
      await this.implementation(wrapper, async () =>
        await method.bind(store)(...args))

  public handle = (
    _: object,
    __: string,
    descriptor?: {
      value?: Function; initializer?: () => Function;
    }) => {
    if (!descriptor)
      return;
    if (descriptor.initializer)
      this.prepareDescriptorInitializer(descriptor);
    else if (descriptor.value)
      this.prepareDescriptorMethod(descriptor);
  }

  private prepareDescriptorMethod = (descriptor: Descriptor) => {
    const originalMethod = descriptor.value!;
    descriptor.value = this.getWrapper(() => originalMethod);
  }

  private prepareDescriptorInitializer = (descriptor: Descriptor) => {
    const getOriginalMethod = descriptor.initializer!;
    descriptor.initializer = () => this.getWrapper(getOriginalMethod);
  }

  protected getWrapper = (getOriginalMethod: Function) => {
    let wrapper: StatefulFunction;
    const wrapMethod = this.wrapMethod;

    // eslint-disable-next-line prefer-const
    wrapper = createStatefulFunction(function (this: TOwner, ...args: any[]) {
      const method = getOriginalMethod.bind(this)();

      if (method.isNewStateful) {
        const statefulFunction = method as StatefulFunction;
        const originalObservable = Object
          .getOwnPropertyDescriptor(statefulFunction, processingObservableProperty)!.get!() as ProcessingObservable & IObservableObject;

        defineProcessingOnStatefulFunction(wrapper, originalObservable);
      }

      return wrapMethod(wrapper, method)(this, ...args);
    });

    return wrapper;
  }
}
