import NotificationDto from 'dto/notification/NotificationDto';
import PersistedHubConnection from './base/PersistedHubConnection';
import ResolvedNotificationDto from 'dto/notification/ResolvedNotificationDto';
import SecurityStore from 'stores/SecurityStore';
import SignalRNotificationDto from './dto/notification/NotificationDto';
import SignalRResolvedNotificationDto from './dto/notification/ResolvedNotificationDto';
import { getJoinedEndpoint } from 'utils/Settings';
import moment from 'moment';

type PlcNotifications = 'NotificationOccurred' | 'NotificationResolved' | 'NotificationsResolved';

type PlcNotificationsMethods = '';

export default class PlcNotificationHubConnection extends PersistedHubConnection<PlcNotifications, PlcNotificationsMethods> {
  constructor(securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.plcNotifications
    ), securityStore);
  }

  public onNotificationOccurred = (callback: (airdomeId: number, notification: Readonly<NotificationDto>) => void) =>
    super.on('NotificationOccurred', (airdomeId: number, notification: SignalRNotificationDto) => callback(airdomeId, {
      ...notification,
      occurred: moment(notification.occurred),
      resolved: notification.resolved
        ? moment(notification.resolved)
        : undefined
    }));

  public onNotificationResolved = (callback: (airdomeId: number, notification: Readonly<ResolvedNotificationDto>) => void) =>
    super.on('NotificationResolved', (airdomeId: number, notification: SignalRResolvedNotificationDto) => callback(airdomeId, {
      ...notification,
      occurred: moment(notification.occurred),
      resolved: moment(notification.resolved)
    }));

  public onNotificationsResolved = (callback: (airdomeId: number, notification: Readonly<ResolvedNotificationDto>[]) => void) =>
    super.on('NotificationsResolved', (airdomeId: number, notifications: SignalRResolvedNotificationDto[]) => callback(airdomeId,
      notifications.map(notification => ({
        ...notification,
        occurred: moment(notification.occurred),
        resolved: moment(notification.resolved)
      }))));
}
