import { Card, CardBody, Col, Row } from 'reactstrap';

import ButtonLoading from '../../../shared/components/ButtonLoading';
import ConditionalRendering from '../../../security/ConditionalRendering';
import Translate from '../../../localization/Translate';

interface SettingsHeaderProps {
  disabled?: boolean;
  translate: Translate;
  airdomeTitle?: string;
  onRestart: () => Promise<void>;
}

const SettingsHeader = (props: SettingsHeaderProps) =>
  <div className="SettingsHeader w-100">
    <Card>
      <CardBody>
        <Row>
          <h2 className="d-inline-block">
            <span className="lnr lnr-cog" />{props.airdomeTitle}
          </h2>
          <ConditionalRendering getAccessDefinition={d => d.Settings.RestartRDev}>
            <ButtonLoading
              disabled={props.disabled}
              onClick={async () => await props.onRestart()}
              content={props.translate(x => x.pages.settings.restartDevice)}
              color="danger" />
          </ConditionalRendering>
        </Row>
      </CardBody>
    </Card>
  </div>;

export default SettingsHeader;
