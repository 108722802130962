import ArtificialIntelligence from '../../../../base/forms/airdome/artificialIntelligence';

const artificialIntelligence: ArtificialIntelligence = {
  constantsHeader: 'Standard-KI-Heizkonstanten',
  mainHeader: 'Künstliche Intelligenz',
  intakeMultiplier: 'Einlassmultiplikator',
  intakeIntegration: 'Einlassintegration',
  spaceMultiplier: 'Raummultiplikator',
  spaceIntegration: 'Raumintegration'
};

export default artificialIntelligence;
