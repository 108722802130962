import moment from 'moment';
import objectHelper from '../utils/ObjectHelper';
import { timeSpanRegex } from '../utils/JSONParser';

export default class TimeSpan {
  public readonly isTimeSpan = true;

  public readonly totalMilliseconds: number;

  public constructor(totalMilliseconds = 0) {
    this.totalMilliseconds = totalMilliseconds;
  }

  public get days(): number {
    return Math.floor(this.totalDays);
  }

  public get totalDays(): number {
    return this.totalHours / 24;
  }

  public get hours(): number {
    return Math.floor(this.totalHours % 24);
  }

  public get totalHours(): number {
    return this.totalMinutes / 60;
  }

  public get minutes(): number {
    return Math.floor(this.totalMinutes % 60);
  }

  public get totalMinutes(): number {
    return this.totalSeconds / 60;
  }

  public get seconds(): number {
    return Math.floor(this.totalSeconds % 60);
  }

  public get unix(): number {
    return Math.floor(this.totalSeconds);
  }

  public get totalSeconds(): number {
    return this.totalMilliseconds / 1000;
  }

  public get milliseconds(): number {
    return this.totalMilliseconds % 1000;
  }

  public static add = (a: TimeSpan, b: TimeSpan) =>
    new TimeSpan(a.totalMilliseconds + b.totalMilliseconds);

  public static subtract = (a: TimeSpan, b: TimeSpan) =>
    new TimeSpan(a.totalMilliseconds - b.totalMilliseconds);

  public static between = (a: Date, b: Date) =>
    new TimeSpan(Math.abs(a.getTime() - b.getTime()));

  public static from = (days: number, hours: number, minutes: number, seconds: number, milliseconds: number) =>
    new TimeSpan(
      (((days
        * 24 + hours)
        * 60 + minutes)
        * 60 + seconds)
      * 1000 + milliseconds
    )

  public static fromDate = (date: Date) =>
    new TimeSpan(date.getTime());

  public static fromMoment = (date: moment.Moment) =>
    new TimeSpan(date.valueOf());

  public static fromDays = (days: number) =>
    TimeSpan.fromHours(days * 24);

  public static fromHours = (hours: number) =>
    TimeSpan.fromMinutes(hours * 60);

  public static fromMinutes = (minutes: number) =>
    TimeSpan.fromSeconds(minutes * 60);

  public static fromSeconds = (seconds: number) =>
    new TimeSpan(seconds * 1000);

  public static parse = (value: string) => {
    const result = timeSpanRegex.exec(value);

    if (!result)
      throw new Error('Invalid value supplied when parsing');

    const days = Number(result[2]) || 0;
    const hours = Number(result[3]);
    const minutes = Number(result[4]);
    const seconds = Number(result[6]) || 0;
    const milliseconds = Number(result[8]) || 0;

    return TimeSpan.from(days, hours, minutes, seconds, milliseconds);
  }

  public toJSON = () =>
    this.toString();

  public toString = () => {
    const days = this.days !== 0
      ? `${this.days.toString()}.`
      : '';

    const hours = objectHelper.justifyLeft(this.hours.toString(), 2, '0');
    const minutes = objectHelper.justifyLeft(this.minutes.toString(), 2, '0');

    const seconds = this.seconds !== 0 || this.milliseconds !== 0
      ? `:${objectHelper.justifyLeft(this.seconds.toString(), 2, '0')}`
      : '';

    const milliseconds = this.milliseconds !== 0
      ? `.${objectHelper.justifyLeft(this.milliseconds.toString(), 3, '0')}`
      : '';

    return `${days}${hours}:${minutes}${seconds}${milliseconds}`;
  }

  public toLocalizedString = (): string =>
    moment(this.totalMilliseconds).utc().format("LT");

  public equals = (other: TimeSpan) =>
    this.totalMilliseconds === other.totalMilliseconds;
}

TimeSpan.prototype.toString = function (this: TimeSpan) {
  return this.toString();
}
