import Lighting from 'localization/base/pages/lighting';

const lighting: Lighting = {
  history: {
    timestampHeader: 'Last update',
    updatedByHeader: 'Updated by',
    subHeader: 'Lights settings history'
  }
}

export default lighting;