export interface AccessDefinition {
  claimType: string;
  claimValue: string;
}

const accessDefinitions = {
  Users: {
    FullAccess: <AccessDefinition>{ claimType: 'users', claimValue: 'full_access' }
  },
  Templates: {
    FullAccess: <AccessDefinition>{ claimType: 'templates', claimValue: 'full_access' }
  },
  Airdomes: {
    Read: <AccessDefinition>{ claimType: 'airdomes', claimValue: 'read' },
    Manage: <AccessDefinition>{ claimType: 'airdomes', claimValue: 'manage' }
  },
  Status: {
    Read: <AccessDefinition>{ claimType: 'status', claimValue: 'read' }
  },
  Settings: {
    Read: <AccessDefinition>{ claimType: 'settings', claimValue: 'read' },
    Modes: <AccessDefinition>{ claimType: 'settings', claimValue: 'modes' },
    Edit: <AccessDefinition>{ claimType: 'settings', claimValue: 'edit' },
    Intervals: <AccessDefinition>{ claimType: 'settings', claimValue: 'intervals' },
    AirdomeUsersRead: <AccessDefinition>{ claimType: 'settings', claimValue: 'airdome_users_read' },
    AirdomeUsersManage: <AccessDefinition>{ claimType: 'settings', claimValue: 'airdome_users_manage' },
    RestartRDev: <AccessDefinition>{ claimType: 'settings', claimValue: 'restart_rdev' }
  },
  Bacnet: {
    FullAccess: <AccessDefinition>{ claimType: 'bacnet', claimValue: 'full_access' }
  },
  Restrictions: {
    Read: <AccessDefinition>{ claimType: 'restrictions', claimValue: 'read' },
    PressureManage: <AccessDefinition>{ claimType: 'restrictions', claimValue: 'pressure_manage' },
    TemperatureManage: <AccessDefinition>{ claimType: 'restrictions', claimValue: 'temp_manage' }
  },
  Lights: {
    FullAccess: <AccessDefinition>{ claimType: 'lights', claimValue: 'full_access' }
  },
  Consumptions: {
    Read: <AccessDefinition>{ claimType: 'consumptions', claimValue: 'read' }
  },
  Schedule: {
    FullAccess: <AccessDefinition>{ claimType: 'schedule', claimValue: 'full_access' }
  },
  SwitchBox: {
    Read: <AccessDefinition>{ claimType: 'switchbox', claimValue: 'read' }
  },
  Notifications: {
    RDev: <AccessDefinition>{ claimType: 'notifications', claimValue: 'rdev' },
    Plc: {
      Read: <AccessDefinition>{ claimType: 'notifications', claimValue: 'plc' },
      Resolve: {
        Single: <AccessDefinition>{ claimType: 'notifications', claimValue: 'resolve_single' },
        All: <AccessDefinition>{ claimType: 'notifications', claimValue: 'resolve_all' }
      }
    }
  },
  AI: {
    Enable: <AccessDefinition>{ claimType: 'ai', claimValue: 'enable' },
    Edit: <AccessDefinition>{ claimType: 'ai', claimValue: 'edit' }
  },
  DoorLocking: {
    FullAccess: <AccessDefinition>{ claimType: 'door_locking', claimValue: 'full_access' }
  },
  PreheatingSettings: {
    FullAccess: <AccessDefinition>{ claimType: 'preheating', claimValue: 'full_access' }
  }
};

export default accessDefinitions;