import { Card, CardBody, Col } from 'reactstrap';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import React, { useState } from 'react';

import CardTitle from 'reactstrap/lib/CardTitle';
import CollapseIcon from 'mdi-react/ArrowDownDropCircleOutlineIcon';
import ExpandIcon from 'mdi-react/ArrowRightDropCircleOutlineIcon';
import SupportedLocale from 'enums/SupportedLocale';
import Translate from '../../../localization/Translate';
import moment from 'moment';

export interface RenderLineProps<TData> {
  dataKey: (item: TData) => string | number | null;
  stroke: string;
  name: string;
  unit: string;
}

interface BaseChartProps<TData> {
  data: TData[];
  translate: Translate;
  locale: SupportedLocale;
}

abstract class BaseChart<TData extends object>
  extends React.Component<BaseChartProps<TData>, { chart?: JSX.Element }> {

  protected containerRef: React.RefObject<HTMLDivElement>;
  private updateChartTimeout?: NodeJS.Timeout;

  constructor(props: any) {
    super(props);

    this.state = { };
    this.containerRef = React.createRef();

    window.addEventListener('resize', () => {
      if (this.state.chart)
        this.delayedUpdateChart(this.props.data);
    });
  }

  public componentWillUnmount() {
    this.setState = () => { };
    if (this.updateChartTimeout)
      clearTimeout(this.updateChartTimeout);
  }

  public componentDidMount() {
    if (!this.state.chart)
      this.updateChart(this.props.data);
  }

  public componentWillUpdate = (nextProps: { data: TData[], locale: SupportedLocale }) => {
    if (this.props.data.length !== nextProps.data.length
      || this.props.locale !== nextProps.locale)
      this.updateChart(nextProps.data);
  }

  private delayedUpdateChart = (data: TData[]) => {
    if (this.updateChartTimeout)
      clearTimeout(this.updateChartTimeout);

    this.updateChartTimeout = setTimeout(() => this.updateChart(data), 500);
  }

  private updateChart = (data: TData[]) => {
    const width = this.containerRef.current ? this.containerRef.current.clientWidth : 100;
    this.setState({
      chart: this.renderChart(width, data)
    });
  }

  private unixUTCTimestampToLocalFormat = (x: number) =>
    moment.unix(x).locale(this.props.locale).format('l LT')

  private renderChart = (width: number, data: TData[]) => {
    const height = width / Math.log(width / 100);
    return <LineChart
      width={width}
      height={height}
      style={{ height: `${height + 20}px` }}
      data={data}>
      <CartesianGrid strokeDasharray="3 3" stroke="#333" />
      <XAxis
        tickSize={12}
        type="number"
        domain={['dataMin', 'dataMax']}
        tickFormatter={x => this.unixUTCTimestampToLocalFormat(x)}
        dataKey={x => x.timestamp.utc(true).unix()}
        interval="preserveStartEnd" />
      <YAxis
        tickSize={12}
        stroke="#000"
        interval="preserveStartEnd"
      />
      <Tooltip labelFormatter={x => this.unixUTCTimestampToLocalFormat(Number(x))} />
      <Legend />
      {this.getLines().map(this.renderLine)}
    </LineChart>;
  }

  protected abstract getLines(): RenderLineProps<TData>[];
  protected abstract getTitle(): string;

  protected renderLine = (props: RenderLineProps<TData>, index: number) => <Line
    {...props}
    key={index}
    strokeWidth={2}
    type="linear"
    legendType="circle"
    dot={false}
    unit={` ${props.unit}`}
  />

  public render() {
    return <BaseChartCard title={this.getTitle()}>
      <div className="w-100 chart-container" ref={this.containerRef}>
        {this.props.data.length > 0
          ? this.state.chart
          : <h3 className="">{this.props.translate(x => x.pages.status.charts.noData)}</h3>}
      </div>
    </BaseChartCard>;
  }
}

export const BaseChartCard = (props: { title: React.ReactNode, children: React.ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <Col sm={12}>
      <Card>
        <CardBody>
          <CardTitle className={isExpanded ? '' : 'mb-0'}>
            <h3 className="d-flex align-items-center justify-content-between">
              {props.title}
              {isExpanded
                ? <CollapseIcon className="clickable user-select-none" onClick={() => setIsExpanded(false)} />
                : <ExpandIcon className="clickable user-select-none" onClick={() => setIsExpanded(true)} />}
            </h3>
          </CardTitle>
          {isExpanded 
            ? props.children
            : null}
        </CardBody>
      </Card>
    </Col>
  );
}

export default BaseChart;
