import * as React from 'react';

import { Col } from 'reactstrap';
import SwitchboxLight from './SwitchboxLight';
import SwitchboxSwitch from './SwitchboxSwitch';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const engineSwitchBoxTypes = {
  isOn: (index: number) => `Vent${index}PowerSupplyContractor`,
  usage: (index: number) => `ContinuousVentControl${index}`,
  switch: {
    simple: (index: number) => `Engine${index}Switch`,
    double: {
      first: (index: number) => `Engine${index}Switch1`,
      second: (index: number) => `Engine${index}Switch2`
    }
  }
}

const SwitchboxEngine = ({
  engineIndex
}: {
  engineIndex: number
}) => {
  const translate = useLocalization();
  const store = useStore(x => x.switchBoxStore);
  if(!store.switchBoxData)
      return null;
  
  const isOnDatum = store.switchBoxData.find(x => x.key === engineSwitchBoxTypes.isOn(engineIndex));
  const usageDatum = store.switchBoxData.find(x => x.key === engineSwitchBoxTypes.usage(engineIndex));
  const switchDatum = store.switchBoxData.find(x => x.key === engineSwitchBoxTypes.switch.simple(engineIndex));
  const switchDoubleFirstDatum = store.switchBoxData.find(x => x.key === engineSwitchBoxTypes.switch.double.first(engineIndex));
  const switchDoubleSecondDatum = store.switchBoxData.find(x => x.key === engineSwitchBoxTypes.switch.double.second(engineIndex));

  if(!isOnDatum && !usageDatum && !switchDatum && (!switchDoubleFirstDatum || !switchDoubleSecondDatum))
    return <Col xs={6}/>;
    
  const isDoubleSwitch = !!switchDoubleFirstDatum && !!switchDoubleSecondDatum;

  const getDoubleSwitchPosition = () => {
    if(switchDoubleFirstDatum?.value === 1) {
      return 'auto';
    } else if (switchDoubleSecondDatum?.value === 1) {
      return 'man';
    } else {
      return 'off';
    }
  }

  const getStandardSwitchPosition = () => {
    switch(switchDatum?.value){
      case 0: 
        return 'auto';
      case 1:
        return 'off';
      default:
        return 'man';
    }
  }

  const isOnValue = isOnDatum?.value;
  const usageValue = usageDatum?.value;

  const switchPosition = isDoubleSwitch
    ? getDoubleSwitchPosition()
    : getStandardSwitchPosition();

  const isOnBypass = switchPosition === 'man';
      
  return <Col xs={6}>
    <SwitchboxLight {...{
      topLabel: translate(x => x.pages.switchbox.operationFailure),
      lightColor: 'green',
      state: isOnValue || isOnBypass ? 'on' : 'off'
    }}/>
    <div>
        <div className="text-value-card alert-success">
          {usageValue !== null && usageValue !== undefined
            ? `${translate(x => x.pages.switchbox.engineUsage)}: ${Math.round(usageValue)} %`
            : translate(x => x.pages.switchbox.engineUsageNotAvailable)}
        </div>
    </div>
    <SwitchboxSwitch {...{
      currentValue: switchPosition,
      leftValue: 'man',
      rightValue: 'auto',
      topLabel: translate(x => x.pages.switchbox.switches.hand0auto),
      bottomLabel: `${translate(x => x.pages.switchbox.labels.engine)} 2`
    }}/>
</Col>
}

export default observer(SwitchboxEngine);