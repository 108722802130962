import * as React from 'react';

import { Card, CardBody, Col, Row } from 'reactstrap';

import {CircularProgress} from '@material-ui/core';
import SwitchboxEngine from './SwitchboxEngine';
import SwitchboxLight from './SwitchboxLight';
import SwitchboxSwitch from './SwitchboxSwitch';
import SwitchboxTable from './SwitchboxTable';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const flap = {
  value: 'AirFlap',
  switch: 'PressureIncreaseSwitch'
};

const heating = {
  failure: 'BurnerFailure',
  isOn: 'BurnerOperation',
  switch: 'BurnerSwitch',
  mode: 'HeatingModeSwitch',
  currentMode: 'CurrentHeatingMode',
  doubleSwitch: {
    1: 'BurnerSwitch1',
    2: 'BurnerSwitch2'
  }
};

const burnerPercentage = 'BurnerPercentage';

const diesel = {
  failure: 'DieselUsuccessfulStart',
  switch: 'DieselModeSwitch'
};

const powerSupply = 'PowerSupply';

const SwitchboxCard = () => {
  const translate = useLocalization();
  const store = useStore(x => x.switchBoxStore)
  
  if(!store.switchBoxData)
    return <Card>
      <CardBody className="box-body switchbox">
        <CircularProgress/>
      </CardBody>
    </Card>;

  const flapValueDatum = store.switchBoxData.find(x => x.key === flap.value);
  const flapSwitchDatum = store.switchBoxData.find(x => x.key === flap.switch);
  
  const heatingIsOnDatum = store.switchBoxData.find(x => x.key === heating.isOn);
  const heatingFailureDatum = store.switchBoxData.find(x => x.key === heating.failure);
  const heatingSwitchDatum = store.switchBoxData.find(x => x.key === heating.switch);
  const heatingModeDatum = store.switchBoxData.find(x => x.key === heating.mode);
  const heatingCurrentModeDatum = store.switchBoxData.find(x => x.key === heating.currentMode);

  const heatingDoubleSwitchDatumOne = store.switchBoxData.find(x => x.key === heating.doubleSwitch[1]);
  const heatingDoubleSwitchDatumTwo = store.switchBoxData.find(x => x.key === heating.doubleSwitch[2]);

  const heatingDoubleSwitchDatum = heatingDoubleSwitchDatumOne && heatingDoubleSwitchDatumTwo 
    ? {
      one: heatingDoubleSwitchDatumOne.value,
      two: heatingDoubleSwitchDatumTwo.value
    } 
    : undefined;

  const dieselFailureDatum = store.switchBoxData.find(x => x.key === diesel.failure);
  const dieselSwitchDatum = store.switchBoxData.find(x => x.key === diesel.switch);
  
  const diselSwitchPosition = dieselSwitchDatum
    ? dieselSwitchDatum.value === 1
      ? 'auto'
      : '0'
    : undefined;

  const isDieselInFailureBypass = diselSwitchPosition && diselSwitchPosition === '0';

  const powerSupplyDatum = store.switchBoxData.find(x => x.key === powerSupply);
  const burnerPercentageDatum = store.switchBoxData.find(x => x.key === burnerPercentage);

  const getHeatingMode = () => {
    switch(heatingModeDatum?.value){
      case 1:
        return 'day';
      case 3:
        return 'night';
      default:
        return 'auto';
    }
  }
  
  return <Card>
      <CardBody className="box-body switchbox">
      
      <SwitchboxTable/>
  
      <Row>
        <SwitchboxEngine engineIndex={1}/>
        <SwitchboxEngine engineIndex={2}/>
      </Row>
  
      <Row>
        <Col xs={12}>
          {flapValueDatum
            ? <div className="text-value-card alert-success">
              {flapValueDatum.value === undefined || flapValueDatum.value === null
                ? translate(x => x.pages.switchbox.flapUsageNotAvailable)
                : `${translate(x => x.pages.switchbox.flapUsage)}: ${Math.round(flapValueDatum.value)} %`}
              </div>
            : null}
        </Col>
      </Row>
            
      <Row>
          <Col xs={4}>
            {heatingIsOnDatum || heatingFailureDatum
              ? <SwitchboxLight {...{
                topLabel: translate(x => x.pages.switchbox.operationFailure),
                lightColor: 'green',
                state: heatingIsOnDatum?.value === 1
                  ? 'on'
                  : heatingFailureDatum?.value === 1
                    ? 'failure'
                    : 'off'
              }}/>
              : null}
          </Col>
          <Col xs={4}>
            {flapSwitchDatum
              ? <SwitchboxSwitch {...{
                topLabel: translate(x => x.pages.switchbox.switches['0auto']),
                bottomLabel: translate(x => x.pages.switchbox.labels.flap),
                currentValue: flapSwitchDatum.value === 1 ? 'off' : 'auto',
                rightValue: 'auto'
              }}/>
              : null}
          </Col>
          <Col xs={4}>
            {dieselFailureDatum
              ? <SwitchboxLight {...{
                bottomLabel: translate(x => x.pages.switchbox.labels.dieselFailure),
                state: dieselFailureDatum.value === 1 || isDieselInFailureBypass ? 'failure' : 'off',
                lightColor: 'dark-red'
              }}/>
              : null}
          </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {burnerPercentageDatum
            ? <div className="text-value-card alert-success">
              {burnerPercentageDatum.value === undefined || burnerPercentageDatum.value === null
                ? translate(x => x.pages.switchbox.burnerPercentageNotAvailable)
                : `${translate(x => x.pages.switchbox.burnerPercentage)}: ${Math.round(burnerPercentageDatum.value)} %`}
              </div>
            : null}
        </Col>
      </Row>

      <Row>
          <Col xs={4}>
            {heatingDoubleSwitchDatum
              ? <SwitchboxSwitch {...{
                topLabel: translate(x => x.pages.switchbox.switches.hand0auto),
                bottomLabel: translate(x => x.pages.switchbox.labels.burnerSwitch),
                currentValue: heatingDoubleSwitchDatum.one === 1 
                  ? 'auto' 
                  : heatingDoubleSwitchDatum.two === 1 
                    ? 'hand' 
                    : 'off',
                leftValue: 'hand',
                rightValue: 'auto'
              }}/>
              : heatingSwitchDatum
                ? <SwitchboxSwitch {...{
                  topLabel: translate(x => x.pages.switchbox.switches['0auto']),
                  bottomLabel: translate(x => x.pages.switchbox.labels.burnerSwitch),
                  currentValue: heatingSwitchDatum.value === 1 ? 'auto' : 'off',
                  rightValue: 'auto'
                }}/>
                : null}
          </Col>
          <Col xs={4}>
            {heatingModeDatum
              ? <SwitchboxSwitch {...{
                topLabel: 'DAY NIGHT AUTO',
                bottomLabel: translate(x => x.pages.switchbox.labels.heating),
                currentValue: getHeatingMode(),
                leftValue: 'day',
                rightValue: 'auto'
              }}/>
              : null}
          </Col>
          <Col xs={4}>
            {diselSwitchPosition
              ? <SwitchboxSwitch {...{
                topLabel: translate(x => x.pages.switchbox.switches['0autotest']),
                bottomLabel: translate(x => x.pages.switchbox.labels.aggregate),
                currentValue: diselSwitchPosition,
                leftValue: '0',
                rightValue: 'test'
              }}/>
              : <SwitchboxSwitch {...{
                topLabel: translate(x => x.pages.switchbox.notAvailable),
                bottomLabel: translate(x => x.pages.switchbox.labels.aggregate),
                currentValue: 'empty',
                rightValue: 'right'
              }}/>}
          </Col>
      </Row>
            
      <Row>
        <Col xs={12}>
          {heatingCurrentModeDatum
            ? <div className="text-value-card alert-info">
              {translate(x => x.pages.switchbox.temperatureMode.label)}<br />
              {heatingCurrentModeDatum.value !== undefined && heatingCurrentModeDatum.value !== null
                ? heatingCurrentModeDatum.value === 1
                  ? translate(x => x.pages.switchbox.temperatureMode.day)
                  : translate(x => x.pages.switchbox.temperatureMode.night)
                : translate(x => x.pages.switchbox.notAvailable)}
            </div>
            : null}
        </Col>
      </Row>
            
      <Row>
        <Col xs={12}>
          {powerSupplyDatum
            ? <SwitchboxLight {...{
              topLabel: translate(x => x.pages.switchbox.labels.powerOn),
              state: powerSupplyDatum.value === 1 ? 'on' : 'off',
              lightColor: 'white'
            }}/>
            : null}
        </Col>
      </Row>
    </CardBody>
  </Card>;
}

export default observer(SwitchboxCard);