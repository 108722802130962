import { Col, Container, Form, Row } from "reactstrap";
import ConfirmationButtonToolbar, { ConfirmationButtonToolbarProps } from "components/basic/ConfirmationButtonToolbar";

import ArcTimePicker from "components/basic/ArcTimePicker";
import ScheduleIntervalFormModel from "stores/ScheduleIntervalFormModel";
import ScheduleTypeButton from "./ScheduleTypeButton";
import ValidationMessage from "components/basic/ValidationMessage";
import { getCalendarTypeByScheduleType } from "./CalendarTablesContainer";
import { observer } from "mobx-react";
import { useLocalization } from "localization/LocalizationProvider";

interface Props {
  model: ScheduleIntervalFormModel;
}

const ScheduleIntervalForm = ({ model, ...buttonToolbarProps }: Props & ConfirmationButtonToolbarProps) => {
  const translate = useLocalization();

  return (
    <Form className="form">
      <Container>
        <Row>
          <Col>
            <ArcTimePicker value={model.fromTime.Value} onChange={value => model.fromTime.setValue(value)} />
          </Col>
          <Col>
            <ArcTimePicker value={model.toTime.Value} onChange={value => model.toTime.setValue(value)} />
          </Col>
          <Col xs={12}>
            <ValidationMessage condition={model.validity.timesOverlapping} message={translate(x => x.pages.calendar.timesOverlapping)} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {
              model.formType === "multiple"
                ? model.scheduleTypesSelection?.map((scheduleType, index) => (
                  <ScheduleTypeButton key={index}
                    scheduleType={getCalendarTypeByScheduleType(scheduleType.scheduleTypeId)}
                    name={scheduleType.name ?? translate(x => x.pages.calendar.scheduleTypes[scheduleType.scheduleTypeId])}
                    selected={scheduleType.selected}
                    toggle={() => model.toggleScheduleType(scheduleType)}
                  />
                ))
                : null
            }
            <ValidationMessage condition={model.validity.intervalOverlapping} message={translate(x => x.pages.calendar.intervalOverlapping)} />
          </Col>
        </Row>
        <ConfirmationButtonToolbar {...buttonToolbarProps} />
      </Container>
    </Form>
  )
}

export default observer(ScheduleIntervalForm);