import Pages from '../../base/pages';
import airdomeList from './airdomelist';
import bacnetObjects from './bacnetObjects';
import calendar from './calendar';
import consumption from './consumption';
import doorLockSettings from './DoorLockSettings';
import preheatingSettings from './PreheatingSettings';
import errorPages from './errorPages';
import lighting from './lighting';
import plcNotifications from './plcnotifications';
import rdevNotifications from './rdevNotifications';
import settings from './settings';
import status from './status';
import switchbox from './switchbox';
import templates from './templates';
import userList from './userlist';
import userProfile from './userProfile';

const pages: Pages = {
  errorPages,
  consumption,
  airdomeList,
  userList,
  settings,
  status,
  calendar,
  bacnetObjects,
  templates,
  lighting,
  plcNotifications,
  switchbox,
  rdevNotifications,
  userProfile,
  doorLockSettings,
  preheatingSettings,
};

export default pages;
