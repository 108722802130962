import Trackable from '../../interfaces/Trackable';

export default class ConsumptionDto implements Trackable {
  public trackingId: string;
  public gasTotal?: number;
  public gasSelection?: number;
  public electricityTotal?: number;
  public electricitySelection?: number;
  public airdomeId: number;

  constructor(data: {
    trackingId: string,
    gasTotal?: number,
    gasSelection?: number,
    electricityTotal?: number,
    electricitySelection?: number;
    airdomeId: number;
  }) {
    this.trackingId = data.trackingId;
    this.gasTotal = data.gasTotal;
    this.gasSelection = data.gasSelection;
    this.electricityTotal = data.electricityTotal;
    this.electricitySelection = data.electricitySelection;
    this.airdomeId = data.airdomeId;
  }
}
