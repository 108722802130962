interface ObjectEntry<T = any> {
  key: string;
  value: T;
}

class ObjectHelper {
  // Index of key and value in array from Object.entries method defined in javascript
  private entryKeyIndex = 0;
  private entryValueIndex = 1;

  public areNumbers = (values: any[]): boolean =>
    values.reduce<boolean>((result, val) => result && this.isNumber(val), true)

  public isNumber = (val: any): boolean =>
    (val !== undefined && val !== null) &&
    (typeof val === 'number' && !Number.isNaN(val))

  public executeIfNumber = <TResult>(val: any, execute: (val: number) => TResult): TResult | undefined =>
    this.isNumber(val)
      ? execute(val)
      : undefined

  public getTypedEntries = <T = any>(object: Object): ObjectEntry<T>[] => {
    const entries = Object.entries(object);
    return entries.map(x => ({
      key: x[this.entryKeyIndex],
      value: x[this.entryValueIndex] as T
    }));
  }

  public getValueForProperty = <T = any>(object: Object, property: string): T | undefined => {
    const item = this.flattenObject<T>(object, '.');
    const typedEntries = this.getTypedEntries(item);
    const entry = typedEntries.find(x => x.key === property);

    return entry && entry.value as T;
  }

  public flattenObject = <T>(complexObject: any, separator: string, prefix: string = ''): T => {
    return Object.keys(complexObject).reduce(
      (messages, key) => this.reduceObject(messages, key, complexObject, separator, prefix),
      {}
    ) as T;
  }

  private reduceObject = (messages: any, key: any, complexObject: any, separator: string, prefix: string = '') => {
    const value = complexObject[key];
    const prefixedKey = prefix ? `${prefix}${separator}${key}` : key;

    if (typeof value === 'function')
      return messages;

    if (typeof value === 'object')
      Object.assign(messages, this.flattenObject(value, separator, prefixedKey));
    else
      messages[prefixedKey] = value;

    return messages;
  }

  public unflattenObject = <T>(
    data: any,
    separator: string,
    modifyKey?: (string: string) => string,
    filterKey?: (string: string) => boolean): T => {
    const result: any = {};
    for (const key in data) {
      if (filterKey && !filterKey(key))
        continue;
      const modifiedKey = modifyKey ? modifyKey(key) : key;
      const keys = modifiedKey.split(separator);
      keys.reduce((r, e, j) =>
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? (keys.length - 1 === j
            ? data[key]
            : {})
          : [])
        , result);
    }
    return result as T;
  }

  public shallowEquals = (a: any, b: any): boolean => {
    const aEntries = this.getTypedEntries(a);
    const bEntries = this.getTypedEntries(b);

    if (aEntries.length !== bEntries.length)
      return false;

    return aEntries.reduce((result, aItem) => {
      const bItem = bEntries.find(x => x.key === aItem.key);
      if (!result)
        return false;

      if (!bItem)
        return false;

      return aItem.value === bItem.value;
    }, true as boolean);
  }

  public getRange = (count: number, start = 0, increment = 1): number[] => {
    const range = [];
    for (let i = 0; i < count; i++)
      range.push(start + (i * increment));

    return range;
  }

  public justifyLeft = (value: string, length: number, character: string) =>
    length - value.length > 0
      ? this.generateJustify(length - value.length, character) + value
      : value;

  private generateJustify = (amount: number, character: string) =>
    objectHelper
      .getRange(amount)
      .map(_ => character)
      .join('');

  public dateToISOString = (date: Date): string =>
    new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )).toISOString();

  public delay = async (ms: number) =>
    await new Promise<void>(x => setTimeout(() => x(), ms));
}

const objectHelper = new ObjectHelper();
export default objectHelper;
