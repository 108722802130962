import Airdome from '../../../base/forms/airdome';
import consumption from './consumption';
import create from './create';
import artificialIntelligence from './artificialIntelligence';
import intervals from './intervals';
import settings from './settings';
import restrictions from './restrictions';

const airdome: Airdome = {
  artificialIntelligence,
  consumption,
  create,
  intervals,
  settings,
  restrictions
};

export default airdome;
