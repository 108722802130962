import { AxiosInstance } from "axios";
import ScheduleIntervalDto from "../../dto/settings/ScheduleIntervalDto";
import ScheduleTypeDto from "../../dto/settings/ScheduleTypeDto";
import { getBackendException } from "../../helpers/ErrorHelper";
import { getJoinedEndpoint } from "../../utils/Settings";
import stringFormat from 'string-format';

export default class CalendarService {
  private axios: AxiosInstance;
  private getSchedulesUrl: string;
  private getScheduleTypesUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getSchedulesUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.schedules.get);
    this.getScheduleTypesUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.scheduleTypes.get);
  }

  public getSchedules = async (airdomeId: number, trackingId: string): Promise<ScheduleIntervalDto[]> => {
    try {
      const url = stringFormat(this.getSchedulesUrl, airdomeId.toString());
      const result = await this.axios.get<ScheduleIntervalDto[]>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public getScheduleTypes = async (trackingId: string): Promise<ScheduleTypeDto[]> => {
    try {
      const result = await this.axios.get<ScheduleTypeDto[]>(this.getScheduleTypesUrl, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public updateSchedules = async (airdomeId: number, intervals: ScheduleIntervalDto[], trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.schedules.update),
        airdomeId.toString());
      await this.axios.put<ScheduleIntervalDto[]>(url, intervals, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
