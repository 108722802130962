import Status from '../../../base/pages/status';
import observedNotifications from './ObservedNotifications';
import charts from './charts';

const status: Status = {
  charts,
  observedNotifications,

  temperatureInside: 'Innentemperatur',
  temperatureOutside: 'Aussentemperatur',
  temperatureInput: 'Zulufttemperatur',
  pressure: 'Druck',
  voltage: 'Bateriespannung',
  lighting: 'Beleuchtung',
  windSpeed: 'Windgeschwindigkeit',
  height: 'Hallenhöhe',
  artificialIntelligence: 'Künstliche Intelligenz ',
  initialized: 'Initialisiert',
  inflated: 'Aufgeblasen',
  macAddresses: 'MAC-Adressen',
  lastUpdate: 'Letzter Stand',
  forceRefresh: 'Aktualisierung',
  noNotifications: 'Es gibt keine Benachrichtigungen'
};

export default status;
