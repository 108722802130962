import CalendarStore, { ScheduleInterval } from "./CalendarStore";
import { action, computed, observable } from "mobx";

import InputModel from "models/InputModel";
import ScheduleIntervalComparer from "utils/ScheduleIntervalComparer";
import ScheduleIntervalDto from "dto/settings/ScheduleIntervalDto";
import ScheduleTypeModel from "models/calendar/ScheduleTypeModel";
import TimeSpan from "dto/TimeSpan";
import TimeSpanRange from "utils/TimeSpanRange";

export interface ScheduleIntervalValidity {
  timesOverlapping: boolean;
  intervalOverlapping: boolean;
}

interface ScheduleTypeSelection extends ScheduleTypeModel {
  selected: boolean;
}

type OriginalValues = {
  fromTime: TimeSpan;
  toTime: TimeSpan;
  intervals: ScheduleInterval[];
};

type FormType = "single" | "multiple";

export default class ScheduleIntervalFormModel {
  private calendarStore: CalendarStore;

  @observable
  private originalValues: OriginalValues | undefined;

  @observable
  public scheduleTypeSelectionChanged = false;

  @observable
  public dayOfWeek: number | undefined;

  @observable
  public selectedScheduleType: ScheduleTypeSelection | undefined;

  @observable
  public fromTime = new InputModel<TimeSpan>();

  @observable
  public toTime = new InputModel<TimeSpan>();

  @observable
  public scheduleTypesSelection: ScheduleTypeSelection[] = [];

  @observable
  public formType: FormType | undefined;

  constructor(calendarStore: CalendarStore) {
    this.calendarStore = calendarStore;
  }

  @action
  public setFormType = (formType: FormType) =>
    this.formType = formType;

  @action
  public setScheduleTypes = (scheduleTypes: ScheduleTypeModel[]) => {
    this.scheduleTypesSelection = scheduleTypes.map(x => ({
      ...x,
      selected: false
    }));
  }

  @action
  public prefillMultiple = (dayOfWeek: number, fromTime: TimeSpan, toTime: TimeSpan, existingIntervals?: ScheduleInterval[]): void => {
    this.dayOfWeek = dayOfWeek;
    this.fromTime.setValue(fromTime, false);
    this.toTime.setValue(toTime, false);
    if (existingIntervals) {
      this.setSelectedScheduleTypes(existingIntervals.map(x => x.scheduleTypeId));
      this.setOriginalValues({ fromTime, toTime, intervals: existingIntervals });
    }
  }

  @action
  public prefillSingle = (dayOfWeek: number, fromTime: TimeSpan, toTime: TimeSpan, scheduleTypeId: number, existingInterval?: ScheduleInterval): void => {
    this.dayOfWeek = dayOfWeek;
    this.fromTime.setValue(fromTime, false);
    this.toTime.setValue(toTime, false);
    this.setSelectedScheduleTypes([scheduleTypeId]);
    this.selectedScheduleType = this.scheduleTypesSelection.find(type => type.selected);
    if (existingInterval)
      this.setOriginalValues({ fromTime, toTime, intervals: [existingInterval] });
  }

  @action
  public setSelectedScheduleTypes = (selectedScheduleTypeIds: number[]) => {
    this.scheduleTypesSelection.forEach(x => {
      x.selected = selectedScheduleTypeIds.some(scheduleTypeIdToSelect => x.scheduleTypeId === scheduleTypeIdToSelect);
    });
  }

  @action
  public toggleScheduleType = (scheduleType: ScheduleTypeSelection) => {
    const scheduleTypeSelection = this.scheduleTypesSelection.find(x => x === scheduleType);
    if (scheduleTypeSelection) {
      scheduleTypeSelection.selected = !scheduleTypeSelection?.selected;
      this.scheduleTypeSelectionChanged = true;
    }
  }

  @action
  public clear = (): void => {
    this.fromTime.clear();
    this.toTime.clear();
    this.setSelectedScheduleTypes([]);
    this.scheduleTypeSelectionChanged = false;
    this.originalValues = undefined;
  }

  @action
  private setOriginalValues = (originalValues: OriginalValues) =>
    this.originalValues = originalValues;

  @computed
  get isEditting(): boolean {
    return this.originalValues !== undefined;
  }

  @computed
  get selectedScheduleTypes(): ScheduleTypeSelection[] {
    return this.scheduleTypesSelection.filter(x => x.selected);
  }

  @computed
  get validity(): ScheduleIntervalValidity {
    return {
      timesOverlapping: !!this.fromTime.Value && !!this.toTime.Value && this.fromTime.Value.totalMinutes >= this.toTime.Value.totalMinutes,
      intervalOverlapping: this.selectedScheduleTypes.length > 0 && this.selectedScheduleTypes.some(scheduleType =>
        this.calendarStore.intervals
          ?.filter(interval => !this.originalValues?.intervals.some(x => ScheduleIntervalComparer.areEqual(x, interval)))
          .some(interval =>
            interval.dayOfWeek === this.dayOfWeek &&
            interval.scheduleTypeId === scheduleType.scheduleTypeId &&
            new TimeSpanRange(interval.start, interval.end).overlapsWithRange(new TimeSpanRange(this.fromTime.Value!, this.toTime.Value!))))
    };
  }

  @computed
  public get isValid(): boolean {
    return !this.validity.timesOverlapping && !this.validity.intervalOverlapping;
  }

  @computed
  public get hasChanges(): boolean {
    return (
      !!this.originalValues && !!this.fromTime.Value && !!this.toTime.Value && (
        !this.originalValues.fromTime.equals(this.fromTime.Value) ||
        !this.originalValues.toTime.equals(this.toTime.Value) ||
        !this.originalValues.intervals.every(x => this.selectedScheduleTypes.some(y => x.scheduleTypeId === y.scheduleTypeId)) ||
        !this.selectedScheduleTypes.every(x => this.originalValues!.intervals.some(y => x.scheduleTypeId === y.scheduleTypeId)))
    ) ||
      (!this.originalValues && (this.fromTime.ValueChanged || this.toTime.ValueChanged || this.scheduleTypeSelectionChanged));
  }

  public get intervalsEdited(): ScheduleInterval[] {
    return this.originalValues?.intervals ?? [];
  }

  public createIntervalDtos = (): ScheduleIntervalDto[] =>
    this.selectedScheduleTypes.map(x => ({
      dayOfWeek: this.dayOfWeek!,
      start: this.fromTime.Value!,
      end: this.toTime.Value!,
      scheduleTypeId: x.scheduleTypeId
    }));
}