import * as validations from '../../validations/validations';

import { FullSettingsDto, SettingsDto } from '../../dto/Container';
import { PressureMode, TemperatureMode } from '../../dto/settings/enums';
import { action, computed, observable } from 'mobx';
import moment, { Moment } from 'moment';

import InputModel from 'models/InputModel';
import { NumberSchema } from 'yup';
import RestrictionsStore from '../../stores/RestrictionsStore';
import SettingsSignalRDto from 'services/signalr/dto/SettingsSignalRDto';
import objectHelper from '../../utils/ObjectHelper';

interface SettingsModelParams {
  restrictionsStore: RestrictionsStore;
}

export default class SettingsModel {
  private params: SettingsModelParams;

  public get temperatureComfortValidationSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: true,
      min: this.params.restrictionsStore.temperatureRestrictionsModel.comfortTemperatureMin.Value,
      max: this.params.restrictionsStore.temperatureRestrictionsModel.comfortTemperatureMax.Value
    });
  }

  public get temperatureCutbackValidationSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: true,
      min: this.params.restrictionsStore.temperatureRestrictionsModel.cutbackTemperatureMin.Value,
      max: this.params.restrictionsStore.temperatureRestrictionsModel.cutbackTemperatureMax.Value
    });
  }

  public get pressureNormalValidationSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: true,
      min: this.params.restrictionsStore.pressureRestrictionsModel.normalPressureMin.Value,
      max: this.params.restrictionsStore.pressureRestrictionsModel.normalPressureMax.Value
    });
  }

  public get pressureMaxValidationSchema(): NumberSchema {
    return validations.getBasicNumberSchema({
      required: true,
      min: this.params.restrictionsStore.pressureRestrictionsModel.maximalPressureMin.Value,
      max: this.params.restrictionsStore.pressureRestrictionsModel.maximalPressureMax.Value
    });
  }

  @observable
  public temperatureComfort = new InputModel<number>();

  @observable
  public temperatureCutback = new InputModel<number>();

  @observable
  public temperatureMode = new InputModel<TemperatureMode>();

  @observable
  public pressureNormal = new InputModel<number>();

  @observable
  public pressureMax = new InputModel<number>();

  @observable
  public pressureMode = new InputModel<PressureMode>();

  @observable
  public lastUpdate?: Moment;

  @observable
  public updatedBy?: string;

  @observable
  public requestState?: string;

  constructor(params: SettingsModelParams) {
    this.params = params;
  }

  @action
  public importfromSignalRDto = (dto: Omit<SettingsSignalRDto & { userName: string}, 'userId'>) => {
    this.temperatureComfort.setValue(dto.temperatureComfort, false);
    this.temperatureCutback.setValue(dto.temperatureCutback, false);
    this.temperatureMode.setValue(dto.temperatureMode, false);
    this.pressureMax.setValue(dto.pressureMax, false);
    this.pressureNormal.setValue(dto.pressureNormal, false);
    this.pressureMode.setValue(dto.pressureMode, false);
    this.lastUpdate = moment(dto.timestamp);
    this.updatedBy = dto.userName;
  }

  @action
  public importfromFullDto = (dto: Omit<FullSettingsDto & { userName: string}, 'userId'>) => {
    this.temperatureComfort.setValue(dto.temperatureComfort, false);
    this.temperatureCutback.setValue(dto.temperatureCutback, false);
    this.temperatureMode.setValue(dto.temperatureMode, false);
    this.pressureMax.setValue(dto.pressureMax, false);
    this.pressureNormal.setValue(dto.pressureNormal, false);
    this.pressureMode.setValue(dto.pressureMode, false);
    this.lastUpdate = dto.timestamp;
    this.updatedBy = dto.userName;
    this.requestState = dto.requestState;
  }

  @action
  public clear = () => {
    this.temperatureComfort.clear();
    this.temperatureCutback.clear();
    this.temperatureMode.clear();
    this.pressureMax.clear();
    this.pressureNormal.clear();
    this.pressureMode.clear();
    this.lastUpdate = undefined;
    this.updatedBy = undefined;
    this.requestState = undefined;
  }

  @action
  public resetHasChanges = () => {
    this.temperatureComfort.resetHasChange();
    this.temperatureCutback.resetHasChange();
    this.temperatureMode.resetHasChange();
    this.pressureMax.resetHasChange();
    this.pressureNormal.resetHasChange();
    this.pressureMode.resetHasChange();
  }

  public getDto = (): SettingsDto | undefined =>
    this.areDataValid ?
      new SettingsDto({
        pressureMax: this.pressureMax.Value!,
        pressureNormal: this.pressureNormal.Value!,
        pressureMode: this.pressureMode.Value!,
        temperatureComfort: this.temperatureComfort.Value,
        temperatureCutback: this.temperatureCutback.Value,
        temperatureMode: this.temperatureMode.Value
      })
      : undefined

  @computed
  public get hasAnyValueChanged(): boolean {
    return this.temperatureComfort.ValueChanged
      || this.temperatureCutback.ValueChanged
      || this.temperatureMode.ValueChanged
      || this.pressureMax.ValueChanged
      || this.pressureNormal.ValueChanged
      || this.pressureMode.ValueChanged;
  }

  @computed
  public get areDataValid(): boolean {
    return !!(
      this.hasAnyValueChanged &&
      (!this.temperatureComfort.Value || this.temperatureComfortValidationSchema.isValidSync(this.temperatureComfort.Value)) &&
      (!this.temperatureCutback.Value || this.temperatureCutbackValidationSchema.isValidSync(this.temperatureCutback.Value)) &&
      (typeof this.temperatureMode.Value === 'undefined' ||
        (objectHelper.isNumber(this.temperatureMode.Value) && this.temperatureMode.Value in TemperatureMode)) &&
      this.pressureMaxValidationSchema.isValidSync(this.pressureMax.Value) &&
      this.pressureNormalValidationSchema.isValidSync(this.pressureNormal.Value) &&
      objectHelper.isNumber(this.pressureMode.Value) && this.pressureMode.Value! in PressureMode
    );
  }
}
