import General from '../../base/general';
import airdome from './airdome';
import datatables from './datatables';
import user from './user';

const general: General = {
  user,
  airdome,
  datatables,

  cancel: 'Stornieren',
  ok: 'Ok',
  requestFailed: 'Daten können nicht geladen werden',
  submit: 'Einreichen',
  yes: 'Ja',
  no: 'Nein',
  add: 'Hinzufügen',
  edit: 'Bearbeiten',
  update: 'Aktualisieren',
  delete: 'Löschen',
  name: 'Name'
};

export default general;
