import Settings from '../../../base/pages/settings';

const settings: Settings = {
  header: 'Settings',
  usersWithAccess: 'Users with access',
  restartDevice: 'Restart device',
  templateStatus: {
    header: 'Template status',
    refresh: 'Refresh',
    reported: {
      header: 'Current',
      template: 'Template',
      revision: 'Revision'
    },
    desired: {
      header: 'Desired',
      template: 'Template',
      revision: 'Revision'
    }
  },

  history: {
    subheader: 'Settings history',
    temperatureMode: 'Temperature mode',
    temperatureComfort: 'Comfort temperature (°C)',
    temperatureCutback: 'Cutback temperature (°C)',
    pressureMode: 'Pressure mode',
    pressureMax: 'Maximal pressure (Pa)',
    pressureNormal: 'Normal pressure (Pa)',
    lastUpdate: 'Last update',
    updatedBy: 'Updated by'
  }
};

export default settings;
