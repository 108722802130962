import Status from '../../../base/pages/status';
import observedNotifications from './ObservedNotifications';
import charts from './charts';

const status: Status = {
  charts,
  observedNotifications,

  temperatureInside: 'Vnitřní teplota',
  temperatureOutside: 'Venkovní teplota',
  temperatureInput: 'Vstupní teplota',
  pressure: 'Tlak',
  voltage: 'Napětí baterie',
  lighting: 'Osvětlení',
  windSpeed: 'Rychlost větru',
  height: 'Výška haly',
  artificialIntelligence: 'Umělá inteligence',
  initialized: 'Inicializovaná',
  inflated: 'Nafouknutá',
  macAddresses: 'MAC adresy',
  lastUpdate: 'Naposledy aktualizováno',
  forceRefresh: 'Aktualizovat',
  noNotifications: 'Žádné notifikace.'
};

export default status;
