import User from '../../base/menu/User';

const user: User = {
  categoryName: 'Users',
  create: 'Add user',
  edit: 'My profile',
  logout: 'Log out'
};

export default user;
