import { DoorAuto, DoorLocked, DoorUnlocked } from './icons';

import { DoorLockState } from 'dto/settings/enums';
import SelectorCard from 'components/selectorCard/SelectorCard';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface DoorLockCardProps {
  doorLockState: DoorLockState;
  setDoorLockState: (value: DoorLockState) => void;
  name: string;
  setName: (value: string) => void;
  disabled?: boolean;
}

const DoorLockCard = (props: DoorLockCardProps) => {
  const translate = useLocalization();
  const { setDoorLockState, doorLockState, name, setName, disabled } = props;

  return (
    <SelectorCard<DoorLockState>
      currentValue={doorLockState}
      name={name}
      setName={setName}
      disabled={disabled}
      selectValue={setDoorLockState}
      selections={[
        {
          value: DoorLockState.Unlocked,
          display: {
            shortName: translate(x => x.enums.doorLockState[DoorLockState.Unlocked].short),
            fullName: translate(x => x.enums.doorLockState[DoorLockState.Unlocked].full)
          },
          getIcon: props => <DoorUnlocked {...props} />
        },
        {
          value: DoorLockState.Auto,
          display: {
            shortName: translate(x => x.enums.doorLockState[DoorLockState.Auto].short),
            fullName: translate(x => x.enums.doorLockState[DoorLockState.Auto].full)
          },
          getIcon: props => <DoorAuto {...props} />
        },
        {
          value: DoorLockState.Locked,
          display: {
            shortName: translate(x => x.enums.doorLockState[DoorLockState.Locked].short),
            fullName: translate(x => x.enums.doorLockState[DoorLockState.Locked].full)
          },
          getIcon: props => <DoorLocked {...props} />
        }
      ]}/>
  );
}

export default observer(DoorLockCard);