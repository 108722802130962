import React from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { TableColumn } from './table-header/TableHeader';

interface RowModel<TItem> {
  item: TItem;
  isSelected: boolean;
}

interface MaterialTableBodyProps<TItem> {
  data: RowModel<TItem>[];
  search: (item: TItem) => boolean;
  sortComparer: (a: TItem, b: TItem) => number;
  page: number;
  rowsPerPage: number;
  columns: TableColumn<TItem>[];
  showPagination: boolean;

  showCheckboxColumn: boolean;
  clickRowToSelect: boolean;

  handleItemClick?: (item: TItem) => void;
  switchSelection: (item: TItem, checked?: boolean) => void;
}

class MaterialTableBody<TItem> extends React.Component<MaterialTableBodyProps<TItem>> {
  public render() {
    const {
      data,
      search,
      sortComparer,
      page,
      rowsPerPage,
      showPagination
    } = this.props;
    const emptyRows = (page * rowsPerPage) + rowsPerPage - data.length;
    let sortedFilteredData = data
      .filter(row => search(row.item))
      .sort((rowA, rowB) => sortComparer(rowA.item, rowB.item));

    if (showPagination)
      sortedFilteredData = sortedFilteredData.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

    return <>
      {sortedFilteredData.map(this.renderRow)}
      {emptyRows > 0 && (
        <TableRow style={{ height: 50 }} />
      )}
    </>;
  }

  private renderRow = (row: RowModel<TItem>, index: number) => {
    const { showCheckboxColumn, columns, switchSelection, handleItemClick, clickRowToSelect } = this.props;

    return <TableRow
      className={`material-table__row ${clickRowToSelect && 'material-table__clickable-row'}`}
      role="checkbox"
      onClick={() => {
        handleItemClick && handleItemClick(row.item);
        clickRowToSelect && switchSelection(row.item, !row.isSelected);
      }}
      aria-checked={row.isSelected}
      tabIndex={-1}
      key={index}
      selected={row.isSelected}
    >
      {showCheckboxColumn && this.renderCheckboxCell({
        checked: row.isSelected,
        onChange: (event, checked) => switchSelection(row.item, checked)
      })}
      {this.renderCells(columns, row.item, index)}
    </TableRow>;
  }

  private renderCheckboxCell = (props: {
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  }) => {
    return <TableCell className="material-table__cell" padding="checkbox">
      <Checkbox {...props} className="material-table__checkbox" />
    </TableCell>;
  }

  private renderCells = (columns: TableColumn<TItem>[], item: TItem, index: number) => {
    return columns.map((column, i) => {
      return <TableCell
        className={`material-table__cell ${column.disablePadding ? 'p-0' : ''}`}
        key={i}
        style={{ width: `${column.width}%` }}>
        {column.renderCell
          ? column.renderCell(item, index)
          : this.renderDefaultCell(column, item, index)
        }
      </TableCell>;
    });
  }

  private renderDefaultCell = (column: TableColumn<TItem>, item: TItem, index: number) =>
    <p className="default-material-table-cell">
      {column.getValue
        ? column.getValue(item, index)
        : (item as any)[column.id]}
    </p>
}

export default MaterialTableBody;
