import Translation from '../base';
import actions from './actions';
import enums from './enums';
import errors from './errors';
import forms from './forms';
import general from './general';
import menu from './menu';
import notifications from './notifications';
import pages from './pages';
import validations from './validations';
import countries from './Countries';

const german: Translation = {
  actions,
  enums,
  errors,
  forms,
  general,
  menu,
  notifications,
  pages,
  validations,
  countries
};

export default german;
