import React from 'react';
import { AdditionalLightsField, MainLightsField } from '../fields';
import Translate from '../../../../localization/Translate';

interface LightingFieldsetProps {
  translate: Translate;
  lightsAdditionalCount?: number;
  lightsMainCount?: number;
  setLightsAdditionalCount: (mainCount?: number) => void;
  setLightsMainCount: (mainCount?: number) => void;
}

const LightingFieldset = ({
  translate, lightsMainCount, lightsAdditionalCount, setLightsAdditionalCount, setLightsMainCount
}: LightingFieldsetProps) =>
  <fieldset>
    <MainLightsField {...{ translate, lightsMainCount, setLightsMainCount }} />
    <AdditionalLightsField {...{ translate, lightsAdditionalCount, setLightsAdditionalCount }} />
  </fieldset>;

export default React.memo(LightingFieldset);
