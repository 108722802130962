import PlcNotifications from "../../../base/pages/plcnotifications";

const plcNotifications: PlcNotifications = {
    header: '{name}: PLC notifications',
    activeNotifications: 'Active notifications: {count}',
    resolvedNotifications: 'Resolved notifications: {count}',
    resolveAllNotifications: 'Resolve all notifications',
    history: {
        header: 'PLC notifications history',
        occured: 'Occured',
        severity: {
            severity: 'Severity',
            information: 'Information',
            warning: 'Warning',
            error: 'Error',
        },
        message: 'Message',
        resolved: 'Resolved',
        notResolved: 'No'
    }
}

export default plcNotifications;