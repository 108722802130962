import { computed, observable } from 'mobx';

export default class InputModel<T> {
  @observable
  protected value?: T = undefined;

  @observable
  protected hasChange = false;

  @computed
  public get Value(): T | undefined {
    return this.value;
  }

  public get ValueChanged(): boolean {
    return this.hasChange;
  }

  public setValue = (value: T | undefined, propagateChange = true): void => {
    this.value = value;
    this.hasChange = propagateChange;
  }

  public clear = (): void =>
    this.setValue(undefined, false);

  public resetHasChange = (): void => {
    this.hasChange = false;
  }
}