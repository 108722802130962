import BasePressureField from '../BasePressureField';
import PressureProps from '../../PressureProps';
import React from 'react';
import { observer } from 'mobx-react';

const MinimumValueField =
  ({ translate, model, readonly }: PressureProps) =>
    <BasePressureField {...{
      readonly,
      numberValidationSchema: model.maximalPressureMinSchema.label(
        translate(x => x.forms.airdome.restrictions.pressure.maximalPressureMin)
      ),
      inputId: 'LowerBound_PressureMaximal_Field',
      label: translate(x => x.forms.airdome.restrictions.pressure.maximalPressureMin),
      setValue: x => model.maximalPressureMin.setValue(x),
      value: model.maximalPressureMin.Value
    }} />;

export default observer(MinimumValueField);
