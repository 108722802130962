import appsettingsJson from '../appsettings.json';
import objectHelper from './ObjectHelper';
import { string } from 'yup/lib/locale';
import urlJoin from 'url-join';

declare global {
  interface Window {
    _env_: any;
  }
}

interface SignalREndpoints {
  signalr: {
    baseUri: string;
    status: string;
    heartbeat: string;
    registry: string;
    plcNotifications: string;
    notificationsChannel: string;
    airdome: {
      registry: string;
      status: string;
      settings: string;
      changeRequest: string;
      bacnetObjects: string;
    }
  };
}

interface IdentityEndpoints {
  identity: {
    baseUri: string;
    users: {
      create: string;
      getAll: string;
      getDetail: string;
      delete: string;
      update: string;
      lock: string;
      unlock: string;
      findByIds: string;
      updateCurrent: string;
    }
    roles: {
      getAll: string;
    };
    usersAirdomes: {
      update: string;
      getAll: string;
    };
    airdomes: {
      getUsers: string;
      removeUser: string;
      addUser: string;
    };
    signInRedirectUrl: string,
    signOutRedirectUrl: string
  };
}

interface RegistryEndpoints {
  registry: {
    baseUri: string;
    airdomes: {
      create: string;
      update: string;
      getAll: string;
      getAllExtended: string;
      addMacAddress: string;
      deleteMacAddress: string;
      initialize: string;
      deinitialize: string;
      getTemplateStatus: string;
      areSchedulesManagedExternally: string;
    };
    intervals: {
      fetchIntervals: string;
      setAirdomeIntervals: string;
    },
    templates: {
      getAll: string;
      update: string;
    };
    countries: {
      getAll: string;
    };
    lights: {
      getAll: string;
      update: string;
    };
    bacnetObjects: {
      getAllForTemplate: string
    };
    doorLocks: {
      getAll: string;
      update: string;
    };
  };
}

interface StatusEndpoints {
  status: {
    baseUri: string;
    airdomes: {
      getAll: string;
    },
    status: {
      get: string;
      force: string;
    },
    switchbox: {
      request: string;
    },
    consumptions: {
      getSummary: string;
    },
    notifications: {
      getActive: string;
      getHistory: string;
      resolve: string;
      resolveAll: string;
    },
    rdevNotifications: {
      getAll: string;
    }
  };
}

interface SettingsEndpoints {
  settings: {
    baseUri: string;
    settings: {
      get: string;
      getHistory: string;
      update: string;
    };
    restrictions: {
      get: string;
      temperature: {
        update: string;
      };
      pressure: {
        update: string;
      };
    };
    lights: {
      get: string;
      getActive: string;
      getHistory: string;
      update: string;
    };
    schedules: {
      get: string;
      update: string;
    };
    scheduleTypes: {
      get: string;
    };
    doorLockSettings: {
      get: string;
      getHistory: string;
      update: string;
    };
    restartRDev: string;
    bacnetObjects: {
      valuesReadRequest: string,
      valuesSetRequest: string
    },
    preheatingSettings: {
      get: string;
      update: string;
      getHistory: string;
    }
  };
}

interface LoggerEndpoints {
  logger: {
    baseUri: string;
    logLevel: {
      get: string;
    }
    airdomes: {
      getLogLevel: string;
      setLogLevel: string;
    }
    log: {
      add: string
    }
  };
}

interface NotificationsEndpoints {
  notifications: {
    baseUri: string;
    notifications: {
      getOptions: string;
      update: string;
      getUserNotificationSettings: string;
      getCurrentUsersNotificationChannel: string;
      updateCurrentUsersNotificationChannel: string;
    }
  };
}

interface ChangeRequestEndpoints {
  changeRequest: {
    baseUri: string;
    isInProgress: string;
  };
}

interface AIEndpoints {
  ai: {
    baseUri: string;
    airdomes: {
      getIsEnabled: string;
      enable: string;
      disable: string;

      defaultConstants: {
        get: string;
        edit: string;
      }
    }
  };
}

interface HeartbeatEndpoints {
  heartbeat: {
    baseUri: string;
    airdomes: {
      getSingle: string;
      getAll: string;
    }
  };
}

type EndpointSettings =
  SignalREndpoints &
  IdentityEndpoints &
  RegistryEndpoints &
  StatusEndpoints &
  SettingsEndpoints &
  LoggerEndpoints &
  NotificationsEndpoints &
  ChangeRequestEndpoints & 
  AIEndpoints & 
  HeartbeatEndpoints & {
    frontendBaseUri: string;
  };

const getEnvironmentVariablesWithPrefix = (regex: RegExp): EndpointSettings => {
  return objectHelper.unflattenObject<EndpointSettings>(window._env_, '__',
    x => x.replace(regex, ''),
    x => regex.test(x));
};

const tryGetEnvironmentEndpoint = (func: (settings: EndpointSettings) => string): string | undefined => {
  try {
    const environmentSettingValue = func(getEnvironmentVariablesWithPrefix(/^(ARC\_\_)/));
    return environmentSettingValue;
  } catch (err) {
    return undefined;
  }
};

type GetEndpointFunction = (settings: EndpointSettings) => string;

export const getEndpoint = (getEndpointFunction: GetEndpointFunction): string => {
  const environmentValue = tryGetEnvironmentEndpoint(getEndpointFunction);
  const final = environmentValue || getEndpointFunction(appsettingsJson);
  return final;
};

export const getJoinedEndpoint = (...getEndpointFunctions: GetEndpointFunction[]) => {
  const endpointParts = getEndpointFunctions.map(x => getEndpoint(x));
  return endpointParts.reduce((x, y) => urlJoin(x, y));
};

export const defaultLogLevelId = 4;
