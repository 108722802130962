import { IObservableObject, action, observable } from 'mobx';

// TODO: Update all usages of old StatefulFunction to this one instead

export enum State {
  Initial = 'initial',
  Processing = 'processing',
  Failed = 'failed',
  Finished = 'finished'
}

export const processingObservableProperty = 'processingObservable';
export const cancelObservableProperty = 'cancelObservable';

export interface StatefulFunction extends Function {
  isNewStateful?: boolean;
  readonly state: State;
  readonly cancel: () => void;
  readonly setState: (state: State) => void;
  readonly setCancel: (cancel: () => void) => void;
}

export interface ProcessingObservable {
  state: State;
  setState: (state: State) => void;
}

export interface CancelObservable {
  cancel: () => void;
  setCancel: (cancel: () => void) => void;
}

export const createStatefulFunction = <TFunction extends Function>(callback: TFunction): StatefulFunction & TFunction => {
  const callable = callback as unknown as (StatefulFunction & TFunction);

  if (callable.isNewStateful)
    return callable;

  callable.isNewStateful = true;

  const processingObservable = observable<ProcessingObservable>({
    state: State.Initial,
    setState(this: ProcessingObservable, state: State) {
      this.state = state;
    }
  }, {
    setState: action
  });

  const cancelObservable = observable<CancelObservable>({
    cancel: () => {},
    setCancel(this: CancelObservable, cancel: () => void) {
      this.cancel = cancel;
    }
  }, {
    setCancel: action
  });

  defineProcessingOnStatefulFunction(callable, processingObservable);
  defineCancelOnStatefulFunction(callable, cancelObservable);

  return callable;
};

export const defineProcessingOnStatefulFunction =
  (func: StatefulFunction, processingObservable: ProcessingObservable & IObservableObject) =>
    Object.defineProperties(func, {
      [processingObservableProperty]: {
        get: () => processingObservable
      },
      state: {
        get: () => processingObservable.state,
        configurable: true
      },
      setState: {
        get: () => (state: State) => processingObservable.setState(state),
        configurable: true
      }
    });

export const defineCancelOnStatefulFunction =
  (func: StatefulFunction, cancelObservable: CancelObservable & IObservableObject) =>
    Object.defineProperties(func, {
      [cancelObservableProperty]: {
        get: () => cancelObservable
      },
      cancel: {
        get: () => cancelObservable.cancel,
        configurable: true
      },
      setCancel: {
        get: () => (cancel: () => void) => cancelObservable.setCancel(cancel),
        configurable: true
      }
    });
