import '../../scss/pages/Status.scss';

import {
  AirdomeHeightChart,
  PressureChart,
  StatusDateSelect,
  TemperatureChart,
  WindSpeedChart
} from '../../components/charts';
import {
  NotificationsBox,
  PropertiesBox,
  StatusBox
} from '../../components/status';

import BaseAirdomePage from '../BaseAirdomePage';
import Dialog from '../../shared/components/Dialog';
import { History } from 'history';
import LanguageStore from '../../stores/LanguageStore';
import LightingStore from '../../stores/LightingStore';
import NotificationStore from '../../stores/NotificationStore';
import { Row } from 'reactstrap';
import StatusBoxStore from '../../stores/status/StatusBoxStore';
import StatusChartStore from '../../stores/status/StatusChartStore';
import Translate from '../../localization/Translate';
import { basePageInject } from '../BasePageInject';

export type StatusProps = {
  languageStore: LanguageStore;
  lightingStore: LightingStore;
  statusBoxStore: StatusBoxStore;
  statusChartStore: StatusChartStore;
  notificationStore: NotificationStore;
  history: History;
};

@basePageInject('statusBoxStore', 'languageStore', 'statusChartStore', 'notificationStore', 'lightingStore')
class StatusPage extends BaseAirdomePage<StatusProps> {
  public componentWillUnmount = () =>
    this.props.statusChartStore!.clearStatuses()

  public componentDidMount = () => {
    this.props.statusChartStore.fetchStatuses()
    this.props.lightingStore.fetchActiveLights()
  }

  public componentWillMount = () =>
    this.props.statusChartStore.resetDates()

  private renderModal = (translate: Translate) => <Dialog
    color="warning"
    isOpen={this.props.statusChartStore!.showWarningModal}
    cancelButtonLabel={translate(x => x.general.cancel)}
    confirmButtonLabel={translate(x => x.general.ok)}
    confirm={async () => await this.props.statusChartStore!.confirmWarningModal()}
    close={() => this.props.statusChartStore!.closeWarningModal()}
    title={translate(x => x.pages.status.charts.dataWarningTitle)}
    message={translate(x => x.pages.status.charts.dataWarningMessage)}
  />

  public render() {
    const {
      statusBoxStore,
      lightingStore: {
        lights: {
          LightsState: lightsState
        }
      },
      languageStore,
      statusChartStore,
      notificationStore
    } = this.props;

    const statusModel = statusBoxStore.status;
    const airdome = this.Airdome;

    const translate = languageStore.translate;
    const locale = languageStore.Locale;

    return <Row className="status-box">
      <PropertiesBox {...{
        translate,
        locale,
        airdomeState: airdome && airdome.state
          ? airdome.state
          : 'Dead',
        airdome: airdome ? {
          name: airdome.name,
          projectNumber: airdome.projectNumber,
          countryShortcut: airdome.country.shortcut
        } : undefined,
        statusTimestamp: statusModel.timestamp,
        onStatusForce: async () => await statusBoxStore.forceStatus()
      }} />
      <StatusBox {...{
        translate,
        statusModel,
        lights: lightsState,
        onLightingClick: () => airdome && this.props.history!.push(`lighting`)
      }} />
      <NotificationsBox {...{
        notifications: notificationStore.notifications,
        observedNotifications: notificationStore.observedNotifications,
        removeNotification: notification => notificationStore.resolveNotification(notification)
      }} />
      <StatusDateSelect {...{
        translate,
        dates: {
          locale,
          fromDate: statusChartStore.fromDate,
          toDate: statusChartStore.toDate,
          setFromDate: statusChartStore.setFromDate,
          setToDate: statusChartStore.setToDate
        },
        handleSubmit: async () => await statusChartStore.fetchStatuses()
      }} />
      <TemperatureChart locale={this.props.languageStore.Locale} translate={translate} data={statusChartStore.statuses} />
      <PressureChart locale={this.props.languageStore.Locale} translate={translate} data={statusChartStore.statuses} />
      <WindSpeedChart locale={this.props.languageStore.Locale} translate={translate} data={statusChartStore.statuses} />
      <AirdomeHeightChart locale={this.props.languageStore.Locale} translate={translate} data={statusChartStore.statuses} />
      {this.renderModal(translate)}
    </Row>;
  }
}

export default StatusPage;
