import 'moment/locale/de';
import 'moment/locale/cs';

import moment from "moment";
import { useLocalization } from "localization/LocalizationProvider";

/**
 * @returns Local weekday order transforming functions from ISO day order (Sunday=0, Saturday=6) to local order (e.g. Monday=0, Sunday=6) and vice versa
 */
const useLocalWeekdays = (): {
  weekdays: string[],
  toLocalDayOrder: (dayIndex: number) => number,
  toIsoDayOrder: (localDayIndex: number) => number
} => {
  useLocalization();

  return {
    weekdays: moment.weekdays(true),
    toLocalDayOrder: dayIndex =>
      (dayIndex - moment().localeData().firstDayOfWeek() + 7) % 7,
    toIsoDayOrder: localDayIndex =>
      moment().isoWeekday(moment.weekdays(true, localDayIndex)).day()
  };
}

export default useLocalWeekdays;