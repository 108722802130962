interface Props {
  message: string;
  condition?: boolean;
}

const ValidationMessage = ({ message, condition }: Props) => (
  <>
    {
      condition === undefined || condition
        ? <span className="element-error">{message}</span>
        : null
    }
  </>
);

export default ValidationMessage;