import PreheatingSettings from 'localization/base/pages/PreheatingSettings';

const preheatingSettings: PreheatingSettings = {
  header: 'Vorluft',
  historyHeader: 'Geschichte der Vorflut',
  maximalPreheatingMinutes: 'Maximale Vorzündzeit (min.)',
  minimumPreheatingMinutes: 'Minimum Vorzündzeit (min.)',
  isPreheatingEnabled: "Vorzünderlaubnis",
  timestamp: 'Letzte Aktualisierung',
  updatedBy: 'Aktualisiert von',
};

export default preheatingSettings;
