import { AxiosError, AxiosInstance } from 'axios';

import DoorLockSettingsDto from 'dto/settings/DoorLockSettingsDto';
import PagingDto from 'services/shared/dtos/PagingDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class DoorLockSettingsService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  public fetchDoorLockSettings = async (airdomeId: number, trackingId: string)
    : Promise<DoorLockSettingsDto> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.settings.baseUri,
          x => x.settings.doorLockSettings.get),
        airdomeId.toString());

      const result = await this.axios.get<DoorLockSettingsDto>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error as AxiosError);
    }
  }

  public fetchDoorLockSettingsHistory = async (airdomeId: number, skip: number, take: number, trackingId: string)
    : Promise<PagingDto<DoorLockSettingsDto>> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.settings.baseUri,
          x => x.settings.doorLockSettings.getHistory),
        airdomeId.toString(),
        skip.toString(),
        take.toString());

      const result = await this.axios.get<PagingDto<DoorLockSettingsDto>>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error as AxiosError);
    }
  }

  public updateDoorLockSettings = async (updatedDoorLockSettings: Omit<DoorLockSettingsDto, 'userId' | 'timestamp'>, airdomeId: number, trackingId: string)
    : Promise<number> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.settings.baseUri,
          x => x.settings.doorLockSettings.update),
        airdomeId.toString());

      const response = await this.axios.put<number>(url, updatedDoorLockSettings, { headers: { trackingId } });
      return response.data;
    } catch (error) {
      throw getBackendException(error as AxiosError);
    }
  }
}
