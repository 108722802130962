import { Button, ButtonToolbar, ButtonToolbarProps } from "reactstrap";

import ButtonLoading from "shared/components/ButtonLoading";

export interface ConfirmationButtonToolbarProps {
  containerProps?: ButtonToolbarProps,
  confirm?: {
    onClick: () => void | Promise<void>;
    content: string | JSX.Element;
    enabled: boolean;
  },
  cancel: {
    onClick: () => void;
    content: string | JSX.Element;
    enabled?: boolean;
  },
  delete?: {
    onClick: () => void | Promise<void>;
    content: string | JSX.Element;
  }
}

const ConfirmationButtonToolbar = (props: ConfirmationButtonToolbarProps) => {
  const containerClassNames = ["confirmation-button-toolbar justify-content-center flex-column flex-xs-row"];
  props.containerProps?.className && containerClassNames.push(props.containerProps.className);

  return (
    <ButtonToolbar {...{
      ...props.containerProps,
      className: containerClassNames.join(' ')
    }}>
      {
        props.confirm
          ? <ButtonLoading
            color="primary"
            disabled={!props.confirm.enabled}
            onClick={props.confirm.onClick}
            content={props.confirm.content} />
          : null
      }
      <Button
        className="icon"
        disabled={!(props.cancel.enabled ?? true)}
        onClick={props.cancel.onClick}>
        {props.cancel.content}
      </Button>
      {
        props.delete
          ? <ButtonLoading
            color="danger"
            onClick={props.delete.onClick}
            content={props.delete.content} />
          : null
      }
    </ButtonToolbar>
  );
}

export default ConfirmationButtonToolbar;