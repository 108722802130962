import User from '../../base/menu/User';

const user: User = {
  categoryName: 'Uživatelé',
  create: 'Přidat uživatele',
  edit: 'Můj profil',
  logout: 'Odhlásit se'
};

export default user;
