import { BasicNotification, ImageNotification } from '../../shared/components/Notification';
import { GetImageNotification, GetNotification } from '../../services/signalr/UINotificationService';
import { inject, observer } from 'mobx-react';

import LanguageStore from '../../stores/LanguageStore';
import NotificationSystem from 'rc-notification';
import React from 'react';
import { UINotificationService } from '../../services';

interface NotificationProviderProps {
  uiNotificationService?: UINotificationService;
  languageStore?: LanguageStore;
}

@inject('uiNotificationService', 'languageStore')
@observer
export default class NotificationProvider extends React.Component<NotificationProviderProps> {
  private notificationWrapper: React.RefObject<HTMLDivElement>;
  private notificationSystem?: NotificationSystem;

  constructor(props: NotificationProviderProps) {
    super(props);

    this.notificationWrapper = React.createRef();
    if (props.languageStore && props.uiNotificationService) {
      props.uiNotificationService.onNotification(x => this.notify(x));
      props.uiNotificationService.onImageNotification(x => this.notifyWithImage(x));
    }
  }

  public componentDidMount() {
    this.createNotificationSystemIfUndefined();
  }

  public render() {
    return <div ref={this.notificationWrapper}>
      {this.props.children}
    </div>;
  }

  public notify = (getNotification: GetNotification) => {
    if (!this.notificationSystem || !this.props.languageStore)
      return;

    const notificationContent = getNotification(this.props.languageStore.translate);

    this.notificationSystem.notice({
      content: <BasicNotification {...notificationContent} />,
      duration: 60,
      closable: true,
      className: 'right-up',
    });
  }

  public notifyWithImage = (getNotification: GetImageNotification) => {
    if (!this.notificationSystem || !this.props.languageStore)
      return;

    const notificationContent = getNotification(this.props.languageStore.translate);

    this.notificationSystem.notice({
      content: <ImageNotification {...notificationContent} />,
      duration: 60,
      closable: true,
      className: 'right-up',
    });
  }

  private createNotificationSystemIfUndefined = () => {
    if (this.notificationSystem)
      return;

    if (!this.notificationWrapper.current)
      return;

    NotificationSystem.newInstance({
      getContainer: () => this.notificationWrapper.current as HTMLElement,
      style: {}
    }, (instance: NotificationSystem) => this.notificationSystem = instance);
  }
}
