import DecoratorBase, { FunctionType } from './NewDecoratorBase';
import { State, StatefulFunction, createStatefulFunction } from '../shared/NewStatefulFunction';

import { CancellablePromise } from 'mobx/lib/api/flow';

class TrackedDecorator extends DecoratorBase<object> {
  public implementation = async <TResult>(wrapper: StatefulFunction, method: FunctionType<TResult>): Promise<TResult> => {
    wrapper.setState(State.Processing);

    let result;
    try {
      const potentialPromise = method();
      const cancellablePromise = potentialPromise as CancellablePromise<TResult>;

      if(typeof cancellablePromise.cancel === 'function')
        wrapper.setCancel(cancellablePromise.cancel);
      else
        wrapper.setCancel(() => {});

      result = await potentialPromise;
      wrapper.setState(State.Finished);
    } catch (err) {
      wrapper.setState(State.Failed);
      throw err;
    }

    return result;
  }
}

const decorator =  new TrackedDecorator();

export const tracked = decorator.handle;
export const asTracked = <TFunction extends Function, TResult>(
  method: TFunction & FunctionType<TResult>
): TFunction & StatefulFunction => {
  // eslint-disable-next-line prefer-const
  let statefulFunction: StatefulFunction & FunctionType<TResult>;

  const newlyWrappedFunction = async (...args: any[]) =>
    await decorator.implementation(
      statefulFunction,
      async () => await method(...args))

  statefulFunction = createStatefulFunction<FunctionType<TResult>>(newlyWrappedFunction);

  return statefulFunction as unknown as (TFunction & StatefulFunction);
};
