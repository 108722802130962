import { CheckboxModel } from '../../../../../models';
import { FormCheckbox } from '../../../..';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface HasGasConsumptionFieldProps {
  hasGasConsumption: boolean;
  setHasGasConsumption: (value: boolean) => void;
}

const HasGasConsumptionField = ({ hasGasConsumption, setHasGasConsumption }: HasGasConsumptionFieldProps) =>
  {
    const translate = useLocalization();
    return <FormCheckbox {...{
      translate,
      model: new CheckboxModel({
        inputId: 'AirdomeGasConsumption',
        label: translate(x => x.forms.airdome.create.hasGasConsumption),
        setValue: setHasGasConsumption,
        value: hasGasConsumption
      })
    }} />;
  };

export default observer(HasGasConsumptionField);
