import AirdomeHubConnection from './base/AirdomeHubConnection';
import AirdomeStore from 'stores/AirdomeStore';
import IntervalsSignalRDto from './dto/IntervalsDto';
import SecurityStore from 'stores/SecurityStore';
import { getJoinedEndpoint } from '../../utils/Settings';

type StatusEvents = 'AirdomeIsInflatedChanged' | 'AirdomeIsInitializedChanged' | 'IntervalsUpdated';

export default class RegistrySingleAirdomeHubConnection extends AirdomeHubConnection<StatusEvents, ''> {
  constructor(airdomeStore: AirdomeStore, securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.airdome.registry
    ), airdomeStore, securityStore);
  }

  public onAirdomeIsInitializedChanged = (callback: (airdomeId: number, isInitialized: boolean) => void) => {
    super.on('AirdomeIsInitializedChanged', callback);
  }

  public onAirdomeIsInflatedChanged = (callback: (airdomeId: number, isInflated: boolean) => void) => {
    super.on('AirdomeIsInflatedChanged', callback);
  }

  public onIntervalsUpdated = (callback: (intervals: IntervalsSignalRDto) => void) => {
    super.on('IntervalsUpdated', callback);
  }
}
