import Template from '../../base/menu/Template';

const template: Template = {
  categoryName: 'Spravovat šablony',
  create: 'Přidat',
  edit: 'Upravit',
  list: 'Šablony'
};

export default template;
