import RDevNotifications from '../../../base/pages/rdevNotifications';
import message from './Message';
import type from './Type';

const rdevNotifications: RDevNotifications = {
  header: 'Notifikace RDev',
  timestampHeader: 'Čas',
  typeHeader: 'Typ notifikace',
  messageHeader: 'Zpráva',
  
  type,
  message,
};

export default rdevNotifications;
