import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import NotificationSeverity from '../../dto/notification/NotificationSeverity';
import React from 'react';

const NotificationSeverityIcon = ({ severity }: { severity: NotificationSeverity }) => {
  switch (severity) {
    case NotificationSeverity.Error:
      return <CloseCircleOutlineIcon />;
    case NotificationSeverity.Warning:
      return <CommentAlertOutlineIcon />;
    case NotificationSeverity.Information:
      return <InformationOutlineIcon />;
  }

  return null;
};

export default NotificationSeverityIcon;
