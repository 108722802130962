import './NotificationsHistory.scss';

import { Card, CardBody, Col } from 'reactstrap';
import { DateTimeRangePicker, MaterialTable } from 'components';
import { LinearProgress, TablePagination } from '@material-ui/core';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import AirdomeTime from 'components/airdome/AirdomeTime';
import NotificationSeverity from 'dto/notification/NotificationSeverity';
import NotificationsHistoryRowData from './NotificationsHistoryRowData';
import { State } from 'stores/shared/NewStatefulFunction';
import { TableColumn } from 'components/basic/material-table/table-header';
import { observer } from "mobx-react";
import { useEffect } from 'react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const NotificationsHistory = () => {
  const translate = useLocalization();

  const airdomeStore = useStore(x => x.airdomeStore);
  const notificationsHistoryStore = useStore(x => x.notificationsHistoryStore);

  useEffect(() => {
    notificationsHistoryStore.fetch();

    return notificationsHistoryStore.clear;
  }, [notificationsHistoryStore, airdomeStore.SelectedAirdomeId]);

  const tableData = notificationsHistoryStore.items
    .map(i => new NotificationsHistoryRowData(i));

  const generateSeverityCell = (severity: NotificationSeverity) => {
    let className = `text-center SeverityCell ${NotificationSeverity[severity]}`;
    let text;

    switch (severity) {
      case NotificationSeverity.Information:
        text = translate(x => x.pages.plcNotifications.history.severity.information)
        break;
      case NotificationSeverity.Warning:
        text = translate(x => x.pages.plcNotifications.history.severity.warning)
        break;
      case NotificationSeverity.Error:
        text = translate(x => x.pages.plcNotifications.history.severity.error)
        break;
    }

    return <p className={className}>
      {text}
    </p>;
  }

  const table = <MaterialTable<NotificationsHistoryRowData>
    disableSorting
    showPagination={false}
    showCheckboxColumn={false}
    clickRowToSelect={false}
    multiselect={false}
    data={tableData}
    columns={[
      {
        id: 'occured',
        width: 20,
        disablePadding: false,
        label: translate(x => x.pages.plcNotifications.history.occured),
        renderCell: (rowData: NotificationsHistoryRowData) => <p className='text-center'><AirdomeTime time={rowData.occured}/></p>
      },
      {
        id: 'severity',
        width: 20,
        disablePadding: false,
        label: translate(x => x.pages.plcNotifications.history.severity.severity),
        renderCell: (rowData: NotificationsHistoryRowData) => generateSeverityCell(rowData.severity)
      },
      {
        id: 'message',
        width: 40,
        disablePadding: false,
        label: translate(x => x.pages.plcNotifications.history.message),
        renderCell: (rowData: NotificationsHistoryRowData) => <p className='text-center'>{rowData.message}</p>
      },
      {
        id: 'resolved',
        width: 20,
        disablePadding: false,
        label: translate(x => x.pages.plcNotifications.history.resolved),
        renderCell: (rowData: NotificationsHistoryRowData) => <p className='text-center'>
          {rowData.resolved && <AirdomeTime time={rowData.resolved}/>}
        </p>
      }
    ].filter(x => !!x) as TableColumn<NotificationsHistoryRowData>[]} />

  const pagination =
    notificationsHistoryStore.totalCount > 0 &&
    <TablePagination
      component="div"
      className="material-table__pagination mt-2"
      count={notificationsHistoryStore.totalCount}
      rowsPerPage={notificationsHistoryStore.pagingModel.rowsPerPage}
      page={notificationsHistoryStore.pagingModel.currentPage}
      backIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.previousPage) }}
      nextIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.nextPage) }}
      onChangePage={(_, page) => notificationsHistoryStore.pagingModel.setCurrentPage(page)}
      onChangeRowsPerPage={(event) => notificationsHistoryStore.pagingModel.setRowsPerPage(Number(event.target.value))}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" }
      ]}
      labelRowsPerPage={translate(x => x.general.datatables.numberOfRowsShown)}
      labelDisplayedRows={(paginationInfo): React.ReactNode => translate(x => x.general.datatables.displayedRows,
        {
          from: paginationInfo.from,
          to: paginationInfo.to,
          page: paginationInfo.page + 1,
          count: paginationInfo.count
        })
      }
    />;

  return <>
    <AirdomePageHeader
      icon={<span className="lnr lnr-warning" />}
      title={`${airdomeStore.SelectedAirdome?.name}: ${translate(x => x.pages.plcNotifications.history.header)}`} />
    <Card className="NotificationsHistoryDateTimeRangeSelector">
      <CardBody>
        <DateTimeRangePicker
          start={notificationsHistoryStore.start}
          end={notificationsHistoryStore.end}
          endMax={notificationsHistoryStore.endMax}
          setStart={notificationsHistoryStore.setStart}
          setEnd={notificationsHistoryStore.setEnd}
          submit={notificationsHistoryStore.fetch}
        />
      </CardBody>
    </Card>
    <Card className="NotificationsHistoryCard">
      <CardBody>
        {
          notificationsHistoryStore.fetch.state === State.Processing
            ? <LinearProgress />
            : null
        }
        {table}
        {pagination}
      </CardBody>
    </Card>
  </ >;
}

export default observer(NotificationsHistory);