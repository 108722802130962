import Airdome from '../../../base/menu/airdome';
import bacnetObjects from './BacnetObjects';
import lighting from './Lighting';
import plcNotifications from './PlcNotifications';
import settings from './Settings';

const airdome: Airdome = {
  lighting,
  settings,
  bacnetObjects,
  plcNotifications,

  create: 'Vytvořit halu',
  list: 'Seznam hal',
  status: 'Stav',
  calendar: 'Kalendář',
  consumption: 'Spotřeba',
  restrictions: 'Omezení',
  switchbox: 'Rozvaděč',
  systemLog: 'Systémový protokol',
  rdevNotifications: 'Notifikace RDev',
  doorLockSettings: 'Zamykání dveří',
  preheatingSettings: 'Předzátop',
  
  history: 'Historie'
};

export default airdome;
