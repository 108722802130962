import * as validations from 'validations/validations';

import {
  ConfirmPasswordField,
  EmailField,
  NotificationsSelectField,
  PasswordField,
  RoleSelectField,
  UsernameField
} from './fields';

import Alert from '../../../shared/components/Alert';
import ButtonLoading from '../../../shared/components/ButtonLoading';
import EditUserFormProps from './EditUserFormProps';
import { observer } from 'mobx-react';

const EditUserForm = (props: EditUserFormProps) => {
  const {
    submitText,
    translate,
    onSubmit,
    children,
    isProcessing,
    userModel,
    roleOptions,
    notificationsOptions,
    notificationLoadError
  } = props;

  return <form onSubmit={(e) => {
    e && e.preventDefault();
    return false;
  }}>
    {notificationLoadError ?
      <Alert {...{
        color: notificationLoadError.level
      }}>
        <span>{translate(notificationLoadError.translation)}</span>
      </Alert>
      : null}
    <fieldset disabled={isProcessing}>
      <UsernameField {...{
        translate,
        validationSchema: validations.getUsernameValidationSchema(
          translate(x => x.general.user.username),
          translate(x => x.validations.string.noWhitespace),
          translate(x => x.validations.string.username)),
        username: userModel.username,
        setUsername: x => userModel.setUsername(x)
      }} />
      <PasswordField {...{
        translate,
        validationSchema: validations.getOptionalPasswordValidationSchema(
          translate(x => x.validations.string.min),
          translate(x => x.validations.string.max)
        ),
        password: userModel.password,
        setPassword: x => userModel.setPassword(x)
      }} />
      <ConfirmPasswordField {...{
        translate,
        confirmPassword: userModel.confirmPassword,
        setConfirmPassword: x => userModel.setConfirmPassword(x),
        arePasswordsDifferent: userModel.arePasswordsDifferent
      }} />
      <EmailField {...{
        translate,
        validationSchema: validations.getEmailValidationSchema(),
        currentEmail: userModel.email,
        setEmail: x => userModel.setEmail(x)
      }} />
      <RoleSelectField {...{
        translate,
        roleOptions,
        currentRole: userModel.role,
        setRole: x => userModel.setRole(x)
      }} />
      <NotificationsSelectField {...{
        translate,
        notificationsOptions,
        notifications: userModel.notifications,
        setNotifications: x => userModel.setNotifications(x),
        readOnly: !!notificationLoadError
      }} />
      <div>
        <ButtonLoading
          disabled={!userModel.areDataValid}
          className="col-md-2 col-12"
          onClick={async () => await onSubmit()}
          content={submitText}
          color="primary" />
        {children}
      </div>
    </fieldset>
  </form>;
};

export default observer(EditUserForm);
