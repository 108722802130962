import Validations from '../../base/validations';

const validations: Validations = {
  mixed: {
    required: 'Pole ${label} je povinné'
  },
  string: {
    min: '${label} musí mít alespoň ${min} znaků',
    max: '${label} musí mít maximálně ${max} znaků',
    email: 'Zadejte platný email',
    noWhitespace: '${label} nemůže obsahovat mezery.',
    macAddress: '\'${value}\' není platná MAC adresa.',
    username: '${label} může obsahovat pouze znaky [a-zA-Z0-9-._@+.]'
  },
  number: {
    min: '${label} musí být větší nebo rovna ${min}',
    max: '${label} musí být menší nebo rovna ${max}'
  }
};

export default validations;
