import Consumption from '../../../base/pages/consumption';
import modal from './Modal';

const consumption: Consumption = {
  modal,

  gas: 'PLYN',
  electricity: 'ELEKTŘINA',
  header: 'Spotřeba elektřiny a plynu',
  selectRange: 'Vyberte rozmezí',
  descriptionTotal: 'Celková spotřeba',
  descriptionSelection: 'Celkem za rozmezí',
  updateButton: 'Aktualizovat'
};

export default consumption;
