import Settings from '../../../../base/forms/airdome/settings';

const settings: Settings = {
  header: 'Nastavení',
  temperatureComfort: 'Teplota komfort (°C)',
  temperatureCutback: 'Teplota útlum (°C)',
  temperatures: 'Teploty',
  pressureNormal: 'Normální tlak (Pa)',
  pressureMax: 'Maximální tlak (Pa)',
  pressures: 'Tlaky',
  lastUpdate: 'Naposledy aktualizováno',
  updatedBy: 'Upravil',
  requestState: 'Stav požadavku na změnu'
};

export default settings;
