import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { getEndpoint, getJoinedEndpoint } from './Settings';

const userManagerConfig: UserManagerSettings = {
  authority: getEndpoint(x => x.identity.baseUri),
  client_id: 'js',
  redirect_uri: getJoinedEndpoint(
    x => x.frontendBaseUri,
    x => x.identity.signInRedirectUrl
  ),
  response_type: 'code',
  scope: 'openid offline_access arc_api',
  post_logout_redirect_uri: getJoinedEndpoint(
    x => x.frontendBaseUri,
    x => x.identity.signOutRedirectUrl
  ),
  accessTokenExpiringNotificationTimeInSeconds: 30 // Default: 60
};

const userManager = new UserManager(userManagerConfig);

export default userManager;
