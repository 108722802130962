import PlcNotifications from "../../../base/pages/plcnotifications";

const plcNotifications: PlcNotifications = {
    header: '{name}: PLC notifizierung',
    activeNotifications: 'Nicht gelöste Notifizierungen: {count}',
    resolvedNotifications: 'Gelöste Notifizierungen: {count}',
    resolveAllNotifications: 'Alle Notifizierungen lösen',
    history: {
        header: 'PLC Benachrichtigungsverlauf',
        occured: 'Aufgetreten ',
        severity: {
            severity: 'Schweregrad',
            information: 'Informationen',
            warning: 'Warnung',
            error: 'Fehler',
        },
        message: 'Botschaft',
        resolved: 'Gelöst',
        notResolved: 'Nein'
    }
}

export default plcNotifications;