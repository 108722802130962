import BaseTemperatureField from '../BaseTemperatureField';
import React from 'react';
import TemperatureProps from '../../TemperatureProps';
import { observer } from 'mobx-react';

const MinimumValueField = ({ translate, model, readonly }: TemperatureProps) =>
  <BaseTemperatureField {...{
    readonly,
    numberValidationSchema: model.comfortTemperatureMinSchema.label(
      translate(x => x.forms.airdome.restrictions.temperature.comfortTemperatureMin)
    ),
    inputId: 'LowerBound_TemperatureComfort_Field',
    label: translate(x => x.forms.airdome.restrictions.temperature.comfortTemperatureMin),
    value: model.comfortTemperatureMin.Value,
    setValue: x => model.comfortTemperatureMin.setValue(x),
    isEnabled: model.isComfortTemperatureMinEnabled.Value ?? false,
    setIsEnabled: x => model.isComfortTemperatureMinEnabled.setValue(x)
  }} />;

export default observer(MinimumValueField);
