import { AxiosInstance } from 'axios';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from './../../utils/Settings';
import stringFormat from 'string-format';

export default class StatusService {
  private axios: AxiosInstance;
  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  public requestSwitchBoxData = async (airdomeId: number, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(x => x.status.baseUri, x => x.status.switchbox.request), 
        airdomeId.toString());
        
      await this.axios.post(url, undefined, { headers: { trackingId }});
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }
}
