import './TemperatureCard.scss';

import { Card, CardBody, Row } from 'reactstrap';
import {
  ComfortTemperatureFieldset,
  CutbackTemperatureFieldset
} from './fieldsets';

import { AwaitingDataSourceComponent } from '../..';
import ButtonLoading from '../../../shared/components/ButtonLoading';
import React from 'react';
import TemperatureProps from './TemperatureProps';
import { observer } from 'mobx-react';

@observer
export default class TemperatureCard extends AwaitingDataSourceComponent<TemperatureProps> {
  protected clearModel = () =>
    this.props.model.resetToOriginalValues()

  protected renderContent(): React.ReactNode {
    return <Card>
      <CardBody>
        <h3>{this.props.translate(x => x.forms.airdome.restrictions.temperature.header)}</h3>
        <fieldset>
          <Row>
            <CutbackTemperatureFieldset  {...this.props} />
            <ComfortTemperatureFieldset  {...this.props} />
          </Row>
          <Row className="justify-content-end temperatureCardRow">
            <ButtonLoading {...{
              className: 'mb-0',
              color: 'primary',
              content: this.props.translate(x => x.general.update),
              onClick: this.props.onUpdate,
              disabled: this.props.readonly || !this.props.model.areDataValid
            }} />
          </Row>
        </fieldset>
      </CardBody>
    </Card>;
  }
}
