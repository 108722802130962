import createStatefulFunction, { StatefulFunction } from '../shared/StatefulFunction';

export interface Descriptor {
  value?: Function;
  initializer?: () => Function;
}

export default abstract class DecoratorBase<TOwner> {
  protected abstract wrapMethod(wrapper: StatefulFunction, getOriginalMethod: () => Function, ...args: any[]): Function;

  public handle = (
    target: object,
    propertyKey: string,
    descriptor?: {
      value?: Function, initializer?: () => Function
    }) => {
    if (!descriptor) return;
    if (descriptor.initializer)
      this.prepareDescriptorInitializer(descriptor);
    else if (descriptor.value)
      this.prepareDescriptorMethod(descriptor);
  }

  private prepareDescriptorMethod = (descriptor: Descriptor) => {
    const originalMethod = descriptor.value!;
    descriptor.value = this.getWrapper(() => originalMethod);
  }

  private prepareDescriptorInitializer = (descriptor: Descriptor) => {
    const getOriginalMethod = descriptor.initializer!;
    descriptor.initializer = () => this.getWrapper(getOriginalMethod);
  }

  protected getWrapper = (getOriginalMethod: Function) => {
    let wrapper: StatefulFunction;
    const wrapMethod = this.wrapMethod;

    wrapper = createStatefulFunction(function (this: TOwner, ...args: any[]) {
      const method = getOriginalMethod!.bind(this)();

      if (method.isStateful) {
        wrapper.getProcessing = method.getProcessing;
        wrapper.setProcessing = method.setProcessing;
        wrapper.getLastExecutionFailed = method.getLastExecutionFailed;
        wrapper.setLastExecutionFailed = method.setLastExecutionFailed;
      }

      return wrapMethod(wrapper, method)(this, ...args);
    });

    return wrapper;
  }
}
