import TimeSpan from '../dto/TimeSpan';

export default class TimeSpanRange {
  public start: TimeSpan;
  public end: TimeSpan;

  constructor(start: TimeSpan, end: TimeSpan) {
    this.start = start;
    this.end = end;
  }

  public containsRange = (otherRange: TimeSpanRange): boolean =>
    this.start.totalMilliseconds <= otherRange.start.totalMilliseconds && this.end.totalMilliseconds >= otherRange.end.totalMilliseconds;

  public overlapsWithRange = (otherRange: TimeSpanRange): boolean =>
    this.start.totalMilliseconds < otherRange.end.totalMilliseconds && this.end.totalMilliseconds > otherRange.start.totalMilliseconds;

  public neighboring = (otherRange: TimeSpanRange): boolean =>
    this.start.totalMilliseconds === otherRange.end.totalMilliseconds || this.end.totalMilliseconds === otherRange.start.totalMilliseconds;

  public mergeRanges = (otherRange: TimeSpanRange): TimeSpanRange =>
    new TimeSpanRange(
      this.start.totalMilliseconds < otherRange.start.totalMilliseconds
        ? this.start
        : otherRange.start,
      this.end.totalMilliseconds > otherRange.end.totalMilliseconds
        ? this.end
        : otherRange.end);

  public equals = (otherRange: TimeSpanRange): boolean =>
    this.start.equals(otherRange.start) && this.end.equals(otherRange.end);

  public toString() {
    return `${this.start.toString()}-${this.end.toString()}`
  }
}