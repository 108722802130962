import SwitchBoxTablePropertyName from 'enums/SwitchBoxTablePropertyName';
import Switchbox from '../../../base/pages/switchbox';

const switchbox: Switchbox = {
  header: 'Switchbox',
  refresh: 'Refresh',
  lastUpdate: 'Last update',

  operationFailure: 'OPERATION/FAILURE',
  
  engineUsage: 'Engine usage',
  engineUsageNotAvailable: 'Engine usage is not available',

  flapUsage: 'Flap opened at',
  flapUsageNotAvailable: 'Flap closeness is not available',

  burnerPercentage: 'Burner usage',
  burnerPercentageNotAvailable: 'Burner usage is not available',

  notAvailable: 'not available',
  valueNotAvailable: 'Value is not available',

  temperatureMode: {
    label: 'Current temperature mode',
    day: 'Day',
    night: 'Night'
  },

  labels: {
    dieselFailure: 'FAILURE',
    engine: 'VENT MOTOR',
    flap: 'FLAP',
    heating: 'HEATING',
    alarmReset: 'ALARM RESET',
    powerOn: 'POWER ON',
    burnerSwitch: 'BURNER',
    aggregate: 'AGGREGATE'
  },

  switches: {
    hand0auto: 'HAND 0 AUTO',
    '0auto': '0 AUTO',
    daynightauto: 'DAY NIGHT AUTO',
    '0autotest': '0 AUTO TEST'
  },

  table: {
    [SwitchBoxTablePropertyName.DomeHeightUpperBound]: 'Airdome height - upper bound',
    [SwitchBoxTablePropertyName.DomeHeightLowerBound]: 'Airdome height - lower bound',
    [SwitchBoxTablePropertyName.DomeHeightAlarmHysteresis]: 'Airdome height hysteresis alarm',
    [SwitchBoxTablePropertyName.WindSpeedLowerBound]: 'Wind - lower bound',
    [SwitchBoxTablePropertyName.WindSpeedUpperBound]: 'Wind - upper bound',
    [SwitchBoxTablePropertyName.WindSpeedAlarmHysteresis]: 'Wind hysteresis alarm',
    [SwitchBoxTablePropertyName.ComputedPressureValue]: 'Computed pressure value',
    [SwitchBoxTablePropertyName.ZesileniRegulaceP]: 'Multiplier regulation - pressure',
    [SwitchBoxTablePropertyName.IntegracniRegulaceP]: 'Integration regulation - pressure',
    [SwitchBoxTablePropertyName.MaximalEmergencyPressure]: 'Maximal emergency pressure',
    [SwitchBoxTablePropertyName.LastPreheatingDurationDeviation]: 'Last pre-heating duration deviation',
    [SwitchBoxTablePropertyName.PredictedPreheatingDuration]: 'Predicted pre-heating duration'
  }
};

export default switchbox;
