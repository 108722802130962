import AirdomeState from '../../localization/base/enums/AirdomeState';
import PersistedHubConnection from './base/PersistedHubConnection';
import SecurityStore from 'stores/SecurityStore';
import { getJoinedEndpoint } from '../../utils/Settings';

type HeartbeatMultiAirdomeEvents = 'ConnectionStateChanged';

export default class HeartbeatMultiAirdomeHubConnection extends PersistedHubConnection<HeartbeatMultiAirdomeEvents, ''> {
  constructor(securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.heartbeat
    ), securityStore);
  }

  public onConnectionStateChanged = (callback: (airdomeId: number, newState: keyof AirdomeState) => void) => {
    super.on('ConnectionStateChanged', callback);
  }
}
