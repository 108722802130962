import './PressureCard.scss';

import { Card, CardBody, Row } from 'reactstrap';
import { MaximalPressureFieldset, NormalPressureFieldset } from './fieldsets';

import { AwaitingDataSourceComponent } from '../..';
import ButtonLoading from '../../../shared/components/ButtonLoading';
import PressureProps from './PressureProps';
import React from 'react';
import { observer } from 'mobx-react';

@observer
export default class PressureCard extends AwaitingDataSourceComponent<PressureProps> {
  protected clearModel = () =>
    this.props.model.resetToOriginalValues()

  protected renderContent(): React.ReactNode {
    return <Card>
      <CardBody>
        <h3>{this.props.translate(x => x.forms.airdome.restrictions.pressure.header)}</h3>
        <fieldset>
          <Row>
            <NormalPressureFieldset {...this.props} />
            <MaximalPressureFieldset {...this.props} />
          </Row>
          <Row className="justify-content-end pressureCardRow">
            <ButtonLoading {...{
              className: 'mb-0',
              color: 'primary',
              content: this.props.translate(x => x.general.update),
              onClick: this.props.onUpdate,
              disabled: this.props.readonly || !this.props.model.areDataValid
            }} />
          </Row>
        </fieldset>
      </CardBody>
    </Card>;
  }
}
