import { TemperatureMode, PressureMode } from './enums';

export default class SettingsDto {
  public temperatureComfort?: number;
  public temperatureCutback?: number;
  public temperatureMode?: TemperatureMode;

  public pressureNormal: number;
  public pressureMax: number;
  public pressureMode: PressureMode;

  constructor(model: {
    temperatureComfort?: number,
    temperatureCutback?: number,
    temperatureMode?: TemperatureMode,

    pressureNormal: number,
    pressureMax: number,
    pressureMode: PressureMode
  }) {
    this.temperatureComfort = model.temperatureComfort;
    this.temperatureCutback = model.temperatureCutback;
    this.temperatureMode = model.temperatureMode;

    this.pressureNormal = model.pressureNormal;
    this.pressureMax = model.pressureMax;
    this.pressureMode = model.pressureMode;
  }
}
