import {
  ArtificialIntelligenceConstantsFieldset,
  ArtificialIntelligenceSwitchFieldset
} from './fieldsets';
import { Card, CardBody, Col, Fade } from 'reactstrap';
import React, { useState } from 'react';

import ConditionalRendering from 'security/ConditionalRendering';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from 'stores/MobXTypedStoreHook';

const ArtificialIntelligenceForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    artificialIntelligenceModel
  } = useStore(x => x.settingsStore);

  useEffect(() => {
    artificialIntelligenceModel
      .fetchArtificialIntelligence()
      .then(() => setIsLoading(false));

    return artificialIntelligenceModel.clear
  }, [artificialIntelligenceModel]);

  if(isLoading)
    return <div className="load__icon-component">
      <svg className="load__icon">
        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    </div>;

  return <Col sm={12}>
      <Fade>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <ConditionalRendering getAccessDefinition={x => x.AI.Enable}>
                <ArtificialIntelligenceSwitchFieldset />
              </ConditionalRendering>
              
              <ArtificialIntelligenceConstantsFieldset />
            </div>
          </CardBody>
        </Card>
      </Fade>
    </Col>;
}

export default observer(ArtificialIntelligenceForm);