import React from 'react';
import Fade from 'reactstrap/lib/Fade';
import InfoFormProps from './InfoFormProps';
import { CardBody, Card, Col, Row } from 'reactstrap';
import { LeftColumnFieldset, RightColumnFieldset } from './fieldsets';
import { observer, inject } from 'mobx-react';
import AwaitingDataSourceComponent from '../../basic/AwaitingDataSourceComponent';

@inject(({ airdomeStore, settingsStore }) => ({ airdomeStore, settingsStore }))
@observer
export default class InfoForm extends AwaitingDataSourceComponent<InfoFormProps> {
  protected fetchData = async () => {
    const { airdomeStore, settingsStore } = this.props;

    if (!airdomeStore || !settingsStore)
      throw new Error('airdomeStore and/or settingsStore is not available.');

    await Promise.all([
      airdomeStore.loadPromise,
      airdomeStore.fetchTemplates(),
      airdomeStore.fetchCountries()
    ]);

    await settingsStore.fetchInfo(airdomeStore.SelectedAirdome!);
  }

  protected clearModel = () =>
    this.props.infoModel.reset()

  protected renderContent = () =>
    <Row>
      <Col sm={12} lg={6}>
        <LeftColumnFieldset {...this.props} />
      </Col>
      <Col sm={12} lg={6}>
        <RightColumnFieldset {...this.props} />
      </Col>
    </Row>

  public render() {
    const { translate } = this.props;

    return <Col sm={12}>
      <Fade>
        <Card>
          <CardBody>
            <h3>{translate(x => x.general.airdome.infoHeader)}</h3>
            {super.render()}
          </CardBody>
        </Card>
      </Fade>
    </Col>;
  }
}
