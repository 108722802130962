import * as validations from '../../../../validations/validations';

import { Col } from 'reactstrap';
import { FormInput } from '../../../';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import IntervalsFormProps from '../IntervalsFormProps';
import React from 'react';
import objectHelper from '../../../../utils/ObjectHelper';
import { observer } from 'mobx-react';

const ConsumptionsIntervalField = ({ translate, intervalModel, disabled }: IntervalsFormProps) => <Col>
  <FormInput {...{
    model: new FormInputModel({
      readonly: disabled,
      validationSchema: validations.getBasicNumberSchema(),
      placeholder: translate(x => x.forms.airdome.intervals.consumptionsInterval),
      handleValueModel: new HandleValueModel({
        setValue: intervalModel.consumptionsInterval.setValue,
        inputType: 'number'
      }),
      label: translate(x => x.forms.airdome.intervals.consumptionsInterval),
      inputId: 'ConsumptionsInterval',
      value: objectHelper.executeIfNumber(intervalModel.consumptionsInterval.Value, x => x.toString()) || ''
    })
  }} />
</Col>;

export default observer(ConsumptionsIntervalField);
