import { FormSelect } from '../../..';
import InfoFormProps from '../InfoFormProps';
import React from 'react';
import objectHelper from '../../../../utils/ObjectHelper';
import { observer } from 'mobx-react';

const CountryField = ({ translate, countryOptions, infoModel, disabled }: InfoFormProps) =>
  <FormSelect {...{
    readOnly: disabled,
    options: countryOptions,
    setValue: x => infoModel.countryId.setValue(Number(x)),
    selectedValue: objectHelper.executeIfNumber(infoModel.countryId.Value, x => x.toString()),
    label: translate(x => x.general.airdome.country),
    selectId: 'CountryField'
  }} />;

export default observer(CountryField);
