import './ArtificialIntelligenceSwitchFieldset.scss';

import { AIOff, AIOn } from '../icons';
import { Col, Row } from 'reactstrap';

import { CircularProgress } from '@material-ui/core';
import React from 'react';
import ToggleButton from '../../../../shared/components/form/ToggleButton';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useState } from 'react';
import { useStore } from 'stores/MobXTypedStoreHook';

const iconHeight = 150;

const iconProps: React.SVGProps<SVGSVGElement> = {
  style: {
    position: 'relative',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    width: '100%',
    height: iconHeight,
    cursor: 'pointer'
  }
};

const ArtificialIntelligenceSwitchFieldset =
  () => {
    const translate = useLocalization();
    const { artificialIntelligenceModel } = useStore(x => x.settingsStore);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    
    const switchIsAIEnabled =
      async () => {
        setIsProcessing(true);
        await artificialIntelligenceModel.setIsAIEnabled(!artificialIntelligenceModel.isAIEnabled);
        setIsProcessing(false);
      };

    return <Col sm={12} md={12} lg={4} xl={3} className="ArtificialIntelligenceSwitch">
      <h3>{translate(x => x.forms.airdome.artificialIntelligence.mainHeader)}</h3>
      {isProcessing
        ? <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: iconHeight }}>
          <CircularProgress/>
        </div>
        : artificialIntelligenceModel.isAIEnabled
          ? <AIOn {...iconProps} onClick={switchIsAIEnabled} />
          : <AIOff {...iconProps} onClick={switchIsAIEnabled} />}
      <Row className="justify-content-center ml-auto mr-auto" style={{ width: 180 }}>
        <label className="col">{artificialIntelligenceModel.isAIEnabled ? 'OFF' : <b>OFF</b>}</label>
        <ToggleButton
          name="Initialized"
          value={artificialIntelligenceModel.isAIEnabled || false}
          onChange={() => switchIsAIEnabled()}
          className="col" />
        <label className="col">{artificialIntelligenceModel.isAIEnabled ? <b>ON</b> : 'ON'}</label>
      </Row>
    </Col>;
  };

export default observer(ArtificialIntelligenceSwitchFieldset);
