import {
  AirdomeService,
  ArtificialIntelligenceService,
  BacnetObjectsHubConnection,
  ChangeRequestHubConnection,
  ChangeRequestService,
  ConsumptionService,
  CountriesService,
  HeartbeatAirdomeService,
  HeartbeatMultiAirdomeHubConnection,
  IdentityAirdomeService,
  IntervalsService,
  LightsService,
  NotificationsChannelHubConnection,
  NotificationsService,
  PlcNotificationsHubConnection,
  RegistryMultiAirdomeHubConnection,
  RegistrySingleAirdomeHubConnection,
  SettingsHubConnection,
  SettingsService,
  StatusAirdomeService,
  StatusService,
  StatusSingleAirdomeHubConnection,
  TemplateService,
  UINotificationService,
  UserService
} from '../services';
import {
  AirdomeStore,
  ChangeRequestStore,
  ConsumptionStore,
  ErrorStore,
  LightingStore,
  NotificationStore,
  RestrictionsStore,
  SettingsStore,
  StatusBoxStore,
  StatusChartStore,
  UserStore
} from '.';

import AIService from 'services/ai/AIService';
import BacnetObjectsService from '../services/settings/BacnetObjectsService';
import BacnetObjectsStore from './BacnetObjectsStore';
import BasicRootStore from './BasicRootStore';
import CalendarService from '../services/settings/CalendarService';
import CalendarStore from './CalendarStore';
import DoorLockSettingsService from 'services/settings/DoorLockSettingsService';
import DoorLockSettingsStore from './DoorLockSettingsStore';
import NotificationsHistoryStore from './NotificationsHistoryStore';
import PreheatingSettingsService  from 'services/settings/PreheatingSettingsService';
import PreheatingSettingsStore from './PreheatingSettingsStore';
import RDevNotificationsStore from './RDevNotificationsStore';
import RestrictionsService from '../services/settings/RestrictionsService';
import SwitchBoxService from 'services/status/SwitchBoxService';
import SwitchBoxStore from './SwitchBoxStore';
import UserProfileStore from './UserProfileStore';

export default class RootStore {
  public axiosApp = BasicRootStore.axiosApp;
  public getTrackingId = BasicRootStore.getTrackingId;
  public loggerService = BasicRootStore.loggerService;
  public loggerStore = BasicRootStore.loggerStore;
  public securityStore = BasicRootStore.securityStore;
  public loginStore = BasicRootStore.loginStore;
  public languageStore = BasicRootStore.languageStore;

  public errorStore: ErrorStore;
  public heartbeatMultiAirdomeHubConnection: HeartbeatMultiAirdomeHubConnection;
  public statusSingleAirdomeHubConnection: StatusSingleAirdomeHubConnection;
  public registrySingleAirdomeHubConnection: RegistrySingleAirdomeHubConnection;
  public registryMultiAirdomeHubConnection: RegistryMultiAirdomeHubConnection;
  public notificationsChannelHubConnection: NotificationsChannelHubConnection;
  public plcNotificationsHubConnection: PlcNotificationsHubConnection;
  public changeRequestHubConnection: ChangeRequestHubConnection;
  public settingsHubConnection: SettingsHubConnection;
  public bacnetObjectsHubConnection: BacnetObjectsHubConnection;
  
  public uiNotificationService: UINotificationService;
  public notificationsService: NotificationsService;
  public userService: UserService;
  public airdomeService: AirdomeService;
  public countriesService: CountriesService;
  public templateService: TemplateService;
  public statusService: StatusService;
  public consumptionService: ConsumptionService;
  public settingsService: SettingsService;
  public artificialIntelligenceService: ArtificialIntelligenceService;
  public intervalsService: IntervalsService;
  public restrictionsService: RestrictionsService;
  public lightsService: LightsService;
  public calendarService: CalendarService;
  public bacnetObjectsService: BacnetObjectsService;
  public identityAirdomeService: IdentityAirdomeService;
  public statusAirdomeService: StatusAirdomeService;
  public aiService: AIService;
  public doorLockSettingsService: DoorLockSettingsService;
  public preheatingSettingsService: PreheatingSettingsService;
  public heartbeatAirdomeService: HeartbeatAirdomeService;

  public userStore: UserStore;
  public airdomeStore: AirdomeStore;
  public consumptionStore: ConsumptionStore;
  public notificationStore: NotificationStore;
  public notificationsHistoryStore: NotificationsHistoryStore;
  public rdevNotificationsStore: RDevNotificationsStore;
  public statusChartStore: StatusChartStore;
  public settingsStore: SettingsStore;
  public restrictionsStore: RestrictionsStore;
  public lightingStore: LightingStore;
  public calendarStore: CalendarStore;
  public bacnetObjectsStore: BacnetObjectsStore;
  public statusBoxStore: StatusBoxStore;
  public changeRequestService: ChangeRequestService;
  public changeRequestStore: ChangeRequestStore;
  public switchBoxService: SwitchBoxService;
  public switchBoxStore: SwitchBoxStore;
  public userProfileStore: UserProfileStore;
  public doorLockSettingsStore: DoorLockSettingsStore;
  public preheatingSettingsStore: PreheatingSettingsStore;

  constructor() {
    this.errorStore = new ErrorStore();

    this.userService = new UserService(BasicRootStore.axiosApp);
    this.airdomeService = new AirdomeService(BasicRootStore.axiosApp);
    this.countriesService = new CountriesService(BasicRootStore.axiosApp);
    this.templateService = new TemplateService(BasicRootStore.axiosApp);
    this.statusService = new StatusService(BasicRootStore.axiosApp);
    this.intervalsService = new IntervalsService(BasicRootStore.axiosApp);
    this.consumptionService = new ConsumptionService(BasicRootStore.axiosApp);
    this.notificationsService = new NotificationsService(BasicRootStore.axiosApp);
    this.settingsService = new SettingsService(BasicRootStore.axiosApp);
    this.lightsService = new LightsService(BasicRootStore.axiosApp);
    this.calendarService = new CalendarService(BasicRootStore.axiosApp);
    this.bacnetObjectsService = new BacnetObjectsService(BasicRootStore.axiosApp);
    this.artificialIntelligenceService = new ArtificialIntelligenceService(BasicRootStore.axiosApp);
    this.restrictionsService = new RestrictionsService(BasicRootStore.axiosApp);
    this.identityAirdomeService = new IdentityAirdomeService(BasicRootStore.axiosApp);
    this.statusAirdomeService = new StatusAirdomeService(BasicRootStore.axiosApp);
    this.changeRequestService = new ChangeRequestService(BasicRootStore.axiosApp);
    this.aiService = new AIService(BasicRootStore.axiosApp);
    this.switchBoxService = new SwitchBoxService(BasicRootStore.axiosApp);
    this.doorLockSettingsService = new DoorLockSettingsService(BasicRootStore.axiosApp);
    this.preheatingSettingsService = new PreheatingSettingsService(BasicRootStore.axiosApp);
    this.heartbeatAirdomeService = new HeartbeatAirdomeService(BasicRootStore.axiosApp);

    this.airdomeStore = new AirdomeStore(this, BasicRootStore.securityStore);

    this.registrySingleAirdomeHubConnection = new RegistrySingleAirdomeHubConnection(this.airdomeStore, BasicRootStore.securityStore);
    this.registryMultiAirdomeHubConnection = new RegistryMultiAirdomeHubConnection(BasicRootStore.securityStore);
    this.plcNotificationsHubConnection = new PlcNotificationsHubConnection(BasicRootStore.securityStore);
    this.heartbeatMultiAirdomeHubConnection = new HeartbeatMultiAirdomeHubConnection(BasicRootStore.securityStore);
    this.statusSingleAirdomeHubConnection = new StatusSingleAirdomeHubConnection(this.airdomeStore, BasicRootStore.securityStore);
    this.notificationsChannelHubConnection = new NotificationsChannelHubConnection(BasicRootStore.securityStore);
    this.changeRequestHubConnection = new ChangeRequestHubConnection(this.airdomeStore, BasicRootStore.securityStore);
    this.settingsHubConnection = new SettingsHubConnection(this.airdomeStore, BasicRootStore.securityStore);
    this.bacnetObjectsHubConnection = new BacnetObjectsHubConnection(this.airdomeStore, BasicRootStore.securityStore);

    this.uiNotificationService = new UINotificationService(this);
    this.userStore = new UserStore(this);
    this.settingsStore = new SettingsStore(this);
    this.consumptionStore = new ConsumptionStore(this);
    this.notificationStore = new NotificationStore(this);
    this.notificationsHistoryStore = new NotificationsHistoryStore(this);
    this.rdevNotificationsStore = new RDevNotificationsStore(this);
    this.statusChartStore = new StatusChartStore(this);
    this.restrictionsStore = new RestrictionsStore(this);
    this.lightingStore = new LightingStore(this);
    this.calendarStore = new CalendarStore(this);
    this.bacnetObjectsStore = new BacnetObjectsStore(this);
    this.statusBoxStore = new StatusBoxStore(this);
    this.changeRequestStore = new ChangeRequestStore(this);
    this.switchBoxStore = new SwitchBoxStore(this);
    this.userProfileStore = new UserProfileStore(this.securityStore, this.userService, this.notificationsService, this);
    this.doorLockSettingsStore = new DoorLockSettingsStore(this);
    this.preheatingSettingsStore = new PreheatingSettingsStore(this);
  }

  public createInstancedStore = <TStore>(createStore: (rootStore: RootStore) => TStore) => createStore(this);
}
