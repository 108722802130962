import React from 'react';
import {
  LastUpdateField,
  UpdatedByField,
  RequestStateField
} from '../fields';
import SettingsFormProps from '../SettingsFormProps';

const InfoFieldset = (props: SettingsFormProps) => <>
  <fieldset>
    <LastUpdateField {...props} />
    <UpdatedByField {...props} />
    <RequestStateField {...props} />
  </fieldset>
  <div className="button-wrapper">
    {props.submitButton}
  </div>
</>;

export default InfoFieldset;
