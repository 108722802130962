import { action, observable, reaction } from 'mobx';
import moment, { Moment } from 'moment-timezone';

import { AirdomeModel } from 'models';
import AirdomeStore from '../AirdomeStore';
import ConsumptionModel from '../../models/store/ConsumptionModel';
import ConsumptionService from '../../services/status/ConsumptionService';
import ErrorStore from '../errors/ErrorStore';
import { catchError } from '../decorators';

interface ConsumptionStoreParams {
  airdomeStore: AirdomeStore;
  consumptionService: ConsumptionService;
  errorStore: ErrorStore;
}

export default class ConsumptionStore {
  @observable public readonly consumptionModel: ConsumptionModel = new ConsumptionModel();
  @observable private isLoading: boolean = true;
  @observable public showNoDataModal: boolean = false;
  @observable public fromDate: Moment;
  @observable public toDate: Moment;
  public dateToMaxDate: Moment;

  private params: ConsumptionStoreParams;
  constructor(params: ConsumptionStoreParams) {
    this.params = params;

    this.fromDate = this.roundNextHour().add(-1, 'month');
    this.toDate = this.roundNextHour();
    this.dateToMaxDate = this.roundNextHour();

    reaction(
      () => params.airdomeStore.SelectedAirdome,
      (airdome) => {
        if (!airdome)
          return;
          
        this.fetchConsumptionInner(airdome);
      }
    );
  }

  private roundNextHour = (): Moment => {
    const m = moment();
    return m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
  }

  public get IsLoading(): boolean {
    return this.isLoading;
  }

  @action public setFromDate = (value: Moment) =>
    this.fromDate = value

  @action public setToDate = (value: Moment) =>
    this.toDate = value

  @action public setIsLoading = (isLoading: boolean) =>
    this.isLoading = isLoading

  @action public setShowNoDataModel = (showNoDataModal: boolean) =>
    this.showNoDataModal = showNoDataModal

  @action
  public fetchConsumption = async () => {
    const airdome = this.params.airdomeStore.SelectedAirdome;
    airdome && await this.fetchConsumptionInner(airdome);
  }

  @catchError
  @action
  private fetchConsumptionInner = async (airdome: AirdomeModel, trackingId: string = '') => {
    try {
      const fromDate = moment.tz(this.fromDate, airdome.timeZone.iana).utc();
      const toDate = moment.tz(this.toDate, airdome.timeZone.iana).utc();

      const consumptions = await this.params.consumptionService
        .getConsumptionWithinInterval(airdome.id, trackingId, fromDate, toDate);

      this.consumptionModel.importFromDto(consumptions);

    } finally {
      this.setIsLoading(false);
    }
  }
}
