import * as React from 'react';
import ErrorStore from '../stores/errors/ErrorStore';
import { match } from 'react-router';
import AirdomeStore from '../stores/AirdomeStore';

export interface BasePageProps {
  errorStore: ErrorStore;
  airdomeStore: AirdomeStore;
  match: match<{ airdomeId: string }>;
}

abstract class BasePage<TProps> extends React.Component<TProps & BasePageProps> {
  constructor(props: TProps & BasePageProps) {
    super(props);

    if (!props.airdomeStore)
      throw new Error('Airdome store is missing in props');

    if (!props.errorStore)
      throw new Error('Error store is missing in props');

    if (!props.match)
      throw new Error('Match is missing in props');

    const airdomeId = props.match && Number(props.match.params.airdomeId);

    if (!airdomeId)
      this.props.airdomeStore.clearAirdomeSelection();

    this.props.errorStore.clearError();
  }
}

export default BasePage;
