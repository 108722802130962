import React from 'react';
import GaugeValueBox from '../basic/GaugeValueBox';
import objectHelper from '../../utils/ObjectHelper';

const angles = {
  start: -140,
  end: -400
};

const StatusGauge = React.memo((props: {
  chartStyle: {
    innerRadius: number;
    outerRadius: number;
  }
  title: string,
  minValue: number,
  maxValue: number,
  value?: number,
  lineForeground: string,
  unit: string,
  icon: JSX.Element,
  valueTextFormat?: string
}) => <GaugeValueBox {...{
  angles,
  chartStyle: props.chartStyle,
  icon: props.icon,
  titles: {
    top: props.title
  },
  value: objectHelper.executeIfNumber(props.value, x => Math.round(x * 100) / 100),
  minValue: props.minValue,
  maxValue: props.maxValue,
  lineFill: {
    foreground: props.lineForeground,
    background: '#eeeeee'
  },
  unit: props.unit,
  valueTextFormat: props.valueTextFormat
}} />);

export default StatusGauge;
