import General from '../../base/general';
import airdome from './airdome';
import datatables from './datatables';
import user from './user';

const general: General = {
  user,
  airdome,
  datatables,

  cancel: 'Cancel',
  ok: 'Ok',
  requestFailed: 'Could not load data.',
  submit: 'Submit',
  yes: 'Yes',
  no: 'No',
  add: 'Add',
  edit: 'Edit',
  update: 'Update',
  delete: 'Delete',
  name: 'name'
};

export default general;
