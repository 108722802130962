import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { chartStyle, getColorIfDefined, iconSize, isTranslateSame, wrapGauge } from './Shared';

import React from 'react';
import StatusGauge from '../StatusGauge';
import ThermometerIcon from 'mdi-react/ThermometerIcon';

const TemperatureInside = React.memo((props: TranslateProps & ColClassName & { temperatureInside?: number }) => {
  const foregroundColor = getColorIfDefined(props.temperatureInside, {
    hotLowerBound: 15,
    warmLowerBound: 10
  });

  return wrapGauge(
    <StatusGauge {...{
      chartStyle,
      title: props.translate(x => x.pages.status.temperatureInside),
      value: props.temperatureInside,
      minValue: 0,
      maxValue: 25,
      lineForeground: foregroundColor,
      unit: '°C',
      icon: <ThermometerIcon size={iconSize} color={foregroundColor} />
    }} />, props.colClassName);
}, (a, b) => a.temperatureInside === b.temperatureInside
  && isTranslateSame(a, b, x => x.pages.status.temperatureInside));

export default TemperatureInside;
