import User from '../../base/actions/User';

const user: User = {
  delete: {
    title: 'Delete user',
    submit: 'Delete user',
    message: 'Are you sure you want to delete user {username}?'
  },
  unlock: {
    title: 'Unlock user',
    submit: 'Unlock user',
    message: 'Are you sure you want to unlock user {username}?'
  },
  lock: {
    title: 'Lock user',
    submit: 'Lock user',
    message: 'Are you sure you want to lock user {username}?'
  },
};

export default user;
