import React from 'react';
import { MinimumValueField, MaximumValueField } from '../fields/comfort';
import TemperatureProps from '../TemperatureProps';

const ComfortTemperatureFieldset = (props: TemperatureProps) => <>
  <MinimumValueField  {...props} />
  <MaximumValueField {...props} />
</>;

export default ComfortTemperatureFieldset;
