import { Card, CardBody } from 'reactstrap';
import React, { useEffect } from 'react';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import AirdomeTime from 'components/airdome/AirdomeTime';
import { MaterialTable } from 'components';
import { PreheatingSettingsHistoryItem } from 'models/preheatingsettings/PreheatingSettingsHistoryModel';
import { TablePagination } from '@material-ui/core';
import { observer } from "mobx-react";
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const PreheatingSettingsHistory = () => {
  const {
    preheatingSettingsHistoryModel
  } = useStore(x => x.preheatingSettingsStore);

  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  useEffect(() => {
    preheatingSettingsHistoryModel.setup();

    return preheatingSettingsHistoryModel.clear;
  }, [preheatingSettingsHistoryModel, airdome]);

  const translate = useLocalization();
  const history = useHistory();
  
  if(!airdome)
    return null;
  
  if(!airdome.hasPreheating)
    history.push('/');

  return (
    <>
      <AirdomePageHeader title={`${airdome.name}: ${translate(x => x.pages.preheatingSettings.historyHeader)}`}/>
      <Card className="LightingHistoryCard">
        <CardBody>
          <MaterialTable<PreheatingSettingsHistoryItem>
            disableSorting
            showPagination={false}
            showCheckboxColumn={false}
            clickRowToSelect={false}
            multiselect={false}
            data={preheatingSettingsHistoryModel.preheatingSettingsEntries}
            columns={[
              {
                id: 'isPreheatingEnabled',
                disablePadding: false,
                label: translate(x => x.pages.preheatingSettings.isPreheatingEnabled),
                getValue: (item: PreheatingSettingsHistoryItem) => item.isPreheatingEnabled.toString(),
                renderCell: (item: PreheatingSettingsHistoryItem) => <p className="text-center">{item.isPreheatingEnabled ? 'ON' : 'OFF'}</p>,
              },
              {
                id: 'maximalPreheatingMinutes',
                disablePadding: false,
                label: translate(x => x.pages.preheatingSettings.maximalPreheatingMinutes),
                renderCell: (item: PreheatingSettingsHistoryItem) => <p className="text-center">{item.maximalPreheatingMinutes}</p>,
                getValue: (item: PreheatingSettingsHistoryItem) => item.maximalPreheatingMinutes.toString()
              },
              {
                id: 'minimumPreheatingMinutes',
                disablePadding: false,
                label: translate(x => x.pages.preheatingSettings.minimumPreheatingMinutes),
                renderCell: (item: PreheatingSettingsHistoryItem) => <p className="text-center">{item.minimumPreheatingMinutes}</p>,
                getValue: (item: PreheatingSettingsHistoryItem) => item.minimumPreheatingMinutes.toString()
              },
              {
                id: 'timestamp',
                disablePadding: false,
                label: translate(x => x.pages.preheatingSettings.timestamp),
                renderCell: (item: PreheatingSettingsHistoryItem) => <p className="text-center"><AirdomeTime time={item.timestamp}/></p>,
                getValue: (item: PreheatingSettingsHistoryItem) => item.timestamp.toString()
              },
              {
                id: 'updatedBy',
                disablePadding: false,
                label: translate(x => x.pages.preheatingSettings.updatedBy),
                renderCell: (item: PreheatingSettingsHistoryItem) => <p className="text-center">{item.userName}</p>,
                getValue: (item: PreheatingSettingsHistoryItem) => item.userName
              }
            ]}/>
          {preheatingSettingsHistoryModel.totalCount > 0 && <TablePagination
            component="div"
            className="material-table__pagination mt-2"
            count={preheatingSettingsHistoryModel.totalCount}
            rowsPerPage={preheatingSettingsHistoryModel.pagingModel.rowsPerPage}
            page={preheatingSettingsHistoryModel.pagingModel.currentPage}
            backIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.previousPage) }}
            nextIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.nextPage) }}
            onChangePage={(_, page) => preheatingSettingsHistoryModel.pagingModel.setCurrentPage(page)}
            onChangeRowsPerPage={event => preheatingSettingsHistoryModel.pagingModel.setRowsPerPage(Number(event.target.value))}
            rowsPerPageOptions={[
              { value: 10, label: "10" },
              { value: 20, label: "20" },
              { value: 50, label: "50" }
            ]}
            labelRowsPerPage={translate(x => x.general.datatables.numberOfRowsShown)}
            labelDisplayedRows={(paginationInfo): React.ReactNode => translate(x => x.general.datatables.displayedRows,
              {
                from: paginationInfo.from,
                to: paginationInfo.to,
                page: paginationInfo.page + 1,
                count: paginationInfo.count
              })
            }
          />}
        </CardBody>
      </Card>
    </>
  );
}

export default observer(PreheatingSettingsHistory);