import { inject, observer } from 'mobx-react';

import AirdomeStore from '../../stores/AirdomeStore';
import LanguageStore from '../../stores/LanguageStore';
import React from 'react';
import SecurityStore from '../../stores/SecurityStore';
import Sidebar from './sidebar/Sidebar';
import SupportedLocale from 'enums/SupportedLocale';
import Topbar from './topbar/Topbar';
import classNames from 'classnames';

interface LayoutProps {
  airdomeStore?: AirdomeStore;
  languageStore?: LanguageStore;
  securityStore?: SecurityStore;
}

interface LayoutState {
  sidebarShow: boolean;
  sidebarCollapse: boolean;
}

@inject('airdomeStore', 'languageStore', 'securityStore')
@observer
class Layout extends React.Component<LayoutProps, LayoutState> {
  public state = {
    sidebarShow: false,
    sidebarCollapse: false
  };

  private changeSidebarVisibility = () => {
    this.setState({ sidebarCollapse: !this.state.sidebarCollapse });
  }

  private changeMobileSidebarVisibility = () =>
    this.setState({ sidebarShow: !this.state.sidebarShow })

  public render() {
    const { airdomeStore, languageStore, securityStore } = this.props;
    const { sidebarShow, sidebarCollapse } = this.state;

    const translate = languageStore && languageStore.translate;

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebarCollapse
    });

    return translate ? <div className={layoutClass}>
      <Topbar {...{
        translate,
        userName: securityStore!.userName,
        changeMobileSidebarVisibility: this.changeMobileSidebarVisibility,
        changeSidebarVisibility: this.changeSidebarVisibility
      }} />
      <Sidebar {...{
        translate,
        sidebarShow,
        sidebarCollapse,
        changeMobileSidebarVisibility: () => this.changeMobileSidebarVisibility(),
        selectedAirdome: airdomeStore!.SelectedAirdome
      }} />
    </div> : null;
  }
}

export default Layout;
