import moment, { Moment } from 'moment';

import RDevNotificationDto from '../../dto/status/RDevNotificationDto';
import RDevNotificationType from './RDevNotificationType';
import Translate from 'localization/Translate';

export const supportedNotificationTypes = [
  RDevNotificationType.InternetOutage,
  RDevNotificationType.OsVersionChanged,
  RDevNotificationType.RDevVersionChanged
];

export default class RDevNotificationRowData {
  public readonly timeStamp: Moment;
  public readonly type: string;
  public readonly message: string;

  constructor(item: RDevNotificationDto, translate: Translate) {
    if (!supportedNotificationTypes.includes(item.typeId)) {
      throw new Error('Unsupported RDev notification type');
    }

    this.timeStamp = item.timeStamp;
    this.type = this.translateType(item.typeId, translate);
    this.message = this.formatMessage(item.typeId, item.data, translate);
  }

  private formatStringAsDate = (timeStamp: string): string => {
    return moment.utc(timeStamp).format('L LT');
  }

  private translateType = (typeId: number, translate: Translate): string => {
    switch (typeId) {
      case RDevNotificationType.InternetOutage:
        return translate(x => x.pages.rdevNotifications.type.internetOutage);
      case RDevNotificationType.OsVersionChanged:
        return translate(x => x.pages.rdevNotifications.type.osVersionChanged);
      case RDevNotificationType.RDevVersionChanged:
        return translate(x => x.pages.rdevNotifications.type.rdevVersionChanged);
    }

    throw new Error('Unsupported RDev notification type for type translation');
  }

  private formatMessage = (typeId: number, data: { [key: string]: string }, translate: Translate): string => {
    switch (typeId) {
      case RDevNotificationType.InternetOutage:
        return translate(x => x.pages.rdevNotifications.message.internetOutage, {
          from: this.formatStringAsDate(data.from),
          to: this.formatStringAsDate(data.to)
        });
      case RDevNotificationType.OsVersionChanged:
        return translate(x => x.pages.rdevNotifications.message.osVersionChanged, data);
      case RDevNotificationType.RDevVersionChanged:
        return translate(x => x.pages.rdevNotifications.message.rdevVersionChanged, data);
    }

    throw new Error('Unsupported RDev notification type for message formatting');
  }
}