import Settings from '../../../../base/forms/airdome/settings';

const settings: Settings = {
  header: 'Einstellung',
  temperatureComfort: 'Komforttemperatur (°C)',
  temperatureCutback: 'Niedertemperatur (°C)',
  temperatures: 'Temperaturmodi',
  pressureNormal: 'Druck normal (Pa)',
  pressureMax: 'Druck maximal (Pa)',
  pressures: 'Druckmodi',
  lastUpdate: 'Letzter Stand',
  updatedBy: 'Bearbeitet durch',
  requestState: 'Anspruchstand für Änderung'
};

export default settings;
