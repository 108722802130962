import {Moment} from 'moment';
import {observer} from 'mobx-react';
import useAirdomeTime from 'common/AirdomeTimeHook';

const AirdomeTime = (props: { time: Moment }) => {
  const { toAirdomeTime } = useAirdomeTime();

  return <>{toAirdomeTime(props.time).format('L LT')}</>;
}

export default observer(AirdomeTime);