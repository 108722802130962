import ButtonLoading from 'shared/components/ButtonLoading';
import DateTimePicker from 'components/datetimepicker/DateTimePicker';
import moment from 'moment';
import { observer } from "mobx-react";
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

interface DateTimeRangePickerProps {
  start: moment.Moment;
  end: moment.Moment;
  endMax: moment.Moment;
  setStart: (start: moment.Moment) => void;
  setEnd: (end: moment.Moment) => void;
  submit: () => Promise<void>;
}

const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
  const translate = useLocalization();
  const languageStore = useStore(x => x.languageStore);

  return <div className="mt-3 div-wrapper">
    <div className="alert alert-dark" role="alert">
      <div className="div-style">
        <span className="lnr lnr-calendar-full pt-1 pr-2" />
        <span >{translate(x => x.pages.consumption.selectRange)}</span>
      </div>
    </div>
    <div className="form-inside row">
      <div className="col-lg d-inline-block mb-3">
        <span >{translate(x => x.forms.airdome.consumption.start)}:</span>
        <DateTimePicker
          locale={languageStore.Locale}
          value={props.start}
          maxDate={props.end}
          onValueChanged={x => props.setStart(x)}
        />
      </div>
      <div className="col-lg d-inline-block mb-3">
        <span >{translate(x => x.forms.airdome.consumption.end)}:</span>
        <DateTimePicker
          locale={languageStore!.Locale}
          value={props.end}
          minDate={props.start}
          maxDate={props.endMax}
          onValueChanged={x => props.setEnd(x)} />
      </div>
      <div className="col-lg my-auto d-inline-block button-div">
        <ButtonLoading
          onClick={async () => await props.submit()}
          className="btn-custom"
          color="primary"
          content={translate(x => x.pages.consumption.updateButton)} />
      </div>
    </div>
  </div>;
}

export default observer(DateTimeRangePicker);