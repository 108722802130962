import Calendar from "../../../base/pages/calendar";
import { ScheduleTypeEnum } from "dto/settings/enums";

const calendar: Calendar = {
  saveChanges: "Uložit změny",
  fullWeek: "Celý týden",
  scheduleTypes: {
    [ScheduleTypeEnum.Heating]: 'Topení',
    [ScheduleTypeEnum.LightsMain1]: 'Hlavní světlo 1',
    [ScheduleTypeEnum.LightsMain2]: 'Hlavní světlo 2',
    [ScheduleTypeEnum.LightsMain3]: 'Hlavní světlo 3',
    [ScheduleTypeEnum.LightsMain4]: 'Hlavní světlo 4',
    [ScheduleTypeEnum.LightsAdditional1]: 'Vedlejší světlo 1',
    [ScheduleTypeEnum.LightsAdditional2]: 'Vedlejší světlo 2',
    [ScheduleTypeEnum.LightsAdditional3]: 'Vedlejší světlo 3',
    [ScheduleTypeEnum.LightsAdditional4]: 'Vedlejší světlo 4',
    [ScheduleTypeEnum.MainDoorLock]: 'Hlavní dveře'
  },
  handledFromExternalSystem: "Kalendář je zpracováván z externího systému – Rezzy",
  pageLeaveWarning: "Chcete opravdu odejít? Byly provedeny změny v kalendáři.",
  timesOverlapping: "Upravte prosím čas. Aktuální časy se překrývají.",
  intervalOverlapping: "Upravte prosím interval. Interval koliduje s již vytvořeným intervalem."
};

export default calendar;
