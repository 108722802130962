import moment from 'moment';
import BaseChart, { RenderLineProps } from './base/BaseChart';

interface TemperatureNode {
  timestamp: moment.Moment;
  temperatureInput?: number;
  temperatureInside?: number;
  temperatureOutside?: number;
}

class TemperatureChart extends BaseChart<TemperatureNode> {
  protected getTitle = () =>
    this.props.translate(x => x.pages.status.charts.temperatureChartTitle)

  protected getLines = (): RenderLineProps<TemperatureNode>[] => [
    {
      dataKey: x => x.temperatureInside || null,
      stroke: 'orange',
      name: this.props.translate(x => x.pages.status.temperatureInside),
      unit: '°C'
    },
    {
      dataKey: x => x.temperatureInput || null,
      stroke: 'red',
      name: this.props.translate(x => x.pages.status.temperatureInput),
      unit: '°C'
    },
    {
      dataKey: x => x.temperatureOutside || null,
      stroke: 'lightblue',
      name: this.props.translate(x => x.pages.status.temperatureOutside),
      unit: '°C'
    }
  ]
}

export default TemperatureChart;
