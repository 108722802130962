import * as validations from '../../../../validations/validations';

import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import React from 'react';
import { State } from 'stores/shared/NewStatefulFunction';
import objectHelper from '../../../../utils/ObjectHelper';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const IntakeMultiplierField = () => {
  const translate = useLocalization();
  const { artificialIntelligenceModel } = useStore(x => x.settingsStore);
  
  return <FormInput {...{
    translate,
    model: new FormInputModel({
      readonly: artificialIntelligenceModel.updateDefaultAIConstants.state === State.Processing,
      validationSchema: validations.getBasicNumberSchema(),
      placeholder: translate(x => x.forms.airdome.artificialIntelligence.intakeMultiplier),
      handleValueModel: new HandleValueModel({
        setValue: artificialIntelligenceModel.inputTemperatureAmplificationRegulation.setValue,
        inputType: 'number'
      }),
      label: translate(x => x.forms.airdome.artificialIntelligence.intakeMultiplier),
      inputId: 'AIIntakeMultiplier',
      value: objectHelper.executeIfNumber(
        artificialIntelligenceModel.inputTemperatureAmplificationRegulation.Value,
        x => x.toString())
        || ''
    })
  }} />
};

export default observer(IntakeMultiplierField);
