import { PressureCard, RestrictionsHeaderCard, TemperatureCard } from '../../components/restrictions';

import AccessDefinitions from '../../security/AccessDefinitions';
import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import BaseAirdomePage from '../BaseAirdomePage';
import { Col } from 'reactstrap';
import LanguageStore from '../../stores/LanguageStore';
import RestrictionsStore from '../../stores/RestrictionsStore';
import SecurityStore from '../../stores/SecurityStore';
import { basePageTypedInject } from '../BasePageInject';

export interface RestrictionsProps {
  languageStore: LanguageStore;
  restrictionsStore: RestrictionsStore;
  securityStore: SecurityStore;
}

@basePageTypedInject(
  ({ restrictionsStore, languageStore, securityStore }) =>
    ({ restrictionsStore, languageStore, securityStore })
)
export default class Restrictions extends BaseAirdomePage<RestrictionsProps> {
  public render() {
    const {
      languageStore: {
        translate
      },
      restrictionsStore,
      securityStore
    } = this.props;

    const airdome = this.Airdome;
    if(!airdome)
      return null;
      
    const isAirdomeAvailable = airdome.state === 'Alive';
    const supportsHeating = airdome.hasHeating;

    return <>
      <AirdomePageHeader
        icon={<span className="lnr lnr-hand" />}
        title={airdome.name}/>
      {
        supportsHeating &&
        <TemperatureCard {...{
          translate,
          isAirdomeAvailable,
          model: restrictionsStore.temperatureRestrictionsModel,
          onUpdate: async () => await restrictionsStore.updateTemperatureRestrictions(),
          readonly: !securityStore.isAccessAllowed(AccessDefinitions.Restrictions.TemperatureManage)
        }} />
      }
      <PressureCard {...{
        translate,
        isAirdomeAvailable,
        model: restrictionsStore.pressureRestrictionsModel,
        onUpdate: async () => await restrictionsStore.updatePressureRestrictions(),
        readonly: !securityStore.isAccessAllowed(AccessDefinitions.Restrictions.PressureManage)
      }} />
    </>;
  }
}
