import { Modal } from "reactstrap";
import ReactChildrenProp from "common/ReactChildrenProp";
import { observer } from "mobx-react";

interface Props {
  isOpen: boolean;
  title: string;
  close: () => void;
  children: ReactChildrenProp;
}

const ArcModal = ({ isOpen, title, close, children }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      className="modal-dialog--primary"
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" onClick={close} />
        <h4 className="bold-text  modal__title">{title}</h4>
      </div>
      <div className="modal__body">
        {children}
      </div>
    </Modal>
  );
}

export default observer(ArcModal);