import DateSelect from '../../../../base/pages/status/charts/DateSelect';

const dateSelect: DateSelect = {
  title: 'Datum einstellen',
  from: 'Startdatum und Uhrzeit:',
  to: 'Enddatum und Uhrzeit:',
  update: 'Aktualisieren'
};

export default dateSelect;
