import { AirdomeService, ChangeRequestHubConnection, ChangeRequestService, SettingsHubConnection } from '../services';
import BaseStore, { Params } from './base/BaseStore';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { catchError, processingState } from './decorators';

import AirdomeStore from './AirdomeStore';
import CalendarService from '../services/settings/CalendarService';
import ErrorStore from './errors/ErrorStore';
import ScheduleIntervalComparer from 'utils/ScheduleIntervalComparer';
import ScheduleIntervalDto from '../dto/settings/ScheduleIntervalDto';
import ScheduleIntervalFormModel from './ScheduleIntervalFormModel';
import { ScheduleTypeEnum } from 'dto/settings/enums';
import ScheduleTypeModel from '../models/calendar/ScheduleTypeModel';
import SchedulesDto from 'services/signalr/dto/SchedulesDto';
import { StatefulFunction } from './shared/StatefulFunction';
import SummaryIntervalModel from '../models/calendar/SummaryIntervalModel';
import TimeSpanRange from '../utils/TimeSpanRange';
import { asTracked } from './decorators/TrackedDecorator';
import FormNewChangesModel from 'models/FormNewChangesModel';

export interface ScheduleInterval extends ScheduleIntervalDto {
  isSaved: boolean;
}

interface CalendarParams {
  calendarService: CalendarService;
  airdomeStore: AirdomeStore;
  airdomeService: AirdomeService;
  errorStore: ErrorStore;
  changeRequestHubConnection: ChangeRequestHubConnection;
  settingsHubConnection: SettingsHubConnection;
  changeRequestService: ChangeRequestService;
  getTrackingId: () => string;
}

export default class CalendarStore extends BaseStore<CalendarParams> {
  @observable
  private savedIntervalsCount = 0;

  @observable
  public intervals = observable.array<ScheduleInterval>([]);

  @observable
  public scheduleTypes?: ScheduleTypeModel[];

  @observable
  public areSchedulesManagedExternally?: boolean;

  @observable
  public intervalFormModel: ScheduleIntervalFormModel;
  
  @observable
  public currentlyReceivingSchedules: SchedulesDto | undefined = undefined;

  @observable
  public newChangesModel : FormNewChangesModel<SchedulesDto>

  constructor(params: Params<CalendarParams>) {
    super(params);

    this.newChangesModel = new FormNewChangesModel<SchedulesDto>((schedules) => this.setSchedules(schedules))
    this.intervalFormModel = new ScheduleIntervalFormModel(this);

    params.settingsHubConnection.onSchedulesUpdated(schedules => {
      this.newChangesModel.updateForm(schedules, schedules.isDataFromPlcDirectly, this.hasChanges);
    });

    params.changeRequestHubConnection.onFailed(() => runInAction(() => {
      this.newChangesModel.isUpdateExecutor = false;
    }));

    reaction(
      () => this.hasChanges,
      hasChanges => {
        hasChanges
          ? window.addEventListener("beforeunload", this.pageLeaveWarningHandler)
          : window.removeEventListener("beforeunload", this.pageLeaveWarningHandler)
      }
    )
  }

  @computed
  public get hasChanges(): boolean {
    return this.intervals.some(x => !x.isSaved) || this.intervals.length < this.savedIntervalsCount;
  }

  @computed
  public get weekIntervalSummary(): SummaryIntervalModel[] {
    const summaryIntervals: SummaryIntervalModel[] = [];
    this.intervals.forEach(interval => {
      const intervalTimeRange = new TimeSpanRange(interval.start, interval.end);
      const summaryIntervalToAddInto = summaryIntervals.find(x =>
        x.dayOfWeek === interval.dayOfWeek && (
          x.timeRange.containsRange(intervalTimeRange) ||
          x.timeRange.neighboring(intervalTimeRange) ||
          x.timeRange.overlapsWithRange(intervalTimeRange))
      );
      if (summaryIntervalToAddInto) {
        summaryIntervalToAddInto.timeRange = summaryIntervalToAddInto.timeRange.mergeRanges(intervalTimeRange);
        summaryIntervalToAddInto.intervalsGrouped.push(interval);
        summaryIntervalToAddInto.isSaved = summaryIntervalToAddInto.isSaved && interval.isSaved;
      }
      else
        summaryIntervals.push({
          dayOfWeek: interval.dayOfWeek,
          timeRange: intervalTimeRange,
          isSaved: interval.isSaved,
          intervalsGrouped: [interval]
        });
    });

    const mergedSummaryIntervals: SummaryIntervalModel[] = [];
    summaryIntervals.forEach(summaryInterval => {
      const summaryIntervalToAddInto = mergedSummaryIntervals.find(x =>
        x.dayOfWeek === summaryInterval.dayOfWeek && (
          x.timeRange.containsRange(summaryInterval.timeRange) ||
          x.timeRange.neighboring(summaryInterval.timeRange) ||
          x.timeRange.overlapsWithRange(summaryInterval.timeRange))
      );
      if (summaryIntervalToAddInto) {
        summaryIntervalToAddInto.timeRange = summaryIntervalToAddInto.timeRange.mergeRanges(summaryInterval.timeRange);
        summaryIntervalToAddInto.intervalsGrouped = summaryIntervalToAddInto.intervalsGrouped.concat(summaryInterval.intervalsGrouped);
        summaryIntervalToAddInto.isSaved = summaryIntervalToAddInto.isSaved && summaryInterval.isSaved;
      }
      else
        mergedSummaryIntervals.push(summaryInterval);
    })

    return mergedSummaryIntervals;
  }

  @action
  public setSchedules = (schedules: SchedulesDto) => {
    this.setIntervals(schedules.scheduleIntervals.map(x => ({ ...x, isSaved: true })));
    this.setSavedIntervalsCount(schedules.scheduleIntervals.length);
  }

  @action
  public setIntervals = (intervals: ScheduleInterval[]): void => {
    this.intervals.replace(intervals);
  }

  @action
  public addInterval = (interval: ScheduleIntervalDto): void => {
    this.intervals.push({ ...interval, isSaved: false });
  }

  @action
  public replaceIntervals = (originalIntervals: ScheduleInterval[], newIntervals: ScheduleIntervalDto[]): void => {
    this.removeIntervals(originalIntervals);
    newIntervals.forEach(newInterval => this.addInterval(newInterval));
  }

  @action
  public removeIntervals = (intervalsToDelete: ScheduleInterval[]) => {
    intervalsToDelete.forEach(intervalToDelete => {
      const interval = this.intervals.find(x => ScheduleIntervalComparer.areEqual(intervalToDelete, x))!;
      this.intervals.remove(interval);
    });
  }

  @action
  public setScheduleTypes = (scheduleTypes: ScheduleTypeModel[]): void => {
    this.scheduleTypes = scheduleTypes;
  }

  @action
  public setSchedulesManagedExternally = (areSchedulesManagedExternally: boolean | undefined): void => {
    this.areSchedulesManagedExternally = areSchedulesManagedExternally;
  }

  @action
  private setSavedIntervalsCount = (count: number) =>
    this.savedIntervalsCount = count;

  public fetchAll = asTracked(async () => {
    this.fetchIntervals();
    this.fetchScheduleTypes();
    this.fetchSchedulesExternalManagement();
  });

  public clear = action(() => {
    this.setSavedIntervalsCount(0);
    this.setIntervals([]);
    this.setScheduleTypes([]);
    this.setSchedulesManagedExternally(undefined);
    this.newChangesModel.isUpdateExecutor = false;
    this.currentlyReceivingSchedules = undefined;
  });

  @action
  public clearCurrentlyReceivingSchedules = () => {
    this.currentlyReceivingSchedules = undefined;
  }

  @action
  public loadFromCurrentlyReceivingSchedules = () => {
    if(!this.currentlyReceivingSchedules)
      return;

    this.setIntervals(this.currentlyReceivingSchedules.scheduleIntervals.map(x => ({ ...x, isSaved: true })));
    this.setSavedIntervalsCount(this.currentlyReceivingSchedules.scheduleIntervals.length);

    this.currentlyReceivingSchedules = undefined;
  }

  @catchError
  @action
  public fetchIntervals: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const result = await this.params.calendarService.getSchedules(this.params.airdomeStore.SelectedAirdomeId!, trackingId);
    this.setIntervals(result.map(x => ({ ...x, isSaved: true })));
    this.setSavedIntervalsCount(result.length);
  }

  @processingState
  @catchError
  @action
  private fetchScheduleTypes: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdome = this.params.airdomeStore.SelectedAirdome;

    const lights = await this.params.airdomeService.getLights(this.params.airdomeStore.SelectedAirdomeId!, trackingId);
    const doorLocks = await this.params.airdomeService.getDoorLocks(this.params.airdomeStore.SelectedAirdomeId!, trackingId);
    const allScheduleTypes = await this.params.calendarService.getScheduleTypes(trackingId);

    let selectedScheduleTypes = allScheduleTypes.map(
      type => ({
        ...type,
        customName: lights.find(light => light.propertyDefinitionName === type.name)?.name
          ?? doorLocks.find(doorLock => doorLock.propertyDefinitionName === type.name)?.name
      }))
      .filter(x => x.customName !== undefined);

    if(airdome?.hasHeating) {
      const heatingScheduleType = allScheduleTypes.find(type => type.id === ScheduleTypeEnum.Heating);
      if(heatingScheduleType)
        selectedScheduleTypes.push({
          ...heatingScheduleType,
          customName: undefined
        });
    }

    const scheduleTypeModels = selectedScheduleTypes.map(type => ({
      scheduleTypeId: type.id,
      name: type.customName
    }));

    this.setScheduleTypes(scheduleTypeModels);
    this.intervalFormModel.setScheduleTypes(scheduleTypeModels);
  }

  @processingState
  @catchError
  @action
  private fetchSchedulesExternalManagement: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const areSchedulesManagedExternally = await this.params.airdomeService
      .areSchedulesManagedExternally(this.params.airdomeStore.SelectedAirdomeId!, trackingId);
    this.setSchedulesManagedExternally(areSchedulesManagedExternally);
  }

  @processingState
  @catchError
  @action
  public updateSchedules: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const mergedNeighboringIntervals = this.mergeNeighboringIntervals(this.intervals);
    await this.params.calendarService.updateSchedules(this.params.airdomeStore.SelectedAirdomeId!,
      mergedNeighboringIntervals, trackingId);
    this.newChangesModel.isUpdateExecutor = true;
  }

  private pageLeaveWarningHandler = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }

  private mergeNeighboringIntervals = (intervals: ScheduleInterval[]): ScheduleInterval[] => {
    const mergedIntervals = intervals.slice();
    for (let interval of intervals) {
      if (mergedIntervals.indexOf(interval) < 0)
        continue;

      const intervalToInsert = { ...interval };
      let neighboringInterval: ScheduleInterval | undefined;
      let mergeHappened = false;
      do {
        let intervalTimeRange = new TimeSpanRange(intervalToInsert.start, intervalToInsert.end);
        neighboringInterval = mergedIntervals.find(x => x.dayOfWeek === intervalToInsert.dayOfWeek && x.scheduleTypeId === intervalToInsert.scheduleTypeId &&
          new TimeSpanRange(x.start, x.end).neighboring(intervalTimeRange));
        if (neighboringInterval) {
          if (!mergeHappened)
            mergedIntervals.splice(mergedIntervals.indexOf(interval), 1);
          const mergedTimeRange = new TimeSpanRange(neighboringInterval.start, neighboringInterval.end).mergeRanges(intervalTimeRange);
          intervalToInsert.start = mergedTimeRange.start;
          intervalToInsert.end = mergedTimeRange.end;
          mergedIntervals.splice(mergedIntervals.indexOf(neighboringInterval), 1);
          mergeHappened = true;
        }
      } while (neighboringInterval);
      mergeHappened && mergedIntervals.push(intervalToInsert);
    }
    return mergedIntervals;
  }
}
