import * as React from 'react';
import * as validations from 'validations/validations';

import {
  ConfirmPasswordField,
  EmailField,
  NotificationsSelectField,
  PasswordField,
  RoleSelectField,
  UsernameField
} from './fields';
import { inject, observer } from 'mobx-react';

import AwaitingDataSourceComponent from '../../basic/AwaitingDataSourceComponent';
import ButtonLoading from '../../../shared/components/ButtonLoading';
import CreateUserFormProps from './CreateUserFormProps';
import { withRouter } from 'react-router';

@inject(({ userStore }) => ({ userStore }))
@observer
class CreateUserForm extends AwaitingDataSourceComponent<CreateUserFormProps> {
  protected fetchData = async () => {
    const { userStore } = this.props;

    await Promise.all([
      userStore!.fetchRoleOptions(),
      userStore!.fetchNotificationsOptions(),
    ]);
  }

  protected clearModel = () =>
    this.props.userStore!.createUserModel.clear()

  protected renderContent(): React.ReactNode {
    if (!this.props.userStore)
      return;

    const {
      submitText,
      translate,
      userStore: { createUserModel, createUser, notificationsOptions, roleOptions }
    } = this.props;

    return <form onSubmit={(e) => {
      e && e.preventDefault();
      return false;
    }}>
      <fieldset disabled={createUser.getProcessing!()}>
        <UsernameField {...{
          translate,
          validationSchema: validations.getUsernameValidationSchema(
            translate(x => x.general.user.username),
            translate(x => x.validations.string.noWhitespace),
            translate(x => x.validations.string.username)),
          username: createUserModel.username,
          setUsername: x => createUserModel.setUsername(x)
        }} />
        <PasswordField {...{
          translate,
          validationSchema: validations.getPasswordValidationSchema(),
          password: createUserModel.password,
          setPassword: x => createUserModel.setPassword(x)
        }} />
        <ConfirmPasswordField {...{
          translate,
          confirmPassword: createUserModel.confirmPassword,
          setConfirmPassword: x => createUserModel.setConfirmPassword(x),
          arePasswordsDifferent: createUserModel.arePasswordsDifferent
        }} />
        <EmailField {...{
          translate,
          validationSchema: validations.getEmailValidationSchema(),
          currentEmail: createUserModel.email,
          setEmail: x => createUserModel.setEmail(x)
        }} />
        <RoleSelectField {...{
          translate,
          roleOptions,
          currentRole: createUserModel.role,
          setRole: x => createUserModel.setRole(x)
        }} />
        <NotificationsSelectField {...{
          translate,
          notificationsOptions,
          notifications: createUserModel.notifications,
          setNotifications: x => createUserModel.setNotifications(x)
        }} />
        <ButtonLoading
          disabled={!createUserModel.areDataValid}
          className="col-md-2 col-12"
          onClick={async () => await this.handleRegisterSubmit()}
          content={submitText}
          color="primary" />
      </fieldset>
    </form>;
  }

  private handleRegisterSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();

    const { userStore } = this.props;

    const userId = await userStore!.createUser();
    if (!userStore!.createUser.getLastExecutionFailed!())
      await this.props.onSubmit(userId);
  }
}

export default withRouter(CreateUserForm);
