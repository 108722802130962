import User from '../../base/actions/User';

const user: User = {
  delete: {
    title: 'Benutzer löschen',
    submit: 'Benutzer löschen',
    message: 'Möchten Sie den Benutzer {username} wirklich löschen?'
  },
  unlock: {
    title: 'Benutzer entsperren',
    submit: 'Benutzer entsperren',
    message: 'Möchten Sie den Benutzer {username} wirklich entsperren?'
  },
  lock: {
    title: 'Benutzer sperren',
    submit: 'Benutzer sperren',
    message: 'Möchten Sie den Benutzer {username} wirklich sperren?'
  }
};

export default user;
