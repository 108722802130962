import { StringSchema } from 'yup';
import { observable, action } from 'mobx';
import MacAddressDto from '../../dto/registry/MacAddressDto';

export default class {
  public readonly validationSchema: StringSchema;

  public id?: number;

  @observable
  public value: string;

  constructor(validationSchema: StringSchema, macAddress: MacAddressDto) {
    this.id = macAddress.id;
    this.value = macAddress.value;
    this.validationSchema = validationSchema;
  }

  @action
  public setValue = (macAddress: string) => this.value = macAddress

  public areDataValid = (valueRequired: boolean): boolean => {
    const isValid = this.validationSchema.isValidSync(this.value);

    if (valueRequired)
      return isValid && this.value !== '';

    return isValid;
  }
}
