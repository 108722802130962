import React from 'react';
import { Col, Input } from 'reactstrap';
import Translate from '../../localization/Translate';

interface SearchBarProps {
  className?: string;
  translate: Translate;
  onChange: (value: string) => void;
}

const SearchBar = (props: SearchBarProps) =>
  <Col className={props.className}>
    <Input
      placeholder={props.translate(x => x.general.datatables.search)}
      onChange={e => props.onChange(e.target.value)}
    />
  </Col>;

export default React.memo(SearchBar);
