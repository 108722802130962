import * as validations from '../../validations/validations';

import { action, computed, observable, reaction } from 'mobx';

import { CreateUserDto } from '../../dto/Container';
import { StringSchema } from 'yup';
import UserStore from '../../stores/UserStore';

export default class CreateUserModel {
  @observable
  public username?: string;

  @observable
  public email?: string;

  @observable
  public password?: string;

  @observable
  public confirmPassword?: string;

  @observable
  public role?: string;

  @observable
  public notifications?: string;

  private readonly usernameValidationSchema: StringSchema;
  private readonly passwordValidationSchema: StringSchema;
  private readonly emailValidationSchema: StringSchema;

  private readonly store: UserStore;

  constructor(store: UserStore) {
    this.store = store;

    this.usernameValidationSchema = validations.getUsernameValidationSchema();
    this.passwordValidationSchema = validations.getPasswordValidationSchema();
    this.emailValidationSchema = validations.getEmailValidationSchema();

    reaction(
      () => store.roleOptions,
      x => this.role = this.role || x[0]
    );

    reaction(
      () => store.notificationsOptions,
      x => this.notifications = this.notifications || x[0].id.toString()
    );
  }

  @action
  public clear = () => {
    this.username = undefined;
    this.email = undefined;
    this.password = undefined;
    this.confirmPassword = undefined;
    this.role = this.store.roleOptions[0];
    this.notifications = this.store.notificationsOptions[0].id.toString();
  }

  public getDto = (): CreateUserDto | undefined =>
    this.areDataValid
      // @ts-ignore
      ? new CreateUserDto(this)
      : undefined;

  @action
  public setUsername = (value?: string) =>
    this.username = value

  @action
  public setEmail = (value?: string) =>
    this.email = value

  @action
  public setPassword = (value?: string) =>
    this.password = value

  @action
  public setConfirmPassword = (value?: string) =>
    this.confirmPassword = value

  @action
  public setRole = (value: string) =>
    this.role = value

  @action
  public setNotifications = (value: string) =>
    this.notifications = value

  @computed
  public get arePasswordsDifferent(): boolean {
    return this.password !== this.confirmPassword;
  }

  @computed
  public get areDataValid(): boolean {
    return !!(
      this.usernameValidationSchema.isValidSync(this.username) &&
      this.passwordValidationSchema.isValidSync(this.password) &&
      this.emailValidationSchema.isValidSync(this.email) &&
      !this.arePasswordsDifferent &&
      (this.role
        && this.store.roleOptions.includes(this.role)) &&
      (this.notifications
        && this.store.notificationsOptions.find(x => x.id.toString() === this.notifications))
    );
  }
}
