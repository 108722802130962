import BasePressureField from '../BasePressureField';
import PressureProps from '../../PressureProps';
import React from 'react';
import { observer } from 'mobx-react';

const MaximumValueField =
  ({ translate, model, readonly }: PressureProps) =>
    <BasePressureField {...{
      readonly,
      numberValidationSchema: model.normalPressureMaxSchema.label(
        translate(x => x.forms.airdome.restrictions.pressure.normalPressureMax)
      ),
      inputId: 'UpperBound_PressureNormal_Field',
      label: translate(x => x.forms.airdome.restrictions.pressure.normalPressureMax),
      setValue: x => model.normalPressureMax.setValue(x),
      value: model.normalPressureMax.Value
    }} />;

export default observer(MaximumValueField);
