import { useStore } from "stores/MobXTypedStoreHook";

const useAirdomeFlags = () => {
  const airdome = useStore(x => x.airdomeStore).SelectedAirdome;

  if(!airdome)
    throw new Error('Attempted to access airdome flags without selected airdome.');

  return {
    hasHeating: airdome.hasHeating,
    hasElectricityConsumption: airdome.hasElectricityConsumption,
    hasGasConsumption: airdome.hasGasConsumption,
  }
}

export default useAirdomeFlags;