import moment from 'moment';
import BaseChart, { RenderLineProps } from './base/BaseChart';

interface PressureNode {
  timestamp: moment.Moment;
  pressure: number;
}

class PressureChart extends BaseChart<PressureNode> {
  protected getTitle = () =>
    this.props.translate(x => x.pages.status.charts.pressureChartTitle)

  protected getLines = (): RenderLineProps<PressureNode>[] => [{
    dataKey: x => x.pressure,
    stroke: 'lightblue',
    name: this.props.translate(x => x.pages.status.pressure),
    unit: 'Pa'
  }]
}

export default PressureChart;
