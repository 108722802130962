import BasicRootStore from '../stores/BasicRootStore';
import LoadingOverlay from 'containers/app/LoadingOverlay';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const LogOutCallback = () => {
  const history = useHistory();

  useEffect(() => {
    BasicRootStore.loginStore
      .signOutRedirectCallback()
      .then(() => history.push('/'));
  }, [])

  return <LoadingOverlay />;
}

export default LogOutCallback;
