export default class ReceivedBacnetObjectRowModel {
  public readonly id: number;
  public readonly bacnetId: number;
  public readonly bacnetType: number;
  public readonly bacnetPropertyType: number;
  public readonly propertyDefinitionName: string;
  public readonly value: string;

  constructor(
    id: number, 
    bacnetId: number, 
    bacnetType: number, 
    bacnetPropertyType: number, 
    propertyDefinitionName: string,
    value: string) {
    this.id = id;
    this.bacnetId = bacnetId;
    this.bacnetType = bacnetType;
    this.bacnetPropertyType = bacnetPropertyType;
    this.propertyDefinitionName = propertyDefinitionName;
    this.value = value;
  }
}
