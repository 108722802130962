import Pressure from '../../../../base/forms/airdome/restrictions/Pressure';

const pressure: Pressure = {
  header: 'Druckbereich für Halle einschränken',
  normalPressureMin: 'Minimaler Wert des Normaldrucks (Pa)',
  normalPressureMax: 'Maximaler Wert des Normaldrucks (Pa)',
  maximalPressureMin: 'Minimaler Wert des maximalen Drucks (Pa)',
  maximalPressureMax: 'Maximaler Wert des maximalen Drucks (Pa)'
};

export default pressure;
