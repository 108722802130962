import React from 'react';
import { Link } from 'react-router-dom';

interface TopbarMenuLinksProps {
  title: string;
  icon: string;
  path: string;
  onClick?: () => void
}

const TopbarMenuLinks = ({ title, icon, path, onClick }: TopbarMenuLinksProps) =>
  <Link className="topbar__link" to={path} onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </Link>;

export default React.memo(TopbarMenuLinks);
