import './RDevNotifications.scss';

import { Card, CardBody, Col } from 'reactstrap';
import { DateTimeRangePicker, MaterialTable } from 'components';
import { LinearProgress, TablePagination } from '@material-ui/core';

import AirdomeTime from 'components/airdome/AirdomeTime';
import RDevNotificationRowData from './RDevNotificationRowData';
import { State } from 'stores/shared/NewStatefulFunction';
import { TableColumn } from 'components/basic/material-table/table-header';
import { observer } from "mobx-react";
import { supportedNotificationTypes } from './RDevNotificationRowData';
import { useEffect } from 'react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const RDevNotifications = () => {
  const translate = useLocalization();

  const airdomeStore = useStore(x => x.airdomeStore);
  const rdevNotificationsStore = useStore(x => x.rdevNotificationsStore);

  useEffect(() => {
    rdevNotificationsStore.fetch();

    return rdevNotificationsStore.clear;
  }, [rdevNotificationsStore, airdomeStore.SelectedAirdomeId]);

  const tableData = rdevNotificationsStore.items
    .filter(item => supportedNotificationTypes.includes(item.typeId))
    .map(i => new RDevNotificationRowData(i, translate));

  const table = <MaterialTable<RDevNotificationRowData>
    disableSorting
    showPagination={false}
    showCheckboxColumn={false}
    clickRowToSelect={false}
    multiselect={false}
    data={tableData}
    columns={[
      {
        id: 'timestamp',
        width: 15,
        disablePadding: false,
        label: translate(x => x.pages.rdevNotifications.timestampHeader),
        renderCell: (rowData: RDevNotificationRowData) => <p className='text-center'><AirdomeTime time={rowData.timeStamp}/></p>
      },
      {
        id: 'type',
        width: 25,
        disablePadding: false,
        label: translate(x => x.pages.rdevNotifications.typeHeader),
        renderCell: (rowData: RDevNotificationRowData) => <p className='text-center'>{rowData.type}</p>
      },
      {
        id: 'message',
        width: 65,
        disablePadding: false,
        label: translate(x => x.pages.rdevNotifications.messageHeader),
        renderCell: (rowData: RDevNotificationRowData) => <p className='text-center'>{rowData.message}</p>
      }
    ].filter(x => !!x) as TableColumn<RDevNotificationRowData>[]} />

  const pagination =
    rdevNotificationsStore.totalCount > 0 &&
    <TablePagination
      component="div"
      className="material-table__pagination mt-2"
      count={rdevNotificationsStore.totalCount}
      rowsPerPage={rdevNotificationsStore.pagingModel.rowsPerPage}
      page={rdevNotificationsStore.pagingModel.currentPage}
      backIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.previousPage) }}
      nextIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.nextPage) }}
      onChangePage={(_, page) => rdevNotificationsStore.pagingModel.setCurrentPage(page)}
      onChangeRowsPerPage={(event) => rdevNotificationsStore.pagingModel.setRowsPerPage(Number(event.target.value))}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" }
      ]}
      labelRowsPerPage={translate(x => x.general.datatables.numberOfRowsShown)}
      labelDisplayedRows={(paginationInfo): React.ReactNode => translate(x => x.general.datatables.displayedRows,
        {
          from: paginationInfo.from,
          to: paginationInfo.to,
          page: paginationInfo.page + 1,
          count: paginationInfo.count
        })
      }
    />;

  return <>
    <Card className="RDevNotificationsHeader">
      <CardBody>
        <h2 className="d-inline-block">
          {airdomeStore.SelectedAirdome?.name}: {translate(x => x.pages.rdevNotifications.header)}
        </h2>
      </CardBody>
    </Card>
    <Card className="RDevNotificationsDateTimeRangeSelector">
      <CardBody>
        <DateTimeRangePicker
          start={rdevNotificationsStore.start}
          end={rdevNotificationsStore.end}
          endMax={rdevNotificationsStore.endMax}
          setStart={rdevNotificationsStore.setStart}
          setEnd={rdevNotificationsStore.setEnd}
          submit={rdevNotificationsStore.fetch}
        />
      </CardBody>
    </Card>
    <Card className="RDevNotificationsCard">
      <CardBody>
        {
          rdevNotificationsStore.fetch.state === State.Processing
            ? <LinearProgress />
            : null
        }
        {table}
        {pagination}
      </CardBody>
    </Card>
  </>;
}

export default observer(RDevNotifications);