import Errors from '../../base/errors';
import airdome from './airdome';
import user from './user';

const errors: Errors = {
  airdome,
  user
};

export default errors;
