import {
  CreateUserDto,
  IdentityAirdomeDto,
  NotificationChannelDto,
  UpdateUserDto,
  UserDetailDto,
  UserDto
} from '../../dto/Container';
import {
  getBackendException,
  getErrorsFromIdentityResponse,
  getFirstOrDefaultErrorFromConditions,
  getIdentityException
} from './../../helpers/ErrorHelper';

import { ApiError } from '../../models';
import { AxiosInstance } from 'axios';
import UpdateCurrentUserDto from 'dto/identity/UpdateCurrentUserDto';
import UserCreatedResponseDto from 'dto/identity/UserCreatedResponseDto';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class UserService {
  private axios: AxiosInstance;
  private registerUserUrl: string;
  private updateUserUrl: string;
  private getRolesUrl: string;
  private postUsersAirdomesUrl: string;
  private getUsersAirdomesUrl: string;
  private getUsersUrl: string;
  private getUserDetailUrl: string;
  private deleteUserUrl: string;
  private unlockUserUrl: string;
  private lockUserUrl: string;
  private getNotificationsOptionsUrl: string;
  private updateNotificationsOptionsUrl: string;
  private getUserNotificationSettings: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.registerUserUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.create);
    this.getRolesUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.roles.getAll);
    this.getUsersUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.getAll);
    this.getUserDetailUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.getDetail);
    this.postUsersAirdomesUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.usersAirdomes.update);
    this.getUsersAirdomesUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.usersAirdomes.getAll);
    this.deleteUserUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.delete);
    this.updateUserUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.update);
    this.unlockUserUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.unlock);
    this.lockUserUrl = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.lock);
    this.getNotificationsOptionsUrl = getJoinedEndpoint(
      x => x.notifications.baseUri, x => x.notifications.notifications.getOptions);
    this.updateNotificationsOptionsUrl = getJoinedEndpoint(
      x => x.notifications.baseUri, x => x.notifications.notifications.update);
    this.getUserNotificationSettings = getJoinedEndpoint(
      x => x.notifications.baseUri, x => x.notifications.notifications.getUserNotificationSettings);
  }

  public async getUserDetail(
    userId: string,
    trackingId: string
  ): Promise<UserDetailDto> {
    try {
      const url = stringFormat(this.getUserDetailUrl, userId);
      const axiosResponse = await this.axios.get<UserDetailDto>(url);
      return axiosResponse.data;
    } catch (error) {
      throw getIdentityException(error);
      // TODO: #4623 3log something
    }
  }

  public async getUsersAirdomes(
    userId: string
  ): Promise<IdentityAirdomeDto[]> {
    try {
      const url = stringFormat(this.getUsersAirdomesUrl, userId);
      const response = await this.axios.get<IdentityAirdomeDto[]>(url);
      return response.data;
    } catch (error) {
      throw getIdentityException(error);
      // TODO: #4623 3log something
    }
  }

  public async postUsersAirdomes(
    userId: string,
    airdomeIds: number[],
    trackingId: string
  ): Promise<void> {
    try {
      const url = stringFormat(this.postUsersAirdomesUrl, userId);
      await this.axios.post(url, airdomeIds);
    } catch (exception) {
      throw getIdentityException(exception);
      // TODO: #4623 3log something
    }
  }

  public async createUser(userDto: CreateUserDto, trackingId: string): Promise<string> {
    try {
      // TODO: #4621 Implement resiliency (retry policy)
      const response = await this.axios.post<UserCreatedResponseDto>(this.registerUserUrl, userDto, {
        headers: { trackingId }
      });

      return response.data.userId;
    } catch (exception) {
      // TODO: #4623 3log something
      throw getFirstOrDefaultErrorFromConditions(exception,
        x => x.request.responseURL === this.registerUserUrl
          && new ApiError({ localizationKey: getErrorsFromIdentityResponse(x)[0] }),
        x => x.request.responseURL === this.updateNotificationsOptionsUrl
          && new ApiError({ localizationKey: 'UnableToSetNotificationChannel' })
      );
    }
  }

  public updateUser = async (userId: string, userDto: UpdateUserDto, trackingId: string) => {
    try {
      const url = stringFormat(this.updateUserUrl, userId);

      // TODO: #4621 Implement resiliency (retry policy)
      await this.axios.put(url, userDto, {
        headers: { trackingId }
      });
    } catch (error) {
      // TODO: #4623 3log something
      throw getBackendException(error);
    }
  }

  public updateUserNotificationChannel = async (userId: string, notificationChannelId: string, trackingId: string) => {
    try {
      const url = stringFormat(this.updateNotificationsOptionsUrl, userId, notificationChannelId);
      await this.axios.put(url, undefined, { headers: { trackingId } });
    } catch (error) {
      // TODO: #4623 3log something
      throw getFirstOrDefaultErrorFromConditions(error,
        x => x.request.responseURL === this.updateUserUrl
          && new ApiError({ localizationKey: getErrorsFromIdentityResponse(x)[0] }),
        x => x.request.responseURL === this.updateNotificationsOptionsUrl
          && new ApiError({ localizationKey: 'UnableToSetNotificationChannel' })
      );
    }
  }

  public deleteUser = async (userId: string, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.deleteUserUrl, userId);
      await this.axios.delete(url, { headers: { trackingId } });
    } catch (exception) {
      throw getIdentityException(exception);
    }
  }

  public unlockUser = async (userId: string, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.unlockUserUrl, userId);
      await this.axios.put(url, userId, { headers: { trackingId } });
    } catch (exception) {
      throw getIdentityException(exception);
    }
  }

  public lockUser = async (userId: string, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.lockUserUrl, userId);
      await this.axios.put(url, userId, { headers: { trackingId } });
    } catch (exception) {
      throw getIdentityException(exception);
    }
  }

  public async getRoleOptions(trackingId: string): Promise<string[]> {
    try {
      const response = await this.axios.get<string[]>(this.getRolesUrl);
      return response.data;
    } catch (exception) {
      throw getIdentityException(exception);
      // TODO: #4623 3log something
    }
  }

  public async getUsers(trackingId: string): Promise<UserDto[]> {
    try {
      const response = await this.axios.get<UserDto[]>(this.getUsersUrl);
      return response.data;
    } catch (exception) {
      throw getIdentityException(exception);
      // TODO: #4623 3log something
    }
  }

  public async getNotificationOptions(trackingId: string): Promise<NotificationChannelDto[]> {
    try {
      const response = await this.axios.get<NotificationChannelDto[]>(this.getNotificationsOptionsUrl);
      return response.data;
    } catch (exception) {
      throw getBackendException(exception);
    }
  }

  public async getUserNotificationSetting(userId: string, trackingId: string): Promise<NotificationChannelDto> {
    try {
      const url = stringFormat(this.getUserNotificationSettings, userId);
      const response = await this.axios.get<NotificationChannelDto>(url);
      return response.data;
    } catch (exception) {
      throw getBackendException(exception);
    }
  }

  public async getUserNamesByIds(userIds: string[], trackingId: string): Promise<{ [userId: string]: string }> {
    try {
      const url = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.findByIds);
      const response = await this.axios.post<{ [userId: string]: string }>(url, userIds);
      return response.data;
    } catch (exception) {
      throw getBackendException(exception);
    }
  }

  public updateCurrentUser = async (username: string, password: string, email: string, trackingId: string) => {
    try {
      const url = getJoinedEndpoint(x => x.identity.baseUri, x => x.identity.users.updateCurrent);
      const dto: UpdateCurrentUserDto = {
        username, password, email
      };
      await this.axios.put(url, dto);
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
