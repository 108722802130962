import React, { PureComponent } from 'react';

import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { Alert as ReactstrapAlert } from 'reactstrap';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';

class AlertProps {
  public color?: string = '';
  public icon?: boolean = false;
  public className?: string = '';
  public children?: JSX.Element[] | JSX.Element;
  public onDismiss?: {
    isDisabled: boolean;
    execute: () => void
  };
}

export default class Alert extends PureComponent<AlertProps> {
  public render() {
    const {
      color,
      className,
      icon,
      children,
      onDismiss
    } = this.props;
    let Icon;

    switch (color) {
      case 'info':
        Icon = <InformationOutlineIcon />;
        break;
      case 'success':
        Icon = <ThumbUpOutlineIcon />;
        break;
      case 'warning':
        Icon = <CommentAlertOutlineIcon />;
        break;
      case 'danger':
        Icon = <CloseCircleOutlineIcon />;
        break;
      default:
        break;
    }

    return (
      <ReactstrapAlert color={color} className={className} isOpen={true}>
        {icon && <div className="alert__icon">{Icon}</div>}
        {onDismiss && <button 
          className="close"
          disabled={onDismiss.isDisabled} 
          onClick={() => onDismiss.execute()}>
           <span className="lnr lnr-cross" />
        </button>}
        <div className="alert__content">
          {children}
        </div>
      </ReactstrapAlert>
    );
  }
}
