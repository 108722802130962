import '../../scss/pages/ErrorPages.scss';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import LanguageStore from '../../stores/LanguageStore';

interface ForbiddenProps {
  languageStore: LanguageStore;
}

@inject(({ languageStore }) => ({ languageStore }))
@observer
export default class Forbidden extends React.Component<ForbiddenProps> {
  public render() {
    const translate = this.props.languageStore.translate;

    const title = translate(x => x.pages.errorPages.forbidden.title);
    const subtitle = translate(x => x.pages.errorPages.forbidden.subtitle);
    const quip = translate(x => x.pages.errorPages.forbidden.quip);

    return <Col>
      <Card className="error-page">
        <CardBody >
          <CardTitle><b>403:</b> {title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
          <CardText>{quip}</CardText>
        </CardBody>
      </Card>
    </Col >;
  }
}
