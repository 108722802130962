import Charts from '../../../../base/pages/status/charts';
import dateSelect from './DateSelect';

const charts: Charts = {
  dateSelect,

  temperatureChartTitle: 'Temperature',
  pressureChartTitle: 'Pressure',
  windSpeedChartTitle: 'Wind speed',
  airdomeHeightChartTitle: 'Air dome height',
  noData: 'Selected range contains no data',
  dataWarningTitle: 'The selected range contains a lot of data, so it will take us a while to load it',
  dataWarningMessage: 'Do you wish to proceed?'
};

export default charts;
