import { AxiosInstance } from 'axios';
import BacnetObjectDto from '../../dto/registry/BacnetObjectDto';
import BacnetObjectsReadValuesRequestModel from 'models/bacnetObjects/BacnetObjectsReadValuesRequestModel';
import BacnetObjectsWriteValuesRequestModel from 'models/bacnetObjects/BacnetObjectsWriteValuesRequestModel';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class BacnetObjectsService {
  private axios: AxiosInstance;

  private getAllForTemplateUrl: string;
  private valuesReadRequestUrl: string;
  private valuesSetRequestUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;

    this.getAllForTemplateUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.bacnetObjects.getAllForTemplate);
    this.valuesReadRequestUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.bacnetObjects.valuesReadRequest);
    this.valuesSetRequestUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.bacnetObjects.valuesSetRequest);
  }
  
  public getTemplateBacnetObjects = async (templateId: number, trackingId: string): Promise<BacnetObjectDto[]> => {
    try {
      const url = stringFormat(this.getAllForTemplateUrl, templateId.toString());
      
      const result = await this.axios.get<BacnetObjectDto[]>(url, { headers: { trackingId } });

      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public createReadValuesRequest = async (airdomeId: number, body: BacnetObjectsReadValuesRequestModel, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.valuesReadRequestUrl, airdomeId.toString());
      
      await this.axios.post(url, body, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public createWriteValuesRequest = async (airdomeId: number, body: BacnetObjectsWriteValuesRequestModel, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.valuesSetRequestUrl, airdomeId.toString());
      
      await this.axios.post(url, body, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
