export default class NotificationOptionModel {

  public id: number;

  public name: string = '';

  public constructor(model: {
    id: number,
    name: string
  }) {
    this.id = model.id;
    this.name = model.name;
  }
}
