import Calendar from "../../../base/pages/calendar";
import { ScheduleTypeEnum } from "dto/settings/enums";

const calendar: Calendar = {
  saveChanges: "Save changes",
  fullWeek: "Full week",
  scheduleTypes: {
    [ScheduleTypeEnum.Heating]: 'Heating',
    [ScheduleTypeEnum.LightsMain1]: 'Main light 1',
    [ScheduleTypeEnum.LightsMain2]: 'Main light 2',
    [ScheduleTypeEnum.LightsMain3]: 'Main light 3',
    [ScheduleTypeEnum.LightsMain4]: 'Main light 4',
    [ScheduleTypeEnum.LightsAdditional1]: 'Additional light 1',
    [ScheduleTypeEnum.LightsAdditional2]: 'Additional light 2',
    [ScheduleTypeEnum.LightsAdditional3]: 'Additional light 3',
    [ScheduleTypeEnum.LightsAdditional4]: 'Additional light 4',
    [ScheduleTypeEnum.MainDoorLock]: 'Main door'
  },
  handledFromExternalSystem: "The calendar is handled from the external system – Rezzy",
  pageLeaveWarning: "Do you really want to leave? Calendar changes have been made.",
  timesOverlapping: "Please adjust the time. Current times overlap.",
  intervalOverlapping: "Please adjust the interval. The interval conflicts with an already created interval."
};

export default calendar;
