import AlertIcon from 'mdi-react/AlertIcon';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import NotificationAlert from './NotificationAlert';
import NotificationDto from '../../dto/notification/NotificationDto';
import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useStore } from 'stores/MobXTypedStoreHook';

const NotificationIcon = observer((props: {
  anyNotifications: boolean,
  count: number
}) => {
    const history = useHistory();
    const airdomeId = useStore(x => x.airdomeStore).SelectedAirdomeId;
    return <div className="alert-count-box">
      <div>
        {props.anyNotifications
          ? <div className="hoverable" onClick={() => { history.push(`/airdome/${airdomeId}/notifications/plc`); } }>
            <AlertIcon size={60} className="d-block" color="#ff4861" />
            {props.count > 0 && <b>({props.count})</b>}
          </div>
          : <CheckCircleIcon size={60} className="d-block" color="#4caf50" />}
      </div>
    </div>;
  });

const Notifications = observer((props: {
  notifications: NotificationDto[],
  locale: string,
  onDismiss: (notification: NotificationDto) => void
}) => {
  const changeRequestStore = useStore(x => x.changeRequestStore);
  const isResolvingDisabled =
    changeRequestStore.ChangeRequestStates[ChangeRequestTypeEnum.ResolveNotifications]
    ?? true;
  
  return <>
    {props.notifications
      .slice(undefined, 9)
      .map(x =>
        <NotificationAlert 
          key={`${x.bacnetObjectId}-${x.bacnetType}-${x.state}`} 
          notification={x} 
          onDismiss={{
            isDisabled: isResolvingDisabled,
            execute: () => props.onDismiss(x)
          }} />
      )}
  </>;
});

export {
  NotificationIcon,
  Notifications
};
