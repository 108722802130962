export default class {
  public setValue: (value: boolean) => void;
  public label: string;
  public inputId: string;
  public value?: boolean;

  public constructor(model: {
    setValue: (value: boolean) => void,
    label: string,
    inputId: string,
    value?: boolean
  }) {
    this.setValue = model.setValue;
    this.label = model.label;
    this.inputId = model.inputId;
    this.value = model.value;
  }
}
