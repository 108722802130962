import Charts from '../../../../base/pages/status/charts';
import dateSelect from './DateSelect';

const charts: Charts = {
  dateSelect,

  temperatureChartTitle: 'Temperatur',
  pressureChartTitle: 'Druck',
  windSpeedChartTitle: 'Windgeschwindigkeit',
  airdomeHeightChartTitle: 'Hallenhöhe',
  noData: 'Der ausgewählte Bereich enthält keine Daten',
  dataWarningTitle:
    'Der ausgewählte Bereich enthält zu großer Datenmenge, und das Laden dieser Daten kann länger dauern',
  dataWarningMessage: 'Möchten Sie fortfahren?'
};

export default charts;
