import SwitchBoxTablePropertyName, { switchBoxTablePropertyNames } from 'enums/SwitchBoxTablePropertyName';
import { action, computed, observable } from 'mobx';

import AirdomeStore from './AirdomeStore';
import ErrorStore from './errors/ErrorStore';
import LightingStore from './LightingStore';
import { StatusSingleAirdomeHubConnection } from 'services';
import SwitchBoxService from 'services/status/SwitchBoxService';
import { asTracked } from './decorators/TrackedDecorator';
import { catchError } from 'stores/decorators';
import moment from 'moment';
import objectHelper from 'utils/ObjectHelper';

interface SwitchBoxStoreParams {
  switchBoxService: SwitchBoxService;
  errorStore: ErrorStore;
  airdomeStore: AirdomeStore;
  lightingStore: LightingStore;
  statusSingleAirdomeHubConnection: StatusSingleAirdomeHubConnection;
}

type SwitchboxDatum<TKey extends string> = { key: TKey, value: number | undefined | null };

export default class SwitchBoxStore {
  @observable
  public switchBoxData: SwitchboxDatum<string>[] | undefined;

  @observable
  public lastUpdate?: moment.Moment;

  @computed
  public get SwitchBoxTableData(): SwitchboxDatum<SwitchBoxTablePropertyName>[] | undefined {
    return this.switchBoxData
      ?.filter(x => switchBoxTablePropertyNames.find(name => name === x.key))
      ?.map(x => ({ key: x.key as SwitchBoxTablePropertyName, value: x.value }));
  }

  private params: SwitchBoxStoreParams;
  constructor(params: SwitchBoxStoreParams) {
    this.params = params;

    params.statusSingleAirdomeHubConnection.onSwitchBoxDataReceived(
      switchBoxData => this.setSwitchBoxData(switchBoxData)
    );
  }

  @action
  private setSwitchBoxData = (switchBoxData?: { [name: string]: string }) => {
    this.switchBoxData = switchBoxData 
      ? objectHelper.getTypedEntries(switchBoxData) 
      : undefined;
    
    if(this.switchBoxData)
      this.lastUpdate = moment();
  }

  public clear = action(() => {
    this.setSwitchBoxData();
  });

  @catchError
  public requestSwitchBox = asTracked(action(async (trackingId: string = '') => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    if (!airdomeId)
      return;
      
    this.clear();

    await this.params.switchBoxService.requestSwitchBoxData(airdomeId, trackingId);
  }));
}
