import * as React from 'react';

import LoginStore from '../stores/LoginStore';
import { inject } from 'mobx-react';

@inject('loginStore')
class Logout extends React.Component<{ loginStore?: LoginStore; }> {

  constructor(props: any) {
    super(props);

    this.props.loginStore!.signOut();
  }

  public render() {
    return <></>;
  }
}

export default Logout;
