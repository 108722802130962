import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import ConditionalRendering from '../../security/ConditionalRendering';
import AccessDefinitions, { AccessDefinition } from '../../security/AccessDefinitions';

interface SecureRouteProps extends RouteProps {
  getAccess: (d: typeof AccessDefinitions) => AccessDefinition;
}

export default class SecureRoute extends Route<SecureRouteProps> {
  public render() {
    const { getAccess } = this.props;

    return <ConditionalRendering getAccessDefinition={getAccess} fallbackChildren={<Redirect to="/forbidden" />}>
      {super.render()}
    </ConditionalRendering>;
  }
}
