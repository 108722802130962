import React from 'react';
import { FormInput } from '../../../..';
import { FormInputModel } from '../../../../../models';
import { HandleValueModel } from '../../../../../models/component/HandleValueModel';
import Translate from '../../../../../localization/Translate';
import { StringSchema } from 'yup';
import { observer } from 'mobx-react';

interface NameFieldProps {
  translate: Translate;
  validationSchema: StringSchema;
  name?: string;
  setName: (name?: string) => void;
}

const NameField = ({ translate, validationSchema, name, setName }: NameFieldProps) =>
  <FormInput {...{
    translate,
    model: new FormInputModel({
      inputId: 'AirdomeName',
      value: name,
      label: translate(x => x.general.airdome.name),
      placeholder: translate(x => x.general.airdome.name),
      handleValueModel: new HandleValueModel({
        inputType: 'text',
        setValue: setName
      }),
      validationSchema: validationSchema.label(translate(x => x.general.airdome.name)),
      maxLength: 100
    })
  }} />;

export default observer(NameField);
