export default class CreateUserDto {
  public username: string;
  public password: string;
  public email: string;
  public role: string;

  constructor(data: {
    username: string;
    password: string;
    email: string;
    role: string;
  }) {
    this.username = data.username;
    this.password = data.password;
    this.email = data.email;
    this.role = data.role;
  }
}
