import {
  AIConstantsDto
} from '../../dto/Container';
import { AxiosInstance } from 'axios';
import format from 'string-format';
import { getJoinedEndpoint } from 'utils/Settings';

export default class AIService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  public getIsArtificialIntelligenceEnabled = async (airdomeId: number, trackingId: string): Promise<boolean> => {
    const response = await this.axios.get<boolean>(
      format(
        getJoinedEndpoint(s => s.ai.baseUri, x => x.ai.airdomes.getIsEnabled),
        airdomeId.toString()
      ),
      { headers: { trackingId }});

    return response.data;
  }

  public enableArtificialIntelligence = async (airdomeId: number, trackingId: string): Promise<void> => {
    await this.axios.post(
      format(
        getJoinedEndpoint(s => s.ai.baseUri, x => x.ai.airdomes.enable),
        airdomeId.toString()
      ),
      undefined,
      { headers: { trackingId }});
  }

  public disableArtificialIntelligence = async (airdomeId: number, trackingId: string): Promise<void> => {
    await this.axios.post(
      format(
        getJoinedEndpoint(s => s.ai.baseUri, x => x.ai.airdomes.disable),
        airdomeId.toString()
      ),
      undefined,
      { headers: { trackingId }});
  }

  public fetchDefaultAIConstants = async (airdomeId: number, trackingId: string)
    : Promise<AIConstantsDto> => {
      const response = await this.axios.get<AIConstantsDto>(
        format(
          getJoinedEndpoint(s => s.ai.baseUri, x => x.ai.airdomes.defaultConstants.get),
          airdomeId.toString()
        ),
        { headers: { trackingId }});
  
      return response.data;
  }

  public updateDefaultAIConstants = async (
    defaultConstantsDto: AIConstantsDto,
    airdomeId: number,
    trackingId: string
  ): Promise<void> => {
    await this.axios.put(
      format(
        getJoinedEndpoint(s => s.ai.baseUri, x => x.ai.airdomes.defaultConstants.edit),
        airdomeId.toString()
      ),
      defaultConstantsDto,
      { headers: { trackingId }});
  }
}
