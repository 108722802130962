import Airdome from '../../../base/general/airdome';

const airdome: Airdome = {
  template: 'Bacnet definition template',
  name: 'Name',
  country: 'Country',
  timeZone: 'Time zone',
  macAddresses: 'MAC addresses of associated devices',
  macAddress: 'MAC address of associated device',
  projectNumber: 'Project number',
  intervalHeader: 'Intervals',
  logLevel: 'Log level',
  infoHeader: 'Info',
  unavailableTemplate: '{airdomeName} does not respond.'
};

export default airdome;
