import { Input } from 'reactstrap';
import SelectedBacnetObjectRowModel from 'models/bacnetObjects/SelectedBacnetObjectRowModel';
import { observer } from 'mobx-react';

export interface BacnetObjectValueInputProps {
  item: SelectedBacnetObjectRowModel,
}

const BacnetObjectValueInput = (props: BacnetObjectValueInputProps) => {
  return <Input
    className='bacnetObjectValueInput bg-white'
    type='text'
    value={props.item.value.Value ?? ''}
    onChange={e => props.item.value.setValue(e.target.value)}
  />
}

export default observer(BacnetObjectValueInput);