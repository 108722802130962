import './Settings.scss';

import * as React from 'react';

import {
  ArtificialIntelligenceForm,
  InfoForm,
  IntervalsForm,
  MacAddressesForm,
  SettingsForm,
  SettingsHeader,
  UserAccessForm
} from '../../components/settings';
import { ChangeRequestStore, LanguageStore, SecurityStore, SettingsStore } from '../../stores';
import { ErrorModel, SelectOptionModel } from '../../models';

import AccessDefinitions from '../../security/AccessDefinitions';
import AirdomeStateExpand from 'components/airdome/AirdomeStateExpand';
import BaseAirdomePage from '../BaseAirdomePage';
import { ChangeRequestTypeEnum } from '../../dto/changeRequest/ChangeRequestTypeEnum';
import ConditionalRendering from '../../security/ConditionalRendering';
import { ErrorBox } from '../../components';
import { LogLevels } from 'enums/LogLevel';
import { Row } from 'reactstrap';
import TemplateStatus from 'components/settings/templateStatus/TemplateStatus';
import { basePageTypedInject } from '../BasePageInject';
import timezones from '../../helpers/Timezones';
import FormNewChangesDialog from 'components/FormNewChangesDialog';

export interface SettingsProps {
  languageStore: LanguageStore;
  settingsStore: SettingsStore;
  securityStore: SecurityStore;
  changeRequestStore: ChangeRequestStore;
}

@basePageTypedInject(({ settingsStore, languageStore, securityStore, changeRequestStore }) =>
  ({ settingsStore, languageStore, securityStore, changeRequestStore }))
export default class Settings extends BaseAirdomePage<SettingsProps> {
  public render() {
    const {
      airdomeStore,
      errorStore,
      settingsStore,
      languageStore,
      securityStore
    } = this.props;

    const {
      infoModel,
      settingsModel,
      intervalModel,
      artificialIntelligenceModel,
      usersWithAccessModel
    } = settingsStore;

    // TODO: Use airdome availability from store
    const isAirdomeAvailable = true;
    const airdome = this.Airdome;
    const serverError = errorStore && this.renderServerError(errorStore.ErrorModel);
    const translate = languageStore.translate;
    const locale = languageStore.Locale;

    const settingsAccessTable = {
      modes: securityStore!.isAccessAllowed(AccessDefinitions.Settings.Modes),
      edit: securityStore!.isAccessAllowed(AccessDefinitions.Settings.Edit)
    };

    const isSettingsCRInProgress = this.props.changeRequestStore.ChangeRequestStates[ChangeRequestTypeEnum.Settings] ?? true
    const isIntervalsCRInProgress = this.props.changeRequestStore.ChangeRequestStates[ChangeRequestTypeEnum.Intervals] ?? true

    return <>
      {this.renderSettingsNewChangesDialog()}
      {this.renderIntervalsNewChangesDialog()}
      <div className="flex-grow flex-wrap">
        <div className="d-flex flex-wrap">
          <SettingsHeader {...{
            translate,
            onRestart: this.onRestartDevice,
            restartTitleKey: 'airdome.restart',
            airdomeTitle: airdome ? airdome.name : undefined
          }} />
        </div>
        <Row>
          <SettingsForm {...{
            locale,
            translate,
            settingsModel,
            disabled: isSettingsCRInProgress,
            accessTable: settingsAccessTable,
            processingRequest: settingsStore.updateSettings.getProcessing!(),
            submitButton:
              <AirdomeStateExpand
                disabled={isSettingsCRInProgress || !settingsAccessTable.edit || !settingsModel.areDataValid}
                className="col-md-4 col-12 ml-auto mr-auto d-block"
                onClick={async () => await this.onSettingsUpdate()}
                enabledTitle={translate(x => x.general.edit)}/>
          }}>
            {settingsStore.updateSettings.getLastExecutionFailed!() ? serverError : undefined}
          </SettingsForm>

          <ConditionalRendering getAccessDefinition={d => d.Templates.FullAccess}>
            <TemplateStatus />
          </ConditionalRendering>

          <ConditionalRendering getAccessDefinition={d => d.Airdomes.Manage}>
            <InfoForm
              translate={translate}
              infoModel={infoModel}
              updateInfo={async () => await settingsStore.updateAirdome()}
              templateOptions={airdomeStore!.templates.map(
                x => new SelectOptionModel({
                  displayName: x.name,
                  value: x.id.toString()
                }))}
              countryOptions={airdomeStore!.countries.map(
                x => new SelectOptionModel({
                  displayName: translate(tr => tr.countries[x.shortcut.toLowerCase()]),
                  value: x.id.toString()
                }))}
              timeZoneOptions={timezones.map(
                x => new SelectOptionModel({
                  value: x.id,
                  displayName: x.displayName
                })
              )}
              logLevelOptions={LogLevels.map(
                x => new SelectOptionModel({
                  value: x.toString(),
                  displayName: translate(tr => tr.enums.logLevel[x])
                })
              )}
            />
          </ConditionalRendering>

          <ConditionalRendering getAccessDefinition={x => x.AI.Enable}>
            <ArtificialIntelligenceForm />
          </ConditionalRendering>

          <ConditionalRendering getAccessDefinition={d => d.Settings.Intervals}>
            <IntervalsForm
              intervalModel={intervalModel}
              translate={translate}
              disabled={isIntervalsCRInProgress}
              processingRequest={settingsStore.updateIntervals.getProcessing!()}
            >
              {settingsStore.updateIntervals.getLastExecutionFailed!() ? serverError : undefined}
            </IntervalsForm>
          </ConditionalRendering>

          <ConditionalRendering getAccessDefinition={d => d.Settings.AirdomeUsersRead}>
            <UserAccessForm
              readonly={!securityStore!.isAccessAllowed(AccessDefinitions.Settings.AirdomeUsersManage)}
              translate={translate}
              error={this.renderUserAccessError()}
              users={usersWithAccessModel.users}
              model={usersWithAccessModel.addUserAccessModel}
              onRemove={async x => await settingsStore.removeUser(x)}
              onAdd={async x => await settingsStore.addUser(x)} >
            </UserAccessForm>
          </ConditionalRendering>

          <ConditionalRendering getAccessDefinition={d => d.Airdomes.Manage}>
            <MacAddressesForm translate={translate} />
          </ConditionalRendering>

        </Row>
      </div >
    </>;
  }

  private renderUserAccessError = () => {
    const translate = this.props.languageStore.translate;
    const { addUser, removeUser } = this.props.settingsStore;

    if (!addUser.getLastExecutionFailed!() && !removeUser.getLastExecutionFailed!())
      return null;

    return <ErrorBox
      key=""
      {...{
        errorText: translate(x => x.errors.airdome.userAccess.unableToLoad),
        errorModel: new ErrorModel({ localizationKey: '' })
      }}
    />;
  }

  private renderServerError = (errorModel?: ErrorModel) => errorModel
    ? <ErrorBox
      key={errorModel.localizationKey}
      {...{
        errorModel,
        errorText: this.props.languageStore.translate(x => x.errors.airdome.intervals[errorModel.localizationKey])
      }}
    />
    : null

  private renderSettingsNewChangesDialog = (): JSX.Element => {
    const { settingsStore } = this.props;

    return <FormNewChangesDialog
      newChangesModel={settingsStore.settingsNewChangesModel}
      onConfirm={async () => await settingsStore.fetchSettings()}
      changeRequestType={ChangeRequestTypeEnum.Settings}
    />;
  }

  private renderIntervalsNewChangesDialog = (): JSX.Element => {
    const { settingsStore } = this.props;

    return <FormNewChangesDialog
    newChangesModel={settingsStore.intervalsNewChangesModel}
      onConfirm={async () => await settingsStore.fetchIntervals()}
      changeRequestType={ChangeRequestTypeEnum.Intervals}
    />;
  }

  private onSettingsUpdate = async (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();
    const { settingsStore } = this.props;

    settingsStore && await settingsStore.updateSettings();
  }

  private onRestartDevice = async () => {
    const { settingsStore } = this.props;

    settingsStore && await settingsStore.restartRDev();
  }
}
