import {
  TemperatureModeAuto,
  TemperatureModeAutoOff,
  TemperatureModeComfort,
  TemperatureModeComfortOff,
  TemperatureModeCutback,
  TemperatureModeCutbackOff
} from '../icons';

import RadialMenuSelect from '../../../basic/RadialMenuSelect';
import React from 'react';
import SettingsFormProps from '../SettingsFormProps';
import { TemperatureMode } from '../../../../dto/settings/enums';
import { observer } from 'mobx-react';

const getTemperatureCutbackCoords = (size: number) => ({
  icon: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 1.275,
    y: size * 0.3125
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 1.48,
    y: size
  }
});

const getTemperatureComfortCoords = (size: number) => ({
  icon: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 0.35,
    y: size * 0.3125
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 0.53,
    y: size
  }
});

const getTemperatureAutoCoords = (size: number) => ({
  icon: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 0.8125,
    y: size * 1.1875
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size,
    y: size * 1.875
  }
});

const TemperatureModeField = ({ translate, settingsModel, accessTable, disabled }: SettingsFormProps) => {
  const { temperatureMode } = settingsModel;

  return <div style={{ height: 220 }}>
    <RadialMenuSelect<TemperatureMode> {...{
      readonly: disabled || !accessTable.modes,
      currentValue: temperatureMode.Value,
      name: translate(x => x.forms.airdome.settings.temperatures),
      selectValue: x => temperatureMode.setValue(x),
      options: [
        {
          value: TemperatureMode.Cutback,
          display: translate(x => x.enums.temperatureModes[TemperatureMode.Cutback]),
          getCoords: getTemperatureCutbackCoords,
          getIcon: props => temperatureMode.Value !== TemperatureMode.Cutback
            ? <TemperatureModeCutbackOff {...props} />
            : <TemperatureModeCutback {...props} />
        },
        {
          value: TemperatureMode.Auto,
          display: translate(x => x.enums.temperatureModes[TemperatureMode.Auto]),
          getCoords: getTemperatureAutoCoords,
          getIcon: props => temperatureMode.Value !== TemperatureMode.Auto
            ? <TemperatureModeAutoOff {...props} />
            : <TemperatureModeAuto {...props} />
        },
        {
          value: TemperatureMode.Comfort,
          display: translate(x => x.enums.temperatureModes[TemperatureMode.Comfort]),
          getCoords: getTemperatureComfortCoords,
          getIcon: props => temperatureMode.Value !== TemperatureMode.Comfort
            ? <TemperatureModeComfortOff {...props} />
            : <TemperatureModeComfort {...props} />
        }
      ],
      selectorSize: 88
    }} />
  </div>;
};

export default observer(TemperatureModeField);
