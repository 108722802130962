import * as uuid from 'uuid';

import { action, computed, observable } from 'mobx';

import AirdomeService from 'services/registry/AirdomeService';
import AirdomeStore from 'stores/AirdomeStore';
import CatchError from 'stores/decorators/CatchError';
import ErrorStore from 'stores/errors/ErrorStore';
import { LoggerStore } from 'stores';
import { asTracked } from 'stores/decorators/TrackedDecorator';

interface TemplateTwinStatus {
  templateId?: number;
  revision?: number;
}

interface TemplateTwinStatusFull {
  templateName?: string;
  revision?: number;
}

export enum TemplateStatus {
  UpToDate,
  Pending,
  Unavailable
}

export default class TemplateStatusModel {
  private readonly params: { loggerStore: LoggerStore, errorStore: ErrorStore, getTrackingId: () => string };
  private readonly airdomeStore: AirdomeStore;
  private readonly airdomeService: AirdomeService;

  @observable
  private reported: TemplateTwinStatus = {};

  @computed
  public get Reported(): TemplateTwinStatusFull {
    return {
      revision: this.reported.revision,
      templateName: this.airdomeStore.templates
        ?.find(x => x.id === this.reported.templateId)?.name
    }
  }

  @observable
  private desired: TemplateTwinStatus = {};
  
  @computed
  public get Desired(): TemplateTwinStatusFull {
    return {
      revision: this.desired.revision,
      templateName: this.airdomeStore.templates
        ?.find(x => x.id === this.desired.templateId)?.name
    }
  }

  @computed
  public get TemplateStatus(): TemplateStatus {
    if(this.desired.templateId === undefined
      || this.desired.revision === undefined
      || this.reported.templateId === undefined
      || this.reported.revision === undefined)
      return TemplateStatus.Unavailable;

    return this.desired.templateId === this.reported.templateId
      && this.desired.revision === this.reported.revision
      ? TemplateStatus.UpToDate
      : TemplateStatus.Pending;
  }

  constructor(
    loggerStore: LoggerStore,
    errorStore: ErrorStore, 
    getTrackingId: () => string,
    airdomeStore: AirdomeStore, 
    airdomeService: AirdomeService) {
    this.params = { loggerStore, errorStore, getTrackingId };
    this.airdomeStore = airdomeStore;
    this.airdomeService = airdomeService;
  }
  
  public clear = asTracked(action(() => {
    this.desired = {};
    this.reported = {};
  }));

  @CatchError
  public fetch = asTracked(action(async () => {
    const airdomeId = this.airdomeStore.SelectedAirdomeId; 

    if(!airdomeId)
      throw new Error('No airdome selected');

    const {
      desired,
      reported
    } = await this.airdomeService.getTemplateStatus(airdomeId, uuid.v4());

    this.desired = {
      revision: desired.revision ?? undefined,
      templateId: desired.templateId ?? undefined
    }
    
    this.reported = {
      revision: reported.revision ?? undefined,
      templateId: reported.templateId ?? undefined
    }
  }));
}