import { InputType } from 'reactstrap/lib/Input';

interface HandleNumberValueModel {
  setValue: (value?: number) => void;
  inputType: 'number';
}

interface HandleStringValueModel {
  setValue: (value?: string) => void;
  inputType: InputType;
}

export class HandleValueModel {
  private baseHandleValueModel: HandleStringValueModel | HandleNumberValueModel;

  constructor(baseHandleValueModel: HandleStringValueModel | HandleNumberValueModel) {
    this.baseHandleValueModel = baseHandleValueModel;
  }

  public get InputType(): InputType {
    return this.baseHandleValueModel.inputType;
  }

  public get SetValue(): (value: string | number) => void {
    const handler = this.baseHandleValueModel as { inputType: InputType };

    if (handler.inputType === 'number') {
      const numberHandleValue = handler as HandleNumberValueModel;
      return value => numberHandleValue.setValue(Number(value));
    }

    const stringHandleValue = handler as HandleStringValueModel;

    return value => stringHandleValue.setValue(value ? value.toString() : '');
  }
}
