import { AxiosInstance } from 'axios';
import PressureRestrictionsDto from '../../dto/settings/PressureRestrictionsDto';
import { TemperatureRestrictionsDto } from '../../dto/Container';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class RestrictionsService {
  private axios: AxiosInstance;
  private updatePressureRestrictionsUrl: string;
  private updateTemperatureRestrictionsUrl: string;
  private getRestrictionsUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.updatePressureRestrictionsUrl =
      getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.restrictions.pressure.update);
    this.updateTemperatureRestrictionsUrl =
      getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.restrictions.temperature.update);
    this.getRestrictionsUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.restrictions.get);
  }

  public getRestrictions = async (airdomeId: number, trackingId: string):
    Promise<PressureRestrictionsDto & TemperatureRestrictionsDto> => {
    try {
      const url = stringFormat(this.getRestrictionsUrl, airdomeId.toString());
      const result = await this.axios.get<PressureRestrictionsDto & TemperatureRestrictionsDto>(url, {
        headers: { trackingId }
      });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public updateTemperatureRestrictions = async (
    updatedRestrictions: TemperatureRestrictionsDto, airdomeId: number, trackingId: string
  ): Promise<TemperatureRestrictionsDto> => {
    try {
      const url = stringFormat(this.updateTemperatureRestrictionsUrl, airdomeId.toString());
      return (await this.axios.put<TemperatureRestrictionsDto>(url, updatedRestrictions, {
        headers: { trackingId }
      })).data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public updatePressureRestrictions = async (
    updatedRestrictions: PressureRestrictionsDto, airdomeId: number, trackingId: string
  ): Promise<PressureRestrictionsDto> => {
    try {
      const url = stringFormat(this.updatePressureRestrictionsUrl, airdomeId.toString());
      return (await this.axios.put<PressureRestrictionsDto>(url, updatedRestrictions, {
        headers: { trackingId }
      })).data;
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
