import { action, observable } from 'mobx';

export type SortOrder = 'asc' | 'desc';

export class MaterialTableStore<TRow>{
  @observable public order?: SortOrder;
  @observable public orderBy?: string;
  @observable public selected!: TRow[];
  @observable public rowsPerPage = 10;
  @observable public page = 0;

  constructor(selected?: TRow[]) {
    this.setSelected(selected || []);
  }

  @action public setOrder = (order?: SortOrder) => {
    this.order = order;
  }

  @action public setOrderBy = (orderBy?: string) => {
    this.orderBy = orderBy;
  }

  @action public setSelected = (selected: TRow[]) => {
    this.selected = selected;
  }

  @action public setRowsPerPage = (rowsPerPage: number) => {
    this.rowsPerPage = rowsPerPage;
  }

  @action public setPage = (page: number) => {
    this.page = page;
  }
}
