import AirdomeListItemModel from '../../models/AirdomeListItemModel';
import CircleIcon from 'mdi-react/CircleIcon';
import React from 'react';
import Translate from '../../localization/Translate';
import Translation from '../../localization/base';
import moment from 'moment';
import { observer } from 'mobx-react';

const getColorForAvailability = (item: AirdomeListItemModel) => {
  switch (item.availability) {
    case 'Alive':
      return 'circle-icon-green';
    case 'HalfDead':
      return 'circle-icon-orange';
    case 'NotInflated':
      return 'circle-icon-black';
    case 'Dead':
      return 'circle-icon-red';
  }
};

const getTextForAvailability = (item: AirdomeListItemModel, translate: Translate, locale: string) => {
  const getLastContactWithTextFallback = (getTranslation: (x: Translation) => string) =>
    item.lastContact
      ? moment(item.lastContact).toDate().toLocaleString(locale)
      : translate(getTranslation);

  switch (item.availability) {
    case 'Alive':
      return translate(x => x.enums.airdomeState.Alive);

    case 'PlcUnavailable':
      return translate(x => x.enums.airdomeState.PlcUnavailable);

    case 'HalfDead':
      return getLastContactWithTextFallback(x => x.enums.airdomeState.HalfDead);

    case 'Dead':
      return getLastContactWithTextFallback(x => x.enums.airdomeState.Dead);

    case 'NotInflated':
    default:
      return translate(x => x.enums.airdomeState.NotInflated);
  }
};

const availabilityCell = (props: { item: AirdomeListItemModel, translate: Translate, locale: string }) => {
  return <span className="airdome-circle-span">
    <p>
      <CircleIcon size={16} className={`mr-1 ${getColorForAvailability(props.item)}`} />
      {getTextForAvailability(props.item, props.translate, props.locale)}
    </p>
  </span>;
};

export default observer(availabilityCell);
