import { BacnetObjectsHubConnection, ChangeRequestHubConnection } from "services";
import BaseStore, { Params } from "./base/BaseStore";
import { action, computed, observable } from 'mobx';

import AirdomeStore from "./AirdomeStore";
import BacnetObjectDefinition from "models/bacnetObjects/BacnetObjectDefinition";
import BacnetObjectDto from "dto/registry/BacnetObjectDto";
import BacnetObjectValue from "models/bacnetObjects/BacnetObjectValue";
import BacnetObjectsReadValuesRequestModel from '../models/bacnetObjects/BacnetObjectsReadValuesRequestModel';
import BacnetObjectsService from "../services/settings/BacnetObjectsService";
import BacnetObjectsWriteValuesRequestModel from "models/bacnetObjects/BacnetObjectsWriteValuesRequestModel";
import { ChangeRequestTypeEnum } from "dto/changeRequest/ChangeRequestTypeEnum";
import ReceivedBacnetObjectRowModel from "models/bacnetObjects/ReceivedBacnetObjectRowModel";
import { SelectOptionModel } from "models";
import SelectedBacnetObjectRowModel from "models/bacnetObjects/SelectedBacnetObjectRowModel";
import SignalR_BacnetObjectValue from "services/signalr/dto/BacnetObjectValue";
import { asTracked } from "./decorators/TrackedDecorator";
import { catchError } from "./decorators";
import objectHelper from "utils/ObjectHelper";
import stringFormat from 'string-format';

export interface BacnetObjectsStoreParams {
  bacnetObjectsService: BacnetObjectsService;
  bacnetObjectsHubConnection: BacnetObjectsHubConnection;
  changeRequestHubConnection: ChangeRequestHubConnection;
  airdomeStore: AirdomeStore;
  getTrackingId: () => string;
}

export default class BacnetObjectsStore extends BaseStore<BacnetObjectsStoreParams> {
  @observable
  public bacnetObjects: BacnetObjectDto[] = [];

  @observable
  public selectedBacnetObjectId?: number;

  @computed
  public get BacnetObjectsSelectOptions(): SelectOptionModel[] {
    const bacnetObjectsMap = this.bacnetObjects.reduce((previousValue, currentValue) => 
      { 
        if(previousValue[currentValue.tableTypeName])
          previousValue[currentValue.tableTypeName].push(currentValue); 
        else
          previousValue[currentValue.tableTypeName] = [currentValue];

        return previousValue; 
      },
      {} as { [key: string]: BacnetObjectDto[] }
    );

    const sortedBacnetObjects = objectHelper.getTypedEntries<BacnetObjectDto[]>(bacnetObjectsMap)
      .map(item => ({
          key: item.key,
          value: item.value.sort((firstElement, secondElement) =>
            secondElement.propertyDefinition.name > firstElement.propertyDefinition.name 
            ? -1 
            : secondElement.propertyDefinition.name < firstElement.propertyDefinition.name 
              ? 1 
              : 0
      )})).sort((firstElement, secondElement) =>
        secondElement.key > firstElement.key 
        ? -1
        : secondElement.key < firstElement.key
          ? 1
          : 0
      ).flatMap(x => x.value);

    return sortedBacnetObjects
      .filter(bo => !this.selectedBacnetObjects.some(x => x.id === bo.id))
      .map(bo => new SelectOptionModel({ 
        value: bo.id.toString(),
        displayName: stringFormat("{0} - {1}", bo.tableTypeName, bo.propertyDefinition.name) }
      ));
  };

  @observable
  public selectedBacnetObjects = observable.array<SelectedBacnetObjectRowModel>([]);
  
  @observable
  public receivedBacnetObjects = observable.array<ReceivedBacnetObjectRowModel>([]);

  @observable
  public isWaitingForValues: boolean = false;

  public constructor(params: Params<BacnetObjectsStoreParams>) {
    super(params);

    params.bacnetObjectsHubConnection.onBacnetObjectValuesRead(this.receiveBacnetObjectValues);

    params.changeRequestHubConnection.onFailed((airdomeId, createdBy, chRType) => {
      const selectedAirdomeId = params.airdomeStore.SelectedAirdomeId;

      if (!selectedAirdomeId || selectedAirdomeId !== airdomeId)
        return;

      if (chRType !== ChangeRequestTypeEnum.BacnetObjectWrite)
        return;

      this.isWaitingForValues = false;
    });
  }

  @action
  public setSelectedBacnetObject = (bacnetObjectId: string) => {
    this.selectedBacnetObjectId = Number(bacnetObjectId);
  };

  @action
  public addSelectedBacnetObjectToSelection = () => {
    if (!this.selectedBacnetObjectId)
      return;

    const bacnetObject = this.bacnetObjects.find(x => x.id === this.selectedBacnetObjectId)!;

    const rowModel = new SelectedBacnetObjectRowModel(
      bacnetObject.id,
      bacnetObject.bacnetId,
      bacnetObject.bacnetType,
      bacnetObject.bacnetPropertyType,
      bacnetObject.propertyDefinition.name);

    this.selectedBacnetObjects.push(rowModel);

    this.selectedBacnetObjectId = undefined;
  };

  @action
  public removeBacnetObjectFromSelection = (bacnetObjectId: number) => {
    const selectedBacnetObject = this.selectedBacnetObjects.find(x => x.id === bacnetObjectId);

    if(!selectedBacnetObject)
      return;

    this.selectedBacnetObjects.remove(selectedBacnetObject);
  };

  @catchError
  @action
  public createReadValuesRequest = asTracked(async () => {
    if (!this.params.airdomeStore.SelectedAirdomeId)
      throw new Error('No airdome selected');

    if (this.selectedBacnetObjects.length === 0)
      throw new Error('No bacnet objects selected');

    this.receivedBacnetObjects.clear();

    const bacnetObjectDefinitions = this.selectedBacnetObjects
      .map(bo => new BacnetObjectDefinition(bo.bacnetId, bo.bacnetType, bo.bacnetPropertyType));

    const trackingId = this.params.getTrackingId();

    this.isWaitingForValues = true;

    await this.params.bacnetObjectsService.createReadValuesRequest(
      this.params.airdomeStore.SelectedAirdomeId,
      new BacnetObjectsReadValuesRequestModel(bacnetObjectDefinitions),
      trackingId);
  });

  @catchError
  @action
  public createWriteValuesRequest = async () => {
    if (!this.params.airdomeStore.SelectedAirdomeId)
      throw new Error('No airdome selected');

    if (this.selectedBacnetObjects.length === 0)
      throw new Error('No bacnet objects selected');

    this.receivedBacnetObjects.clear();

    const bacnetObjectDefinitions = this.selectedBacnetObjects
      .map(x => new BacnetObjectValue(x.bacnetId, x.bacnetType, x.bacnetPropertyType, x.value.Value ?? ''));

    const trackingId = this.params.getTrackingId();

    this.isWaitingForValues = true;

    await this.params.bacnetObjectsService.createWriteValuesRequest(
      this.params.airdomeStore.SelectedAirdomeId,
      new BacnetObjectsWriteValuesRequestModel(bacnetObjectDefinitions),
      trackingId);
  };

  @catchError
  @action
  public fetchBacnetObjects = asTracked(async () => {
    if (!this.params.airdomeStore.SelectedAirdome)
      throw new Error('No airdome selected');

    if (!this.params.airdomeStore.SelectedAirdome.templateId)
      throw new Error('Unknown template id');

    const trackingId = this.params.getTrackingId();

    const result = await this.params.bacnetObjectsService.getTemplateBacnetObjects(
      this.params.airdomeStore.SelectedAirdome.templateId,
      trackingId);

    this.bacnetObjects = result;
  });


  @action
  public clear = () => {
    this.bacnetObjects = [];
    this.selectedBacnetObjectId = undefined;
    this.selectedBacnetObjects.clear();
    this.receivedBacnetObjects.clear();
    this.isWaitingForValues = false;
  }

  @action
  private receiveBacnetObjectValues = (receivedValues: SignalR_BacnetObjectValue[]) => {
    for(const receivedBacnetObjectValue of receivedValues) {
      for(const selectedBacnetObject of this.selectedBacnetObjects.filter(x =>
         x.bacnetId === receivedBacnetObjectValue.bacnetId 
         && x.bacnetType === receivedBacnetObjectValue.bacnetType)) {

        this.receivedBacnetObjects.push(new ReceivedBacnetObjectRowModel(
          selectedBacnetObject.id,
           selectedBacnetObject.bacnetId, 
           selectedBacnetObject.bacnetType, 
           selectedBacnetObject.bacnetPropertyType, 
           selectedBacnetObject.propertyDefinitionName,
           receivedBacnetObjectValue.value
        ));
      }
    }
    this.isWaitingForValues = false;
  }
}
