import PlcNotifications from "../../../base/pages/plcnotifications";

const plcNotifications: PlcNotifications = {
    header: '{name}: PLC notifikace',
    activeNotifications: 'Nevyřešené notifikace: {count}',
    resolvedNotifications: 'Vyřešené notifikace: {count}',
    resolveAllNotifications: 'Vyřešit všechny notifikace',
    history: {
        header: 'Historie PLC notifikací',
        occured: 'Čas výskytu',
        severity: {
            severity: 'Závažnost',
            information: 'Informace',
            warning: 'Varování',
            error: 'Chyba',
        },
        message: 'Zpráva',
        resolved: 'Vyřešeno',
        notResolved: 'Ne'
    }
}

export default plcNotifications;