import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[];
  label?: string;
  icon?: JSX.Element;
  invalid?: boolean;

  formGroupProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  formFieldProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

const FormFieldGroup = (props: Props) => {
  const groupClassNames = ["form__form-group"];
  props.formGroupProps?.className && groupClassNames.push(props.formGroupProps.className);
  const fieldClassNames = ["form__form-group-field"];
  props.icon && fieldClassNames.push("input-icon");
  props.invalid && fieldClassNames.push("invalid");
  props.formFieldProps?.className && fieldClassNames.push(props.formFieldProps.className);

  return (
    <div {...{
      ...props.formGroupProps,
      className: groupClassNames.join(" ")
    }}>
      {props.label
        ? <span className="form__form-group-label">{props.label}</span>
        : null}
      <div {...{
        ...props.formFieldProps,
        className: fieldClassNames.join(" ")
      }}>
        {
          props.icon &&
          <div className="form__form-group-field-icon">
            {props.icon}
          </div>
        }
        {props.children}
      </div>
    </div>
  )
};

export default FormFieldGroup;