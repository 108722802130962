enum TemperatureMode {
  Comfort = 1,
  Auto = 2,
  Cutback = 3
}

enum PanelTemperatureMode {
  Disabled = 0,
  Comfort = 1,
  Auto = 2,
  Cutback = 3
}

enum PressureMode {
  Normal = 0,
  Maximum = 1
}

enum LightState {
  Auto = 1,
  Off = 2,
  On = 3
}

enum DoorLockState {
  Auto = 1,
  Locked = 2,
  Unlocked = 3
}

enum ScheduleTypeEnum {
  Heating = 1,
  LightsMain1 = 2,
  LightsMain2 = 3,
  LightsMain3 = 4,
  LightsMain4 = 5,
  LightsAdditional1 = 6,
  LightsAdditional2 = 7,
  LightsAdditional3 = 8,
  LightsAdditional4 = 9,
  MainDoorLock = 10
}

export {
  TemperatureMode,
  PanelTemperatureMode,
  PressureMode,
  LightState,
  DoorLockState,
  ScheduleTypeEnum
};
