/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classNames from 'classnames';

interface CheckBoxFieldProps{
  onChange: (...args: any[]) => any;
  name: string;
  value: boolean;
  label?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  color?: string;
}

export default class CheckBoxField extends PureComponent<CheckBoxFieldProps> {
  static defaultProps = {
    value: true,
    label: '',
    defaultChecked: false,
    disabled: false,
    className: '',
    color: '',
  };

  public render() {
    const {
      disabled, className, name, value, onChange, label, color,
    } = this.props;
    const CheckboxClass = classNames({
      disabled,
      'checkbox-btn': true,
    });

    return (
      <label className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`}>
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <span
          className="checkbox-btn__checkbox-custom"
          style={color ? { background: color, borderColor: color } : {}}
        >
          <CheckIcon />
        </span>
        {className === 'button' ?
          <span className="checkbox-btn__label-svg">
            <CheckIcon className="checkbox-btn__label-check" />
            <CloseIcon className="checkbox-btn__label-uncheck" />
          </span> : ''}
        <span className="checkbox-btn__label">
          {label}
        </span>
      </label>
    );
  }
}
