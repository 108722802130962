import BaseStore, { Params } from './base/BaseStore';
import { ChangeRequestHubConnection, NotificationsService, PlcNotificationsHubConnection } from '../services';
import { action, observable, reaction } from 'mobx';

import AirdomeStore from './AirdomeStore';
import ErrorStore from './errors/ErrorStore';
import NotificationDto from '../dto/notification/NotificationDto';
import { catchError } from './decorators';

interface NotificationStoreParams {
  airdomeStore: AirdomeStore;
  notificationsService: NotificationsService;
  changeRequestHubConnection: ChangeRequestHubConnection;
  plcNotificationsHubConnection: PlcNotificationsHubConnection;
  errorStore: ErrorStore;
  getTrackingId: () => string;
}

export interface ObservedNotificationsState {
  internetOutage: boolean;
  wrongTemplate: boolean;
  plcConnectivity: boolean;
}

export default class NotificationStore extends BaseStore<NotificationStoreParams> {
  @observable
  public observedNotifications: ObservedNotificationsState = {
    internetOutage: false,
    wrongTemplate: false,
    plcConnectivity: false
  };

  public notifications = observable.array<NotificationDto>([]);
  
  @observable
  public resolvedNotificationsCount?: number;

  constructor(params: Params<NotificationStoreParams>) {
    super(params);
    super.setLoadPromise(async () => await this.fetchNotifications());

    params.plcNotificationsHubConnection.onNotificationOccurred(this.onNotificationOccurred);
    params.plcNotificationsHubConnection.onNotificationResolved(this.onNotificationResolved);
    params.plcNotificationsHubConnection.onNotificationsResolved(this.onNotificationsResolved);

    reaction(
      () => params.airdomeStore.SelectedAirdomeId,
      async (airdomeId) => {
        this.notifications.replace([]);
        this.setResolvedNotificationsCount(undefined);
        if (!airdomeId)
          return;

        await this.fetchNotifications();
      }
    );
  }

  @action
  private onNotificationOccurred = (airdomeId: number, notification: Readonly<NotificationDto>) =>
  {
    if(this.params.airdomeStore.SelectedAirdomeId !== airdomeId)
      return;

    this.notifications.push(notification);
  }

  @action
  private onNotificationResolved = (airdomeId: number, notification: Readonly<NotificationDto>) =>
  {
    if(this.params.airdomeStore.SelectedAirdomeId !== airdomeId)
      return;

    this.resolvedNotificationsCount = (this.resolvedNotificationsCount ?? 0) + 1;
    const notificationToResolve = this.notifications
      .find(x =>
        x.bacnetObjectId === notification.bacnetObjectId 
        && x.bacnetType === notification.bacnetType 
        && x.state === notification.state);

    if(notificationToResolve)
      this.notifications.remove(notificationToResolve);
  }
  
  @action
  private onNotificationsResolved = (airdomeId: number, notifications: Readonly<NotificationDto>[]) =>
  {
    if(this.params.airdomeStore.SelectedAirdomeId !== airdomeId)
      return;
    
    this.resolvedNotificationsCount = (this.resolvedNotificationsCount ?? 0) + notifications.length;
    
    for(const notification of notifications) {
      const notificationToResolve = this.notifications
        .find(x =>
          x.bacnetObjectId === notification.bacnetObjectId 
          && x.bacnetType === notification.bacnetType 
          && x.state === notification.state);

      if(notificationToResolve)
        this.notifications.remove(notificationToResolve);
    }
  }
  
  @action
  private setResolvedNotificationsCount(resolvedNotificationsCount: number | undefined) {
    this.resolvedNotificationsCount = resolvedNotificationsCount;
  }

  @catchError
  @action
  public fetchNotifications = async (trackingId: string = '') => {
    const selectedAirdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!selectedAirdomeId)
      return;

    const notifications = await this.params.notificationsService
      .getNotificationsForAirdome(selectedAirdomeId, trackingId);
      
    this.notifications.replace(notifications.activeNotifications);
    this.setResolvedNotificationsCount(notifications.totalCountOfResolvedNotifications);
  }

  @catchError
  @action
  public resolveNotification = async (notification: NotificationDto, trackingId: string = '') => {
    const selectedAirdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!selectedAirdomeId)
      return;

    await this.params.notificationsService
      .resolveNotificationForAirdome(
        selectedAirdomeId,
        {
          bacnetType: notification.bacnetType,
          bacnetObjectId: notification.bacnetObjectId
        },
        trackingId);
  }
  
  @catchError
  @action
  public resolveAllNotifications = async (trackingId: string = '') => {
    const selectedAirdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!selectedAirdomeId)
      return;

    await this.params.notificationsService
      .resolveAllNotificationsForAirdome(
        selectedAirdomeId,
        trackingId);
  }
}
