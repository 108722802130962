import Dialog from '../shared/components/Dialog';
import { ErrorModel } from '../models';
import React from 'react';
import Translate from '../localization/Translate';

interface ErrorPopupProps {
  translate: Translate;
  error?: ErrorModel;
  confirm?: () => void;
  close: () => void;
}

const errorPopup = (props: ErrorPopupProps) => {
  const { error, translate } = props;
  const translationData = error && error.localizationData;
  const translatedMessage = error && translate(
    x => x.errors.user.create[error.localizationKey],
    translationData);

  return <Dialog
    confirmButtonLabel={translate(x => x.general.ok)}
    cancelButtonLabel={translate(x => x.general.cancel)}
    confirm={props.confirm}
    close={props.close}
    isOpen={!!props.error}
    colored={true}
    color="danger"
    title={translatedMessage}
  />;
};

export default React.memo(errorPopup);
