import {
  MaximumValueField,
  MinimumValueField
} from '../fields/normal';
import PressureProps from '../PressureProps';
import React from 'react';

const NormalPressureFieldset = (props: PressureProps) => <>
  <MinimumValueField {...props} />
  <MaximumValueField {...props} />
</>;

export default NormalPressureFieldset;
