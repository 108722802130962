import Create from '../../../../base/forms/airdome/create';

const create: Create = {
  header: 'Halle hinzufügen',
  submit: 'Hinzufügen',
  supportAi: 'Künstliche Intelligenz aktivieren',
  cannotHaveAi: 'Die ausgewählte Bacnet-Vorlage unterstützt keine künstliche Intelligenzfunktion.',
  numberOfMainLights: 'Anzahl der Hauptlampen',
  numberOfAdditionalLights: 'Anzahl der Nebenlampen',
  cannotHaveLighting: 'Die ausgewählte Bacnet-Vorlage unterstützt keine Beleuchtung.',
  cannotHaveHeating: 'Die ausgewählte Bacnet-Vorlage unterstützt kein Heizen.',
  
  hasHeating: 'Heizung',
  hasPreheating: 'Vorflut',
  hasDoorLocking: 'Türverriegelung',
  hasElectricityConsumption: 'Strombrauch',
  hasGasConsumption: 'Gasverbrauch'
};

export default create;
