import ChangeRequest from '../../base/enums/ChangeRequest';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';

const changeRequest: ChangeRequest = {
  [ChangeRequestTypeEnum.Settings]: 'nastavení',
  [ChangeRequestTypeEnum.Intervals]: 'intervalů',
  [ChangeRequestTypeEnum.Schedule]: 'kalendáře',
  [ChangeRequestTypeEnum.Lights]: 'světel',
  [ChangeRequestTypeEnum.DoorLockSettings]: 'zámků dveří',
  [ChangeRequestTypeEnum.BacnetObjectWrite]: 'zapisování bacnet objektů',
  [ChangeRequestTypeEnum.LogLevel]: 'úroveň logování',
  [ChangeRequestTypeEnum.ResolveNotifications]: 'vyřešení notifikací',
  [ChangeRequestTypeEnum.PreheatingSettings]: 'předzátopu'
};

export default changeRequest;
