import Settings from '../../../../base/forms/airdome/settings';

const settings: Settings = {
  header: 'Settings',
  temperatureComfort: 'Comfort temperature (°C)',
  temperatureCutback: 'Cutback temperature (°C)',
  temperatures: 'Temperature modes',
  pressureNormal: 'Normal pressure (Pa)',
  pressureMax: 'Maximal pressure (Pa)',
  pressures: 'Pressure modes',
  lastUpdate: 'Last update',
  updatedBy: 'Updated by',
  requestState: 'Request state'
};

export default settings;
