import Actions from '../../base/actions';
import formNewChanges from './FormNewChanges';
import user from './User';

const actions: Actions = {
  user,
  formNewChanges
};

export default actions;
