import RDevNotifications from '../../../base/pages/rdevNotifications';
import message from './Message';
import type from './Type';

const rdevNotifications: RDevNotifications = {
  header: 'RDev Notifications',
  timestampHeader: 'Occured',
  typeHeader: 'Notification type',
  messageHeader: 'Message',
  
  type,
  message
};

export default rdevNotifications;
