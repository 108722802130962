import React from 'react';
import SettingsFormProps from '../SettingsFormProps';
import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import { observer } from 'mobx-react';

const UpdatedByField = ({ translate, settingsModel }: SettingsFormProps) => <FormInput {...{
  translate,
  model: new FormInputModel({
    placeholder: translate(x => x.forms.airdome.settings.updatedBy),
    handleValueModel: new HandleValueModel({
      setValue: () => {},
      inputType: 'text'
    }),
    readonly: true,
    label: translate(x => x.forms.airdome.settings.updatedBy),
    inputId: 'UpdatedBy',
    value: settingsModel.updatedBy || ''
  })
}} />;

export default observer(UpdatedByField);
