import {
  AirdomeDto,
  AirdomeListItemExtendedDto,
  CountryDto,
  IntervalsDto,
  LightDto,
  MacAddressDto
} from '../../dto/Container';
import { action, computed, observable } from 'mobx';

import AirdomeState from '../../localization/base/enums/AirdomeState';
import { Moment } from 'moment-timezone';

export default class AirdomeModel {
  @observable public id: number;
  @observable public name: string;
  @observable public timeZone: {
    iana: string;
    windows: string;
  };
  @observable public country: CountryDto;
  @observable public macAddresses: MacAddressDto[];
  @observable public lights: LightDto[];
  @observable public intervals: IntervalsDto;
  @observable public state?: keyof AirdomeState;
  @observable public lastContact?: Moment;
  @observable public notificationCount?: number;
  @observable public isInitialized: boolean;
  @observable public isInflated: boolean;

  @observable public hasHeating: boolean;
  @observable public hasElectricityConsumption: boolean;
  @observable public hasGasConsumption: boolean;
  @observable public hasDoorLocking: boolean;
  @observable public hasPreheating: boolean;

  @observable public templateId?: number;
  @observable public templateName?: string;
  @observable public projectNumber?: string;

  @computed
  public get hasLighting(): boolean {
    return this.lights.length > 0;
  }

  public constructor(dto: AirdomeDto | AirdomeListItemExtendedDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.timeZone = dto.timeZone;
    this.country = dto.country;
    this.macAddresses = dto.macAddresses;
    this.lights = dto.lights;
    this.intervals = dto.intervals;
    this.isInitialized = dto.isInitialized;
    this.isInflated = dto.isInflated;
    this.hasHeating = dto.hasHeating;
    this.hasElectricityConsumption = dto.hasElectricityConsumption;
    this.hasGasConsumption = dto.hasGasConsumption;
    this.hasDoorLocking = dto.hasDoorLocking;
    this.hasPreheating = dto.hasPreheating;
    
    const listItemExtendedDto = dto as AirdomeListItemExtendedDto;
    
    this.templateId = listItemExtendedDto.templateId;
    this.templateName = listItemExtendedDto.templateName;
    this.projectNumber = listItemExtendedDto.projectNumber;
  }

  @action
  public setState = (state?: keyof AirdomeState) => (this.state = state);

  @action
  public setIsInitialized = (isInitialized: boolean) =>
    (this.isInitialized = isInitialized);

  @action
  public setIsInflated = (isInflated: boolean) =>
    (this.isInflated = isInflated);

  @action
  public setLastContact = (lastContact?: Moment) =>
    (this.lastContact = lastContact);

  @action
  public setNotificationCount = (notificationCount: number) =>
    (this.notificationCount = notificationCount);
}
