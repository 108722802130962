import AirdomeState from '../../base/enums/AirdomeState';

const airdomeState: AirdomeState = {
  Alive: 'Halle erreichbar',
  HalfDead: 'Halle reagiert nicht',
  Dead: 'Halle nicht erreichbar',
  PlcUnavailable: 'PLC von Halle ist nicht verfügbar',
  NotInflated: 'Halle ist nicht aufgeblasen'
};

export default airdomeState;
