import AirdomeListItemModel from '../../models/AirdomeListItemModel';
import CircleIcon from 'mdi-react/CircleIcon';
import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';

const getColorForNotificationCount = (notificationCount: number) => {
  if (notificationCount >= 50)
    return 'circle-icon-black';
  if (notificationCount >= 20)
    return 'circle-icon-red';
  if (notificationCount > 0)
    return 'circle-icon-orange';
  return 'circle-icon-green';
};

const NotificationCell = (props: {
  item: AirdomeListItemModel,
  onClick: (item: AirdomeListItemModel) => void
}) => {
  const history = useHistory();
  return <span
    className="airdome-circle-span clickable-material-table-cell"
    onClick={() => props.onClick(props.item)}>
    <p className="clickable-material-table-cell" onClick={() => history.push(`/airdome/${props.item.id}/notifications/plc`)}>
      <CircleIcon size={16} className={`mr-1 ${getColorForNotificationCount(props.item.notifications)}`} />
      {props.item.notifications > 0 ? props.item.notifications : undefined}
    </p>
  </span>;
};

export default observer(NotificationCell);
