import Forms from '../../base/forms';
import user from './user';
import airdome from './airdome';

const forms: Forms = {
  airdome,
  user
};

export default forms;
