import AccessDefinitions from 'security/AccessDefinitions';
import AlertComponent from 'shared/components/Alert';
import NotificationDto from 'dto/notification/NotificationDto';
import NotificationSeverity from 'dto/notification/NotificationSeverity';
import React from 'react';
import { observer } from 'mobx-react';
import useSelectedAirdomeTime from 'common/AirdomeTimeHook';
import { useStore } from 'stores/MobXTypedStoreHook';

interface Props {
    key: React.Key | null
    notification: NotificationDto;
    onDismiss: {
        isDisabled: boolean;
        execute: () => void
    };
}

const AlertText = React.memo((props: { dateText: string, notificationText: string }) => <>
  <b>{props.dateText}</b>
  <p>{props.notificationText}</p>
</>);

const NotificationAlert = ({ key, notification, onDismiss } : Props) => {
    const securityStore = useStore(x => x.securityStore);
    const languageStore = useStore(x => x.languageStore);
    const {
      SelectedAirdome: airdome
    } = useStore(x => x.airdomeStore);

    const {
        toAirdomeTime
    } = useSelectedAirdomeTime();

    if(!airdome)
        return null;

    const getColorForSeverity = (severity: NotificationSeverity): 'info' | 'warning' | 'danger' => {
        switch (severity) {
            case NotificationSeverity.Information:
                return 'info';
            case NotificationSeverity.Warning:
                return 'warning';
            default:
            case NotificationSeverity.Error:
                return 'danger';
        }
    };

    return (
        <div className="col-xl-4 col-lg-6 col-md-12 d-inline-block p-2" key={key}>
            <AlertComponent
                className="alert--bordered"
                icon={true}
                color={getColorForSeverity(notification.severity)}
                onDismiss={securityStore.isAccessAllowed(AccessDefinitions.Notifications.Plc.Resolve.Single)
                    ? {
                        execute: onDismiss.execute,
                        isDisabled: onDismiss.isDisabled || airdome.state !== 'Alive'
                    }
                    : undefined}>
                <AlertText
                dateText={toAirdomeTime(notification.occurred).locale(languageStore.Locale).format('l LT')}
                notificationText={`${notification.description} - ${notification.value}`} />
            </AlertComponent>
        </div>
    );
}

export default observer(NotificationAlert);