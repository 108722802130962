import { AirdomeStore, ErrorStore } from "stores";
import { IReactionDisposer, action, observable, reaction } from "mobx";

import DoorLockSettingsDto from "dto/settings/DoorLockSettingsDto";
import DoorLockSettingsService from "services/settings/DoorLockSettingsService";
import PagingModel from "models/store/PagingModel";
import UserService from "services/identity/UserService";
import { asTracked } from "stores/decorators/TrackedDecorator";

const PanelUserId = "Panel";

export type DoorLockSettingsHistoryItem = Omit<DoorLockSettingsDto & { userName: string }, 'userId'>;

export default class DoorLockSettingsHistoryModel {
  private readonly errorStore: ErrorStore;
  private readonly getTrackingId: () => string;
  private readonly doorLockSettingsService: DoorLockSettingsService;
  private readonly airdomeStore: AirdomeStore;
  private readonly userService: UserService;

  public readonly pagingModel = new PagingModel();

  private fetchReactionDisposer: IReactionDisposer | undefined;

  public constructor(
    params: {
      errorStore: ErrorStore,
      getTrackingId: () => string,
      doorLockSettingsService: DoorLockSettingsService,
      airdomeStore: AirdomeStore,
      userService: UserService
    }) {
    this.errorStore = params.errorStore;
    this.getTrackingId = params.getTrackingId;
    this.doorLockSettingsService = params.doorLockSettingsService;
    this.airdomeStore = params.airdomeStore;
    this.userService = params.userService;
  }

  @observable
  public totalCount = 0;

  @observable
  public doorLockSettingsEntries: DoorLockSettingsHistoryItem[] = [];

  @action
  public clear = () => {
    this.doorLockSettingsEntries = [];
    this.totalCount = 0;
    this.pagingModel.clear();

    if(this.fetchReactionDisposer) {
      this.fetchReactionDisposer();
      this.fetchReactionDisposer = undefined;
    }
  }

  public setup = asTracked(action(() => {
    const airdomeId = this.airdomeStore.SelectedAirdomeId;
    if(!airdomeId) {
      this.errorStore.handleErrorModel(
        this.getTrackingId(),
        new Error('No airdome selected!')
      );
      return;
    }

    if(this.fetchReactionDisposer)
      return;

    this.fetchReactionDisposer = reaction(
      () => ({
        skip: this.pagingModel.Skip,
        take: this.pagingModel.Take
      }),
      ({ skip, take }) => this.fetch(airdomeId, skip, take),
      {
        fireImmediately: true
      });
  }));

  public readonly fetch = asTracked(action(async (airdomeId: number, skip: number, take: number) => {
    const trackingId = this.getTrackingId();

    const doorLockSettingsHistoryPaged = await this.doorLockSettingsService.fetchDoorLockSettingsHistory(
      airdomeId,
      skip,
      take,
      trackingId);

    const userIds = doorLockSettingsHistoryPaged.entities
      .map(x => x.userId)
      .filter(x => x !== PanelUserId);

    const userNames = userIds.length > 0 
      ? await this.userService.getUserNamesByIds(userIds, trackingId)
      : {};

    this.doorLockSettingsEntries = doorLockSettingsHistoryPaged.entities.map(doorLockSettings => ({
      ...doorLockSettings,
      userName: doorLockSettings.userId === PanelUserId
        ? doorLockSettings.userId
        : userNames[doorLockSettings.userId] ?? " - "
    }));

    this.totalCount = doorLockSettingsHistoryPaged.totalCount;
  }))
}