import { action, observable } from 'mobx';

import InputModel from 'models/InputModel';
import { LightState } from '../../dto/settings/enums';

export default class LightModel {
  public readonly propertyDefinitionId: number;

  @observable
  public name = new InputModel<string>();

  @observable
  public lightState = new InputModel<LightState>();

  constructor({ propertyDefinitionId, name, lightState }: { propertyDefinitionId: number, name: string, lightState: LightState }) {
    this.propertyDefinitionId = propertyDefinitionId;
    this.name.setValue(name, false);
    this.lightState.setValue(lightState, false);
  }

  @action
  public setLightState = (value: LightState) =>
    this.lightState.setValue(value)
}
