import AvTimerIcon from "mdi-react/AvTimerIcon"
import FormFieldGroup from "shared/components/form/FormFieldGroup"
import FormFieldGroupInputWrap from "shared/components/form/FormFieldGroupInputWrap"
import TimePicker from "rc-time-picker"
import TimeSpan from "dto/TimeSpan"
import moment from "moment"
import { useRef } from "react"

interface Props {
  value: TimeSpan | undefined;
  onChange: (value: TimeSpan) => void;
}

/**
 * For proper styling, the picker should be placed inside an element with *form* class.
 */
const ArcTimePicker = ({ value, onChange }: Props) => {
  const pickerElement = useRef<TimePicker>(null);

  return (
    <FormFieldGroup>
      <FormFieldGroupInputWrap>
        <TimePicker
          ref={pickerElement}
          className="rc-time-picker--icon"
          placeholder="LT"
          clearIcon={<></>}
          showSecond={false}
          hideDisabledOptions={true}
          use12Hours={false}
          minuteStep={5}
          value={value ? moment.utc(value.totalMilliseconds) : moment.min()}
          onChange={x => x && onChange(TimeSpan.from(0, x.hours(), x.minutes(), 0, 0))}
          inputIcon={<div className="rc-time-picker-icon clickable">
            <div className="form__form-group-icon"
              onClick={() => pickerElement.current && pickerElement.current.focus()}>
              <AvTimerIcon color="#ffffffff" />
            </div>
          </div>}
        />
      </FormFieldGroupInputWrap>
    </FormFieldGroup>
  )
}

export default ArcTimePicker;