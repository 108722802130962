import React from 'react';
import { inject, observer } from 'mobx-react';
import SecurityStore from '../stores/SecurityStore';
import AccessDefinitions, { AccessDefinition } from './AccessDefinitions';

interface ConditionalRenderingProps {
  securityStore?: SecurityStore;
  getAccessDefinition: (d: typeof AccessDefinitions) => AccessDefinition;
  fallbackChildren?: React.ReactNode | React.ReactNode[];
}

@inject('securityStore')
@observer
class ConditionalRendering extends React.Component<ConditionalRenderingProps> {
  public render() {
    const { securityStore, getAccessDefinition, fallbackChildren, children } = this.props;

    const accessDefiniton = getAccessDefinition(AccessDefinitions);

    const render = !!securityStore && securityStore.isAccessAllowed(accessDefiniton);

    return render
      ? children
      : fallbackChildren || null;
  }
}

export default ConditionalRendering;
