import * as uuid from 'uuid';

import BaseHubConnection from './BaseHubConnection';
import SecurityStore from 'stores/SecurityStore';
import format from 'string-format';
import { reaction } from 'mobx';

export default class PersistedHubConnection<TEvents extends string, TMethods extends string>
  extends BaseHubConnection<TEvents, TMethods>{
  constructor(connectionUrl: string, securityStore: SecurityStore) {
    super();
    
    reaction(
      () => ({
        accessToken: securityStore.AccessToken
      }),
      async ({ accessToken }) => {
        if(this.connection){
          await this.connection.stop();
          this.connection = undefined;
        }
        
        if(accessToken)
          await this.createConnection(format(connectionUrl, uuid.v4(), encodeURIComponent(accessToken)));
      }, 
      {
        fireImmediately: true
      });
  }
}
