import Menu from '../../base/menu';
import user from './User';
import airdome from './airdome';
import template from './Template';

const menu: Menu = {
  template,
  user,
  airdome
};

export default menu;
