import { Card, CardBody, Col, Row, Spinner } from "reactstrap";

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import ButtonLoading from 'shared/components/ButtonLoading';
import { ChangeRequestTypeEnum } from "dto/changeRequest/ChangeRequestTypeEnum";
import FireplaceIcon from 'mdi-react/FireplaceIcon';
import { FormInput } from "components";
import { FormInputModel } from "models";
import FormNewChangesDialog from "components/FormNewChangesDialog";
import { HandleValueModel } from 'models/component/HandleValueModel';
import { LinearProgress } from "@material-ui/core";
import { State } from "stores/shared/NewStatefulFunction";
import ToggleButton from "shared/components/form/ToggleButton";
import { observer } from "mobx-react"
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const PreheatingSettings = () => {
  const translate = useLocalization();
  const preheatingSettingsStore = useStore(x => x.preheatingSettingsStore);

  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  const {
    ChangeRequestStates: {
      PreheatingSettings: isPreheatingChangeRequestPending
    }
  } = useStore(x => x.changeRequestStore);

  const history = useHistory();

  if (!airdome)
    return null;

  if(!airdome.hasPreheating)
    history.push('/');

  if (!preheatingSettingsStore)
    return null;

  if (!airdome.hasPreheating)
    history.push('/');

  const isAnyUpdatePending = isPreheatingChangeRequestPending || preheatingSettingsStore.newChangesModel.isUpdatePending;
  const isPreheatingEnabled = preheatingSettingsStore.isPreheatingEnabledModel.Value || false;
  const fireplaceColor = isPreheatingEnabled ? '#4caf50' : '#666'

  return (
    <>
      <AirdomePageHeader
        icon={<span className='lnr lnr-lighter'></span>}
        title={`${airdome.name}: ${translate(x => x.pages.preheatingSettings.header)}`}
        additionalContent={<ButtonLoading
          onClick={async () => await preheatingSettingsStore.updatePreheatingSettings()}
          content={translate(x => x.general.update)}
          disabled={isAnyUpdatePending || !preheatingSettingsStore.hasAnyChanges}
          color="primary" />} />

      {preheatingSettingsStore.fetchPreheatingSettings.state !== State.Processing
        ?
        <Row>
          <Col sm={12} xl={3}>
            <Card>
              <CardBody>
                <div className="pb-4">
                  <FireplaceIcon style={{
                    position: 'relative',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'block',
                    width: '100%',
                    height: 150,
                    cursor: 'pointer',
                    fill: fireplaceColor
                  }} onClick={async () => {
                      if(!isAnyUpdatePending)
                        preheatingSettingsStore.isPreheatingEnabledModel.setValue(!isPreheatingEnabled, true) 
                  }} />
                </div>
                <Row className="justify-content-center ml-auto mr-auto" style={{ width: 180 }}>
                  <label className="col">{isPreheatingEnabled ? 'OFF' : <b>OFF</b>}</label>
                  <ToggleButton
                    name="Initialized"
                    disabled={isAnyUpdatePending}
                    value={isPreheatingEnabled}
                    onChange={x => preheatingSettingsStore.isPreheatingEnabledModel.setValue(x, true)}
                    className="col" />
                  <label className="col">{isPreheatingEnabled ? <b>ON</b> : 'ON'}</label>
                </Row>
                <div>
                  <FormInput {...{
                    model: new FormInputModel({
                      label: translate(t => t.pages.preheatingSettings.minimumPreheatingMinutes),
                      validationSchema: preheatingSettingsStore.minimalPreheatingMinutesValidationSchema.label(
                        translate(t => t.pages.preheatingSettings.minimumPreheatingMinutes)),
                      handleValueModel: new HandleValueModel({
                        setValue: (number: any) => {
                          if (number !== undefined)
                            preheatingSettingsStore.minimumPreheatingMinutes.setValue(Math.round(number * 10) / 10, true)
                          else
                            preheatingSettingsStore.minimumPreheatingMinutes.setValue(0, true)
                        },
                        inputType: 'number'
                      }),
                      inputId: 'MinimumPreheatingMinutes',
                      value: preheatingSettingsStore ? preheatingSettingsStore.minimumPreheatingMinutes.Value?.toString() : '',
                      readonly: isAnyUpdatePending,
                    })
                  }} />
                  <FormInput {...{
                    model: new FormInputModel({
                      label: translate(t => t.pages.preheatingSettings.maximalPreheatingMinutes),
                      validationSchema: preheatingSettingsStore.maximalPreheatingMinutesValidationSchema.label(
                        translate(t => t.pages.preheatingSettings.maximalPreheatingMinutes)),
                      handleValueModel: new HandleValueModel({
                        setValue: (number: any) => {
                          if (number !== undefined)
                            preheatingSettingsStore.maximalPreheatingMinutes.setValue(Math.round(number * 10) / 10, true)
                          else
                            preheatingSettingsStore.maximalPreheatingMinutes.setValue(0, true)
                        },
                        inputType: 'number'
                      }),
                      inputId: 'MaximalPreheatingMinutes',
                      value: preheatingSettingsStore ? preheatingSettingsStore.maximalPreheatingMinutes.Value?.toString() : '',
                      readonly: isAnyUpdatePending,
                    })
                  }} />
                </div>
                {
                  isAnyUpdatePending ?
                    <div className="overlay d-flex align-items-center justify-content-center">
                      <Spinner color='secondary' />
                    </div>
                    : null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        : <LinearProgress />}

      <FormNewChangesDialog
        newChangesModel={preheatingSettingsStore.newChangesModel}
        onConfirm={async () => await preheatingSettingsStore.fetchPreheatingSettings()}
        changeRequestType={ChangeRequestTypeEnum.PreheatingSettings}
      />
    </>
  );
}

export default observer(PreheatingSettings);