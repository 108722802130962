import {
  CreateUserModel,
  EditUserModel,
  NotificationOptionModel,
  SelectedUserModel,
  UserListItemModel
} from '../models';
import { action, observable } from 'mobx';
import { catchError, processingState } from './decorators';

import AirdomeStore from './AirdomeStore';
import ErrorStore from './errors/ErrorStore';
import LanguageStore from './LanguageStore';
import { StatefulFunction } from './shared/StatefulFunction';
import UserService from '../services/identity/UserService';

export interface UserStoreParams {
  languageStore: LanguageStore;
  airdomeStore: AirdomeStore;
  userService: UserService;
  errorStore: ErrorStore;
  getTrackingId: () => string;
}

export default class UserStore {
  @observable
  public createUserModel: CreateUserModel;

  @observable
  public editUserModel: EditUserModel;

  @observable
  public users: UserListItemModel[] = [];

  @observable
  public selectedUserModel?: SelectedUserModel = undefined;

  @observable
  public roleOptions: string[] = [];

  @observable
  public notificationsOptions: NotificationOptionModel[] = [];

  private params: UserStoreParams;

  constructor(params: UserStoreParams) {
    this.params = params;

    this.createUserModel = new CreateUserModel(this);
    this.editUserModel = new EditUserModel(this);
  }

  @action
  public setSelectedUserModel = (userModel?: SelectedUserModel) =>
    this.selectedUserModel = userModel

  @action
  public setUsers = (users: UserListItemModel[]) =>
    this.users = users

  @action
  public setRoleOptions = (value: string[]) =>
    this.roleOptions = value

  @action
  public setNotificationsOptions = (value: NotificationOptionModel[]) =>
    this.notificationsOptions = value

  @processingState
  @catchError
  @action
  public sendSelectedUsersAirdomes: StatefulFunction = async ( trackingId: string = ''): Promise<void> => {
    if (!this.selectedUserModel)
      return;

    const userId = this.selectedUserModel.user.id;
    const airdomeIds = this.selectedUserModel.SelectedAirdomeIds;

    await this.sendUsersAirdomes(userId, airdomeIds, trackingId);
  }

  @processingState
  @catchError
  @action
  public sendUsersAirdomes: StatefulFunction = async (userId: string, airdomeIds: number[], trackingId: string = ''): Promise<void> =>
    await this.params.userService.postUsersAirdomes(
      userId,
      airdomeIds.slice(),
      trackingId
    );

  @processingState
  @catchError
  @action
  public createUser: StatefulFunction = async (trackingId: string = ''): Promise<string> => {
    const userDto = this.createUserModel.getDto();
    if (!userDto)
      throw new Error();

    const userId = await this.params.userService.createUser(userDto, trackingId);
    await this.params.userService
      .updateUserNotificationChannel(userId, this.createUserModel.notifications!, trackingId);

    this.createUserModel.clear();

    return userId;
  }

  @catchError
  @processingState
  @action
  public updateUser: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const userDto = this.editUserModel.getDto();
    if (!userDto)
      throw new Error();

    await this.params.userService.updateUser(this.editUserModel.id!, userDto, trackingId);

    if (!this.fetchUserDetail.alerts!.find(x => x.key === 'NotificationChanneUnableToLoad'))
      await this.params.userService.updateUserNotificationChannel(
        this.editUserModel.id!, this.editUserModel.notifications!, trackingId);

    this.updateUserInList(this.editUserModel.id!, this.editUserModel.username!);
  }
  
  @action
  private updateUserInList = (id: string, newUsername: string) => {
    const users = this.users.slice();
    const updatedUser = users.find(x => x.id === id);
    if (updatedUser) {
      updatedUser.username = newUsername;
      this.setUsers(users);
    }
  }

  @catchError
  @processingState
  @action
  public deleteUser: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    if (!this.selectedUserModel || !this.selectedUserModel.user.id)
      return;

    const userId = this.selectedUserModel.user.id;
    await this.params.userService.deleteUser(userId, trackingId);
    this.setSelectedUserModel();

    const currentUsers = this.users.slice();
    const removedUserIndex = currentUsers.findIndex(x => x.id === userId);
    currentUsers.splice(removedUserIndex, 1);
    this.setUsers(currentUsers);
  }

  @catchError
  @processingState
  @action
  public unlockUser: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    if (!this.selectedUserModel || !this.selectedUserModel.user.id)
      return;

    const userId = this.selectedUserModel.user.id;
    await this.params.userService.unlockUser(userId, trackingId);
    this.selectedUserModel.user.isLocked = false;
  }

  @catchError
  @processingState
  @action
  public lockUser: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    if (!this.selectedUserModel || !this.selectedUserModel.user.id)
      return;

    const userId = this.selectedUserModel.user.id;
    await this.params.userService.lockUser(userId, trackingId);
    this.selectedUserModel.user.isLocked = true;
  }

  @catchError
  @action
  public fetchUsersAirdomes = async (trackingId: string = ''): Promise<void> => {
    if (!this.selectedUserModel || !this.selectedUserModel.user.id)
      return;

    const airdomes = await
      this.params.userService.getUsersAirdomes(this.selectedUserModel.user.id);

    const allAirdomes = this.params.airdomeStore.airdomes.slice();
    const selectedAirdomeIds = allAirdomes.filter(x => airdomes.some(y => y.id === x.id)).map(x => x.id);

    this.selectedUserModel.setSelectedAirdomeIds(selectedAirdomeIds);
  }

  @catchError
  @action
  public fetchUserDetail: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    if (!this.selectedUserModel || !this.selectedUserModel.user.id)
      return;

    const userDto = await this.params.userService.getUserDetail(
      this.selectedUserModel.user.id,
      trackingId
    );
    this.editUserModel.importFromDto(userDto);

    try {
      const notificationsDto = await this.params.userService
        .getUserNotificationSetting(this.selectedUserModel.user.id, trackingId);

      this.editUserModel.importNotificationChannel(notificationsDto.id.toString());
      this.fetchUserDetail.alerts = [];
    } catch (err) {
      this.fetchUserDetail.alerts!.push({
        level: 'warning',
        key: 'NotificationChanneUnableToLoad',
        translation: x => x.errors.user.notificationChannelUnableToLoad
      });
    }
  }

  @action
  public clearEditUserModelPasswords = () => {
    this.editUserModel.setPassword('');
    this.editUserModel.setConfirmPassword('');
  }

  @catchError
  @action
  public fetchUsers = async (trackingId: string = ''): Promise<void> => {
    const userDtos = await this.params.userService.getUsers(trackingId);
    if (userDtos.length > 0)
      this.setUsers(userDtos.map(x => new UserListItemModel({
        id: x.id,
        username: x.username,
        isLocked: x.isLocked
      })));
  }

  @catchError
  @action
  public fetchRoleOptions = async (trackingId: string = '') => {
    const roles = await this.params.userService.getRoleOptions(trackingId);
    this.setRoleOptions(roles);
  }

  @catchError
  @action
  public fetchNotificationsOptions = async (trackingId: string = '') => {
    const notificationsDto = await this.params.userService.getNotificationOptions(trackingId);
    const notificationModels = notificationsDto.map(x => new NotificationOptionModel(x));
    this.setNotificationsOptions(notificationModels.sort((a, b) => b.id - a.id));
  }
}
