import SupportedLocale, { supportedLocales } from 'enums/SupportedLocale';
import { action, computed, observable } from 'mobx';

import MissingTranslationError from '../localization/MissingTranslationError';
import Translate from '../localization/Translate';
import format from 'string-format';
import moment from 'moment';
import { setLocale } from 'yup';
import translations from '../localization/translations';

const savedLocaleKey = 'rezzy_locale';

export default class LanguageStore {
  @observable
  private locale: SupportedLocale = SupportedLocale.enGB;

  @observable
  public translate!: Translate;

  constructor() {
    const savedLocaleValue = window.localStorage.getItem(savedLocaleKey);
    
    const savedLocale = savedLocaleValue
      ? supportedLocales.find(x => x.toLowerCase() === savedLocaleValue.toLowerCase())
      : undefined;

    const toIsoTwoLetterCode = (locale: string) => locale.toLowerCase().substr(0, 2);

    const browserLocale = window.navigator.language
      ? supportedLocales.find(x => toIsoTwoLetterCode(x) === toIsoTwoLetterCode(window.navigator.language))
      : undefined;

    this.setLocale(savedLocale || browserLocale || SupportedLocale.enGB);
  }

  @computed
  public get Locale(): SupportedLocale {
    return this.locale;
  }

  @action
  public setLocale = (supportedLocale: SupportedLocale) => {
    this.locale = supportedLocale;

    const translation = translations.getTranslation(this.locale);

    this.setTranslate((getTranslation, data) => {
      const translatedText = getTranslation(translation!);
      if (typeof translatedText !== 'string')
        throw new MissingTranslationError(this.locale, getTranslation);

      return data
        ? format(translatedText, data)
        : translatedText;
    });

    moment.locale(this.locale.split(/-/)[0]);

    if (translation.validations)
      setLocale(translation.validations);
    
    window.localStorage.setItem(savedLocaleKey, supportedLocale.toLowerCase());
  }

  @action
  private setTranslate = (translate: Translate) => {
    this.translate = translate;
  }
}
