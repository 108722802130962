import Airdome from '../../../base/general/airdome';

const airdome: Airdome = {
  template: 'Šablona bacnet definice',
  name: 'Název',
  country: 'Země',
  timeZone: 'Časové pásmo',
  macAddresses: 'MAC adresy přiřazených zařízení',
  macAddress: 'MAC adresa přiřazeného zařízení',
  projectNumber: 'Číslo projektu',
  intervalHeader: 'Intervaly',
  logLevel: 'Úroveň logování',
  infoHeader: 'Info',
  unavailableTemplate: '{airdomeName} neodpovídá.'
};

export default airdome;
