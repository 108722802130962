import './DoorLockSettingsHistory.scss';

import { Card, CardBody } from 'reactstrap';
import React, { useEffect } from 'react';

import AirdomePageHeader from 'components/basic/AirdomePageHeader';
import AirdomeTime from 'components/airdome/AirdomeTime';
import { DoorLockSettingsHistoryItem } from 'models/doorlocksettings/DoorLockSettingsHistoryModel';
import { MaterialTable } from 'components';
import { TablePagination } from '@material-ui/core';
import { observer } from "mobx-react";
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const DoorLockSettingsHistory = () => {
  const {
    doorLockSettingsHistoryModel,
    mainDoorsModel: {
      name: {
        Value: mainDoorsName
      }
    }
  } = useStore(x => x.doorLockSettingsStore);

  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  useEffect(() => {
    doorLockSettingsHistoryModel.setup();

    return doorLockSettingsHistoryModel.clear;
  }, [doorLockSettingsHistoryModel, airdome]);

  const translate = useLocalization();
  const history = useHistory();
  
  if(!airdome || !mainDoorsName)
    return null;
  
  if(!airdome.hasDoorLocking)
    history.push('/');

  return (
    <>
      <AirdomePageHeader title={`${airdome.name}: ${translate(x => x.pages.lighting.history.subHeader)}`}/>
      <Card className="LightingHistoryCard">
        <CardBody>
          <MaterialTable<DoorLockSettingsHistoryItem>
            disableSorting
            showPagination={false}
            showCheckboxColumn={false}
            clickRowToSelect={false}
            multiselect={false}
            data={doorLockSettingsHistoryModel.doorLockSettingsEntries}
            columns={[
              {
                id: 'mainDoors',
                disablePadding: false,
                label: mainDoorsName,
                renderCell: (item: DoorLockSettingsHistoryItem) => (
                  <p className="text-center">
                    {translate(x => x.enums.doorLockState[item.mainDoorLock].full)}
                  </p>
                ),
                getValue: (item: DoorLockSettingsHistoryItem) => item.mainDoorLock.toString()
              },
              {
                id: 'timestamp',
                disablePadding: false,
                label: translate(x => x.pages.lighting.history.timestampHeader),
                renderCell: (item: DoorLockSettingsHistoryItem) => <p className="text-center"><AirdomeTime time={item.timestamp}/></p>,
                getValue: (item: DoorLockSettingsHistoryItem) => item.timestamp.toString()
              },
              {
                id: 'updatedBy',
                disablePadding: false,
                label: translate(x => x.pages.lighting.history.updatedByHeader),
                renderCell: (item: DoorLockSettingsHistoryItem) => <p className="text-center">{item.userName}</p>,
                getValue: (item: DoorLockSettingsHistoryItem) => item.userName
              }
            ]}/>
          {doorLockSettingsHistoryModel.totalCount > 0 && <TablePagination
            component="div"
            className="material-table__pagination mt-2"
            count={doorLockSettingsHistoryModel.totalCount}
            rowsPerPage={doorLockSettingsHistoryModel.pagingModel.rowsPerPage}
            page={doorLockSettingsHistoryModel.pagingModel.currentPage}
            backIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.previousPage) }}
            nextIconButtonProps={{ 'aria-label': translate(x => x.general.datatables.nextPage) }}
            onChangePage={(_, page) => doorLockSettingsHistoryModel.pagingModel.setCurrentPage(page)}
            onChangeRowsPerPage={event => doorLockSettingsHistoryModel.pagingModel.setRowsPerPage(Number(event.target.value))}
            rowsPerPageOptions={[
              { value: 10, label: "10" },
              { value: 20, label: "20" },
              { value: 50, label: "50" }
            ]}
            labelRowsPerPage={translate(x => x.general.datatables.numberOfRowsShown)}
            labelDisplayedRows={(paginationInfo): React.ReactNode => translate(x => x.general.datatables.displayedRows,
              {
                from: paginationInfo.from,
                to: paginationInfo.to,
                page: paginationInfo.page + 1,
                count: paginationInfo.count
              })
            }
          />}
        </CardBody>
      </Card>
    </>
  );
}

export default observer(DoorLockSettingsHistory);