import BasePressureField from '../BasePressureField';
import PressureProps from '../../PressureProps';
import React from 'react';
import { observer } from 'mobx-react';

const MinimumValueField =
  ({ translate, model, readonly }: PressureProps) =>
    <BasePressureField {...{
      readonly,
      numberValidationSchema: model.normalPressureMinSchema.label(
        translate(x => x.forms.airdome.restrictions.pressure.normalPressureMin)
      ),
      inputId: 'LowerBound_PressureNormal_Field',
      label: translate(x => x.forms.airdome.restrictions.pressure.normalPressureMin),
      setValue: x => model.normalPressureMin.setValue(x),
      value: model.normalPressureMin.Value
    }} />;

export default observer(MinimumValueField);
