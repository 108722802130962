import Pressure from '../../../../base/forms/airdome/restrictions/Pressure';

const pressure: Pressure = {
  header: 'Restrict range of pressure settings for air dome',
  normalPressureMin: 'Minimal value of normal pressure (Pa)',
  normalPressureMax: 'Maximal value of normal pressure (Pa)',
  maximalPressureMin: 'Minimal value of maximal pressure (Pa)',
  maximalPressureMax: 'Maximal value of maximal pressure (Pa)'
};

export default pressure;
