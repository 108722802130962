import Template from '../../base/menu/Template';

const template: Template = {
  categoryName: 'Manage Templates',
  create: 'Add',
  edit: 'Edit',
  list: 'Templates'
};

export default template;
