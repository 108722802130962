import { AxiosRequestConfig } from 'axios';

class AxiosRequestConfigBuilder {
  public buildJsonContentTypeHeaders = (baseHeaders: {}): AxiosRequestConfig => {
    const headers = { ...baseHeaders, 'Content-Type': 'application/json' };

    return { headers };
  }
}

export default new AxiosRequestConfigBuilder();
