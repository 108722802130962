import User from '../../../base/general/user';

const user: User = {
  username: 'Name',
  password: 'Passwort',
  userIsLocked: 'Konto ist gesperrt',
  passwordAgain: 'Passwort bestätigen',
  email: 'Email',
  role: 'Zugriff',
  notifications: 'Erhalten Sie Benachrichtigungen der Traglufthalle',
  passwordsNotSame: 'Passwörter stimmen nicht überein',
  logout: 'Abmelden'
};

export default user;
