import { Button, ButtonToolbar, Modal } from 'reactstrap';

import ButtonLoading from './ButtonLoading';
import { PureComponent } from 'react';
import classNames from 'classnames';

interface DialogComponentProps {
  color: 'primary' | 'success' | 'warning' | 'danger';
  isOpen: boolean;

  cancelButtonLabel: string;
  confirmButtonLabel?: string;

  confirm?: () => any | Promise<any>;
  close?: () => any;
  title?: string;
  message?: string;
  colored?: boolean;
  header?: boolean;
}

export default class Dialog extends PureComponent<DialogComponentProps> {
  public static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
  };

  public render() {
    const {
      color, title, message, colored, header, isOpen, close, confirm, cancelButtonLabel, confirmButtonLabel
    } = this.props;
    let icon: JSX.Element | undefined;

    switch (color) {
      case 'primary':
        icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <Modal
        isOpen={isOpen}
        toggle={close}
        className={`dialog modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" onClick={close} />
          {header ? '' : icon}
          <h4 className="bold-text  modal__title">{title}</h4>
        </div>
        <div className="modal__body">
          {message}
        </div>
        <ButtonToolbar className="modal__footer">
          {confirm ? <>
            <ButtonLoading
              outline={colored}
              color={color}
              content={confirmButtonLabel ?? ''}
              onClick={async () => await confirm()} />
            <Button onClick={close}>{cancelButtonLabel}</Button>{' '}
          </>
            : <Button outline={colored} color={color} onClick={close}>{cancelButtonLabel}</Button>
          }
        </ButtonToolbar>
      </Modal>
    );
  }
}
