import BacnetObjects from "../../../base/pages/bacnetObjects";

const calendar: BacnetObjects = {
  readTitle: "Bacnet-Objekte anzeigen",
  writeTitle: "Bearbeiten von Bacnet-Objekten",
  selectObjects: "Objekte auswählen",
  bacnetObject: "Bacnet-Objekte",
  addToSelection: "Zur Auswahl hinzufügen ",
  selectedObjects: "ausgewählte Objekte",
  bacnetType: "Typ",
  bacnetName: "Name",
  objectValues: "Objektwerte",
  sendRequest: "Anfrage senden",
  noSelectedObjects: "Keine Objekte ausgewählt",
  bacnetValue: "Wert"
};

export default calendar;
