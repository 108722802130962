import React, { Component } from 'react';
import { TableColumn, TableHeader } from './table-header';
import { inject, observer } from 'mobx-react';

import LanguageStore from '../../../stores/LanguageStore';
import { MatTableMenuItem } from './TableFilterButton';
import MatTableToolbar from './TableToolbar';
import MaterialTableBody from './MaterialTableBody';
import { MaterialTableStore } from './MaterialTableStore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import objectHelper from '../../../utils/ObjectHelper';

interface MaterialTableProps<TRow> {
  languageStore?: LanguageStore;

  columns: TableColumn<TRow>[];
  data: TRow[];
  showPagination: boolean;
  rowsPerPageOptions?: number[];
  selected?: TRow[];

  search?: string;
  searchIn?: TableColumn<TRow>[];
  showCheckboxColumn: boolean;
  clickRowToSelect: boolean;
  disableSorting?: boolean;

  multiselect: boolean;

  menuItems?: MatTableMenuItem[];

  handleDeleteSelected?: (selected: TRow[]) => void;
  handleItemClick?: (item: TRow) => void;
  handleSelectionChanged?: (selected: TRow[]) => void;
}

@inject('languageStore')
@observer
export default class MaterialTable<TRow> extends Component<MaterialTableProps<TRow>> {
  private tableStore = new MaterialTableStore<TRow>(this.props.selected);

  constructor(props: MaterialTableProps<TRow>) {
    super(props);

    props.selected && this.tableStore.setSelected(props.selected);
  }

  private search = (item: TRow): boolean => {
    const { search, searchIn } = this.props;

    if (!search || !searchIn)
      return true;

    return searchIn
      .reduce<boolean>((result, column, index) =>
        result || column
          .getValue(item, index)
          .toLowerCase()
          .includes(search.toLowerCase())
        , false);
  }

  private getSorting = (order?: 'asc' | 'desc', orderBy?: string): ((a: TRow, b: TRow) => number) => {
    if (orderBy && order) {
      const invert = order === 'desc' ? 1 : -1;
      return (a: TRow, b: TRow) => {
        const column = this.props.columns.find(x => x.id === orderBy);

        let valueA;
        let valueB;
        if (column && column.getValue) {
          valueB = column.getValue(b);
          valueA = column.getValue(a);
        } else {
          valueB = objectHelper.getValueForProperty(b, orderBy);
          valueA = objectHelper.getValueForProperty(a, orderBy);
        }

        return valueB > valueA ? invert : -invert;
      };
    }

    return () => 0;
  }

  private handleRequestSort = (event: React.MouseEvent<HTMLElement, MouseEvent>, property: string) => {
    const order = this.tableStore.order === 'asc' ? 'desc' : 'asc';
    this.tableStore.setOrder(order);
    this.tableStore.setOrderBy(property);
  }

  private handleSelectAllClick = () => {
    const { data, handleSelectionChanged } = this.props;
    handleSelectionChanged && handleSelectionChanged(data);
    if (this.tableStore.selected.length === data.length)
      this.tableStore.setSelected([]);
    else
      this.tableStore.setSelected(data);
  }

  private onChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.tableStore.setPage(page);
  }

  private onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (Number(event.target.value))
      this.tableStore.setRowsPerPage(Number(event.target.value));
  }

  private isSelected = (item: TRow) => {
    const { selected } = this.tableStore;
    return !!selected.length && selected.some(x => x === item);
  }

  private switchSelection = (item: TRow, checked?: boolean) => {
    const { handleSelectionChanged, multiselect } = this.props;

    if (multiselect)
      this.switchSelectionInMultiselect(item, checked);
    else this.tableStore.setSelected([item]);

    const selected = this.tableStore.selected.slice();
    handleSelectionChanged && handleSelectionChanged(selected);
  }

  private switchSelectionInMultiselect = (item: TRow, checked?: boolean) => {
    const selected = this.tableStore.selected.slice();
    const isSelected = this.isSelected(item);
    if (isSelected && (checked === undefined || !checked)) {
      const index = selected.findIndex(x => x === item);
      selected.splice(index, 1);
      this.tableStore.setSelected(selected);
    } else if (!isSelected && checked) {
      selected.push(item);
      this.tableStore.setSelected(selected);
    }
  }

  public render() {
    const {
      languageStore,
      data,
      menuItems,
      handleDeleteSelected,
      columns,
      showCheckboxColumn,
      rowsPerPageOptions,
      showPagination,
      disableSorting,
      ...restOfProps
    } = this.props;
    const {
      selected,
      order,
      orderBy,
      page,
      rowsPerPage
    } = this.tableStore;

    const translate = languageStore!.translate;

    return <>
      {menuItems && <MatTableToolbar
        menuItems={menuItems}
        numSelected={selected.length}
        handleDeleteSelected={() => handleDeleteSelected && handleDeleteSelected(selected)}
        onRequestSort={this.handleRequestSort}
      />}
      <div className="material-table__wrap">
        <Table className="material-table">
          <TableHeader<TRow>
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={this.handleSelectAllClick}
            onRequestSort={this.handleRequestSort}
            rowCount={data.length}
            showCheckboxColumn={showCheckboxColumn}
            disableSorting={disableSorting}
          />
          <TableBody>
            <MaterialTableBody
              {... {
                ...restOfProps,
                showPagination,
                columns,
                page,
                rowsPerPage,
                showCheckboxColumn,
                search: this.search,
                sortComparer: this.getSorting(order, orderBy),
                switchSelection: this.switchSelection,
                data: data.map(x => ({
                  item: x,
                  isSelected: this.isSelected(x)
                }))
              }}
            />
          </TableBody>
        </Table>
      </div>
      {showPagination && <TablePagination
        component="div"
        className="material-table__pagination mt-2"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ 'aria-label': translate && translate(x => x.general.datatables.previousPage) }}
        nextIconButtonProps={{ 'aria-label': translate && translate(x => x.general.datatables.nextPage) }}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50]}
        labelRowsPerPage={translate && translate(x => x.general.datatables.numberOfRowsShown)}
        labelDisplayedRows={paginationInfo => translate && translate(x => x.general.datatables.displayedRows,
          {
            from: paginationInfo.from,
            to: paginationInfo.to,
            page: paginationInfo.page + 1,
            count: paginationInfo.count
          })
        }
      />}
    </>;
  }
}
