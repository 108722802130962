enum SwitchBoxTablePropertyName {  
  DomeHeightUpperBound = 'DomeHeightUpperBound',
  DomeHeightLowerBound = 'DomeHeightLowerBound',
  DomeHeightAlarmHysteresis = 'DomeHeightAlarmHysteresis',
  WindSpeedUpperBound = 'WindSpeedUpperBound',
  WindSpeedLowerBound = 'WindSpeedLowerBound',
  WindSpeedAlarmHysteresis = 'WindSpeedAlarmHysteresis',
  ComputedPressureValue = 'ComputedPressureValue',
  ZesileniRegulaceP = 'Zesileni regulace P',
  IntegracniRegulaceP = 'Integracni regulace P',
  MaximalEmergencyPressure = 'MaximalEmergencyPressure',
  LastPreheatingDurationDeviation = 'LastPreheatingDurationDeviation',
  PredictedPreheatingDuration = 'PredictedPreheatingDuration'
}

export const switchBoxTablePropertyNames = [
  SwitchBoxTablePropertyName.DomeHeightUpperBound,
  SwitchBoxTablePropertyName.DomeHeightLowerBound,
  SwitchBoxTablePropertyName.DomeHeightAlarmHysteresis,
  SwitchBoxTablePropertyName.WindSpeedUpperBound,
  SwitchBoxTablePropertyName.WindSpeedLowerBound,
  SwitchBoxTablePropertyName.WindSpeedAlarmHysteresis,
  SwitchBoxTablePropertyName.ComputedPressureValue,
  SwitchBoxTablePropertyName.ZesileniRegulaceP,
  SwitchBoxTablePropertyName.IntegracniRegulaceP,
  SwitchBoxTablePropertyName.MaximalEmergencyPressure,
  SwitchBoxTablePropertyName.LastPreheatingDurationDeviation,
  SwitchBoxTablePropertyName.PredictedPreheatingDuration
]

export default SwitchBoxTablePropertyName;