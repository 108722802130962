import { action, computed, observable } from 'mobx';

import AirdomeListItemModel from '../models/AirdomeListItemModel';
import AirdomeStore from './AirdomeStore';

interface AirdomeListStoreParams {
  airdomeStore: AirdomeStore;
}

export default class AirdomeListStore {
  private airdomeStore: AirdomeStore;

  @observable
  public airdomeSearch: string = '';

  public get UserHasAirdomeManageRights(): boolean {
    return this.airdomeStore.userHasAirdomeManageRights;
  }

  constructor(params: AirdomeListStoreParams) {
    this.airdomeStore = params.airdomeStore;
  }

  @action
  public setAirdomeSearch = (value: string) =>
    this.airdomeSearch = value

  @computed
  public get Airdomes(): AirdomeListItemModel[] {
    return this.airdomeStore.airdomes.map(x =>
      new AirdomeListItemModel({
        id: x.id,
        name: x.name,
        availability: x.state || 'Dead',
        lastContact: x.lastContact,
        country: x.country,
        projectNumber: x.projectNumber,
        templateName: x.templateName,
        notifications: x.notificationCount || 0
      }));
  }
}
