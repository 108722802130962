import { Moment } from 'moment';
import NotificationSeverity from 'dto/notification/NotificationSeverity';
import NotificationsHistoryItemDto from 'dto/notification/NotificationsHistoryItemDto';

export default class NotificationsHistoryRowData {
  public readonly severity: NotificationSeverity;
  public readonly message: string;
  public readonly occured: Moment;
  public readonly resolved?: Moment;

  constructor(item: NotificationsHistoryItemDto) {
    this.severity = item.severity;
    this.message = item.description;
    this.occured = item.occurred;
    this.resolved = item.resolved;
  }
}