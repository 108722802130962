import { AxiosInstance } from 'axios';
import FullSettingsDto from '../../dto/settings/FullSettingsDto';
import PagingDto from 'services/shared/dtos/PagingDto';
import SettingsDto from '../../dto/settings/SettingsDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class SettingsService {
  private axios: AxiosInstance;
  private updateSettingsUrl: string;
  private getSettingsUrl: string;
  private getSettingsHistoryUrl: string;
  private restartRDevUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.updateSettingsUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.settings.update);
    this.getSettingsUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.settings.get);
    this.getSettingsHistoryUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.settings.getHistory);
    this.restartRDevUrl = getJoinedEndpoint(x => x.settings.baseUri, x => x.settings.restartRDev);
  }

  public fetchSettings = async (airdomeId: number, trackingId: string)
    : Promise<FullSettingsDto> => {
    try {
      const url = stringFormat(this.getSettingsUrl, airdomeId.toString());
      const result = await this.axios.get<FullSettingsDto>(url, {
        headers: { trackingId }
      });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public fetchSettingsHistory = async (airdomeId: number, skip: number, take: number, trackingId: string) 
    : Promise<PagingDto<FullSettingsDto>> => {
      try {
        const url = stringFormat(this.getSettingsHistoryUrl, airdomeId.toString(), skip.toString(), take.toString());
        const result = await this.axios.get<PagingDto<FullSettingsDto>>(url, {
          headers: { trackingId }
        });
        return result.data;
      } catch (error) {
        throw getBackendException(error);
      }
  }

  public updateSettings = async (updatedSettings: SettingsDto, airdomeId: number, trackingId: string)
    : Promise<void> => {
    try {
      const url = stringFormat(this.updateSettingsUrl, airdomeId.toString());
      await this.axios.put(url, updatedSettings, {
        headers: { trackingId }
      });
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public restartRDev = async (airdomeId: number, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.restartRDevUrl, airdomeId.toString());
      await this.axios.put(url, undefined, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
