import { Card, CardBody } from "reactstrap";

import React from "react";

interface Props {
  title: string;
  icon?: JSX.Element;
  additionalContent?: JSX.Element;
}

const AirdomePageHeader = ({ icon, title, additionalContent }: Props) => (
  <Card className="airdome-page-header">
    <CardBody className="p-3">
      <div className="d-flex">
        <h2 className="d-flex align-items-center">
          {icon}
          <span className="ml-3">
            {title}
          </span>
        </h2>
        <div className="ml-auto mb-0">
          {additionalContent}
        </div>
      </div>
    </CardBody>
  </Card>
)

export default AirdomePageHeader;