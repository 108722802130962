import { Card, CardBody } from 'reactstrap';

import AirdomeAlive from '../../content/images/airdome-alive.svg';
import AirdomeCountryFlag from '../basic/AirdomeCountryFlag';
import AirdomeDead from '../../content/images/airdome-dead.svg';
import AirdomeHalfDead from '../../content/images/airdome-half-dead.svg';
import AirdomeNotInflated from '../../content/images/airdome-not-inflated.svg';
import AirdomeState from '../../localization/base/enums/AirdomeState';
import AirdomeStateExpand from '../airdome/AirdomeStateExpand';
import React from 'react';
import Translate from '../../localization/Translate';
import { observer } from 'mobx-react';
import useSelectedAirdomeTime from 'common/AirdomeTimeHook';

const getAvailabilityIcon = (state: keyof AirdomeState): string => {
  switch (state) {
    case 'Alive': return AirdomeAlive;
    case 'Dead': return AirdomeDead;
    case 'HalfDead': return AirdomeHalfDead;
    default: case 'NotInflated': return AirdomeNotInflated;
  }
};

interface PropertiesBoxProps {
  airdomeState: keyof AirdomeState;
  airdome?: {
    name: string;
    projectNumber?: string;
    countryShortcut: string;
  };
  statusTimestamp?: Date;
  locale: string;
  translate: Translate;
  onStatusForce: () => Promise<void>;
}

const PropertiesBox = (props: PropertiesBoxProps) => {
  const {
    toAirdomeTime
  } = useSelectedAirdomeTime();
  
  const getDateText = (locale: string, date: Date, translate: Translate) => {
    const dateText = translate(x => x.pages.status.lastUpdate);
    const dateValue = toAirdomeTime(date).locale(locale).format('l LT');
    return `${dateText}: ${dateValue}`;
  };

  return <Card>
    <CardBody>
      <div className="airdome-icon d-flex flex-wrap">
        <img className="ml-2" src={getAvailabilityIcon(props.airdomeState)} />
        <div className="header ml-2 order-2 order-lg-1">
          <h2>{props.airdome && props.airdome.name}</h2>
          <h5>{props.airdome && props.airdome.projectNumber}</h5>
        </div>
        <div className="ml-2 order-lg-2 order-1">
          <AirdomeCountryFlag {...{
            translate: props.translate,
            className: 'airdome-country-flag',
            languageCode: props.airdome
              ? props.airdome.countryShortcut.toLowerCase()
              : undefined
          }} />
        </div>
        <div className="ml-auto refresh order-3">
          <AirdomeStateExpand 
            className="mb-0"
            onClick={props.onStatusForce}
            enabledTitle={props.translate(x => x.pages.status.forceRefresh)}/>
        </div>
      </div>
      <div className="last-update">
        {props.statusTimestamp
          ? <p>{getDateText(props.locale, props.statusTimestamp, props.translate)}</p>
          : null}
      </div>
    </CardBody>
  </Card>
}

export default observer(PropertiesBox);
