import DecoratorBase from './DecoratorBase';
import ErrorStore from '../errors/ErrorStore';
import LoggerStore from '../LoggerStore';
import { StatefulFunction } from '../shared/StatefulFunction';

export type CatchErrorRequiredParams = {
  getTrackingId: () => string;
  errorStore: ErrorStore;
  loggerStore: LoggerStore;
};

interface StoreBase {
  params: CatchErrorRequiredParams;
}

class CatchError extends DecoratorBase<StoreBase> {
  private checkStore = (store: StoreBase) => {
    if (!store)
      throw new Error('CatchError Decorator - method is not bound to any store');

    if (!store.params)
      throw new Error(`CatchError Decorator - method's bound store does not contain params`);

    if (!store.params.errorStore)
      throw new Error(`CatchError Decorator - method's bound store's params object does not contain errorStore`);

    if (!store.params.getTrackingId)
      throw new Error(`CatchError Decorator - method's bound store's params object does not contain getTrackingId`);

    if (!store.params.loggerStore)
      throw new Error(`CatchError Decorator - method's bound store's params object does not contain loggerStore`);
  }

  protected wrapMethod = (wrapper: StatefulFunction, method: Function) => {
    return async (store: StoreBase, ...args: any[]) => {
      this.checkStore(store);

      const trackingId = store.params.getTrackingId();
      try {
        const result = await method.bind(store)(...args, trackingId);

        wrapper.setLastExecutionFailed!(false);
        store.params.errorStore.requestSucceeded();
        return result;
      } catch (e) {
        wrapper.setLastExecutionFailed!(true);
        store.params.loggerStore.logError(e.message, trackingId);
        store.params.errorStore.handleErrorModel(trackingId, e);
      }
    };
  }
}

export default new CatchError().handle;
