import { CheckboxModel } from '../../../../../models';
import { FormCheckbox } from '../../../..';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface HasElectricityConsumptionFieldProps {
  hasElectricityConsumption: boolean;
  setHasElectricityConsumption: (value: boolean) => void;
}

const HasElectricityConsumptionField = ({ hasElectricityConsumption, setHasElectricityConsumption }: HasElectricityConsumptionFieldProps) =>
  {
    const translate = useLocalization();
    return <FormCheckbox {...{
      translate,
      model: new CheckboxModel({
        inputId: 'AirdomeElectricityConsumption',
        label: translate(x => x.forms.airdome.create.hasElectricityConsumption),
        setValue: setHasElectricityConsumption,
        value: hasElectricityConsumption
      })
    }} />;
  };

export default observer(HasElectricityConsumptionField);
