import Create from '../../../base/errors/airdome/Create';

const create: Create = {
  GeneralError:
    'Bei der Verarbeitung der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an die Calypso group mit dem Fehlercode <{trackingId}>.',
  AirdomeAlreadyPresent: 'Hinzufügen mit demselben Namen existiert bereits.',
  MacAddressAlreadyPresent: 'Die MAC-Adresse ist bereits einem anderen Traglufthalle zugewiesen.'
};

export default create;
