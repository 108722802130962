import { Moment } from 'moment';
import moment from 'moment-timezone';
import { useStore } from 'stores/MobXTypedStoreHook';

type TransformTime = (x: Date | Moment) => Moment;

const useSelectedAirdomeTime = (): {
  toAirdomeTime: TransformTime,
  fromAirdomeTime: TransformTime
} => {
  const airdomeStore = useStore(x => x.airdomeStore);
  const airdome = airdomeStore.SelectedAirdome;

  if(airdome === undefined)
    throw new Error('Can\'t use selected airdome time with no airdome selected!');

  return {
    toAirdomeTime: date => moment(date).tz(airdome.timeZone.iana),
    fromAirdomeTime: date => moment.tz(
      moment(date).utc(false).format('YYYY-MM-DDTHH:mm:ss'),
      airdome.timeZone.iana
    ).utc()
  }
}


export default useSelectedAirdomeTime;