import AirdomeHubConnection from './base/AirdomeHubConnection';
import AirdomeStore from 'stores/AirdomeStore';
import SecurityStore from 'stores/SecurityStore';
import StatusDto from '../../dto/status/StatusDto';
import { getJoinedEndpoint } from '../../utils/Settings';
import moment from 'moment-timezone';

type StatusSingleAirdomeEvents = 'StatusReceived' | 'SwitchBoxDataReceived';

export default class StatusSingleAirdomeHubConnection extends AirdomeHubConnection<StatusSingleAirdomeEvents, ''> {
  constructor(airdomeStore?: AirdomeStore, securityStore?: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.airdome.status
    ), airdomeStore, securityStore);
  }

  public onStatusReceived = (callback: (status: StatusDto) => void) => {
    super.on('StatusReceived', status =>
      callback({
        ...status,
        timestamp: moment.utc(status.timestamp)
      })
    );
  }

  public onSwitchBoxDataReceived = (callback: (switchBox: { [name: string]: string }) => void) => {
    super.on('SwitchBoxDataReceived', callback);
  }
}
