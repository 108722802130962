import {
  MaximumValueField,
  MinimumValueField
} from '../fields/maximal';
import PressureProps from '../PressureProps';
import React from 'react';

const MaximalPressureFieldset = (props: PressureProps) => <>
  <MinimumValueField {...props} />
  <MaximumValueField {...props} />
</>;

export default MaximalPressureFieldset;
