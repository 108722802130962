import React, { ReactNode } from 'react';
import NotificationProvider from './NotificationProvider';

interface MainWrapperProps {
  children: ReactNode;
}

const MainWrapper = (props: MainWrapperProps) => <NotificationProvider>
  <div className="theme-light">
    <div className="wrapper">
      {props.children}
    </div>
  </div>
</NotificationProvider>;

export default React.memo(MainWrapper);
