import React from 'react';
import { MinimumValueField, MaximumValueField } from '../fields/cutback';
import TemperatureProps from '../TemperatureProps';

const CutbackTemperatureFieldset = (props: TemperatureProps) => <>
  <MinimumValueField  {...props} />
  <MaximumValueField {...props} />
</>;

export default CutbackTemperatureFieldset;
