import Roles from '../../../base/enums/user/Roles';

const roles: Roles = {
  admin: 'Admin',
  airdome_admin: 'Admin haly',
  basic: 'Základní',
  advanced: 'Pokročilý',
  electrician: 'Elektrikář'
};

export default roles;
