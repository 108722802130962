import { Link } from 'react-router-dom';
import React from 'react';
import TopbarLanguage from './TopbarLanguage';
import TopbarProfile from './TopbarProfile';
import TopbarSidebarButton from './TopbarSidebarButton';
import Translate from '../../../localization/Translate';

interface TopbarProps {
  changeMobileSidebarVisibility: (...args: any[]) => any;
  changeSidebarVisibility: () => any;
  translate: Translate;
  userName: string;
}

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility, translate, userName }: TopbarProps) =>
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to="/airdomes" />
      </div>
      <div className="topbar__right">
        <TopbarProfile {...{ translate, userName }} />
        <TopbarLanguage />
      </div>
    </div>
  </div>;

export default React.memo(Topbar);
