import { RegistrySingleAirdomeHubConnection, StatusSingleAirdomeHubConnection } from '../../services';
import { action, observable, reaction } from 'mobx';
import { catchError, processingState } from '../decorators';

import AIService from 'services/ai/AIService';
import AirdomeStore from '../AirdomeStore';
import ErrorStore from '../errors/ErrorStore';
import LightingStore from '../LightingStore';
import { StatefulFunction } from '../shared/StatefulFunction';
import StatusModel from '../../models/store/StatusModel';
import StatusService from '../../services/status/StatusService';

interface StatusBoxStoreParams {
  statusService: StatusService;
  artificialIntelligenceService: AIService;
  errorStore: ErrorStore;
  airdomeStore: AirdomeStore;
  lightingStore: LightingStore;
  statusSingleAirdomeHubConnection: StatusSingleAirdomeHubConnection;
  registrySingleAirdomeHubConnection: RegistrySingleAirdomeHubConnection;
}

export default class StatusBoxStore {
  @observable public readonly status: StatusModel = new StatusModel();

  private params: StatusBoxStoreParams;
  constructor(params: StatusBoxStoreParams) {
    this.params = params;

    params.statusSingleAirdomeHubConnection.onStatusReceived(
      status => this.status.importFromStatusDto(status)
    );

    params.registrySingleAirdomeHubConnection.onAirdomeIsInitializedChanged(this.setStatusIsInitialized);
    params.registrySingleAirdomeHubConnection.onAirdomeIsInflatedChanged(this.setStatusIsInflated);

    reaction(
      () => params.airdomeStore.SelectedAirdome,
      (airdome) => {
        this.status.clear();
        if (!airdome)
          return;

        this.status.importFromAirdomeDto(airdome);
        this.fetchAIState(airdome.id);
        this.fetchStatus(airdome.id);
      }
    );
  }

  public get IsLoading(): boolean {
    return this.fetchAIState.getProcessing!() || this.fetchStatus.getProcessing!();
  }

  private getAirdomeId = (): number | undefined =>
    this.params.airdomeStore.SelectedAirdomeId

  @action
  public setStatusIsInitialized = (airdomeId: number, isInitialized: boolean) => {
    if (this.getAirdomeId() === airdomeId)
      this.status.setIsInitialized(isInitialized);
  }

  @action
  public setStatusIsInflated = (airdomeId: number, isInflated: boolean) => {
    if (this.getAirdomeId() === airdomeId)
      this.status.setIsInflated(isInflated);
  }
  
  @action
  public setStatusIsAIEnabled = (airdomeId: number, isAIEnabled: boolean) => {
    if (this.getAirdomeId() === airdomeId)
      this.status.setIsAIEnabled(isAIEnabled);
  }

  @processingState
  @catchError
  @action
  public fetchAIState: StatefulFunction = async (airdomeId: number, trackingId: string = '') => {
    const isAIEnabled = await this.params.artificialIntelligenceService.getIsArtificialIntelligenceEnabled(airdomeId, trackingId);
    this.status.setIsAIEnabled(isAIEnabled);
  }

  @processingState
  @catchError
  @action
  public fetchStatus: StatefulFunction = async (airdomeId: number, trackingId: string = '') => {
    const status = await this.params.statusService.getStatus(airdomeId, trackingId);
    this.status.importFromStatusDto(status);
  }

  @catchError
  @action
  public forceStatus = async (trackingId: string = '') => {
    const airdomeId = this.getAirdomeId();
    if (!airdomeId)
      return;

    await this.params.statusService.forceStatus(airdomeId, trackingId);
  }
}
