import {
  AirdomeListAvailabilityCell,
  AirdomeListCountryCell,
  AirdomeListNotificationCell
} from '../../components';

import AirdomeListItemModel from '../../models/AirdomeListItemModel';
import { TableColumn } from '../../components/basic/material-table/table-header/TableHeader';
import Translate from '../../localization/Translate';

const getColumns = (
  locale: string,
  translate: Translate,
  onNameClick: (item: AirdomeListItemModel) => void,
  onNotificationsClick: (item: AirdomeListItemModel) => void,
  userHasAirdomeManageRights: boolean
): TableColumn<AirdomeListItemModel>[] => {
  let columns: TableColumn<AirdomeListItemModel>[] = [
    {
      disablePadding: false,
      id: 'name',
      width: 21,
      label: translate(x => x.pages.airdomeList.columns.name),
      getValue: (item: AirdomeListItemModel) => item.name.toLowerCase(),
      renderCell: (item: AirdomeListItemModel) =>
        <p className="clickable-material-table-cell airdome-name-p" onClick={() => onNameClick(item)}>{item.name}</p>
    },
    {
      disablePadding: false,
      id: 'availability',
      width: 13,
      label: translate(x => x.pages.airdomeList.columns.availability),
      getValue: (x: AirdomeListItemModel) => x.availability || '',
      renderCell: (item: AirdomeListItemModel) => <AirdomeListAvailabilityCell {...{ item, translate, locale }} />
    },
    {
      disablePadding: false,
      id: 'notifications',
      width: 2,
      label: translate(x => x.pages.airdomeList.columns.notifications),
      getValue: (x: AirdomeListItemModel) => x.notifications.toString(),
      renderCell: (item: AirdomeListItemModel) =>
        <AirdomeListNotificationCell {...{ item, onClick: item => onNotificationsClick(item) }} />,
    },
    {
      disablePadding: false,
      id: 'country.name',
      width: 2,
      label: translate(x => x.pages.airdomeList.columns.country),
      getValue: (item: AirdomeListItemModel) => translate(x => x.countries[item.country.shortcut.toLowerCase()]),
      renderCell: (item: AirdomeListItemModel) => <AirdomeListCountryCell {...{ item, translate }} />
    }
  ];

  if (userHasAirdomeManageRights) {
    columns.push({
      disablePadding: false,
      id: 'projectNumber',
      width: 8,
      getValue: (x: AirdomeListItemModel) => x.projectNumber ?? "",
      label: translate(x => x.pages.airdomeList.columns.projectNumber)
    });

    columns.push({
      disablePadding: false,
      id: 'template',
      width: 8,
      getValue: (x: AirdomeListItemModel) => x.templateName ?? "",
      label: translate(x => x.pages.airdomeList.columns.template)
    });
  }

  return columns;
}

export default getColumns;
