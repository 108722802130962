const countries = {
  cz: 'Czech Republic',
  de: 'Germany',
  at: 'Austria',
  sk: 'Slovakia',
  tr: 'Turkey',
  gb: 'United Kingdom',
  ie: 'Ireland',
  pl: 'Poland',
  bg: 'Bulgaria',
  lu: 'Luxembourg',
  gr: 'Greece'
};

export default countries;
