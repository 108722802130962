import { getMacAddressValidationSchema } from '../../validations/validations';
import { StringSchema } from 'yup';
import { observable, computed, action } from 'mobx';
import MacAddressModel from './MacAddressModel';
import MacAddressDto from '../../dto/registry/MacAddressDto';

export default class MacAddressesModel {
  @observable public macAddresses: MacAddressModel[];
  @observable public macAddressToAdd: MacAddressModel;

  public validationSchema: StringSchema;

  constructor() {
    this.validationSchema = this.getValidationSchema();

    this.macAddresses = [];
    this.macAddressToAdd = new MacAddressModel(this.validationSchema, { value: '' });
  }

  public getValidationSchema = (macAddress?: String, label?: String): StringSchema => {
    return getMacAddressValidationSchema({ macAddress, label, required: false });
  }

  @action
  public setMacAddressToAdd = (value: string) => {
    if (!this.macAddressToAdd) {
      this.macAddressToAdd = new MacAddressModel(this.validationSchema, { value });
      return;
    }

    this.macAddressToAdd.setValue(value);
  }

  @action
  public import = (macAddresses: MacAddressDto[]) =>
    this.macAddresses = macAddresses
      .map(macAddress => new MacAddressModel(this.validationSchema, macAddress))
      .sort((a: MacAddressModel, b: MacAddressModel) => {
        const aId = a.id || 0;
        const bId = b.id || 0;

        if (aId > bId) return 1;
        if (aId < bId) return -1;
        return 0;
      })

  @action
  public clear = () => {
    this.macAddresses = [];
    this.clearMacAddressToAdd();
  }

  @action
  public clearMacAddressToAdd = () => this.macAddressToAdd = new MacAddressModel(this.validationSchema, { value: '' })

  @action public removeMacAddress = (macAddressId: number) =>
    this.macAddresses = this.macAddresses.filter(m => m.id !== macAddressId)

  @computed public get areDataValid(): boolean {
    return this.macAddresses.reduce<boolean>((aggregate, current) =>
      aggregate && current.areDataValid(true), true);
  }
}
