import './AddUserField.scss';

import AddUserAccessModel from '../../../../models/settings/AddUserAccessModel';
import Autocomplete from 'react-autosuggest';
import ButtonLoading from '../../../../shared/components/ButtonLoading';
import Translate from '../../../../localization/Translate';
import { observer } from 'mobx-react';

interface AddUserFieldProps {
  readonly?: boolean;
  translate: Translate;
  submit: (userName: string) => Promise<void>;
  model: AddUserAccessModel;
}

const AddUserField = ({ readonly, translate, model, submit }: AddUserFieldProps) =>
  <div className="input-group addUserField">
    <Autocomplete {...{
      multiSection: false,
      onSuggestionsFetchRequested: () => { },
      inputProps: {
        value: model.userName ?? '',
        onChange: x => {
          const target = x.target as HTMLInputElement;
          model.setUserName(target?.value);
        }
      },
      containerProps: {
        className: 'userNameInput'
      },
      suggestions: model.Suggestions.map((text, index) => ({ text, index })),
      getSuggestionValue: x => x.text,
      renderSuggestion: (item, params) =>
        <div className={`addUserSelectItem ${params.isHighlighted ? 'highlight' : ''}`} key={item.index}>
          {item.text}
        </div>,
      renderSuggestionsContainer: (params) =>
        <div {...{
          ...params.containerProps,
          className: params.containerProps.className ? `${params.containerProps.className} addUserSuggestionBox` : 'addUserSuggestionBox'
        }}>
          {params.children}
        </div>,
      onSuggestionSelected: x => {
        const target = x.target as HTMLDivElement;
        model.setUserName(target.innerText);
      },
      onSuggestionsClearRequested: () => {}
    }} />
    <div className="input-group-prepend">
      <ButtonLoading
        disabled={readonly || model.userName === undefined}
        color="secondary"
        onClick={async () => {
          if (model.userName)
            await submit(model.userName);
        }}
        content={translate(x => x.general.add)} />
    </div>
  </div>;

export default observer(AddUserField);
