import Intervals from '../../../../base/forms/airdome/intervals';

const intervals: Intervals = {
  statusInterval: 'Stavový interval',
  plcReadingInterval: 'PLC interval čtení',
  aiInterval: 'AI interval',
  consumptionsInterval: 'Interval spotřeby'
};

export default intervals;
