import LightState from '../../base/enums/LightState';

const lightState: LightState = {
  1: {
    short: 'Auto',
    full: 'Auto'
  },
  2: {
    short: 'Off',
    full: 'Off'
  },
  3: {
    short: 'On',
    full:'On'
  }
};

export default lightState;
