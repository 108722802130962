import Airdome from '../../../base/notifications/airdome';

const airdome: Airdome = {
  stateChanged: {
    title: 'Benachrichtigung',
    messageFormat: {
      alive: 'Halle {airdomeName} ist aktiv',
      halfdead: 'Halle {airdomeName} reagiert nicht',
      dead: 'Airdome {airdomeName}  ist inaktiv',
      plcunavailable: 'Die PLC {airdomeName} von Airdome ist nicht verfügbar'
    }
  },
  created: {
    title: '{airdomeName}',
    message: 'Halle wurde erstellt'
  }
};

export default airdome;
