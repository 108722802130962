import { AirdomeStore, BaseStore } from '.';
import { ChangeRequestTypeEnum, changeRequestTypes } from '../dto/changeRequest/ChangeRequestTypeEnum';
import { action, computed, observable, reaction } from 'mobx';

import { BaseStoreParams } from './base/BaseStore';
import ChangeRequestHubConnection from '../services/signalr/ChangeRequestHubConnection';
import { ChangeRequestService } from '../services';
import { StatefulFunction } from './shared/StatefulFunction';
import UserDto from 'dto/changeRequest/UserDto';
import { catchError } from './decorators';

export interface ChangeRequestStoreParams extends BaseStoreParams {
  changeRequestService: ChangeRequestService;
  airdomeStore: AirdomeStore;
  changeRequestHubConnection: ChangeRequestHubConnection;
}

type ChangeRequestStates = {
  [key in ChangeRequestTypeEnum]?: boolean;
};

export default class ChangeRequestStore extends BaseStore<ChangeRequestStoreParams> {
  @observable
  private changeRequestStateStorage: ChangeRequestStates = {};

  @computed
  public get ChangeRequestStates(): Readonly<ChangeRequestStates> {
    return this.changeRequestStateStorage;
  }

  constructor(params: ChangeRequestStoreParams) {
    super(params);

    this.params.changeRequestHubConnection.onCreated(this.onCreated);
    this.params.changeRequestHubConnection.onFinished(this.onFinished);
    this.params.changeRequestHubConnection.onFailed(this.onFailed);

    reaction(
      () => params.airdomeStore.SelectedAirdomeId,
      async (airdomeId) => {
        this.changeRequestStateStorage = {};

        if(!airdomeId)
          return;

        await this.fetchInitialChangeRequestStates(airdomeId);
      });
  }

  @catchError
  @action
  public fetchInitialChangeRequestStates: StatefulFunction = async (airdomeId: number, trackingId: string = '') => {
    const fetchPromises = changeRequestTypes
      .map(type => this.params.changeRequestService
        .isChangeRequestInProgress(airdomeId, type, trackingId)
        .then<[ChangeRequestTypeEnum, boolean]>(result => [type, result]));
    
    for(const [type, isInProgress] of await Promise.all(fetchPromises))
      this.changeRequestStateStorage[type] = isInProgress;
  }

  @action
  private onCreated = (_: number, __: UserDto, type: ChangeRequestTypeEnum) =>
    this.changeRequestStateStorage[type] = true;

  @action
  private onFinished = (_: number, __: UserDto, type: ChangeRequestTypeEnum) =>
    this.changeRequestStateStorage[type] = false;

  @action
  private onFailed = (_: number, __: UserDto, type: ChangeRequestTypeEnum) =>
    this.changeRequestStateStorage[type] = false;
}
