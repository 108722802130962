import { action, computed, observable } from 'mobx';

import LightModel from './LightModel';
import { LightState } from '../../dto/settings/enums';
import LightsDto from 'dto/settings/LightsDto';

export interface LightSetting {
  lightState: LightState;
}

export interface Light extends LightSetting {
  propertyDefinitionId: number;
  name: string;
}

export default class LightsModel {
  @observable
  public hasData: boolean = false;

  @observable
  public lightsMain1?: LightModel;

  @observable
  public lightsMain2?: LightModel;

  @observable
  public lightsMain3?: LightModel;

  @observable
  public lightsMain4?: LightModel;

  @observable
  public lightsAdditional1?: LightModel;

  @observable
  public lightsAdditional2?: LightModel;

  @observable
  public lightsAdditional3?: LightModel;

  @observable
  public lightsAdditional4?: LightModel;

  @observable
  public activeLights: number = 0;

  @action public importAll = (dto: {
    lightsMain1?: Light,
    lightsMain2?: Light,
    lightsMain3?: Light,
    lightsMain4?: Light,
    lightsAdditional1?: Light,
    lightsAdditional2?: Light,
    lightsAdditional3?: Light,
    lightsAdditional4?: Light
  }) => {
    this.lightsMain1 = dto.lightsMain1
      ? new LightModel(dto.lightsMain1)
      : undefined;

    this.lightsMain2 = dto.lightsMain2
      ? new LightModel(dto.lightsMain2)
      : undefined;

    this.lightsMain3 = dto.lightsMain3
      ? new LightModel(dto.lightsMain3)
      : undefined;

    this.lightsMain4 = dto.lightsMain4
      ? new LightModel(dto.lightsMain4)
      : undefined;

    this.lightsAdditional1 = dto.lightsAdditional1
      ? new LightModel(dto.lightsAdditional1)
      : undefined;

    this.lightsAdditional2 = dto.lightsAdditional2
      ? new LightModel(dto.lightsAdditional2)
      : undefined;

    this.lightsAdditional3 = dto.lightsAdditional3
      ? new LightModel(dto.lightsAdditional3)
      : undefined;

    this.lightsAdditional4 = dto.lightsAdditional4
      ? new LightModel(dto.lightsAdditional4)
      : undefined;

    this.hasData = true;
  }

  @action public importData = (dto: {
    lightsMain1?: LightSetting,
    lightsMain2?: LightSetting,
    lightsMain3?: LightSetting,
    lightsMain4?: LightSetting,
    lightsAdditional1?: LightSetting,
    lightsAdditional2?: LightSetting,
    lightsAdditional3?: LightSetting,
    lightsAdditional4?: LightSetting
  }) => {
    if (this.lightsMain1)
      this.lightsMain1.lightState.setValue(dto.lightsMain1?.lightState, false);

    if (this.lightsMain2)
      this.lightsMain2.lightState.setValue(dto.lightsMain2?.lightState, false);

    if (this.lightsMain3)
      this.lightsMain3.lightState.setValue(dto.lightsMain3?.lightState, false);

    if (this.lightsMain4)
      this.lightsMain4.lightState.setValue(dto.lightsMain4?.lightState, false);

    if (this.lightsAdditional1)
      this.lightsAdditional1.lightState.setValue(dto.lightsAdditional1?.lightState, false);

    if (this.lightsAdditional2)
      this.lightsAdditional2.lightState.setValue(dto.lightsAdditional2?.lightState, false);

    if (this.lightsAdditional3)
      this.lightsAdditional3.lightState.setValue(dto.lightsAdditional3?.lightState, false);

    if (this.lightsAdditional4)
      this.lightsAdditional4.lightState.setValue(dto.lightsAdditional4?.lightState, false);
  }

  @action
  public clear = () => {
    this.lightsMain1 = undefined;
    this.lightsMain2 = undefined;
    this.lightsMain3 = undefined;
    this.lightsMain4 = undefined;
    this.lightsAdditional1 = undefined;
    this.lightsAdditional2 = undefined;
    this.lightsAdditional3 = undefined;
    this.lightsAdditional4 = undefined;
    this.hasData = false;
  }

  public get DataDto(): Omit<LightsDto, 'userId' | 'timestamp'> {
    return {
      lightsMain1: this.lightsMain1
        && this.lightsMain1.lightState.Value,
      lightsMain2: this.lightsMain2
        && this.lightsMain2.lightState.Value,
      lightsMain3: this.lightsMain3
        && this.lightsMain3.lightState.Value,
      lightsMain4: this.lightsMain4
        && this.lightsMain4.lightState.Value,
      lightsAdditional1: this.lightsAdditional1
        && this.lightsAdditional1.lightState.Value,
      lightsAdditional2: this.lightsAdditional2
        && this.lightsAdditional2.lightState.Value,
      lightsAdditional3: this.lightsAdditional3
        && this.lightsAdditional3.lightState.Value,
      lightsAdditional4: this.lightsAdditional4
        && this.lightsAdditional4.lightState.Value
    };
  }

  public get NamesDto(): { [propertyDefinitionId: number]: string } {
    const dto: { [propertyDefinitionId: number]: string } = {};

    if (this.lightsMain1)
      dto[this.lightsMain1.propertyDefinitionId] = this.lightsMain1.name.Value ?? '';

    if (this.lightsMain2)
      dto[this.lightsMain2.propertyDefinitionId] = this.lightsMain2.name.Value ?? '';

    if (this.lightsMain3)
      dto[this.lightsMain3.propertyDefinitionId] = this.lightsMain3.name.Value ?? '';

    if (this.lightsMain4)
      dto[this.lightsMain4.propertyDefinitionId] = this.lightsMain4.name.Value ?? '';

    if (this.lightsAdditional1)
      dto[this.lightsAdditional1.propertyDefinitionId] = this.lightsAdditional1.name.Value ?? '';

    if (this.lightsAdditional2)
      dto[this.lightsAdditional2.propertyDefinitionId] = this.lightsAdditional2.name.Value ?? '';

    if (this.lightsAdditional3)
      dto[this.lightsAdditional3.propertyDefinitionId] = this.lightsAdditional3.name.Value ?? '';

    if (this.lightsAdditional4)
      dto[this.lightsAdditional4.propertyDefinitionId] = this.lightsAdditional4.name.Value ?? '';

    return dto;
  }

  @computed
  public get areNamesValid(): boolean {
    return [
      this.lightsMain1,
      this.lightsMain2,
      this.lightsMain3,
      this.lightsMain4,
      this.lightsAdditional1,
      this.lightsAdditional2,
      this.lightsAdditional3,
      this.lightsAdditional4
    ].filter(l => !!l)
      .every(l => !!l!.name.Value);
  }

  @computed
  public get hasAnyChanges(): boolean {
    return this.hasAnyValueChange
      || this.hasAnyNameChange;
  }

  @computed
  public get hasAnyValueChange(): boolean {
    const hasLightValueChange = (light?: LightModel) =>
      !!light && (
        light.lightState.ValueChanged
      );

    return hasLightValueChange(this.lightsMain1)
      || hasLightValueChange(this.lightsMain2)
      || hasLightValueChange(this.lightsMain3)
      || hasLightValueChange(this.lightsMain4)
      || hasLightValueChange(this.lightsAdditional1)
      || hasLightValueChange(this.lightsAdditional2)
      || hasLightValueChange(this.lightsAdditional3)
      || hasLightValueChange(this.lightsAdditional4);
  }

  @computed
  public get hasAnyNameChange(): boolean {
    const hasLightNameChange = (light?: LightModel) =>
      !!light && (
        light.name.ValueChanged
      );

    return hasLightNameChange(this.lightsMain1)
      || hasLightNameChange(this.lightsMain2)
      || hasLightNameChange(this.lightsMain3)
      || hasLightNameChange(this.lightsMain4)
      || hasLightNameChange(this.lightsAdditional1)
      || hasLightNameChange(this.lightsAdditional2)
      || hasLightNameChange(this.lightsAdditional3)
      || hasLightNameChange(this.lightsAdditional4);
  }

  @computed public get LightsState(): { total: number; active: number } {
    const lights = [
      this.lightsAdditional1,
      this.lightsAdditional2,
      this.lightsAdditional3,
      this.lightsAdditional4,
      this.lightsMain1,
      this.lightsMain2,
      this.lightsMain3,
      this.lightsMain4
    ].filter(x => !!x);

    return {
      total: lights.length,
      active: this.activeLights
    };
  }

  @action
  public resetHasValueChanges = () => {
    const resetLightChange = (light?: LightModel) =>
      light && light.lightState.resetHasChange();

    resetLightChange(this.lightsMain1);
    resetLightChange(this.lightsMain2);
    resetLightChange(this.lightsMain3);
    resetLightChange(this.lightsMain4);
    resetLightChange(this.lightsAdditional1);
    resetLightChange(this.lightsAdditional2);
    resetLightChange(this.lightsAdditional3);
    resetLightChange(this.lightsAdditional4);
  }

  @action
  public resetHasNameChanges = () => {
    const resetLightChange = (light?: LightModel) =>
      light && light.name.resetHasChange();

    resetLightChange(this.lightsMain1);
    resetLightChange(this.lightsMain2);
    resetLightChange(this.lightsMain3);
    resetLightChange(this.lightsMain4);
    resetLightChange(this.lightsAdditional1);
    resetLightChange(this.lightsAdditional2);
    resetLightChange(this.lightsAdditional3);
    resetLightChange(this.lightsAdditional4);
  }
}
