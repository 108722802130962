import * as React from 'react';

export interface BacnetTemplateListProps {
    children?: React.ReactNode
}

export interface BacnetTemplateListState {
}

export default class BacnetTemplateList extends React.Component<BacnetTemplateListProps, BacnetTemplateListState> {

    constructor(props: BacnetTemplateListProps) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
                BacnetTemplateList
                { this.props.children }
            </div>
        )
    }
}
