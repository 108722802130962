import * as uuid from 'uuid';

import { Card, CardBody, Col, Fade, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import ButtonLoading from 'shared/components/ButtonLoading';
import FormSelect from 'components/basic/FormSelect';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const TemplatesPage = () => {
  const airdomeStore = useStore(x => x.airdomeStore);
  const templateService = useStore(x => x.templateService);
  const translate = useLocalization();

  useEffect(() => {
    airdomeStore.fetchTemplates();
  }, []);

  const [templateId, setTemplateId] = useState<number | undefined>(undefined);

  return <Row>
    <Col sm={6}>
      <Fade>
        <Card>
          <CardBody>
            <FormSelect
              options={airdomeStore.templates.map(x => ({
                value: x.id.toString(),
                displayName: x.name
              }))}
              label={translate(x => x.pages.templates.selectTemplate)}
              selectId="Template"
              setValue={templateId => Number(templateId) && setTemplateId(Number(templateId))}
              selectedValue={templateId?.toString()} />
            <ButtonLoading
              color="primary"
              className="col-md-6 col-lg-4 col-12"
              disabled={!templateId}
              onClick={async () => {
                if (templateId)
                  await templateService.updateTemplate(uuid.v4(), templateId)
              }}
              content={translate(x => x.pages.templates.update)} />
          </CardBody>
        </Card>
      </Fade>
    </Col>
  </Row>;
}

export default observer(TemplatesPage);