import { PressureModeMax, PressureModeMaxOff, PressureModeNormal, PressureModeNormalOff } from '../icons';

import { PressureMode } from '../../../../dto/settings/enums';
import RadialMenuSelect from '../../../basic/RadialMenuSelect';
import SettingsFormProps from '../SettingsFormProps';
import { observer } from 'mobx-react';

const getPressureNormalCoords = (size: number) => ({
  icon: {
    width: size * 0.6,
    height: size * 0.5,
    x: size * 0.25,
    y: size * 0.55
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 0.55,
    y: size * 1.3
  }
});

const getPressureMaximalCoords = (size: number) => ({
  icon: {
    width: size * 0.6,
    height: size * 0.5,
    x: size * 1.175,
    y: size * 0.55
  },
  text: {
    width: size * 0.35,
    height: size * 0.5625,
    x: size * 1.48,
    y: size * 1.3
  }
});

const PressureModeField = ({ translate, settingsModel, accessTable, disabled }: SettingsFormProps) => {
  const { pressureMode } = settingsModel;

  return <div style={{ height: 220 }}>
    <RadialMenuSelect<PressureMode> {...{
      readonly: disabled || !accessTable.modes,
      currentValue: pressureMode.Value,
      name: translate(x => x.forms.airdome.settings.pressures),
      selectValue: x => pressureMode.setValue(x),
      options: [
        {
          value: PressureMode.Maximum,
          display: translate(x => x.enums.pressureModes[PressureMode.Maximum]),
          getCoords: getPressureMaximalCoords,
          getIcon: props => pressureMode.Value !== PressureMode.Maximum
            ? <PressureModeMaxOff {...props} />
            : <PressureModeMax {...props} />
        },
        {
          value: PressureMode.Normal,
          display: translate(x => x.enums.pressureModes[PressureMode.Normal]),
          getCoords: getPressureNormalCoords,
          getIcon: props => pressureMode.Value !== PressureMode.Normal
            ? <PressureModeNormalOff {...props} />
            : <PressureModeNormal {...props} />
        }
      ],
      selectorSize: 88
    }} />
  </div>;
};

export default observer(PressureModeField);
