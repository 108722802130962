import { AxiosInstance } from 'axios';
import Properties from '../../models/airdome/Properties';
import RDevNotificationsDto from '../../dto/status/RDevNotificationsDto';
import StatusDto from '../../dto/status/StatusDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from './../../utils/Settings';
import moment from 'moment';
import stringFormat from 'string-format';

export default class StatusService {
  private axios: AxiosInstance;
  private getStatusUrl: string;
  private forceStatusUrl: string;
  private getRDevNotificationsUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getStatusUrl = getJoinedEndpoint(x => x.status.baseUri, x => x.status.status.get);
    this.forceStatusUrl = getJoinedEndpoint(x => x.status.baseUri, x => x.status.status.force);
    this.getRDevNotificationsUrl = getJoinedEndpoint(x => x.status.baseUri, x => x.status.rdevNotifications.getAll);
  }

  public async getRDevNotifications(airdomeId: number, start: Date, end: Date, skip: number, take: number, trackingId: string): Promise<RDevNotificationsDto> {
    try {
      const url = stringFormat(this.getRDevNotificationsUrl, airdomeId.toString());

      const config = {
        headers: { trackingId },
        params: { start, end, skip, take }
      };

      const response = await this.axios.get<RDevNotificationsDto>(url, config);

      return response.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async getStatus(airdomeId: number, trackingId: string): Promise<StatusDto> {
    try {
      const url = stringFormat(this.getStatusUrl, airdomeId.toString());
      const response = await this.axios.get<StatusDto>(url);
      const status = response.data;
      status.timestamp = moment(status.timestamp).utc(false);

      return status;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }

  public getStatusesForInterval = async (airdomeId: number, from: Date, to: Date, trackingId: string)
    : Promise<StatusDto[]> => {
    try {
      const url = stringFormat(this.getStatusUrl, airdomeId.toString());
      const response = await this.axios.post<StatusDto[]>(url, { from, to });
      return response.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 Log something
    }
  }

  public forceStatus = async (airdomeId: number, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(this.forceStatusUrl, airdomeId.toString());
      await this.axios.post(url);
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }
}
