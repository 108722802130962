import { AirdomeDto, AirdomeListItemExtendedDto, CreateAirdomeCommand, LightDto } from '../../dto/Container';

import AirdomeTemplateStatusDto from 'dto/registry/AirdomeTemplateStatusDto';
import AirdomeUpdateDto from '../../dto/registry/AirdomeUpdateDto';
import { AxiosInstance } from 'axios';
import DoorLockDto from 'dto/registry/DoorLockDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import requestConfigBuilder from '../../utils/axiosRequestConfigBuilder';
import stringFormat from 'string-format';

export default class AirdomeService {
  private axios: AxiosInstance;
  private createAirdomeUrl: string;
  private getAirdomesUrl: string;
  private getAirdomesExtendedUrl: string;
  private getAirdomeLightsUrl: string;
  private updateAirdomeUrl: string;
  private addMacAddressUrl: string;
  private deleteMacAddressUrl: string;
  private initializeAirdomeUrl: string;
  private deinitializeAirdomeUrl: string;
  private areSchedulesManagedExternallyUrl: string;
  private getTemplateStatusUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.createAirdomeUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.create);
    this.getAirdomesUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.getAll);
    this.getAirdomesExtendedUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.getAllExtended);
    this.getAirdomeLightsUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.lights.getAll);
    this.updateAirdomeUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.update);
    this.addMacAddressUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.addMacAddress);
    this.deleteMacAddressUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.deleteMacAddress);
    this.initializeAirdomeUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.initialize);
    this.deinitializeAirdomeUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.deinitialize);
    this.areSchedulesManagedExternallyUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.areSchedulesManagedExternally);
    this.getTemplateStatusUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.airdomes.getTemplateStatus);
  }

  public async getLights(airdomeId: number, trackingId: string): Promise<LightDto[]> {
    try {
      const url = stringFormat(this.getAirdomeLightsUrl, airdomeId.toString());
      const result = await this.axios.get<LightDto[]>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async updateLights(airdomeId: number, lightNames: { [propertyDefinitionId: number]: string }, trackingId: string): Promise<void> {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.registry.baseUri,
          x => x.registry.lights.update), 
        airdomeId.toString());
      await this.axios.post(url, lightNames, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }
  
  public async getDoorLocks(airdomeId: number, trackingId: string): Promise<DoorLockDto[]> {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.registry.baseUri,
          x => x.registry.doorLocks.getAll), 
        airdomeId.toString());
        
      const result = await this.axios.get<DoorLockDto[]>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async updateDoorLocks(airdomeId: number, doorLockNames: { [propertyDefinitionId: number]: string }, trackingId: string): Promise<void> {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.registry.baseUri,
          x => x.registry.doorLocks.update), 
        airdomeId.toString());
        
      await this.axios.post(url, doorLockNames, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async getAirdomes(trackingId: string): Promise<AirdomeDto[]> {
    try {
      const result = await this.axios.get<AirdomeDto[]>(this.getAirdomesUrl, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async getAirdomesExtended(trackingId: string): Promise<AirdomeListItemExtendedDto[]> {
    try {
      const result = await this.axios.get<AirdomeListItemExtendedDto[]>(this.getAirdomesExtendedUrl, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async createAirdome(dto: CreateAirdomeCommand, trackingId: string): Promise<AirdomeListItemExtendedDto> {
    try {
      const result = await this.axios.post<AirdomeListItemExtendedDto>(this.createAirdomeUrl, dto, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public updateAirdome = async (airdomeId: number, airdomeUpdateDto: AirdomeUpdateDto, trackingId: string): Promise<AirdomeListItemExtendedDto> => {
    try {
      const url = stringFormat(this.updateAirdomeUrl, airdomeId.toString());
      const result = await this.axios.put<AirdomeListItemExtendedDto>(url, airdomeUpdateDto, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async addMacAddress(airdomeId: number, macAddress: string, trackingId: string): Promise<AirdomeDto> {
    try {
      const url = stringFormat(this.addMacAddressUrl, airdomeId.toString());
      const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
      const result = await this.axios.post<AirdomeDto>(url, `'${macAddress}'`, requestConfig);
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async removeMacAddress(airdomeId: number, macAddressId: number, trackingId: string): Promise<AirdomeDto> {
    try {
      const url = stringFormat(this.deleteMacAddressUrl, airdomeId.toString(), macAddressId.toString());
      const result = await this.axios.delete<AirdomeDto>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async initializeAirdome(airdomeId: number, trackingId: string): Promise<AirdomeDto> {
    try {
      const url = stringFormat(this.initializeAirdomeUrl, airdomeId.toString());
      const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
      const result = await this.axios.put<AirdomeDto>(url, requestConfig);
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async deinitializeAirdome(airdomeId: number, trackingId: string): Promise<AirdomeDto> {
    try {
      const url = stringFormat(this.deinitializeAirdomeUrl, airdomeId.toString());
      const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
      const result = await this.axios.put<AirdomeDto>(url, requestConfig);
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async areSchedulesManagedExternally(airdomeId: number, trackingId: string): Promise<boolean> {
    try {
      const url = stringFormat(this.areSchedulesManagedExternallyUrl, airdomeId.toString());
      const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
      const result = await this.axios.get(url, requestConfig);
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public getTemplateStatus = async (airdomeId: number, trackingId: string): Promise<AirdomeTemplateStatusDto> => {
    try {
      const url = stringFormat(this.getTemplateStatusUrl, airdomeId.toString());
      const requestConfig = requestConfigBuilder.buildJsonContentTypeHeaders({ trackingId });
      const result = await this.axios.get<AirdomeTemplateStatusDto>(url, requestConfig);
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }
}
