import InputModel from "models/InputModel";
import { observable } from "mobx";

export default class SelectedBacnetObjectRowModel {
  public readonly id: number;
  public readonly bacnetId: number;
  public readonly bacnetType: number;
  public readonly bacnetPropertyType: number;
  public readonly propertyDefinitionName: string;

  @observable
  public readonly value = new InputModel<string>();

  constructor(
    id: number, 
    bacnetId: number, 
    bacnetType: number, 
    bacnetPropertyType: number, 
    propertyDefinitionName: string) {
    this.id = id;
    this.bacnetId = bacnetId;
    this.bacnetType = bacnetType;
    this.bacnetPropertyType = bacnetPropertyType;
    this.propertyDefinitionName = propertyDefinitionName;
  }
}
