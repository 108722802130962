import BaseStore, { Params } from 'stores/base/BaseStore';
import {
  CountryDto,
  CreateAirdomeCommand,
  TemplateDto
} from '../../dto/Container';
import { NumberSchema, StringSchema } from 'yup';
import { action, computed, observable } from 'mobx';
import { catchError, processingState } from 'stores/decorators';
import {
  getBasicNumberSchema,
  getBasicStringSchema,
  getMacAddressValidationSchema
} from '../../validations/validations';

import { AirdomeService } from 'services';
import { AirdomeStore } from 'stores';
import ErrorStore from 'stores/errors/ErrorStore';
import { StatefulFunction } from 'stores/shared/StatefulFunction';
import timezones from '../../helpers/Timezones';

interface CreateAirdomeModelParams {
  getTrackingId: () => string;
  errorStore: ErrorStore;
  airdomeService: AirdomeService;
  airdomeStore: AirdomeStore;
}

export default class CreateAirdomeModel extends BaseStore<CreateAirdomeModelParams> {
  @observable public name?: string;
  @observable public projectNumber?: string;
  @observable public macAddress?: string;
  @observable public timeZone: string;
  @observable public country?: CountryDto;
  @observable public lightsMainCount?: number;
  @observable public lightsAdditionalCount?: number;
  @observable public hasHeating?: boolean;
  @observable public hasPreheating?: boolean;
  @observable public hasDoorLocking?: boolean;
  @observable public hasElectricityConsumption?: boolean;
  @observable public hasGasConsumption?: boolean;
  @observable public template?: TemplateDto;
  
  @computed
  public get isValid(): boolean {
    const isMacAddressValid = this.macAddressSchema.isValidSync(this.macAddress);

    const isMainLightCountValid =
      !this.lightsMainCount ||
      this.lightsMainCountSchema.isValidSync(this.lightsMainCount);

    const isAdditionalLightCountValid =
      !this.lightsAdditionalCount ||
      this.lightsAdditionalCountSchema.isValidSync(
        this.lightsAdditionalCount
      );

    const isProjectNumberValid = this.projectNumberSchema.isValidSync(
      this.projectNumber
    );

    const isNameValid = this.nameSchema.isValidSync(this.name);

    return !!(
      this.template &&
      this.country &&
      this.timeZone &&
      isMacAddressValid &&
      isMainLightCountValid &&
      isAdditionalLightCountValid &&
      isProjectNumberValid &&
      isNameValid
    );
  }

  public get createAirdomeCommand(): CreateAirdomeCommand | undefined {
    if (!this.isValid)
      return undefined;

    return new CreateAirdomeCommand({
      countryId: this.country!.id,
      lightsAdditionalCount: this.template!.supportsLighting
        ? this.lightsAdditionalCount
        : undefined,
      lightsMainCount: this.template!.supportsLighting
        ? this.lightsMainCount
        : undefined,
      hasHeating: this.template!.supportsHeating && !!this.hasHeating,
      hasPreheating: this.template!.supportsPreheating && !!this.hasPreheating,
      hasDoorLocking: this.template!.supportsDoorLocking && !!this.hasDoorLocking,
      hasElectricityConsumption: !!this.hasElectricityConsumption,
      hasGasConsumption: !!this.hasGasConsumption,
      macAddress: this.macAddress,
      name: this.name!,
      projectNumber: this.projectNumber!,
      templateId: this.template!.id,
      timeZone: this.timeZone
    });
  }

  private readonly lightsAdditionalCountSchema: NumberSchema;
  private readonly lightsMainCountSchema: NumberSchema;
  private readonly macAddressSchema: StringSchema;
  private readonly nameSchema: StringSchema;
  private readonly projectNumberSchema: StringSchema;

  constructor(params: Params<CreateAirdomeModelParams>) {
    super(params);
    this.timeZone = this.getDefaultTimezoneId();

    this.lightsAdditionalCountSchema = this.getLightsAdditionalCountSchema();
    this.lightsMainCountSchema = this.getLightsMainCountSchema();
    this.macAddressSchema = this.getMacAddressSchema();
    this.nameSchema = this.getNameSchema();
    this.projectNumberSchema = this.getProjectNumberSchema();
    
    this.setDefaultTemplate();
    this.setDefaultCountry();
    this.setDefaultFlagsBasedOnTemplate();
  }

  public getLightsAdditionalCountSchema = (): NumberSchema => {
    return getBasicNumberSchema({ max: 4, min: 1, required: false, strict: true });
  }

  public getLightsMainCountSchema = (): NumberSchema => {
    return getBasicNumberSchema({ max: 4, min: 1, required: false, strict: true });
  }

  public getMacAddressSchema = (macAddress?: String, label?: String): StringSchema => {
    return getMacAddressValidationSchema({ macAddress, label, required: true });
  }

  public getNameSchema = (): StringSchema => {
    return getBasicStringSchema({ required: true, strict: true });
  }

  public getProjectNumberSchema = (): StringSchema => {
    return getBasicStringSchema({ required: true, strict: true });
  }

  @action
  private setDefaultFlagsBasedOnTemplate = () => {
    if(!this.template)
      return;

    this.setHasHeating(this.template.supportsHeating);
    this.setHasElectricityConsumption(true);
  }

  @action
  private setDefaultTemplate = () => {
    const templates = this.params.airdomeStore.templates;
    if (templates && templates.length) {
      const defaultTemplate = templates.find(x => x.name.toLowerCase().includes('full air dome'));
      this.setTemplate(defaultTemplate ?? templates[0]);
    } else
      this.template = undefined;
  }

  @action
  private setDefaultCountry = () => {
    const countries = this.params.airdomeStore.countries;
    if (countries && countries.length)
      this.country = countries[0];
    else this.country = undefined;
  }

  private getDefaultTimezoneId = (): string => {
    const defaultTimezone = timezones.find(t => t.id === 'Central Europe Standard Time');

    return defaultTimezone ? defaultTimezone.id : timezones[0].id;
  }

  @action public setName = (value?: string) =>
    this.name = value

  @action public setProjectNumber = (value?: string) =>
    this.projectNumber = value

  @action public setCountry = (value: CountryDto) =>
    this.country = value

  @action public setTemplate = (value: TemplateDto) => {
    this.template = value;
    this.setDefaultFlagsBasedOnTemplate();
  }

  @action public setTimeZone = (value: string) =>
    this.timeZone = value

  @action public setLightsMainCount = (value?: number) =>
    this.lightsMainCount = value

  @action public setLightsAdditionalCount = (value?: number) =>
    this.lightsAdditionalCount = value

  @action public setHasHeating = (value: boolean) =>
    this.hasHeating = value

  @action public setHasPreheating = (value: boolean) =>
    this.hasPreheating = value;

  @action public setHasDoorLocking = (value: boolean) =>
    this.hasDoorLocking = value;
    
  @action public setHasElectricityConsumption = (value: boolean) =>
    this.hasElectricityConsumption = value
  
  @action public setHasGasConsumption = (value: boolean) =>
    this.hasGasConsumption = value

  @action public setMacAddress = (value?: string) =>
    this.macAddress = value

  @action public clear = () => {
    this.name = undefined;
    this.projectNumber = undefined;
    this.macAddress = undefined;
    this.timeZone = this.getDefaultTimezoneId();
    this.lightsMainCount = undefined;
    this.lightsAdditionalCount = undefined;
    this.hasHeating = undefined;
    this.hasElectricityConsumption = undefined;
    this.hasGasConsumption = undefined;

    this.setDefaultTemplate();
    this.setDefaultCountry();
  }
  
  @catchError
  @processingState
  @action
  public createAirdome: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    if (!this.isValid)
      return;

    const command = this.createAirdomeCommand;

    if (command) {
      const result = await this.params.airdomeService.createAirdome(
        command,
        trackingId
      );

      this.params.airdomeStore.addAirdome(result);

      this.clear();
    }
  }
}
