import BasicRootStore from '../stores/BasicRootStore';
import LoadingOverlay from 'containers/app/LoadingOverlay';
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Callback = () => {
  const history = useHistory();

  const delay = (ms: number) => new Promise(res => setTimeout(() => res(undefined), ms));

  const handleSignInRetry = async () => {
    for(let retry = 0; retry < 5; retry++) {
      try{
        const user = await BasicRootStore.loginStore.signinRedirectCallback();
        
        const userState = user?.state as { signInLocationHref?: string }
        if (userState?.signInLocationHref)
          window.location.href = userState.signInLocationHref;
        else
          history.push('/')

        break;
      } catch {
        await delay(200);
      }
    }
  }

  useEffect(() => {
    handleSignInRetry();
  }, []);

  return <LoadingOverlay />;
}

export default Callback;
