import { observable, action } from 'mobx';
import UserStore from '../stores/UserStore';
import { catchError, processingState } from './decorators';
import ErrorStore from './errors/ErrorStore';

interface UserListStoreParams {
  getTrackingId: () => string;
  errorStore: ErrorStore;
  userStore: UserStore;
}

export default class UserListStore {
  @observable public isDeleteModalShown: boolean = false;
  @observable public isUnlockModalShown: boolean = false;
  @observable public isLockModalShown: boolean = false;
  @observable public userSearch?: string;
  @observable public airdomeSearch?: string;

  private params: UserListStoreParams;
  constructor(params: UserListStoreParams) {
    this.params = params;
  }

  @action
  public setIsDeleteModalShown = (isShown: boolean) => {
    this.isDeleteModalShown = isShown;
  }

  @action
  public setIsUnlockModalShown = (isShown: boolean) => {
    this.isUnlockModalShown = isShown;
  }

  @action
  public setIsLockModalShown = (isShown: boolean) => {
    this.isLockModalShown = isShown;
  }

  @action
  public setAirdomeSearch = (search?: string) => {
    this.airdomeSearch = search;
  }

  @action
  public setUserSearch = (search?: string) => {
    this.userSearch = search;
  }

  @catchError
  @processingState
  @action
  public fetchUsers = async () => {
    await this.params.userStore.fetchUsers();
  }

  @catchError
  @processingState
  @action
  public fetchUserDetail = async () => {
    return Promise.all([
      this.params.userStore.fetchUserDetail(),
      this.params.userStore.fetchRoleOptions(),
      this.params.userStore.fetchNotificationsOptions()
    ]);
  }

  @catchError
  @processingState
  @action
  public fetchUsersAirdomes = async () => {
    await this.params.userStore.fetchUsersAirdomes();
  }
}
