import Map from '../interfaces/Map';
import SupportedLocale from 'enums/SupportedLocale';
import Translation from './base';
import czech from './czech';
import english from './english';
import german from './german';

class Translations {
  private languages: Map<Translation> = {
    [SupportedLocale.enGB]: english,
    [SupportedLocale.csCZ]: czech,
    [SupportedLocale.deDE]: german
  };

  public getTranslation(locale: SupportedLocale): Translation {
    return this.languages[locale] || english;
  }
}

export default new Translations();
