import * as React from 'react';

import { AirdomeFlagsFieldset, LightingFieldset, StandardFieldset } from './fieldsets';
import { CreateAirdomeModel, ErrorModel } from 'models';

import AirdomeCreateErrors from '../../../localization/base/errors/airdome/Create';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import { CountryDto } from 'dto/Container';
import { ErrorBox } from '../..';
import TemplateDto from 'dto/registry/TemplateDto';
import Translation from '../../../localization/base';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useState } from 'react';
import { useStore } from 'stores/MobXTypedStoreHook';

interface TranslateServerErrorOption {
  match: keyof AirdomeCreateErrors;
  getTranslation: (translation: Translation) => string;
}

const CreateAirdomeForm = observer(({ 
  errorModel, 
  createAirdomeModel,
  countries,
  templates,
  onAirdomeCreated
}: { 
  createAirdomeModel: CreateAirdomeModel;
  errorModel?: ErrorModel;
  countries: CountryDto[];
  templates: TemplateDto[];
  onAirdomeCreated: () => void;
}) => {
  const translate = useLocalization();

    const translateServerError = (key: string, options: TranslateServerErrorOption[]): string =>
      options.reduce<string | undefined>((result, option) =>
        result || (!result && option.match !== key)
          ? result
          : translate(option.getTranslation)
        , undefined)
      || translate(x => x.errors.airdome.create.GeneralError);

    const serverError = errorModel && <ErrorBox {...{
      errorModel,
      errorText: translateServerError(errorModel.localizationKey, [
        {
          match: 'AirdomeAlreadyPresent',
          getTranslation: x => x.errors.airdome.create.AirdomeAlreadyPresent
        },
        {
          match: 'MacAddressAlreadyPresent',
          getTranslation: x => x.errors.airdome.create.MacAddressAlreadyPresent
        }
      ])
    }} />;

    const template = createAirdomeModel.template || {
      supportsLighting: false,
      supportsHeating: false
    } as TemplateDto;

    return <Card>
    <CardBody>
      <h1>{translate(x => x.forms.airdome.create.header)}</h1>
      {serverError}
      <form>
        <StandardFieldset {...{
          countries,
          templates,
          translate,
          createAirdomeModel,
          createAirdome: async () => {
            await createAirdomeModel.createAirdome();
            onAirdomeCreated();
          },
          isProcessing: createAirdomeModel.createAirdome.getProcessing!()
        }}>
          {template.supportsLighting
            ? <LightingFieldset {...{
              translate,
              lightsAdditionalCount: createAirdomeModel.lightsAdditionalCount,
              lightsMainCount: createAirdomeModel.lightsMainCount,
              setLightsAdditionalCount: x => createAirdomeModel.setLightsAdditionalCount(x),
              setLightsMainCount: x => createAirdomeModel.setLightsMainCount(x)
            }} />
            : null}
          <AirdomeFlagsFieldset {...{
              translate,
              createAirdomeModel,
              supportsHeating: template.supportsHeating,
              supportsPreheating: template.supportsPreheating,
              supportsDoorLocking: template.supportsDoorLocking
            }} />
        </StandardFieldset>
      </form>
    </CardBody>
  </Card>;
});

const CreateAirdomeFormWrapper = ({ onAirdomeCreated }: { onAirdomeCreated: () => void }) => {
  const airdomeStore = useStore(x => x.airdomeStore);
  const errorStore = useStore(x => x.errorStore);
  const [createAirdomeModel, setCreateAirdomeModel] = useState<CreateAirdomeModel | undefined>(undefined);
  
  useEffect(() => {
    Promise.all([
      airdomeStore.fetchTemplates(),
      airdomeStore.fetchCountries()
    ])
    .then(() => setCreateAirdomeModel(airdomeStore.getCreateAirdomeModel()));

    return () => createAirdomeModel && createAirdomeModel.clear();
  }, [airdomeStore]);

  return createAirdomeModel
    ? <CreateAirdomeForm {...{
      createAirdomeModel,
      onAirdomeCreated: () => {
        if (errorStore.ErrorModel || (createAirdomeModel && createAirdomeModel.createAirdome.getLastExecutionFailed!()))
          return;
  
        onAirdomeCreated();
      },
      countries: airdomeStore.countries,
      templates: airdomeStore.templates,
      errorModel: errorStore.ErrorModel
    }}/>
    : null;
}

export default observer(CreateAirdomeFormWrapper);