import React, { useState } from 'react';

import IntervalCategory from 'models/calendar/IntervalCategory';
import IntervalModel from 'models/calendar/IntervalModel';
import IntervalSelection from 'models/calendar/IntervalSelection';
import TimeSpan from 'dto/TimeSpan';
import TimeSpanRange from 'utils/TimeSpanRange';
import { UncontrolledTooltip } from 'reactstrap';
import objectHelper from 'utils/ObjectHelper';
import { observer } from 'mobx-react';

interface Props {
  tableId?: string;
  intervals?: IntervalModel[];
  categories?: IntervalCategory[];
  intervalSelected?: (selection: IntervalSelection) => void;
  intervalClicked?: (intervalClicked: IntervalSelection) => void;
  categoryClick?: (category: IntervalCategory) => void;
}

const cellIntervalHours = 4;
const timeSelectionGranularityMinutes = 15;
const wholeDay = TimeSpan.fromDays(1);
const cellsPerInterval = cellIntervalHours * 60 / timeSelectionGranularityMinutes;
const tableIntervalsCount = wholeDay.totalHours / cellIntervalHours;

const timeCells = objectHelper
  .getRange(tableIntervalsCount * cellsPerInterval, 0, timeSelectionGranularityMinutes)
  .map((minutes) => new TimeSpanRange(TimeSpan.fromMinutes(minutes), TimeSpan.fromMinutes(minutes + timeSelectionGranularityMinutes)));

const isCellFirstInHour = (range: TimeSpanRange) =>
  range.start.totalHours % cellIntervalHours === 0 && range.start.minutes === 0;

const CalendarTable = (props: Props) => {
  const [selectionStart, SetSelectionStart] = useState<TimeSpanRange | undefined>(undefined);
  const [currentIntervalSelection, setSelection] = useState<IntervalSelection | undefined>(undefined);

  const getIntervalsForRow = (categoryId: number, intervals?: IntervalModel[]): IntervalModel[] =>
    intervals?.filter(interval => interval.categoryId === categoryId) || [];

  const tableBody = props.categories?.map((category, categoryIndex) => {
    const rowIndex = categoryIndex + 1;

    const preparedIntervals = getIntervalsForRow(category.id, props.intervals)
      .map(interval => {
        const startCellId = Math.ceil(interval.start.totalMinutes / timeSelectionGranularityMinutes);
        const endCellId = TimeSpan.subtract(interval.end, interval.start).totalMinutes < timeSelectionGranularityMinutes
          ? startCellId
          : Math.floor(interval.end.totalMinutes / timeSelectionGranularityMinutes);

        return {
          interval,
          startCellId,
          endCellId,
          range: new TimeSpanRange(interval.start, interval.end)
        }
      })
      .sortBy([x => x.startCellId]);

    return (
      <React.Fragment key={categoryIndex}>
        <span className={[
          `row-${rowIndex} row-header`,
          props.categoryClick ? "is-clickable" : ""
        ].join(' ')}
          onClick={() => props.categoryClick && props.categoryClick(category)}>
          {category.name}
        </span>
        {
          timeCells.reduce((cells, range, _) => {
            const cellId = Math.floor(range.start.totalMinutes / timeSelectionGranularityMinutes);
            const classNames = [
              `row-${rowIndex}`,
              `cell-${cellId + 1}`,
              'clickable',
              'cell'
            ];

            currentIntervalSelection?.category.id === category.id &&
              currentIntervalSelection?.timeRange.containsRange(range) &&
              classNames.push("selecting");

            const containingInterval = preparedIntervals.find(x => 
              (x.startCellId === x.endCellId && x.startCellId === cellId) 
              || (x.startCellId <= cellId && cellId < x.endCellId));
            if (containingInterval) {
              // Don't re-render interval
              if (containingInterval.startCellId !== cellId)
                return cells;

              const intervalElementId = `interval-${props.tableId}-${category.id}-${cellId}`;
              cells.push(
                <React.Fragment key={`interval-${containingInterval.startCellId}-${containingInterval.endCellId + 1}`}>
                  <div
                    id={intervalElementId}
                    className={`interval interval-start-${containingInterval.startCellId + 1} interval-end-${containingInterval.endCellId + 1} row-${rowIndex} background--${containingInterval.interval.type}${containingInterval.interval.isSaved ? "" : " not-saved"}`}
                    style={{
                      gridColumnStart: containingInterval.startCellId + 2,
                      gridColumnEnd: containingInterval.endCellId + 2
                    }}
                    onClick={() => props.intervalClicked && props.intervalClicked({
                      category,
                      timeRange: new TimeSpanRange(containingInterval.interval.start, containingInterval.interval.end)
                    })}>
                  </div>
                  <UncontrolledTooltip delay={{ show: 0, hide: 50 }} target={intervalElementId}>
                    {containingInterval.interval.start.toLocalizedString()}–{containingInterval.interval.end.toLocalizedString()}
                  </UncontrolledTooltip>
                </React.Fragment>
              );
              return cells;
            }

            cells.push(
              <span
                key={cellId}
                className={classNames.join(' ')}
                onMouseDown={() => {
                  const newSelectionStart = new TimeSpanRange(range.start, range.end);
                  SetSelectionStart(newSelectionStart);
                  setSelection({
                    category,
                    timeRange: newSelectionStart
                  });
                }}
                onMouseOver={() => {
                  if (currentIntervalSelection && selectionStart) {
                    const newStart = range.start.totalMinutes < selectionStart.start.totalMinutes
                      ? range.start
                      : selectionStart.start;
                    const newEnd = range.end.totalMinutes > selectionStart.end.totalMinutes
                      ? range.end
                      : selectionStart.end;
                    setSelection({
                      category: currentIntervalSelection.category,
                      timeRange: new TimeSpanRange(newStart, newEnd)
                    });
                  }
                }}
              />
            )

            return cells;
          }, [] as JSX.Element[])
        }
        <span className={`header-row-separator-${categoryIndex}`} />
        <span className={`table-row-separator-${categoryIndex}`} />
      </React.Fragment>
    )
  });

  return (
    <div className="calendar-table-container">
      <div className={`r-calendar-table r-calendar-table-${props.categories?.length ?? 1} w-100`}
        onMouseUp={() => {
          if (currentIntervalSelection && props.intervalSelected) {
            props.intervalSelected(currentIntervalSelection);
          }
          setSelection(undefined);
        }}>
        {timeCells
          .filter(range => isCellFirstInHour(range))
          .map((range, index) => (
            <span key={`header-${index}`} className={`hour hour-${range.start.hours}`}>
              {range.start.toLocalizedString()}
            </span>
          ))}
        {tableBody}
      </div>
    </div>
  )
};

export default observer(CalendarTable);