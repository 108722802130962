import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { PureComponent } from 'react';
import TopbarMenuLink from './TopbarMenuLink';
import Translate from '../../../localization/Translate';

interface TopbarProfileProps {
  translate: Translate;
  userName: string;
}

interface TopbarProfileState {
  collapse: boolean;
}

export default class TopbarProfile extends PureComponent<TopbarProfileProps, TopbarProfileState> {
  constructor(props?: any) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  private toggle = () =>
    this.setState({ collapse: !this.state.collapse })

  public render() {
    const { translate, userName } = this.props;
    
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <p className="topbar__avatar-name">{userName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title={translate(x => x.menu.user.edit)} icon="user" onClick={this.toggle} path="/user-profile" />
            <TopbarMenuLink title={translate(x => x.menu.user.logout)} icon="exit" onClick={this.toggle} path="/logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}
