import LockIcon from 'mdi-react/LockIcon';
import LockOpenIcon from 'mdi-react/LockOpenIcon';
import React from 'react';

export default class {
  public id: string;
  public username: string;
  public isLocked: boolean;

  constructor(
    data: {
      id: string;
      username: string;
      isLocked: boolean;
    }
  ) {
    this.id = data.id;
    this.username = data.username;
    this.isLocked = data.isLocked;
  }
  public get lockIcon(): JSX.Element {
    return this.isLocked
      ? <LockIcon />
      : <LockOpenIcon />;
  }
}
