import ChangeRequest from '../../base/enums/ChangeRequest';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';

const changeRequest: ChangeRequest = {
  [ChangeRequestTypeEnum.Settings]: 'der Einstellungen',
  [ChangeRequestTypeEnum.Intervals]: 'intervalle',
  [ChangeRequestTypeEnum.Schedule]: 'Kalendar',
  [ChangeRequestTypeEnum.Lights]: 'lichteinstellungen',
  [ChangeRequestTypeEnum.DoorLockSettings]: 'Türschloss',
  [ChangeRequestTypeEnum.BacnetObjectWrite]: 'Bacnet-Objekte schreiben',
  [ChangeRequestTypeEnum.LogLevel]: 'log level',
  [ChangeRequestTypeEnum.ResolveNotifications]: 'resolve notifications',
  [ChangeRequestTypeEnum.PreheatingSettings]: 'vorflut'
};

export default changeRequest;
