import React, { Component } from 'react';
import { renderItem } from './RenderItem';

export interface ItemProps {
  buttonFunctions: () => void;
  name: string;
  icon?: JSX.Element;
  fill?: string;
}

export interface RadialProps {
  className: string;
  innerRadius: number;
  outerRadius: number;
  stroke: string;
  strokeWidth: number;
  cx: number;
  cy: number;
  data: ItemProps[];
  fill: string;
  updateData: () => void;
}

export default class extends Component<RadialProps> {
  public componentDidMount() {
    this.props.updateData();
  }

  public render() {
    const {
      className,
      innerRadius,
      outerRadius,
      stroke,
      strokeWidth,
      cx,
      cy,
      data,
      fill
    } = this.props;
    const totalRadius = innerRadius + outerRadius;
    const size = totalRadius * 2 + strokeWidth * 2;

    const nodes = data.map((x, i) => ({
      data: x,
      state: renderItem(this.props, x.name, i)
    }));
    return <div className={className} style={{
      position: 'fixed',
      zIndex: 500,
      width: `${size}px`,
      height: `${size}px`,
      transform: `translate(${cx - totalRadius}px,${cy - totalRadius}px)`
    }}>
      <svg
        viewBox={`0 0 ${size} ${size}`}
        style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', }}>
        {nodes.map(({ state, data }, index) =>
          <g {...{
            ...state.g,
            key: index,
            fillOpacity: 0.4,
            fill: data.fill || fill,
            color: data.fill ? '#444' : '#888',
            onMouseOver: (e) => {
              e.currentTarget.style.fillOpacity = '1';
              e.currentTarget.style.fill = '#8ef592';
              e.currentTarget.style.color = '#444';
              e.currentTarget.style.cursor = 'pointer';
            },
            onMouseOut: (e) => {
              e.currentTarget.style.fillOpacity = '.4';
              e.currentTarget.style.fill = data.fill || fill;
              if (!data.fill)
                e.currentTarget.style.color = '#888';
              e.currentTarget.style.removeProperty('cursor');
            },
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();

              data.buttonFunctions();
            }
          }}>
            <path {...{
              ...state.region,
              style: {
                ...state.region.style,
                ...{
                  stroke,
                  fill: 'inherit'
                }
              }
            }} />
            <path {...state.arc} />
            {data.icon}
          </g>)}
      </svg>
    </div>;
  }
}
