import React from 'react';
import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import Translate from '../../../../localization/Translate';
import { StringSchema } from 'yup';
import { observer } from 'mobx-react';

interface PasswordFieldProps {
  validationSchema: StringSchema;
  translate: Translate;
  password?: string;
  setPassword: (password?: string) => void;
}

const PasswordField = ({ validationSchema, translate, password, setPassword }: PasswordFieldProps) => <FormInput {...{
  translate,
  model: new FormInputModel({
    validationSchema: validationSchema.label(translate(x => x.general.user.password)),
    handleValueModel: new HandleValueModel({
      setValue: setPassword,
      inputType: 'password'
    }),
    value: password,
    placeholder: translate(x => x.general.user.password),
    label: translate(x => x.general.user.password),
    inputId: 'Password'
  })
}} />;

export default observer(PasswordField);
