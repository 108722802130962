import LightState from '../../base/enums/LightState';

const lightState: LightState = {
  1: {
    short: 'Auto',
    full: 'Auto'
  },
  2: {
    short: 'Aus',
    full: 'Aus'
  },
  3: {
    short: 'Zün.',
    full: 'Zündete'
  }
};

export default lightState;
