import {
  AirdomeDto,
  AirdomeListItemExtendedDto,
  CountryDto,
  MacAddressDto,
  TemplateDto,
} from "../dto/Container";
import { AirdomeModel, CreateAirdomeModel } from "../models";
import {
  AirdomeService,
  CountriesService,
  PlcNotificationsHubConnection,
  RegistryMultiAirdomeHubConnection,
  RegistrySingleAirdomeHubConnection,
  StatusAirdomeService,
  TemplateService,
} from "../services";
import BaseStore, { Params } from "./base/BaseStore";
import { action, computed, observable } from "mobx";

import AccessDefinitions from "security/AccessDefinitions";
import AirdomeState from "../localization/base/enums/AirdomeState";
import ErrorStore from "./errors/ErrorStore";
import HeartbeatAirdomeService from "services/heartbeat/HeartbeatAirdomeService";
import HeartbeatMultiAirdomeHubConnection from "services/signalr/HeartbeatMultiAirdomeHubConnection";
import NotificationDto from "../dto/notification/NotificationDto";
import SecurityStore from "./SecurityStore";
import { asTracked } from "./decorators/TrackedDecorator";
import { catchError } from "./decorators";

export interface AirdomeStoreParams {
  airdomeService: AirdomeService;
  errorStore: ErrorStore;
  templateService: TemplateService;
  countriesService: CountriesService;
  heartbeatMultiAirdomeHubConnection: HeartbeatMultiAirdomeHubConnection;
  registrySingleAirdomeHubConnection: RegistrySingleAirdomeHubConnection;
  registryMultiAirdomeHubConnection: RegistryMultiAirdomeHubConnection;
  statusAirdomeService: StatusAirdomeService;
  heartbeatAirdomeService: HeartbeatAirdomeService;
  plcNotificationsHubConnection: PlcNotificationsHubConnection;
  getTrackingId: () => string;
}

export default class AirdomeStore extends BaseStore<AirdomeStoreParams> {
  public userHasAirdomeManageRights: boolean;

  @observable
  private selectedAirdomeId?: number;

  @observable
  public airdomes: AirdomeModel[] = [];

  @computed
  public get SelectedAirdomeId(): number | undefined {
    return this.selectedAirdomeId;
  }

  @computed
  public get SelectedAirdome(): AirdomeModel | undefined {
    return this.airdomes.find((x) => x.id === this.selectedAirdomeId);
  }

  @observable
  public templates: TemplateDto[] = [];

  @observable
  public countries: CountryDto[] = [];

  constructor(
    params: Params<AirdomeStoreParams>,
    securityStore: SecurityStore
  ) {
    super(params);

    this.userHasAirdomeManageRights = securityStore.isAccessAllowed(
      AccessDefinitions.Airdomes.Manage
    );

    super.setLoadPromise(async () => {
      await this.fetchAirdomes();

      this.params.heartbeatMultiAirdomeHubConnection.onConnectionStateChanged(
        this.connectionStateChanged
      );

      if (this.userHasAirdomeManageRights)
        this.params.registryMultiAirdomeHubConnection.onAirdomeCreated(
          this.addAirdome
        );

      this.params.registrySingleAirdomeHubConnection.onAirdomeIsInitializedChanged(
        this.setAirdomeIsInitialized
      );
      this.params.registrySingleAirdomeHubConnection.onAirdomeIsInflatedChanged(
        this.setAirdomeIsInflated
      );
      this.params.plcNotificationsHubConnection.onNotificationOccurred(
        this.notificationOccurred
      );
      this.params.plcNotificationsHubConnection.onNotificationResolved(
        this.notificationResolved
      );
      this.params.plcNotificationsHubConnection.onNotificationsResolved(
        this.notificationsResolved
      );
    });
  }

  public fetchAirdomes = asTracked(
    action(async () => {
      const {
        airdomeService,
        statusAirdomeService,
        heartbeatAirdomeService,
        getTrackingId,
      } = this.params;

      const trackingId = getTrackingId();

      try {
        const getAirdomes = async (): Promise<
          (AirdomeDto | AirdomeListItemExtendedDto)[]
        > =>
          this.userHasAirdomeManageRights
            ? await airdomeService.getAirdomesExtended(trackingId)
            : await airdomeService.getAirdomes(trackingId);

        const [airdomeListItems, statusAirdomes, airdomeStates] =
          await Promise.all([
            getAirdomes(),
            statusAirdomeService.getAirdomes(trackingId),
            heartbeatAirdomeService.getAllAirdomeStates(trackingId),
          ]);

        const airdomeModels = airdomeListItems.map((airdomeListItem) => {
          const airdomeModel = new AirdomeModel(airdomeListItem);
          const statusAirdome = statusAirdomes.find(
            (x) => x.id === airdomeListItem.id
          );

          const airdomeState = airdomeStates.find(
            (x) => x.airdomeId === airdomeListItem.id
          );

          if (statusAirdome) {
            airdomeModel.setNotificationCount(statusAirdome.notificationCount);
          }

          if (airdomeState) {
            airdomeModel.setState(airdomeState.state);
            airdomeModel.setLastContact(airdomeState.lastContact);
          }

          return airdomeModel;
        });

        this.setAirdomes(airdomeModels);
      } catch (error) {
        this.params.loggerStore.logError(error.message, trackingId);
        this.params.errorStore.handleErrorModel(trackingId, error);
      }
    })
  );

  public getCreateAirdomeModel = () =>
    new CreateAirdomeModel({
      ...this.params,
      airdomeStore: this,
    });

  @action
  public notificationOccurred = (
    airdomeId: number,
    notification: NotificationDto
  ) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);
    if (!airdome) return;

    const currentNotificationCount = airdome.notificationCount || 0;
    airdome.setNotificationCount(currentNotificationCount + 1);
  };

  @action
  public notificationResolved = (
    airdomeId: number,
    notification: NotificationDto
  ) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);
    if (!airdome) return;

    const currentNotificationCount = airdome.notificationCount || 0;
    airdome.setNotificationCount(currentNotificationCount - 1);
  };

  @action
  public notificationsResolved = (
    airdomeId: number,
    notifications: NotificationDto[]
  ) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);
    if (!airdome) return;

    const currentNotificationCount = airdome.notificationCount || 0;
    airdome.setNotificationCount(
      currentNotificationCount - notifications.length
    );
  };

  @action
  public setAirdomes = (value: AirdomeModel[]) => (this.airdomes = value);

  @action
  public addAirdome = (value: AirdomeListItemExtendedDto) => {
    if (this.airdomes.find((x) => x.id === value.id)) return;

    this.airdomes.push(new AirdomeModel(value));
  };

  @action
  public setAirdomeIsInitialized = (
    airdomeId: number,
    isInitialized: boolean
  ) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);

    if (!airdome) return;

    airdome.setIsInitialized(isInitialized);
  };

  @action
  public updateAirdomesMacAddresses = (
    airdomeId: number,
    macAddresses: MacAddressDto[]
  ) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);

    if (!airdome) return;

    airdome.macAddresses = macAddresses;
  };

  @action
  public setAirdomeIsInflated = (airdomeId: number, isInflated: boolean) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);

    if (!airdome) return;

    airdome.setIsInflated(isInflated);
  };

  @action
  public setSelectedAirdomeId = (airdomeId: number) =>
    (this.selectedAirdomeId = airdomeId);

  @action
  public clearAirdomeSelection = () => (this.selectedAirdomeId = undefined);

  @action
  public setTemplates = (value: TemplateDto[]) => (this.templates = value);

  @action
  public setCountries = (value: CountryDto[]) => (this.countries = value);

  @action
  private connectionStateChanged = (
    airdomeId: number,
    newState: keyof AirdomeState
  ) => {
    const airdome = this.airdomes.find((x) => x.id === airdomeId);
    if (airdome) airdome.setState(newState);
  };

  @catchError
  @action
  public fetchTemplates = async (trackingId: string = "") => {
    const templates = await this.params.templateService.getTemplates(
      trackingId
    );
    if (templates.length > 0) this.setTemplates(templates);
    else this.params.errorStore.handleErrorModel(trackingId);
  };

  @catchError
  @action
  public fetchCountries = async (trackingId: string = "") => {
    const countries = await this.params.countriesService.getCountries(
      trackingId
    );
    if (countries.length > 0) this.setCountries(countries);
    else this.params.errorStore.handleErrorModel(trackingId);
  };
}
