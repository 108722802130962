import ReactChildrenProp from 'common/ReactChildrenProp';

interface Props {
  children: ReactChildrenProp;
}

const FormFieldGroupInputWrap = (props: Props) => (
  <div className="form__form-group-input-wrap">
    {props.children}
  </div>
);

export default FormFieldGroupInputWrap;