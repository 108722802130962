import Datatables from '../../../base/general/datatables';

const datatables: Datatables = {
  previousPage: 'Vorherige Seite',
  nextPage: 'Nächste Seite',
  search: 'Suche',
  numberOfRowsShown: ' ',
  displayedRows: 'Seite {page}'
};

export default datatables;
