import { AirdomeStore, SecurityStore } from 'stores';

import AirdomeHubConnection from './base/AirdomeHubConnection';
import { ChangeRequestTypeEnum } from '../../dto/changeRequest/ChangeRequestTypeEnum';
import UserDto from 'dto/changeRequest/UserDto';
import { getJoinedEndpoint } from '../../utils/Settings';

type ChangeRequestMethods = '';
type ChangeRequestEvents = 'Created' | 'Finished' | 'Failed';

export default class ChangeRequestHubConnection extends AirdomeHubConnection<ChangeRequestEvents, ChangeRequestMethods> {
  constructor(airdomeStore: AirdomeStore, securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.airdome.changeRequest
    ), airdomeStore, securityStore);
  }

  public onCreated = (callback: (airdomeId: number, createdBy: UserDto, chRType: ChangeRequestTypeEnum) => void) => {
    super.on('Created', callback);
  }

  public onFinished = (callback: (airdomeId: number, createdBy: UserDto, chRType: ChangeRequestTypeEnum) => void) => {
    super.on('Finished', callback);
  }

  public onFailed = (callback: (airdomeId: number, createdBy: UserDto, chRType: ChangeRequestTypeEnum) => void) => {
    super.on('Failed', callback);
  }
}
