import { StatefulFunction } from '../shared/StatefulFunction';
import DecoratorBase from './DecoratorBase';

class ProcessingState extends DecoratorBase<object> {
  protected wrapMethod = (wrapper: StatefulFunction, method: Function) => {
    return async (store: object, ...args: any[]) => {
      wrapper.setProcessing!(true);
      let result;
      try {
        result = await method.bind(store)(...args);
      } catch (err) {
        throw err;
      } finally {
        wrapper.setProcessing!(false);
      }

      return result;
    };
  }
}

export default new ProcessingState().handle;
