import { observable, IObservableValue, action } from 'mobx';
import AlertModel from './AlertModel';

export interface StatefulFunction {
  (...args: any[]): Promise<any>;
  isStateful?: boolean;
  alerts?: AlertModel[];
  getProcessing?: () => boolean;
  setProcessing?: (value: boolean) => void;
  getLastExecutionFailed?: () => boolean;
  setLastExecutionFailed?: (value: boolean) => void;
}

const createStatefulFunction = (callback: (...args: any[]) => any): StatefulFunction => {
  const callable = <StatefulFunction & {
    processing: IObservableValue<boolean>,
    lastExecutionFailed: IObservableValue<boolean>
  }>callback;

  callable.alerts = [];
  callable.isStateful = true;
  callable.processing = observable.box(false);
  callable.lastExecutionFailed = observable.box(false);

  callable.getProcessing = () => callable.processing.get();
  callable.setProcessing = action((value: boolean) => callable.processing.set(value));
  callable.getLastExecutionFailed = () => callable.lastExecutionFailed.get();
  callable.setLastExecutionFailed = action((value: boolean) => callable.lastExecutionFailed.set(value));
  return callable;
};

export default createStatefulFunction;
