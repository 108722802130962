import Airdome from '../../../base/menu/airdome';
import bacnetObjects from './BacnetObjects';
import lighting from './Lighting';
import plcNotifications from './PlcNotifications';
import settings from './Settings';

const airdome: Airdome = {
  lighting,
  settings,
  bacnetObjects,
  plcNotifications,

  create: 'Halle hinzufügen',
  list: 'Traglufthallen',
  status: 'Stand',
  calendar: 'Kalendar',
  consumption: 'Verbrauch',
  restrictions: 'Begrenzung',
  switchbox: 'Schaltanlage',
  systemLog: 'Systemprotokoll',
  rdevNotifications: 'RDev Notifizierung',
  doorLockSettings: 'Türverriegelung',
  preheatingSettings: 'Vorluft',
  history: 'Historie'
};

export default airdome;
