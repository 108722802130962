import React from 'react';
import Col from 'reactstrap/lib/Col';
import { TranslateProps } from '../../../interfaces/Props';
import Translation from '../../../localization/base';
import objectHelper from '../../../utils/ObjectHelper';

export const iconSize = 48;

const grayedOutColor = '#666';

const getColorHex = (value: number, settings: TemperatureSettings): string => {
  if (value < settings.warmLowerBound) return '#03b8ee';
  if (value < settings.hotLowerBound) return '#f57314';
  return '#ff1c1f';
};

export const getColorIfDefined = (value: number | undefined, settings: TemperatureSettings): string =>
  objectHelper.executeIfNumber(value,
    x => getColorHex(x, settings))
  || grayedOutColor;

export const chartStyle = {
  innerRadius: 90,
  outerRadius: 100
};

interface TemperatureSettings {
  warmLowerBound: number;
  hotLowerBound: number;
}

export const wrapGauge = (element: JSX.Element, className?: string) =>
  <Col lg={4} className={`col-xxs-12 ${className || ''}`}>
    {element}
  </Col>;

export const isTranslateSame = (oldProps: TranslateProps, newProps: TranslateProps,
  getTranslation: (translation: Translation) => string) =>
  oldProps.translate
  && newProps.translate
  && oldProps.translate(getTranslation) === newProps.translate(getTranslation);
