/* eslint-disable jsx-a11y/anchor-is-valid */

import "react-datepicker/dist/react-datepicker.css";
import './DateTimePicker.scss';

import React, { useEffect, useState } from 'react';
import { cs, de, enGB } from 'date-fns/locale'

import ReactDatePicker from 'react-datepicker';
import SupportedLocale from "enums/SupportedLocale";
import moment from 'moment';
import { useLocalization } from 'localization/LocalizationProvider';

interface DatePickerProps {
  onValueChanged?: (date: moment.Moment) => void;
  locale: SupportedLocale;
  value: moment.Moment;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
}

const getDatePickerLocale = (locale: SupportedLocale) => {
  switch (locale) {
    case SupportedLocale.csCZ:
      return cs;
    case SupportedLocale.enGB:
      return enGB;
    case SupportedLocale.deDE:
      return de;
    default:
      throw new Error('Locale does not exist!');
  }
}

interface ReactDatePickerInputProps {
  maxDate?: Date;
  minDate?: Date;
  value: string;
  onClick: () => void;
  onChange: (x: any) => void;
  onKeyDown: () => void;
}

const CustomDateTimeInput = ({ value, onClick, onChange, onKeyDown }: ReactDatePickerInputProps) => {
  const [inputState, setInputState] = useState(value);

  useEffect(() => {
    setInputState(value);
  }, [value]);

  const onChangeFinished = () =>
    onChange({
      target: {
        value: inputState
      }
    })

  return <div className="form-group mb-0">
    <div className="input-group date customDateTime">
      <input
        type="text"
        className="form-control datetimepicker-input"
        value={inputState}
        onClick={onClick}
        onChange={x => setInputState(x.target?.value ?? '')}
        onBlur={onChangeFinished}
        onKeyDown={onKeyDown} />
      <div className="input-group-append">
        <div className="input-group-text clickable" onClick={onClick}>
          <i className="lnr lnr-calendar-full"></i>
        </div>
      </div>
    </div>
  </div>
};

const InnerTimeSelect = React.memo(({ value, onChange }: {
  value: string,
  onChange: (value: string) => void
}) => {
  const [hourRaw, minuteRaw] = value.split(':');
  const [hour, minute] = [Number(hourRaw), Number(minuteRaw)];

  if(Number.isNaN(hour) || Number.isNaN(minute)) {
    onChange("00:00");
    return null;
  }

  const minGran = Math.floor(minute / 10);

  return (
    <div className="table-condensed">
      <span className="hour-up lnr lnr-arrow-up h3 clickable" onClick={() => onChange(`${(hour + 1) % 24}:${minute}`)}></span>
      <span className="hour-value">{hour}</span>
      <span className="hour-down lnr lnr-arrow-down h3 clickable" onClick={() => onChange(`${(hour - 1) % 24}:${minute}`)}></span>
      
      <span className="separator">:</span>

      <span className="minute-up lnr lnr-arrow-up h3 clickable" onClick={() => onChange(`${hour}:${(minGran * 10 + 10) % 60}`)}></span>
      <span className="minute-value">{minute.toString().padStart(2, '0')}</span>
      <span className="minute-down lnr lnr-arrow-down h3 clickable" onClick={() => onChange(`${hour}:${(minGran * 10 - 10) % 60}`)}></span>
    </div>
  );
});

const DateTimePicker = (props: DatePickerProps) => {
  useLocalization();

  const minDate = props.minDate?.toDate();
  const maxDate = props.maxDate?.toDate();
  return <ReactDatePicker
    showTimeInput
    locale={getDatePickerLocale(props.locale)}
    minDate={minDate}
    maxDate={maxDate}
    selected={props.value?.toDate()}
    wrapperClassName="d-block"
    dateFormat="Pp"
    calendarClassName="d-flex align-items-center position-relative"
    timeInputLabel=""
    timeIntervals={60}
    onChange={date => {
      if (!date || Array.isArray(date))
        return;

      props.onValueChanged?.(moment(date));
    }}
    // @ts-ignore
    customInput={<CustomDateTimeInput />}
    // @ts-ignore
    customTimeInput={<InnerTimeSelect />}
    
  />;
}

export default DateTimePicker;