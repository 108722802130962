import '../../scss/pages/AirdomeList.scss';

import * as React from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import { MaterialTable, SearchBar } from '../../components';

import AirdomeListItemModel from '../../models/AirdomeListItemModel';
import AirdomeListStore from '../../stores/AirdomeListStore';
import BasePage from '../BasePage';
import LanguageStore from '../../stores/LanguageStore';
import { LinearProgress } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { RouteComponentProps } from 'react-router';
import { State } from '../../stores/shared/NewStatefulFunction';
import { basePageInject } from '../BasePageInject';
import getAirdomeListColumns from './AirdomeListColumns';

interface AirdomeListProps {
  languageStore: LanguageStore;
  createInstancedStore: <TStore>(createStore: (rootStore: RootStore) => TStore) => TStore;
}

@basePageInject('languageStore', 'createInstancedStore')
export default class AirdomeList extends BasePage<AirdomeListProps & RouteComponentProps> {
  private store: AirdomeListStore;
  
  constructor(props: any) {
    super(props);

    this.store = this.props.createInstancedStore(rootStore => new AirdomeListStore(rootStore));
  }

  private onNameClick = (item: AirdomeListItemModel) => {
    this.props.airdomeStore!.setSelectedAirdomeId(item.id);
    this.props.history.push(`/airdome/${item.id}/status`);
  }

  private onNotificationsClick = (item: AirdomeListItemModel) => {
  }

  public render() {
    const translate = this.props.languageStore.translate;
    const locale = this.props.languageStore.Locale;

    const columns = getAirdomeListColumns(
      locale, 
      translate, 
      this.onNameClick, 
      this.onNotificationsClick,
      this.store.UserHasAirdomeManageRights);

    const isLoading = !this.props.airdomeStore || this.props.airdomeStore.fetchAirdomes.state === State.Processing;

    return this.renderCard(
      <>
        <div className="d-flex">
          <h1 className="lnr lnr-list pt-1 pr-2" />
          <h1>{translate(x => x.pages.airdomeList.header)}</h1>
        </div>
        <SearchBar {...{
          translate,
          onChange: this.store.setAirdomeSearch,
          className: 'offset-sm-0 offset-md-6 offset-xl-8 col-md-6 col-xl-4 col-sm-12'
        }} />
        {isLoading
          ? <div className="my-4">
            <LinearProgress />
          </div>
          : <MaterialTable {... {
            columns,
            translate,
            showPagination: false,
            search: this.store.airdomeSearch,
            searchIn: columns,
            clickRowToSelect: false,
            multiselect: false,
            rowsPerPageOptions: [10, 25, 50],
            showCheckboxColumn: false,
            data: this.store.Airdomes
          }} />}
      </>
    );
  }

  private renderCard = (children: React.ReactNode) => (
    <Card>
      <CardBody>
        {children}
      </CardBody>
    </Card>
  )
}
