import './SelectorCard.scss';

import * as yup from 'yup';

import { Card, CardBody, Col } from 'reactstrap';
import { FormInputModel, HandleValueModel } from 'models';

import FormInput from 'components/basic/FormInput';
import RadialMenuSelect from '../basic/RadialMenuSelect';
import { SVGProps } from 'react';
import { Spinner } from 'reactstrap/lib';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

const getFirstCoords = (size: number) => ({
  icon: {
    width: size * 0.475,
    height: size * 0.5625,
    x: size * 1.175,
    y: size * 0.3125
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 1.45,
    y: size * 1.0625
  }
});

const getSecondCoords = (size: number) => ({
  icon: {
    width: size * 0.475,
    height: size * 0.5625,
    x: size * 0.75,
    y: size * 1.1875
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 0.975,
    y: size * 1.925
  }
});

const getThirdCoords = (size: number) => ({
  icon: {
    width: size * 0.475,
    height: size * 0.5625,
    x: size * 0.325,
    y: size * 0.3125
  },
  text: {
    width: size * 0.375,
    height: size * 0.5625,
    x: size * 0.55,
    y: size * 1.0625
  }
});

const DefaultSelectorSize = 120;

interface Selection<TValue> {
  getIcon: (props: SVGProps<SVGSVGElement> | undefined) => JSX.Element,
  display: {
    shortName: string,
    fullName: string
  },
  value: TValue
}

const SelectorCard = <TValue,>(props: {
  name: string;
  setName: (newName: string) => void;
  disabled?: boolean;
  currentValue: TValue;
  selectValue: (newValue: TValue) => void;
  selections: [Selection<TValue>, Selection<TValue>, Selection<TValue>]
}) => {
  const translate = useLocalization();
  const {
    name,
    setName,
    disabled,
    currentValue,
    selectValue,
    selections: [
      firstSelection,
      secondSelection,
      thirdSelection
    ]
  } = props;

  return (
    <Col xs={12} sm={6} md={12} lg={6} xl={4} className="col-xxl-3">
      <Card>
        <CardBody style={{ height: 300, position: 'relative' }} className="selector-card">
          {
            disabled && <div className="overlay d-flex align-items-center justify-content-center">
              <Spinner color='secondary' />
            </div>
          }
          <FormInput className="form-field-marginless-y" model={new FormInputModel({
            inputId: '',
            value: name,
            validationSchema: yup
              .string()
              .required()
              .label(translate(x => x.general.name)),
            handleValueModel: new HandleValueModel({
              inputType: 'text',
              setValue: name => setName(name ?? '')
            })
          })}/>
          <RadialMenuSelect<TValue> {...{
            currentValue,
            selectValue,
            selectorSize: DefaultSelectorSize,
            readonly: disabled,
            options: [
              {
                ...firstSelection,
                getCoords: getFirstCoords
              },
              {
                ...secondSelection,
                getCoords: getSecondCoords
              },
              {
                ...thirdSelection,
                getCoords: getThirdCoords
              }
            ],

            selector: {
              className: 'mt-3 selectorRadial'
            }
          }}/>
        </CardBody>
      </Card>
    </Col>
  );
}

export default observer(SelectorCard);