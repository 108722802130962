import ArtificialIntelligence from '../../../../base/forms/airdome/artificialIntelligence';

const artificialIntelligence: ArtificialIntelligence = {
  constantsHeader: 'Výchozí regulační konstanty pro umělou inteligenci',
  mainHeader: 'Umělá inteligence',
  intakeMultiplier: 'Zesílení regulace T přívod',
  intakeIntegration: 'Integrační regulace T přívod',
  spaceMultiplier: 'Zesílení regulace T prostor',
  spaceIntegration: 'Integrační regulace T prostor'
};

export default artificialIntelligence;
