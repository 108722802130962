import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import InfoFormProps from '../InfoFormProps';
import React from 'react';
import { observer } from 'mobx-react';

const NameField = ({ translate, infoModel, disabled }: InfoFormProps) => {
  const label = translate(x => x.general.airdome.name);

  return <FormInput {...{
    model: new FormInputModel({
      label,
      readonly: disabled,
      validationSchema: infoModel.getNameValidationSchema().label(label),
      placeholder: label,
      handleValueModel: new HandleValueModel({
        setValue: infoModel.name.setValue,
        inputType: 'text'
      }),
      inputId: 'NameField',
      value: infoModel.name.Value || ''
    })
  }} />;
};

export default observer(NameField);
