import { Provider, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import AirdomeList from 'pages/AirdomeList/AirdomeList';
import BacnetObjectsRead from 'pages/BacnetObjects/BacnetObjectsRead';
import BacnetObjectsWrite from 'pages/BacnetObjects/BacnetObjectsWrite';
import BacnetTemplateEdit from 'pages/BacnetTemplateEdit';
import BacnetTemplateList from 'pages/BacnetTemplateList';
import BasicRootStore from 'stores/BasicRootStore';
import CalendarPage from 'pages/Calendar';
import Callback from 'pages/Callback';
import Consumption from 'pages/Consumption';
import { Container } from 'reactstrap';
import CreateAirdome from 'pages/CreateAirdome';
import CreateUser from 'pages/CreateUser';
import DoorLockSettings from 'pages/DoorLockSettings/DoorLockSettings';
import DoorLockSettingsHistory from 'pages/DoorLockSettings/DoorLockSettingsHistory';
import Forbidden from 'pages/ErrorPages/Forbidden';
import Layout from '../layout/Layout';
import Lighting from 'pages/Lighting/Lighting';
import LightingHistory from 'pages/Lighting/LightingHistory';
import LoadingOverlay from './LoadingOverlay';
import { LocalizationProvider } from 'localization/LocalizationProvider';
import LogOutCallback from 'pages/LogOutCallback';
import Logout from 'pages/Logout';
import MainWrapper from './MainWrapper';
import NotFound from 'pages/ErrorPages/NotFound';
import NotificationsHistory from 'pages/Notifications/NotificationsHistory';
import PlcNotifications from 'pages/Notifications/PlcNotifications';
import PreheatingSettings from 'pages/PreheatingSettings/PreheatingSettings';
import PreheatingSettingsHistory from 'pages/PreheatingSettings/PreheatingSettingsHistory';
import RDevNotifications from 'pages/RDevNotifications/RDevNotifications';
import Restrictions from 'pages/Restrictions';
import { RootStore } from 'stores';
import SecureRoute from './SecureRoute';
import Settings from 'pages/Settings';
import SettingsHistory from 'pages/Settings/SettingsHistory';
import SingleAirdomeRouteWrapper from './SingleAirdomeRouteWrapper';
import Status from 'pages/Status';
import SwitchboxPage from 'pages/Switchbox';
import TemplatesPage from 'pages/Templates/TemplatesPage';
import UserList from 'pages/UserList';
import UserProfilePage from 'pages/UserProfile';

const RedirectToAirdomeList = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/airdomes');
  }, [history]);

  return <LoadingOverlay/>;
}

const Pages = () => (
  <>
    <Layout />
    <div className="container__wrap">
      <Container>
        <Switch>
          <SecureRoute exact getAccess={x => x.Airdomes.Read} path="/airdomes" component={AirdomeList} />
          <SecureRoute exact getAccess={x => x.Templates.FullAccess} path="/templates" component={TemplatesPage} />
          <SecureRoute exact getAccess={x => x.Users.FullAccess} path="/users/register" component={CreateUser} />
          <SecureRoute exact getAccess={x => x.Users.FullAccess} path="/users" component={UserList} />
          <SecureRoute exact getAccess={x => x.Airdomes.Manage} path="/airdome/create" component={CreateAirdome} />
          <SecureRoute exact getAccess={x => x.Templates.FullAccess} path="/bacnettemplates/list" component={BacnetTemplateList} />
          <SecureRoute exact getAccess={x => x.Templates.FullAccess} path="/bacnettemplates/edit" component={BacnetTemplateEdit} />
          <SecureRoute getAccess={x => x.Airdomes.Read} path="/airdome/:airdomeId">
            <SingleAirdomeRouteWrapper>
              <Switch>
                <SecureRoute exact getAccess={x => x.Status.Read} path="/airdome/:airdomeId/status" component={Status} />
                <SecureRoute exact getAccess={x => x.Settings.Read} path="/airdome/:airdomeId/settings" component={Settings} />
                <SecureRoute exact getAccess={x => x.Settings.Read} path="/airdome/:airdomeId/settings/history" component={SettingsHistory} />
                <SecureRoute exact getAccess={x => x.Restrictions.Read} path="/airdome/:airdomeId/restrictions" component={Restrictions} />
                <SecureRoute exact getAccess={x => x.Notifications.Plc.Read} path="/airdome/:airdomeId/notifications/plc" component={PlcNotifications} />
                <SecureRoute exact getAccess={x => x.Notifications.Plc.Read} path="/airdome/:airdomeId/notifications/plc/history" component={NotificationsHistory} />
                <SecureRoute exact getAccess={x => x.Notifications.RDev} path="/airdome/:airdomeId/notifications/rdev" component={RDevNotifications} />
                <SecureRoute exact getAccess={x => x.Lights.FullAccess} path="/airdome/:airdomeId/lighting" component={Lighting} />
                <SecureRoute exact getAccess={x => x.Lights.FullAccess} path="/airdome/:airdomeId/lighting/history" component={LightingHistory} />
                <SecureRoute exact getAccess={x => x.Consumptions.Read} path="/airdome/:airdomeId/consumption" component={Consumption} />
                <SecureRoute exact getAccess={x => x.Schedule.FullAccess} path="/airdome/:airdomeId/calendar" component={CalendarPage} />
                <SecureRoute exact getAccess={x => x.Bacnet.FullAccess} path="/airdome/:airdomeId/bacnetObjects" component={BacnetObjectsRead} />
                <SecureRoute exact getAccess={x => x.Bacnet.FullAccess} path="/airdome/:airdomeId/bacnetObjects/read" component={BacnetObjectsRead} />
                <SecureRoute exact getAccess={x => x.Bacnet.FullAccess} path="/airdome/:airdomeId/bacnetObjects/edit" component={BacnetObjectsWrite} />
                <SecureRoute exact getAccess={x => x.DoorLocking.FullAccess} path="/airdome/:airdomeId/door-locking" component={DoorLockSettings} />
                <SecureRoute exact getAccess={x => x.DoorLocking.FullAccess} path="/airdome/:airdomeId/door-locking/history" component={DoorLockSettingsHistory} />
                <SecureRoute exact getAccess={x => x.PreheatingSettings.FullAccess} path="/airdome/:airdomeId/preheating" component={PreheatingSettings} />
                <SecureRoute exact getAccess={x => x.PreheatingSettings.FullAccess} path="/airdome/:airdomeId/preheating/history" component={PreheatingSettingsHistory} />
                <SecureRoute exact getAccess={x => x.SwitchBox.Read} path="/airdome/:airdomeId/switchbox" component={SwitchboxPage} />
              </Switch>
            </SingleAirdomeRouteWrapper>
          </SecureRoute>
          <Route exact path="/user-profile" component={UserProfilePage} />
          <Route exact path="/logout" render={(props: any) => <Logout {...props} />} />
          <Route exact path="/forbidden" component={Forbidden} />
          <Route exact path="/notFound" component={NotFound} />
          <Route component={RedirectToAirdomeList} />
        </Switch>
      </Container>
    </div>
  </>
);

const ApplicationWithRootStore = React.memo(() => {
  const [rootStore] = useState<RootStore>(new RootStore());

  return (
    <Provider {...rootStore}>
      <LocalizationProvider>
        <MainWrapper>
          <main>
            <Pages />
          </main>
        </MainWrapper>
      </LocalizationProvider>
    </Provider>
  );
}, () => true);

const ApplicationWithEnsuredLogin = observer(() => {
  useEffect(() => {
    BasicRootStore.loginStore
      .loadUser()
      .then(() => {
        if (!BasicRootStore.loginStore.isLoggedIn)
          BasicRootStore.loginStore.redirectToSignIn();
      });
  }, []);

  return BasicRootStore.loginStore.isLoggedIn
    ? <ApplicationWithRootStore />
    : <LoadingOverlay />;
});

const Router = () => (
  <Switch>
    <Route path="/callback" component={(props: any) => <Callback {...props} />} />
    <Route path="/logoutcallback" component={(props: any) => <LogOutCallback {...props} />} />
    <Route path="/" component={(props: any) => <ApplicationWithEnsuredLogin {...props} />} />
  </Switch>
);

export default Router;
