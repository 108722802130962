import BacnetObjects from "../../../base/pages/bacnetObjects";

const calendar: BacnetObjects = {
  readTitle: "Prohlížení bacnet objektů",
  writeTitle: "Úprava bacnet objektů",
  selectObjects: "Vybrat objekty",
  bacnetObject: "Bacnet objekt",
  addToSelection: "Přidat do výběru",
  selectedObjects: "Vybrané objekty",
  bacnetType: "Typ",
  bacnetName: "Název",
  objectValues: "Hodnoty objektů",
  sendRequest: "Poslat požadavek",
  noSelectedObjects: "Nejsou vybrány žádné objekty",
  bacnetValue: "Hodnota"
};

export default calendar;
