import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'mdi-react/DeleteIcon';
import MatTableFilterButton from './TableFilterButton';

const TableToolbar = (props: {
  menuItems: { column: string, name: string }[],
  numSelected: number,
  handleDeleteSelected: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  onRequestSort: (event: React.MouseEvent<HTMLElement, MouseEvent>, property: string) => void
}) => (
    <div className="material-table__toolbar-wrap">
      <Toolbar className="material-table__toolbar">
        <div>
          {props.numSelected > 0 && (
            <h5 className="material-table__toolbar-selected">{props.numSelected} <span>selected</span></h5>
          )}
        </div>
        <div>
          {props.numSelected > 0 ? (
            <IconButton
              onClick={props.handleDeleteSelected}
              className="material-table__toolbar-button"
            >
              <DeleteIcon />
            </IconButton>
          ) : (
              <MatTableFilterButton menuItems={props.menuItems} onRequestSort={props.onRequestSort} />
            )}
        </div>
      </Toolbar>
    </div>
  );

export default React.memo(TableToolbar);
