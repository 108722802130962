import Consumption from '../../../base/pages/consumption';
import modal from './Modal';

const consumption: Consumption = {
  modal,

  gas: 'GAS',
  electricity: 'ELECTRICITY',
  header: 'Electricity and gas consumption',
  selectRange: 'Select range',
  descriptionTotal: 'Total consumption',
  descriptionSelection: 'Total number for selected range',
  updateButton: 'Update',
};

export default consumption;
