import HeaderCellLabel from './HeaderCellLabel';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TableColumn } from '.';

interface HeaderCellProps<TItem> {
  column: TableColumn<TItem>;
  order?: 'asc' | 'desc';
  orderBy?: string;
  onRequestSort: (event: React.MouseEvent<HTMLElement, MouseEvent>, property: string) => void;
  disableSorting?: boolean;
}

const HeaderCell = <TItem extends {}>({ column, order, orderBy, onRequestSort, disableSorting }: HeaderCellProps<TItem>) => 
  <TableCell
    className="material-table__cell material-table__cell--sort"
    key={column.id}
    padding={column.disablePadding ? 'none' : 'default'}
    sortDirection={(orderBy && orderBy === column.id) ? order : false}
  >
    <HeaderCellLabel {...{ column, order, orderBy, onRequestSort, disableSorting }} />
  </TableCell>

export default HeaderCell;
