import User from '../../../base/forms/user';
import create from './Create';
import edit from './Edit';
import airdomeAccess from './AirdomeAccess';

const user: User = {
  create,
  edit,
  airdomeAccess
};

export default user;
