import LogLevel from 'enums/LogLevel';
import { Moment } from 'moment-timezone';

export default class LogMessageCommand {
  public airdomeId?: number;
  public timeStamp: Moment;
  public logLevel: LogLevel;
  public message: string;
  public source: string;
  public data?: { [key: string]: any };

  constructor(command: {
    airdomeId?: number;
    timeStamp: Moment;
    logLevel: LogLevel;
    message: string;
    source: string;
    data?: { [key: string]: any };
  }) {
    this.message = command.message;
    this.timeStamp = command.timeStamp;
    this.logLevel = command.logLevel;
    this.source = command.source;
    if (command.airdomeId) this.airdomeId = command.airdomeId;
    if (command.data) this.data = command.data;
  }
}
