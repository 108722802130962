import './Switchbox.scss';

import * as React from 'react';

import { Col, Row } from 'reactstrap';

import SwitchboxCard from './components/SwitchboxCard';
import SwitchboxHeader from './components/SwitchboxHeader';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from 'stores/MobXTypedStoreHook';

const SwitchBox = () => {
  const airdomeStore = useStore(x => x.airdomeStore);
  const store = useStore(x => x.switchBoxStore);

  useEffect(() => {
    if(airdomeStore.SelectedAirdome?.state === 'Alive')
      store.requestSwitchBox();
    

    return () => store.clear();
  }, [store, airdomeStore.SelectedAirdome])

  return <Row>
    <Col xs={12} className="d-flex flex-wrap">
      <SwitchboxHeader />
    </Col>
    
    {airdomeStore.SelectedAirdome?.state === 'Alive'
      ? <Col xs={12} xl={{ offset: 2, size: 8 }}>
        <SwitchboxCard/>
      </Col>
      : null}
  </Row>;
};

export default observer(SwitchBox);
