import { getJoinedEndpoint } from './../../utils/Settings';
import { AxiosInstance } from 'axios';
import { ApiError } from '../../models';
import { CountryDto } from '../../dto/Container';

export default class CountriesService {
  private axios: AxiosInstance;
  private getCountriesUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getCountriesUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.countries.getAll);
  }

  public async getCountries(trackingId: string): Promise<CountryDto[]> {
    try {
      const response = await this.axios.get<CountryDto[]>(this.getCountriesUrl);
      return response.data;
    } catch (error) {
      throw new ApiError({ data: trackingId });
    }
  }
}
