import * as uuid from 'uuid';

import axios, { AxiosInstance } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';

import { JSONParser } from '../utils/JSONParser';
import LanguageStore from './LanguageStore';
import LoggerService from '../services/logger/LoggerService';
import LoggerStore from './LoggerStore';
import LoginStore from './LoginStore';
import SecurityStore from './SecurityStore';

class BasicRootStore {
  public readonly axiosApp: AxiosInstance;
  public readonly getTrackingId: () => string;
  public readonly loggerService: LoggerService;
  public readonly loggerStore: LoggerStore;
  public readonly securityStore: SecurityStore;
  public readonly languageStore: LanguageStore
  public readonly loginStore: LoginStore;

  constructor() {    
    this.axiosApp = this.buildAxiosApp();
    this.getTrackingId = () => uuid.v4();

    this.loggerService = new LoggerService(this.axiosApp);
    this.loggerStore = new LoggerStore(this.loggerService);

    this.securityStore = new SecurityStore();
    this.languageStore = new LanguageStore();
    this.loginStore = new LoginStore(this.securityStore, this.languageStore);
  }

  private buildAxiosApp(): AxiosInstance {
    const axiosApp = axios.create({
      transformRequest: (data, headers) => {
        if (!data || typeof data === 'string')
          return data;

        if (data instanceof FormData)
          return data;

        const jsonContent = JSONParser.stringify(data);

        headers['Content-Type'] = 'application/json';
        return jsonContent;
      },
      transformResponse: x => x && JSONParser.parse(x)
    });

    axiosRetry(axiosApp, {
      retries: 2,
      retryDelay: exponentialDelay,
      retryCondition: x =>
        !x.response
        || (x.response.status >= 500 && x.response.status < 600)
    });

    axiosApp.interceptors.request.use(
      (config) => {
        const token = this.securityStore.AccessToken;

        if (token)
          config.headers['Authorization'] = `Bearer ${token}`;

        return config;
      },
      (error) => {
        Promise.reject(error);
      });

    return axiosApp;
  }
}

export default new BasicRootStore();
