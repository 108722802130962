import * as React from 'react';
import { CheckboxModel } from '../../models';

const formCheckbox = (props: {
  model: CheckboxModel,
  children?: React.Component[]
}) => {
  const { setValue, label, inputId, value } = props.model;
  return <div className="form-group">
    <div className="d-flex">
      <label className="col-md-8 no-padding" htmlFor={inputId}>
        {label}
      </label>
      <div className="col-md-3">
        <input
          type="checkbox"
          id={inputId}
          name={inputId}
          checked={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target instanceof HTMLInputElement)
              setValue(e.target.checked);
          }}
        />
      </div>
    </div>
    {props.children}
  </div>;
};

export default React.memo(formCheckbox);
