import React from 'react';
import { FormSelect } from '../../../..';
import { SelectOptionModel } from '../../../../../models';
import { CountryDto } from '../../../../../dto/Container';
import Translate from '../../../../../localization/Translate';

interface CountryFieldProps {
  translate: Translate;
  setCountry: (name: string) => void;
  countries: SelectOptionModel[];
  currentCountry?: CountryDto;
}

const CountryField = ({ translate, setCountry, countries, currentCountry }: CountryFieldProps) =>
  <FormSelect {...{
    label: translate(x => x.general.airdome.country),
    options: countries,
    selectId: 'AirdomeCountry',
    setValue: setCountry,
    selectedValue: currentCountry
      ? currentCountry.id.toString()
      : undefined
  }} />;

export default React.memo(CountryField);
