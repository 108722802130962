import AirdomePageHeader from '../../components/basic/AirdomePageHeader';
import AirdomeStateExpand from 'components/airdome/AirdomeStateExpand';
import Alert from '../../shared/components/Alert';
import CalendarTablesContainer from '../../components/calendar/CalendarTablesContainer';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const CalendarPage = () => {
  const translate = useLocalization();
  const calendarStore = useStore(x => x.calendarStore);
  const {
    ChangeRequestStates: {
      Schedule: isScheduleChangeRequestInProgress
    }
  } = useStore(x => x.changeRequestStore);
  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  useEffect(() => {
    calendarStore.fetchAll();

    return () => calendarStore.clear();
  }, [calendarStore]);
  
  if(!airdome)
    return null;

  return (
    <>
      <AirdomePageHeader
        icon={<span className="lnr lnr-calendar-full" />}
        title={airdome.name}
        additionalContent={<div className="d-flex align-items-center">
          {
            calendarStore.areSchedulesManagedExternally
              ? <Alert
                className="alert--bordered mb-0 mr-3"
                icon={true}
                color="warning">
                <p>{translate(x => x.pages.calendar.handledFromExternalSystem)}</p>
              </Alert>
              : null
          }
          <AirdomeStateExpand
            disabled={!calendarStore.hasChanges || isScheduleChangeRequestInProgress}
            onClick={async () => await calendarStore.updateSchedules()}
            enabledTitle={translate(x => x.pages.calendar.saveChanges)}/>
        </div>}
      />
      <Card>
        <CardBody>
          <CalendarTablesContainer />
        </CardBody>
      </Card>
    </>
  );
}

export default observer(CalendarPage);
