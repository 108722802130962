import * as React from 'react';

import Col from 'reactstrap/lib/Col';
import CreateAirdomeForm from '../components/airdome/create/CreateAirdomeForm';
import { Row } from 'reactstrap';
import { useHistory } from 'react-router';

const CreateAirdome = () => {
  const history = useHistory();

  return <Row>
    <Col xl={6} lg={12}>
      <CreateAirdomeForm {...{
        onAirdomeCreated: () => history.push('/')
      }} />
    </Col>
  </Row>;
}

export default CreateAirdome;
