import React from 'react';
import InfoFormProps from '../InfoFormProps';
import { NameField, CountryField, ProjectNumberField, TimeZoneField } from '../fields';

const LeftColumnFieldset = (props: InfoFormProps) => <>
  <NameField {...props} />
  <CountryField {...props} />
  <ProjectNumberField {...props} />
  <TimeZoneField {...props} />
</>;

export default LeftColumnFieldset;
