import ChangeRequest from '../../base/notifications/ChangeRequest';

const changeRequest: ChangeRequest = {
  created: {
    title: '{airdomeName}',
    message: 'Anforderung von {author} zum Ändern {type} wurde erstellt.'
  },
  finished: {
    title: '{airdomeName}',
    message: 'Anforderung von {author} zum Ändern {type} abgeschlossen.'
  },
  failed: {
    title: '{airdomeName}',
    message: 'Anforderung von {author} zum Ändern {type} fehlgeschlagen.'
  }
};

export default changeRequest;
