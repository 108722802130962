import { AirdomeService, ChangeRequestHubConnection, LightsService, UINotificationService, UserService } from '../services';
import BaseStore, { Params } from './base/BaseStore';
import { action, observable, reaction } from 'mobx';
import { catchError, processingState } from './decorators';

import AirdomeStore from './AirdomeStore';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';
import ErrorStore from './errors/ErrorStore';
import { LightState } from '../dto/settings/enums';
import LightsHistoryModel from 'models/lighting/LightsHistoryModel';
import { LightsModel } from '../models';
import LightsSignalRDto from 'services/signalr/dto/LightsSignalRDto';
import SettingsHubConnection from '../services/signalr/SettingsHubConnection';
import { StatefulFunction } from './shared/StatefulFunction';
import objectHelper from 'utils/ObjectHelper';
import FormNewChangesModel from 'models/FormNewChangesModel';

export interface SettingsParams {
  lightsService: LightsService;
  airdomeStore: AirdomeStore;
  airdomeService: AirdomeService;
  errorStore: ErrorStore;
  userService: UserService;
  changeRequestHubConnection: ChangeRequestHubConnection;
  settingsHubConnection: SettingsHubConnection;
  uiNotificationService: UINotificationService;
  getTrackingId: () => string;
}

export default class LightingStore extends BaseStore<SettingsParams> {
  private receivedLights?: LightsSignalRDto;

  @observable
  public lights: LightsModel = new LightsModel();

  @observable
  public lightsHistoryModel: LightsHistoryModel;

  @observable
  public newChangesModel: FormNewChangesModel<LightsSignalRDto>;

  constructor(params: Params<SettingsParams>) {
    super(params);

    this.newChangesModel = new FormNewChangesModel<LightsSignalRDto>((lights) => this.importLights(lights));

    this.lightsHistoryModel = new LightsHistoryModel(
      params.errorStore,
      params.getTrackingId,
      params.lightsService,
      params.airdomeStore,
      params.userService
    );

    reaction(
      () => params.airdomeStore.SelectedAirdomeId,
      (airdomeId) => {
        this.lights.clear();
        if (!airdomeId)
          return;

        this.fetchLights();
      });

    params.settingsHubConnection.onLightsUpdated((lights) => {
      this.newChangesModel.updateForm(lights, lights.isDataFromPlcDirectly, this.lights.hasAnyChanges);
    });

    params.changeRequestHubConnection.onFailed(async (airdomeId, _, chRType) => {
      const selectedAirdomeId = params.airdomeStore.SelectedAirdomeId;

      if (!selectedAirdomeId || selectedAirdomeId !== airdomeId)
        return;

      if(chRType === ChangeRequestTypeEnum.Lights){
        this.newChangesModel.isUpdateExecutor = false;
        return;
      }

      return;
    });
  }

  @processingState
  @catchError
  @action
  public fetchLights: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId!;
    this.lights.resetHasValueChanges();

    const lightDefinitions = await this.params.airdomeService.getLights(airdomeId, trackingId);

    const lightSettings = await this.params.lightsService.fetchLights(airdomeId, trackingId);
    const lightSettingsProperties = objectHelper.getTypedEntries(lightSettings).filter(x => x.key.match(/^[lL]ights/));

    const lights: {
      [propertyDefinitionName: string]: {
        name: string,
        propertyDefinitionId: number,
        lightState: LightState
      }
    } = {};

    for(const lightDefinition of lightDefinitions){
      const lightSetting = lightSettingsProperties.find(x => 
        x.key.toLowerCase() === lightDefinition.propertyDefinitionName.toLowerCase())

      if(!lightSetting)
        continue;

      lights[lightSetting.key] = {
        name: lightDefinition.name,
        propertyDefinitionId: lightDefinition.propertyDefinitionId,
        lightState: lightSetting.value as LightState
      };
    }

    this.lights.importAll(lights);
  }

  @processingState
  @catchError
  @action
  public fetchActiveLights: StatefulFunction = async (): Promise<void> => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!airdomeId)
      return;

    this.lights.activeLights = await this.params.lightsService.getActiveLights(airdomeId, this.params.getTrackingId());
  }

  @action
  public importReceivedLights = (): void => {
    if (!this.receivedLights)
      return;

    this.importLights(this.receivedLights);
    this.receivedLights = undefined;
  };

  @processingState
  @catchError
  @action
  public updateLights: StatefulFunction = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    if (!airdomeId)
      return;

    if(this.lights.hasAnyValueChange) {
      this.newChangesModel.isUpdateExecutor = true;
      await this.params.lightsService.updateLights(this.lights.DataDto, airdomeId, trackingId);
    }

    if(this.lights.hasAnyNameChange && this.lights.areNamesValid) {
      await this.params.airdomeService.updateLights(airdomeId, this.lights.NamesDto, trackingId);
      this.lights.resetHasNameChanges();
    }
  }

  @action
  private importLights = (lights: Omit<LightsSignalRDto, 'isDataFromPlcDirectly'>): void => {
    const createLight = (lightState?: LightState) => 
      lightState ? { 
        lightState 
      }
      : undefined;
    
    this.lights.importData({
      lightsMain1: createLight(lights.lightsMain1),
      lightsMain2: createLight(lights.lightsMain2),
      lightsMain3: createLight(lights.lightsMain3),
      lightsMain4: createLight(lights.lightsMain4),
      lightsAdditional1: createLight(lights.lightsAdditional1),
      lightsAdditional2: createLight(lights.lightsAdditional2),
      lightsAdditional3: createLight(lights.lightsAdditional3),
      lightsAdditional4: createLight(lights.lightsAdditional4)
    });
  };
}
