import Status from '../../../base/pages/status';
import observedNotifications from './ObservedNotifications';
import charts from './charts';

const status: Status = {
  charts,
  observedNotifications,

  temperatureInside: 'Inside temperature',
  temperatureOutside: 'Outside temperature',
  temperatureInput: 'Input temperature',
  pressure: 'Pressure',
  voltage: 'Voltage',
  lighting: 'Lighting',
  windSpeed: 'Wind speed',
  height: 'Air dome height',
  artificialIntelligence: 'Artificial intelligence',
  initialized: 'Initialized',
  inflated: 'Inflated',
  macAddresses: 'MAC addresses',
  lastUpdate: 'Last update',
  forceRefresh: 'Refresh',
  noNotifications: 'There are no notifications'
};

export default status;
