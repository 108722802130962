import ObservedNotifications from '../../../base/pages/status/ObservedNotifications';

const observedNotifications: ObservedNotifications = {
  level: {
    info: 'Informace:',
    danger: 'Upozornění!',
    warning: 'Pozor!'
  },
  internetOutage: 'Časté výpadky internetu.',
  wrongTemplate: 'Špatná šablona.',
  plcConnectivity: 'Časté výpadky spojení s PLC.'
};

export default observedNotifications;
