import Datatables from '../../../base/general/datatables';

const datatables: Datatables = {
  previousPage: 'Předchozí strana',
  nextPage: 'Další strana',
  search: 'Vyhledávání',
  numberOfRowsShown: ' ',
  displayedRows: 'Strana {page}'
};

export default datatables;
