import '../../scss/component/form-input.scss';

import * as React from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import { SelectOptionModel } from '../../models';

interface FormSelectProps {
  setValue: (value: string) => void;
  options: SelectOptionModel[];
  label: string;
  selectId: string;
  selectedValue?: string;
  readOnly?: boolean;
}

const FormSelect = (props: FormSelectProps) => {
  const { setValue, options, label, selectId, selectedValue, readOnly } = props;
  return <div className="form-group">
    <TextField select className="form-control text-field" {...{
      label,
      disabled: readOnly || false,
      onChange: e => setValue(e.target.value),
      id: selectId,
      value: selectedValue || '',
      margin: 'normal'
    }}>
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.displayName}
        </MenuItem>
      ))}
    </TextField>
  </div>;
};

export default React.memo(FormSelect);
