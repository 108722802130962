import Airdome from '../../../base/general/airdome';

const airdome: Airdome = {
  template: 'Schablone der Bacnet Definizierung',
  name: 'Name',
  country: 'Land',
  timeZone: 'Zeitzone',
  macAddresses: 'MAC-Adressen der zugeordneten Geräte',
  macAddress: 'MAC-Adresse des zugehörigen Geräts',
  projectNumber: 'Projektnummer',
  intervalHeader: 'Intervall',
  logLevel: 'Protokollebene',
  infoHeader: 'Info',
  unavailableTemplate: '{airdomeName} ist nicht verfügbar.'
};

export default airdome;
