import '../BacnetObjects/BacnetObjects.scss';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormSelect, MaterialTable } from 'components';

import BacnetObjectValueInput from './BacnetObjectValueInput';
import ButtonLoading from 'shared/components/ButtonLoading';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import ReceivedBacnetObjectRowModel from 'models/bacnetObjects/ReceivedBacnetObjectRowModel';
import SelectedBacnetObjectRowModel from 'models/bacnetObjects/SelectedBacnetObjectRowModel';
import { TableColumn } from 'components/basic/material-table/table-header';
import { observer } from "mobx-react";
import { useEffect } from 'react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

export interface BacnetObjectsProps {
  mode: 'read' | 'write'
}

const BacnetObjects = (props: BacnetObjectsProps) => {
  const mode = props.mode;

  const translate = useLocalization();
  const airdomeStore = useStore(x => x.airdomeStore);
  const bacnetObjectsStore = useStore(x => x.bacnetObjectsStore);

  useEffect(() => {
    bacnetObjectsStore.fetchBacnetObjects();

    return bacnetObjectsStore.clear;
  }, [bacnetObjectsStore, airdomeStore.SelectedAirdomeId, mode]);

  const getSelectedBacnetObjectsEditTableColumns = (): TableColumn<SelectedBacnetObjectRowModel>[] =>
    [
      {
        label: 'Id',
        id: 'bacnetId',
        disablePadding: false,
        width: 15,
        getValue: (item, _) => item.bacnetId.toString()
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetType),
        id: 'bacnetType',
        disablePadding: false,
        width: 15,
        getValue: (item, _) => item.bacnetType.toString()
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetName),
        id: 'name',
        disablePadding: false,
        width: 35,
        getValue: (item, _) => item.propertyDefinitionName
      },
      {
        renderCell: (item: SelectedBacnetObjectRowModel) => <BacnetObjectValueInput item={item} />,
        label: translate(x => x.pages.bacnetObjects.bacnetValue),
        id: 'value',
        disablePadding: false,
        getValue: () => '',
        width: 30
      },
      {
        renderCell: (item: SelectedBacnetObjectRowModel) =>
          <div
            className='clickable text-right'
            onClick={() => bacnetObjectsStore.removeBacnetObjectFromSelection(item.id)}>
            <CloseCircleIcon />
          </div >,
        label: '',
        id: 'remove',
        disablePadding: false,
        getValue: () => '',
        width: 5
      }
    ];

  const getSelectedBacnetObjectsTableColumns = (): TableColumn<SelectedBacnetObjectRowModel>[] =>
    [
      {
        label: 'Id',
        id: 'bacnetId',
        disablePadding: false,
        width: 15,
        getValue: (item, _) => item.bacnetId.toString()
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetType),
        id: 'bacnetType',
        disablePadding: false,
        width: 15,
        getValue: (item, _) => item.bacnetType.toString()
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetName),
        id: 'name',
        disablePadding: false,
        width: 68,
        getValue: (item, _) => item.propertyDefinitionName
      },
      {
        renderCell: (item: SelectedBacnetObjectRowModel) =>
          <div
            className="clickable"
            onClick={() => bacnetObjectsStore.removeBacnetObjectFromSelection(item.id)}>
            <CloseCircleIcon />
          </div >,
        label: '',
        id: 'remove',
        disablePadding: false,
        getValue: () => '',
        width: 2
      }
    ];

  const getBacnetObjectsValuesTableColumns = (): TableColumn<ReceivedBacnetObjectRowModel>[] =>
    [
      {
        label: 'Id',
        id: 'bacnetId',
        disablePadding: false,
        width: 15,
        getValue: (item, _) => item.bacnetId.toString()
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetType),
        id: 'bacnetType',
        disablePadding: false,
        width: 15,
        getValue: (item, _) => item.bacnetType.toString()
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetName),
        id: 'name',
        disablePadding: false,
        width: 50,
        getValue: (item, _) => item.propertyDefinitionName
      },
      {
        label: translate(x => x.pages.bacnetObjects.bacnetValue),
        id: 'value',
        disablePadding: false,
        width: 20,
        getValue: (item, _) => item.value ?? ''
      }
    ];

  return <>
    <div className="flex-grow flex-wrap BacnetObjects">
      <div className="d-flex flex-wrap">
        <Col className="bacnetObjectsHeader">
          <Card>
            <CardBody className="p-4">
              <Row className="mx-0">
                <h2 className="d-inline-block mt-1">
                  <span className="lnr lnr-rocket mr-2 d-inline-block" />
                  {airdomeStore.SelectedAirdome?.name}: {mode === 'read'
                    ? translate(x => x.pages.bacnetObjects.readTitle)
                    : translate(x => x.pages.bacnetObjects.writeTitle)}
                </h2>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </div>
      <div className="d-flex flex-wrap">
        <Col>
          <Row>
            <Col sm={12} lg={6} className="mb-4 mb-lg-0">
              <Card>
                <CardBody>
                  <Row>
                    <Card>
                      <CardBody>
                        <h3 className="pb-2">
                          {translate(x => x.pages.bacnetObjects.selectObjects)}
                        </h3>
                        <Row>
                          <Col sm={12} xl={8}>
                            <FormSelect {...{
                              label: translate(x => x.pages.bacnetObjects.bacnetObject),
                              options: bacnetObjectsStore.BacnetObjectsSelectOptions,
                              selectId: 'selectedBacnetObject',
                              setValue: bacnetObjectsStore.setSelectedBacnetObject,
                              selectedValue: (bacnetObjectsStore.selectedBacnetObjectId ?? undefined)?.toString()
                            }} />
                          </Col>
                          <Col sm={12} xl={4} className="text-right">
                            <Button
                              color="primary"
                              className="mt-3 px-3"
                              disabled={bacnetObjectsStore.selectedBacnetObjectId === undefined}
                              onClick={bacnetObjectsStore.addSelectedBacnetObjectToSelection}>
                              {translate(x => x.pages.bacnetObjects.addToSelection)}
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row className="selectedObjects mt-4">
                    <Card>
                      <CardBody>
                        <h3 className="pb-3">
                          {translate(x => x.pages.bacnetObjects.selectedObjects)}
                        </h3>
                        {bacnetObjectsStore.selectedBacnetObjects.length > 0
                          ? <>
                            <MaterialTable<SelectedBacnetObjectRowModel>
                              {...{
                                columns: mode === 'read'
                                  ? getSelectedBacnetObjectsTableColumns()
                                  : getSelectedBacnetObjectsEditTableColumns(),
                                clickRowToSelect: false,
                                multiselect: false,
                                data: bacnetObjectsStore.selectedBacnetObjects,
                                showCheckboxColumn: false,
                                showPagination: false
                              }}
                            />
                            <Row>
                              <Col className="text-right">
                                <ButtonLoading
                                  color="primary"
                                  content={translate(x => x.pages.bacnetObjects.sendRequest)}
                                  onClick={async () => mode === 'read'
                                    ? await bacnetObjectsStore.createReadValuesRequest()
                                    : await bacnetObjectsStore.createWriteValuesRequest()} />
                              </Col>
                            </Row>
                          </>
                          : <div className="mt-2 text-secondary">
                              <small>{translate(x => x.pages.bacnetObjects.noSelectedObjects)}</small>
                          </div>
                        }
                      </CardBody>
                    </Card>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {
              (bacnetObjectsStore.isWaitingForValues || bacnetObjectsStore.receivedBacnetObjects.length > 0) &&
              <Col sm={12} lg={6}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <h3 className="pb-3">
                          {translate(x => x.pages.bacnetObjects.objectValues)}
                        </h3>
                      </Col>
                    </Row>
                    <Row className="objectValues">
                      <Col>
                        {
                          bacnetObjectsStore.isWaitingForValues
                            ? <div className="load__icon-component waitingForValues">
                                <svg className="load__icon">
                                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                </svg>
                            </div>
                            : <MaterialTable<ReceivedBacnetObjectRowModel>
                              {...{
                                columns: getBacnetObjectsValuesTableColumns(),
                                clickRowToSelect: false,
                                multiselect: false,
                                data: bacnetObjectsStore.receivedBacnetObjects,
                                showCheckboxColumn: false,
                                showPagination: false
                              }}
                            />
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
        </Col>
      </div>
    </div>
  </>
}

export default observer(BacnetObjects);
