import Validations from '../../base/validations';

const validations: Validations = {
  mixed: {
    required: 'Feld ${label} ist erforderlich'
  },
  string: {
    min: 'Der ${label} darf nicht kürzer als ${min} Zeichen sein.',
    max: 'Der ${label} darf nicht kürzer als ${max} Zeichen sein.',
    email: 'Kein gültiges Email-Format.',
    noWhitespace: 'Der ${label} darf keine Leerzeichen enthalten.',
    macAddress: 'Der \'${value}\' ist keine gültige MAC-Adresse',
    username: '${label} darf nur Zeichen enthalten [a-zA-Z0-9-._@+.]'
  },
  number: {
    min: '${label} muss größer als oder gleich ${min}',
    max: '${label} muss kleiner als oder gleich ${max}'
  }
};

export default validations;
