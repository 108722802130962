interface Props {
  selected?: boolean;
  scheduleType: 'heating' | 'light' | 'door-lock';
  name: string;
  toggle: () => void;
}

const ScheduleTypeButton = ({ selected, scheduleType, name, toggle }: Props) => {
  const classNames = ["schedule-type-button my-2 w-100 rounded border text-center p-2 clickable text-unselectable"];
  if (selected) {
    classNames.push("border-white");
    classNames.push(`background--${scheduleType}`);
  } else {
    classNames.push("border-black text-black");
  }

  return <span className={classNames.join(" ")} onClick={toggle}>{name}</span>
}

export default ScheduleTypeButton;