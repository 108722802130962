import { useHistory, useRouteMatch } from 'react-router';

import { Collapse } from 'reactstrap';
import { Path } from 'history';
import React from 'react';
import SidebarLink from './SidebarLink';
import classNames from 'classnames';

interface SidebarCategoryProps {
  isNew?: boolean;
  children: JSX.Element | JSX.Element[];

  navLinkProps: {
    title: string;
    icon?: string;
    route: Path;
    onClick?: (...args: any[]) => any;
  }
}

const SidebarCategory = (props: SidebarCategoryProps) => {
  useRouteMatch();
  const history = useHistory();
  
  var routes = [props.navLinkProps.route];
  Array.isArray(props.children) 
  ? routes = routes.concat(props.children.map(child => child.props.route))
  : routes.push(props.children.props.route)

  const isOpen = routes.filter(route => history.location.pathname.startsWith(route)).length > 0;
  
  const categoryClass = classNames({
    'sidebar__category-wrap': true,
    'sidebar__category-wrap--open': isOpen,
  });

  return (
    <>
      <SidebarLink {...{ 
        ...props.navLinkProps, 
        className: categoryClass,
        badges: <span className="sidebar__category-icon lnr lnr-chevron-right" /> 
      }} />
      <Collapse isOpen={isOpen} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          <div>
            {props.children}
          </div>
        </ul>
      </Collapse>
    </>
  );
}

export default SidebarCategory;
