interface Point {
  x: number;
  y: number;
}

const polarToCartesian =
  (centerX: number, centerY: number, radius: number, angleInDegrees: number): Point => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  };

export const describeArc =
  (x: number, y: number, radius: number, startAngle: number, endAngle: number): string => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180
      ? '0'
      : '1';
    const d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');
    return d;
  };

export const describeArcRegion =
  (x: number, y: number, radiusA: number, radiusB: number, startAngle: number, endAngle: number): string => {
    const startA = polarToCartesian(x, y, radiusA, endAngle);
    const endA = polarToCartesian(x, y, radiusA, startAngle);
    const startB = polarToCartesian(x, y, radiusB, endAngle);
    const endB = polarToCartesian(x, y, radiusB, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    const d = [
      'M', startA.x, startA.y,
      'A', radiusA, radiusA, 0, largeArcFlag, 0, endA.x, endA.y,
      'L', endA.x, endA.y, endB.x, endB.y,
      'A', radiusB, radiusB, 0, largeArcFlag, 1, startB.x, startB.y,
      'L', startB.x, startB.y, startA.x, startA.y
    ].join(' ');
    return d;
  };