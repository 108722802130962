import Create from '../../../../base/forms/airdome/create';

const create: Create = {
  header: 'Přidat halu',
  submit: 'Přidat',
  supportAi: 'Povolit umělou inteligenci',
  cannotHaveAi: 'Vybraná šablona nepodporuje funkci umělé inteligence.',
  numberOfMainLights: 'Počet hlavních svítidel',
  numberOfAdditionalLights: 'Počet vedlejších svítidel',
  cannotHaveLighting: 'Vybraná šablona nepodporuje osvětlení.',
  cannotHaveHeating: 'Vybraná šablona nepodporuje topení.',
  
  hasHeating: 'Topení',
  hasPreheating: 'Předzátop',
  hasDoorLocking: 'Zamykání dveří',
  hasElectricityConsumption: 'Spotřeba elektřiny',
  hasGasConsumption: 'Spotřeba plynu'
};

export default create;
