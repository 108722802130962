import { AxiosInstance } from 'axios';
import { IntervalsDto } from '../../dto/Container';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class IntervalsService {
  private axios: AxiosInstance;
  private getAirdomeIntervalsUrl: string;
  private setAirdomeIntervalsUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getAirdomeIntervalsUrl = getJoinedEndpoint(x => x.registry.baseUri, x => x.registry.intervals.fetchIntervals);
    this.setAirdomeIntervalsUrl = getJoinedEndpoint(
      x => x.registry.baseUri, x => x.registry.intervals.setAirdomeIntervals);
  }

  public async fetchAirdomeIntervals(airdomeId: number, trackingId: string): Promise<IntervalsDto> {
    try {
      const url = stringFormat(this.getAirdomeIntervalsUrl, airdomeId.toString());
      const result = await this.axios.get<IntervalsDto>(url, {
        headers: { trackingId }
      });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public updateAirdomeIntervals = async (intervalsDto: IntervalsDto, airdomeId: number, trackingId: string) => {
    try {
      const url = stringFormat(this.setAirdomeIntervalsUrl, airdomeId.toString());
      await this.axios.put(url, intervalsDto, {
        headers: { trackingId }
      });

    } catch (error) {
      throw getBackendException(error);
    }
  }
}
