import * as validations from '../../validations/validations';

import { action, computed, observable } from 'mobx';

import InputModel from 'models/InputModel';
import { IntervalsDto } from '../../dto/Container';
import { NumberSchema } from 'yup';

export default class IntervalModel {
  private validationSchema: NumberSchema
    = validations.getBasicNumberSchema({ required: true });

  @observable
  public statusInterval = new InputModel<number>();

  @observable
  public plcReadingInterval = new InputModel<number>();

  @observable
  public artificialIntelligenceInterval = new InputModel<number>();

  @observable
  public consumptionsInterval = new InputModel<number>();

  @action
  public importFromDto = (dto: {
    statusInterval: number,
    plcReadingInterval: number,
    artificialIntelligenceInterval: number,
    consumptionsInterval: number
  }) => {
    this.statusInterval.setValue(dto.statusInterval, false);
    this.plcReadingInterval.setValue(dto.plcReadingInterval, false);
    this.artificialIntelligenceInterval.setValue(dto.artificialIntelligenceInterval, false);
    this.consumptionsInterval.setValue(dto.consumptionsInterval, false);
  }

  @action
  public clear = () => {
    this.statusInterval.clear();
    this.plcReadingInterval.clear();
    this.artificialIntelligenceInterval.clear();
    this.consumptionsInterval.clear();
  }

  @action
  public resetHasChanges = () => {
    this.statusInterval.resetHasChange();
    this.plcReadingInterval.resetHasChange();
    this.artificialIntelligenceInterval.resetHasChange();
    this.consumptionsInterval.resetHasChange();
  }

  @action
  public getDto = (): IntervalsDto | undefined =>
    this.areDataValid
      ? new IntervalsDto({
        statusInterval: this.statusInterval.Value!,
        artificialIntelligenceInterval: this.artificialIntelligenceInterval.Value!,
        plcReadingInterval: this.plcReadingInterval.Value!,
        consumptionsInterval: this.consumptionsInterval.Value!
      })
      : undefined

  @computed
  public get anyValueChanged(): boolean {
    return this.statusInterval.ValueChanged
      || this.artificialIntelligenceInterval.ValueChanged
      || this.plcReadingInterval.ValueChanged
      || this.consumptionsInterval.ValueChanged;
  }

  @computed
  public get areDataValid(): boolean {
    return !!(
      this.anyValueChanged &&
      this.validationSchema.isValidSync(this.statusInterval.Value) &&
      this.validationSchema.isValidSync(this.plcReadingInterval.Value) &&
      this.validationSchema.isValidSync(this.artificialIntelligenceInterval.Value) &&
      this.validationSchema.isValidSync(this.consumptionsInterval.Value)
    );
  }
}
