import { CountryDto, TemplateDto } from '../../../../dto/Container';
import { CountryField, MacAddressField, NameField, ProjectNumberField, TemplateField, TimeZoneField } from '../fields';
import { CreateAirdomeModel, SelectOptionModel } from '../../../../models';

import ButtonLoading from '../../../../shared/components/ButtonLoading';
import Translate from '../../../../localization/Translate';
import { observer } from 'mobx-react';
import timezones from '../../../../helpers/Timezones';

export interface StandardFieldsetProps {
  translate: Translate;
  countries: CountryDto[];
  templates: TemplateDto[];
  createAirdomeModel: CreateAirdomeModel;
  createAirdome: () => Promise<void>;
  isProcessing: boolean;
  children: (null | JSX.Element)[];
}

const getTimezones = (): SelectOptionModel[] =>
  timezones.map(
    x => new SelectOptionModel({
      displayName: x.displayName,
      value: x.id
    })
  );

const getCountries = ({ translate, countries }: StandardFieldsetProps): SelectOptionModel[] =>
  countries.map(
    x => new SelectOptionModel({
      value: x.id.toString(),
      displayName: translate(tr => tr.countries[x.shortcut.toLowerCase()])
    })
  );

const getTemplates = ({ templates }: StandardFieldsetProps): SelectOptionModel[] =>
  templates.map(
    x => new SelectOptionModel({
      displayName: x.name,
      value: x.id.toString()
    })
  );

const setCountry = ({ createAirdomeModel, countries }: StandardFieldsetProps, id: string) => {
  const country = countries.find(
    item => item.id === Number(id)
  );

  if (country)
    createAirdomeModel.setCountry(country);
};

const setTemplate = ({ createAirdomeModel, templates }: StandardFieldsetProps, id: string) => {
  const template = templates.find(
    item => item.id === Number(id)
  );

  if (template)
    createAirdomeModel.setTemplate(template);
};

const StandardFieldset = (props: StandardFieldsetProps) =>
  <fieldset disabled={props.isProcessing}>
    <NameField {...{
      translate: props.translate,
      validationSchema: props.createAirdomeModel.getNameSchema(),
      name: props.createAirdomeModel.name,
      setName: x => props.createAirdomeModel.setName(x)
    }} />
    <CountryField {...{
      translate: props.translate,
      countries: getCountries(props),
      currentCountry: props.createAirdomeModel.country,
      setCountry: x => setCountry(props, x)
    }} />
    <TimeZoneField {...{
      translate: props.translate,
      timezones: getTimezones(),
      currentTimeZone: props.createAirdomeModel.timeZone,
      setTimeZone: props.createAirdomeModel.setTimeZone
    }} />
    <MacAddressField {...{
      translate: props.translate,
      macAddress: props.createAirdomeModel.macAddress,
      getValidationSchema: props.createAirdomeModel.getMacAddressSchema,
      setMacAddress: x => props.createAirdomeModel.setMacAddress(x)
    }} />
    <ProjectNumberField {...{
      translate: props.translate,
      projectNumber: props.createAirdomeModel.projectNumber,
      validationSchema: props.createAirdomeModel.getProjectNumberSchema(),
      setProjectNumber: x => props.createAirdomeModel.setProjectNumber(x)
    }} />
    <TemplateField {...{
      translate: props.translate,
      templates: getTemplates(props),
      currentTemplate: props.createAirdomeModel.template,
      setTemplate: x => setTemplate(props, x)
    }} />
    {props.children}
    <ButtonLoading
      disabled={!props.createAirdomeModel.isValid}
      className="col-md-2 col-12 float-left"
      onClick={async () => await props.createAirdome()}
      content={props.translate(x => x.forms.airdome.create.submit)}
      color="primary" />
  </fieldset>;

export default observer(StandardFieldset);
