import Temperature from '../../../../base/forms/airdome/restrictions/Temperature';

const temperature: Temperature = {
  header: 'Omezte rozsah nastavení teploty pro halu',
  comfortTemperatureMin: 'Minimální komfortní teplota (°C)',
  comfortTemperatureMax: 'Maximální komfortní teplota (°C)',
  cutbackTemperatureMin: 'Minimální teplota v útlumu (°C)',
  cutbackTemperatureMax: 'Maximální teplota v útlumu (°C)'
};

export default temperature;
