import ChangeRequest from '../../base/enums/ChangeRequest';
import { ChangeRequestTypeEnum } from 'dto/changeRequest/ChangeRequestTypeEnum';

const changeRequest: ChangeRequest = {
  [ChangeRequestTypeEnum.Settings]: 'settings',
  [ChangeRequestTypeEnum.Intervals]: 'intervals',
  [ChangeRequestTypeEnum.Schedule]: 'calendar',
  [ChangeRequestTypeEnum.Lights]: 'lights',
  [ChangeRequestTypeEnum.DoorLockSettings]: 'door locks',
  [ChangeRequestTypeEnum.BacnetObjectWrite]: 'writing bacnet objects',
  [ChangeRequestTypeEnum.LogLevel]: 'log level',
  [ChangeRequestTypeEnum.ResolveNotifications]: 'resolve notifications',
  [ChangeRequestTypeEnum.PreheatingSettings]: 'preheating'
};

export default changeRequest;
