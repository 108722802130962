import User from '../../../base/general/user';

const user: User = {
  username: 'Přihlašovací jméno',
  password: 'Heslo',
  userIsLocked: 'Účet je zamčen',
  passwordAgain: 'Opakujte heslo',
  email: 'Email',
  role: 'Přístup',
  notifications: 'Přijímat notifikace haly',
  passwordsNotSame: 'Hesla se neshodují',
  logout: 'Odhlásit se'
};

export default user;
