import { Card, CardBody, Col } from 'reactstrap';

import ButtonLoading from '../../shared/components/ButtonLoading';
import DateTimePicker from '../datetimepicker/DateTimePicker';
import React from 'react';
import SupportedLocale from 'enums/SupportedLocale';
import Translate from '../../localization/Translate';
import moment from 'moment';

interface StatusDateSelectProps {
  dates: DatePickerProps;
  translate: Translate;
  handleSubmit: () => Promise<void>;
}

interface DatePickerProps {
  locale: SupportedLocale;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  setFromDate: (date: moment.Moment) => void;
  setToDate: (date: moment.Moment) => void;
}

const renderHeader = (translate: Translate) =>
  <div className="alert alert-dark" role="alert">
    <div className="div-style">
      <span className="lnr lnr-calendar-full pt-1 pr-2" />
      <span>{translate(x => x.pages.status.charts.dateSelect.title)}</span>
    </div>
  </div>;

const renderFromDatePicker = (props: DatePickerProps, translate: Translate) =>
  <div className="col-lg d-inline-block mb-3">
    <span>{translate(x => x.pages.status.charts.dateSelect.from)}</span>
    <DateTimePicker
      locale={props.locale}
      value={props.fromDate}
      onValueChanged={props.setFromDate}
      maxDate={props.toDate}
    />
  </div>;

const renderToDatePicker = (props: DatePickerProps, translate: Translate) =>
  <div className="col-lg d-inline-block mb-3">
    <span>{translate(x => x.pages.status.charts.dateSelect.to)}</span>
    <DateTimePicker
      locale={props.locale}
      value={props.toDate}
      onValueChanged={props.setToDate}
      minDate={props.fromDate}
      maxDate={moment()}
    />
  </div>;

const renderUpdateButton = (props: StatusDateSelectProps) =>
  <div className="col-lg my-auto d-inline-block button-div">
    <ButtonLoading
      onClick={async () => await props.handleSubmit()}
      className="btn-custom"
      color="primary"
      content={props.translate(x => x.pages.status.charts.dateSelect.update)} />
  </div>;

const StatusDateSelect = (props: StatusDateSelectProps) => <Col sm={12}>
  <Card>
    <CardBody>
      <div className="mt-3 div-wrapper">
        {renderHeader(props.translate)}
        <div className="form-inside row">
          {renderFromDatePicker(props.dates, props.translate)}
          {renderToDatePicker(props.dates, props.translate)}
          {renderUpdateButton(props)}
        </div>
      </div>
    </CardBody>
  </Card>
</Col>;

export default React.memo(StatusDateSelect);
