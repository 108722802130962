import ButtonLoading from '../../shared/components/ButtonLoading';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const AirdomeStateExpand = ({
  enabledTitle,
  onClick,
  className,
  disabled
}: {
  enabledTitle: string | JSX.Element;
  onClick: () => void | Promise<void>;
  className?: string;
  disabled?: boolean;
}) => {
  const translate = useLocalization();
  const {
    SelectedAirdome: airdome
  } = useStore(x => x.airdomeStore);

  if(!airdome)
    return null;
  
  return <ButtonLoading {...airdome.state === 'Alive'
    ? {
      className,
      disabled,
      color: 'primary',
      content: enabledTitle
    }
    : {
      color: 'danger',
      content: translate(x => x.general.airdome.unavailableTemplate, { airdomeName: airdome.name }),
      className: `${className} disabled`
    }}
    onClick={onClick}
  />;
};

export default observer(AirdomeStateExpand);
