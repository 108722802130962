import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { ValuesUnits, chartStyle, getColorIfDefined, iconSize, isTranslateSame, wrapGauge } from './Shared';

import GaugeIcon from 'mdi-react/GaugeIcon';
import React from 'react';
import StatusGauge from '../StatusGauge';

const Pressure = React.memo((props: TranslateProps & ColClassName & {
  pressure?: number;
}) => wrapGauge(
  <StatusGauge {...{
    chartStyle,
    title: props.translate(x => x.pages.status.pressure),
    value: props.pressure,
    minValue: 0,
    maxValue: 350,
    lineForeground: getColorIfDefined(props.pressure, '#771BAA'),
    unit: ValuesUnits.pressure,
    icon: <GaugeIcon size={iconSize} color={getColorIfDefined(props.pressure, '#771BAA')} />
  }} />, props.colClassName),
  (a, b) => a.pressure === b.pressure
    && isTranslateSame(a, b, x => x.pages.status.pressure));

export default Pressure;
