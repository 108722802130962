import * as uuid from 'uuid';
import * as validations from '../../validations/validations';

import { AirdomeStore, StatusBoxStore } from 'stores';
import { action, computed, observable } from 'mobx';

import { AIConstantsDto } from '../../dto/Container';
import AIService from 'services/ai/AIService';
import { CatchErrorRequiredParams } from 'stores/decorators/CatchError';
import InputModel from 'models/InputModel';
import { NumberSchema } from 'yup';
import { asTracked } from 'stores/decorators/TrackedDecorator';
import { catchError } from 'stores/decorators';

type ArtificialIntelligenceModelParams = CatchErrorRequiredParams & {
  airdomeStore: AirdomeStore;
  statusBoxStore: StatusBoxStore;
  aiService: AIService;
};

export default class ArtificialIntelligenceModel {
  private readonly params: ArtificialIntelligenceModelParams;

  private validationSchema: NumberSchema
    = validations.getBasicNumberSchema({ required: true });

  @observable
  public isAIEnabled?: boolean;

  @observable
  public inputTemperatureIntegrationRegulation = new InputModel<number>();

  @observable
  public inputTemperatureAmplificationRegulation = new InputModel<number>();

  @observable
  public innerTemperatureIntegrationRegulation = new InputModel<number>();

  @observable
  public innerTemperatureAmplificationRegulation = new InputModel<number>();

  constructor(params: ArtificialIntelligenceModelParams) {
    this.params = params;
  }

  public setIsAIEnabled = asTracked(action(async (value: boolean) => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!airdomeId)
      return;

    const trackingId = uuid.v4();
    if (value)
      await this.params.aiService.enableArtificialIntelligence(airdomeId, trackingId);
    else
      await this.params.aiService.disableArtificialIntelligence(airdomeId, trackingId);

    this.isAIEnabled = value;
    this.params.statusBoxStore.setStatusIsAIEnabled(airdomeId, value);
  }));

  @action
  public importFromDto = (dto: {
    inputTemperatureIntegrationRegulation: number,
    inputTemperatureAmplificationRegulation: number,
    innerTemperatureIntegrationRegulation: number,
    innerTemperatureAmplificationRegulation: number
  }, isAIEnabled: boolean) => {
    this.isAIEnabled = isAIEnabled;

    this.inputTemperatureIntegrationRegulation.setValue(dto.inputTemperatureIntegrationRegulation, false);
    this.inputTemperatureAmplificationRegulation.setValue(dto.inputTemperatureAmplificationRegulation, false);
    this.innerTemperatureIntegrationRegulation.setValue(dto.innerTemperatureIntegrationRegulation, false);
    this.innerTemperatureAmplificationRegulation.setValue(dto.innerTemperatureAmplificationRegulation, false);
  }

  @action
  public clear = () => {
    this.isAIEnabled = undefined;
    this.inputTemperatureIntegrationRegulation.clear();
    this.inputTemperatureAmplificationRegulation.clear();
    this.innerTemperatureIntegrationRegulation.clear();
    this.innerTemperatureAmplificationRegulation.clear();
  }

  public getDto = (): AIConstantsDto | undefined =>
    this.areDataValid
      // @ts-ignore
      ? new AIConstantsDto(this)
      : undefined

  @computed
  public get anyValueChanged(): boolean {
    return this.inputTemperatureIntegrationRegulation.ValueChanged
      || this.inputTemperatureAmplificationRegulation.ValueChanged
      || this.innerTemperatureIntegrationRegulation.ValueChanged
      || this.innerTemperatureAmplificationRegulation.ValueChanged;
  }

  @computed
  public get areDataValid(): boolean {
    return !!(
      this.anyValueChanged &&
      this.validationSchema.isValidSync(this.inputTemperatureIntegrationRegulation.Value) &&
      this.validationSchema.isValidSync(this.inputTemperatureAmplificationRegulation.Value) &&
      this.validationSchema.isValidSync(this.innerTemperatureIntegrationRegulation.Value) &&
      this.validationSchema.isValidSync(this.innerTemperatureAmplificationRegulation.Value)
    );
  }

  @catchError
  @action
  public fetchArtificialIntelligence = async (trackingId: string = ''): Promise<void> => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!airdomeId)
      return;

    const artificialIntelligenceDto =
      await this.params.aiService.fetchDefaultAIConstants(airdomeId, trackingId);

    const artificialIntelligenceEnabled =
      await this.params.aiService.getIsArtificialIntelligenceEnabled(airdomeId, trackingId);

    this.isAIEnabled = artificialIntelligenceEnabled;

    this.innerTemperatureAmplificationRegulation.setValue(artificialIntelligenceDto.innerTemperatureAmplificationRegulation, false);
    this.innerTemperatureIntegrationRegulation.setValue(artificialIntelligenceDto.innerTemperatureIntegrationRegulation, false);
    this.inputTemperatureAmplificationRegulation.setValue(artificialIntelligenceDto.inputTemperatureAmplificationRegulation, false);
    this.inputTemperatureIntegrationRegulation.setValue(artificialIntelligenceDto.inputTemperatureIntegrationRegulation, false);
  }

  @catchError
  public updateDefaultAIConstants = asTracked(action(async (trackingId: string = '') => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;

    if (!airdomeId || !this.areDataValid)
      return;

    await this.params.aiService.updateDefaultAIConstants({
      innerTemperatureAmplificationRegulation: this.innerTemperatureAmplificationRegulation.Value!,
      innerTemperatureIntegrationRegulation: this.innerTemperatureIntegrationRegulation.Value!,
      inputTemperatureAmplificationRegulation: this.inputTemperatureAmplificationRegulation.Value!,
      inputTemperatureIntegrationRegulation: this.inputTemperatureIntegrationRegulation.Value!
    }, airdomeId, trackingId)
  }));
}
