import React from 'react';
import {
  TemperatureComfortField,
  TemperatureModeField,
  TemperatureCutbackField
} from '../fields';
import SettingsFormProps from '../SettingsFormProps';
import { observer } from 'mobx-react';

const TemperatureFieldset = (props: SettingsFormProps) => <>
  <TemperatureModeField {...props} />
  <fieldset>
    <TemperatureComfortField {...props} />
    <TemperatureCutbackField {...props} />
  </fieldset>
</>;

export default observer(TemperatureFieldset);
