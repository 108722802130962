import DateSelect from '../../../../base/pages/status/charts/DateSelect';

const dateSelect: DateSelect = {
  title: 'Select date',
  from: 'Start date and time:',
  to: 'End date and time:',
  update: 'Update'
};

export default dateSelect;
