import './ToggleButtonRow.scss';
import './ToggleButtonGroup.scss';

import InfoFormProps from '../InfoFormProps';
import React from 'react';
import ToggleButton from '../../../../shared/components/form/ToggleButton';
import { observer } from 'mobx-react';

const InflatedField = ({ translate, infoModel, disabled }: InfoFormProps) => <div className="toggleButtonGroup">
  <label>{translate(x => x.pages.status.inflated)}</label>
  <div>
    <ToggleButton
      disabled={disabled}
      defaultChecked={infoModel.isInflated.Value || false}
      value={infoModel.isInflated.Value || false}
      name="InflatedField"
      onChange={x => infoModel.isInflated.setValue(x)} />
  </div>
</div>;

export default observer(InflatedField);
