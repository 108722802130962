import '../../scss/pages/Consumption.scss';

import { Card, CardBody, Col } from 'reactstrap';

import ConsumptionCell from '../../components/consumption/ConsumptionCell';
import { DateTimeRangePicker } from 'components';
import Dialog from '../../shared/components/Dialog';
import FlashIcon from 'mdi-react/FlashIcon';
import GasCylinderIcon from 'mdi-react/GasCylinderIcon';
import React from 'react';
import objectHelper from '../../utils/ObjectHelper';
import { observer } from 'mobx-react';
import useAirdomeFlags from 'common/AirdomeFlagsHook';
import { useHistory } from 'react-router';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const ConsumptionModal = observer(() => {
  const translate = useLocalization();
  const consumptionStore = useStore(x => x.consumptionStore);

  return <Dialog
    color="warning"
    isOpen={consumptionStore.showNoDataModal}
    cancelButtonLabel={translate(x => x.general.ok)}
    close={() => consumptionStore.setShowNoDataModel(false)}
    title={translate(x => x.pages.consumption.modal.noDataTitle)}
    message={translate(x => x.pages.consumption.modal.noDataMessage)}
  />;
});

const ElectricityConsumptionCol = observer(() => {
  const consumptionModel = useStore(x => x.consumptionStore).consumptionModel;
  const translate = useLocalization();

  const {
    hasElectricityConsumption
  } = useAirdomeFlags();

  return hasElectricityConsumption
    ? <div className="col-xl-12 col-xxl-6">
      <Card >
        <CardBody>
          <ConsumptionCell
            title={translate(x => x.pages.consumption.electricity)}
            maxValue={consumptionModel.electricityTotal}
            minValue={0}
            valueSelection={consumptionModel.electricitySelection}
            valueTotal={consumptionModel.electricityTotal}
            icon={<FlashIcon size={28} color="#FFEE58" />}
            unit="kWh"
            color="#FFEE58"
            totalDescription={translate(x => x.pages.consumption.descriptionTotal)}
            selectionDescription={translate(x => x.pages.consumption.descriptionSelection)} />
        </CardBody>
      </Card>
    </div>
    : null
});

const GasConsumptionCol = observer(() => {
  const consumptionModel = useStore(x => x.consumptionStore).consumptionModel;
  const translate = useLocalization();

  const {
    hasGasConsumption
  } = useAirdomeFlags();

  return hasGasConsumption
    ? <div className="col-xl-12 col-xxl-6">
      <Card >
        <CardBody>
          <ConsumptionCell
            title={translate(x => x.pages.consumption.gas)}
            maxValue={consumptionModel.gasTotal}
            minValue={0}
            valueSelection={consumptionModel.gasSelection}
            valueTotal={consumptionModel.gasTotal}
            icon={<GasCylinderIcon size={32} color="#03A9F4" />}
            unit="m&sup3;"
            color="#03A9F4"
            totalDescription={translate(x => x.pages.consumption.descriptionTotal)}
            selectionDescription={translate(x => x.pages.consumption.descriptionSelection)} />
        </CardBody>
      </Card>
    </div>
    : null
});


const Consumption = () => {
  const translate = useLocalization();
  const consumptionStore = useStore(x => x.consumptionStore);
  const airdome = useStore(x => x.airdomeStore).SelectedAirdome;
  const consumptionModel = consumptionStore.consumptionModel;
  const history = useHistory();

  if(!airdome)
    return null;
    
  if(!airdome.hasGasConsumption && !airdome.hasElectricityConsumption)
    history.push('/');
  
  const handleSubmit = async (): Promise<void> => {
    await consumptionStore.fetchConsumption();

    if (!objectHelper.areNumbers([
      consumptionModel.electricitySelection,
      consumptionModel.electricityTotal,
      consumptionModel.gasSelection,
      consumptionModel.gasTotal
    ]))
      consumptionStore.setShowNoDataModel(true);
  }

  return <div className="d-flex flex-wrap">
    <ConsumptionModal/>
    <Col xl={12} lg={12}>
      <div>
          <Card>
            <CardBody>
              <div className="d-flex">
                <h1 className="lnr lnr-leaf pt-1 pr-2 leaf"></h1>
                <h1>{airdome ? airdome.name : undefined}: {translate(x => x.pages.consumption.header)}</h1>
              </div>
              <DateTimeRangePicker
                start={consumptionStore.fromDate}
                end={consumptionStore.toDate}
                endMax={consumptionStore.dateToMaxDate}
                setStart={consumptionStore.setFromDate}
                setEnd={consumptionStore.setToDate}
                submit={handleSubmit}
              />
            </CardBody>
          </Card>
      </div>
    </Col>
    <ElectricityConsumptionCol/>
    <GasConsumptionCol/>
  </div>
}

export default observer(Consumption);