import User from '../../../base/general/user';

const user: User = {
  username: 'Username',
  password: 'Password',
  userIsLocked: 'Account is locked',
  passwordAgain: 'Confirm password',
  email: 'Email',
  role: 'Access',
  notifications: `Receive air dome's notifications`,
  passwordsNotSame: 'Passwords do not match',
  logout: 'Log out'
};

export default user;
