import Calendar from "../../../base/pages/calendar";
import { ScheduleTypeEnum } from "dto/settings/enums";

const calendar: Calendar = {
  saveChanges: "Änderungen speichern",
  fullWeek: "Fulles Weeke",
  scheduleTypes: {
    [ScheduleTypeEnum.Heating]: 'Heizung',
    [ScheduleTypeEnum.LightsMain1]: 'Hauptlicht 1',
    [ScheduleTypeEnum.LightsMain2]: 'Hauptlicht 2',
    [ScheduleTypeEnum.LightsMain3]: 'Hauptlicht 3',
    [ScheduleTypeEnum.LightsMain4]: 'Hauptlicht 4',
    [ScheduleTypeEnum.LightsAdditional1]: 'Seitenlicht 1',
    [ScheduleTypeEnum.LightsAdditional2]: 'Seitenlicht 2',
    [ScheduleTypeEnum.LightsAdditional3]: 'Seitenlicht 3',
    [ScheduleTypeEnum.LightsAdditional4]: 'Seitenlicht 4',
    [ScheduleTypeEnum.MainDoorLock]: 'Haupteingang'
  },
  handledFromExternalSystem: "Der Kalender wird vom externen System verwaltet – Rezzy",
  pageLeaveWarning: "Willst du wirklich gehen? Kalenderänderungen wurden vorgenommen.",
  timesOverlapping: "Bitte passen Sie die Uhrzeit an. Aktuelle Zeiten überschneiden sich.",
  intervalOverlapping: "Bitte passen Sie das Intervall an. Das Intervall steht in Konflikt mit einem bereits erstellten Intervall."
};

export default calendar;
