import Dialog from "../shared/components/Dialog";
import FormNewChangesModel from "../models/FormNewChangesModel";
import { useLocalization } from 'localization/LocalizationProvider';
import { observer } from "mobx-react";
import { ChangeRequestTypeEnum } from "dto/changeRequest/ChangeRequestTypeEnum";

export interface FormNewChangesDialogProps {
    newChangesModel: FormNewChangesModel<any>;
    changeRequestType: ChangeRequestTypeEnum;
    onConfirm: () => Promise<void>;
    onClose?: () => void;
}

const FormNewChangesDialog = (props: FormNewChangesDialogProps) => {
    const translate = useLocalization();

    const { newChangesModel, onConfirm, onClose, changeRequestType } = props;

    return (
        <Dialog
            title={newChangesModel.displayCRRefreshModal
                ? translate(x => x.actions.formNewChanges.changeRequestFinishedTitle, { type: translate(x => x.enums.changeRequest[changeRequestType]) })
                : translate(x => x.actions.formNewChanges.updatedFromPlcTitle, { type: translate(x => x.enums.changeRequest[changeRequestType]) })}
            message={translate(x => x.actions.formNewChanges.dialogMessage)}
            cancelButtonLabel={translate(x => x.general.cancel)}
            confirmButtonLabel={translate(x => x.general.ok)}
            isOpen={newChangesModel.isModalOpened}
            close={() => {
                if (onClose) {
                    onClose();
                }

                newChangesModel.hideModals();
                newChangesModel.isUpdateExecutor = false;
            }}
            confirm={async () => {
                await onConfirm();
                newChangesModel.hideModals();
            }}
            color="success"
        />
    )
}

export default observer(FormNewChangesDialog);