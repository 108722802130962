import * as validations from '../../validations/validations';

import { CountryDto, TemplateDto } from '../../dto/Container';
import { action, computed, observable } from 'mobx';

import AirdomeUpdateDto from '../../dto/registry/AirdomeUpdateDto';
import InputModel from 'models/InputModel';
import LogLevel from 'enums/LogLevel';
import { StringSchema } from 'yup';

interface SettingsInfoDto {
  name: string;
  countryId: number;
  projectNumber: string;
  timeZone: string;
  templateId: number;
  isInitialized: boolean;
  isInflated: boolean;
  logLevel: LogLevel;
}

export default class InfoModel {
  private originalValues!: SettingsInfoDto;

  private readonly nameValidationSchema: StringSchema;
  private readonly projectNumberSchema: StringSchema;

  @observable public name = new InputModel<string>();
  @observable public countryId = new InputModel<number>();
  @observable public projectNumber = new InputModel<string>();
  @observable public timeZone = new InputModel<string>();
  @observable public templateId = new InputModel<number>();
  @observable public isInitialized = new InputModel<boolean>();
  @observable public isInflated = new InputModel<boolean>();
  @observable public logLevel = new InputModel<LogLevel>();

  constructor() {
    this.nameValidationSchema = this.getNameValidationSchema();
    this.projectNumberSchema = this.getProjectNumberSchema();
  }

  public getNameValidationSchema = (): StringSchema => {
    return validations.getBasicStringSchema({ required: true, strict: true });
  }

  public getProjectNumberSchema = (): StringSchema => {
    return validations.getBasicStringSchema({ required: true, strict: true });
  }

  @action public importFromDto = (dto: SettingsInfoDto) => {
    this.originalValues = { ...dto };

    this.name.setValue(dto.name, false);
    this.countryId.setValue(dto.countryId, false);
    this.projectNumber.setValue(dto.projectNumber, false);
    this.timeZone.setValue(dto.timeZone, false);
    this.templateId.setValue(dto.templateId, false);
    this.isInitialized.setValue(dto.isInitialized, false);
    this.isInflated.setValue(dto.isInflated, false);
    this.logLevel.setValue(dto.logLevel, false);
  }

  public getAirdomeUpdateDto = (): AirdomeUpdateDto | undefined => {
    if (!this.name.Value || !this.projectNumber.Value || !this.countryId.Value ||
      !this.templateId.Value || !this.timeZone.Value || this.isInflated.Value === undefined)
      return;

    return {
      name: this.name.Value,
      projectNumber: this.projectNumber.Value,
      countryId: this.countryId.Value,
      templateId: this.templateId.Value,
      timeZone: this.timeZone.Value,
      isInflated: this.isInflated.Value
    };
  }

  public getInitializationChange = (): 'initialize' | 'deinitialize' | undefined => {
    if (!!this.isInitialized.Value && !this.originalValues.isInitialized)
      return 'initialize';

    if (!this.isInitialized.Value && !!this.originalValues.isInitialized)
      return 'deinitialize';
  }

  public getLogLevelChange = (): number | undefined =>
    this.logLevel.Value && this.logLevel.Value !== this.originalValues.logLevel
      ? this.logLevel.Value
      : undefined

  @action public clear = () => {
    this.name.clear();
    this.countryId.clear();
    this.projectNumber.clear();
    this.timeZone.clear();
    this.templateId.clear();
    this.isInitialized.clear();
    this.isInflated.clear();
    this.logLevel.clear();
  }

  @action public resetHasChanges = () => {
    this.name.resetHasChange();
    this.countryId.resetHasChange();
    this.projectNumber.resetHasChange();
    this.timeZone.resetHasChange();
    this.templateId.resetHasChange();
    this.isInitialized.resetHasChange();
    this.isInflated.resetHasChange();
    this.logLevel.resetHasChange();
  }

  @action public reset = () =>
    this.originalValues && this.importFromDto(this.originalValues)

  @action public setCountry = (country: CountryDto) =>
    this.countryId.setValue(country.id)

  @action public setTemplate = (template: TemplateDto) =>
    this.templateId.setValue(template.id)

  @computed public get anyValueChanged(): boolean {
    return this.name.ValueChanged
      || this.countryId.ValueChanged
      || this.projectNumber.ValueChanged
      || this.timeZone.ValueChanged
      || this.templateId.ValueChanged
      || this.logLevel.ValueChanged
      || this.isInflated.ValueChanged
      || this.isInitialized.ValueChanged;
  }

  @computed public get areDataValid(): boolean {
    return this.anyValueChanged
      && this.nameValidationSchema.isValidSync(this.name.Value)
      && this.projectNumberSchema.isValidSync(this.projectNumber.Value);
  }
}
