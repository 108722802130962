import Notifications from '../../base/notifications';
import airdome from './airdome';
import changeRequest from './ChangeRequest';

const notifications: Notifications = {
  airdome,
  changeRequest,
  notificationChannelTimedOut: {
    title: 'Upozornění',
    message: 'Uživatel byl vytvořen, ale nepodařilo se nastavit notifikace'
  },
};

export default notifications;
