import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface HeaderCheckboxCellProps {
  rowCount: number;
  numSelected: number;
  onSelectAllClick: () => void;
}

const HeaderCheckboxCell = (props: HeaderCheckboxCellProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return <TableCell padding="checkbox" >
    <Checkbox
      className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
      indeterminate={numSelected > 0 && numSelected < rowCount}
      checked={numSelected === rowCount}
      onChange={onSelectAllClick}
    />
  </TableCell>;
};

export default HeaderCheckboxCell;
