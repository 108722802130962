import { action, computed, observable } from 'mobx';

import { LanguageStore } from 'stores';
import SecurityStore from './SecurityStore';
import { User } from 'oidc-client-ts';
import { asTracked } from './decorators/TrackedDecorator';
import userManager from '../utils/UserManager';

export default class LoginStore {
  private securityStore: SecurityStore;
  private languageStore: LanguageStore;

  @observable
  public user?: User;

  constructor(
    securityStore: SecurityStore,
    languageStore: LanguageStore
  ) {
    this.securityStore = securityStore;
    this.languageStore = languageStore;

    userManager.events.addUserLoaded(user => this.onUserLoaded(user));
    userManager.events.addUserUnloaded(() => this.securityStore.clear());
    userManager.events.addAccessTokenExpired(() => this.signOut());
  }

  public loadUser = asTracked(action(async () => {
    if (!(await userManager.getUser()))
      return;

    await userManager.signinSilent();
    userManager.startSilentRenew();
  }));

  @computed
  public get isLoggedIn(): boolean {
    return !!this.user && !this.user.expired;
  }

  @action
  public signOut = async () => {
    this.securityStore.clear();
    await userManager.signoutRedirect();
  }

  @action
  public redirectToSignIn = async () => {
    this.securityStore.clear();
    await userManager.signinRedirect({
      extraQueryParams: {
        ui_locales: this.languageStore.Locale
      },
      state: {
        signInLocationHref: window.location.href
      }
    } as any);
  }

  @action
  public signinRedirectCallback = async () =>
    await userManager.signinRedirectCallback()
      .then(user => {
        userManager.storeUser(user);
        return user;
      })

  @action
  public signOutRedirectCallback = async (): Promise<void> =>
    await userManager.signoutRedirectCallback()
      .then(() => userManager.removeUser())

  @action
  private onUserLoaded = (user: User) => {
    if (user) {
      this.securityStore.onUserLoaded(user);
      this.user = user;
    }
  }
}
