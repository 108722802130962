import Radial, { ItemProps } from './radial/Radial';
import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useRef } from 'react';

export interface RadialMenuSelectOptionCoords {
  icon: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  text: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
}

export interface RadialMenuSelectOption<TValue> {
  value: TValue;
  display: {
    shortName: string;
    fullName: string;
  } | string;
  getCoords: (size: number) => RadialMenuSelectOptionCoords;
  getIcon: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

export interface RadialMenuSelectProps<TValue> {
  currentValue?: TValue;
  selectValue: (value: TValue) => void;
  selectorSize: number;
  options: RadialMenuSelectOption<TValue>[];
  readonly?: boolean;

  selector?: {
    className?: string;
  };
}

const RadialMenuSelect = <TValue,>(props: RadialMenuSelectProps<TValue>) => {
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onMouseDown = () => {
      if (showSelector)
        setTimeout(() => setShowSelector(false), 150);
    };

    document.addEventListener('mousedown', onMouseDown);
    return () => document.removeEventListener('mousedown', onMouseDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getSelectorSize = () =>
    cardRef.current
      ? Math.min(
        cardRef.current.clientHeight / 2.5,
        cardRef.current.clientWidth / 2.5,
        props.selectorSize)
      : props.selectorSize;

  const renderSelector = () => {
    const size = getSelectorSize();
    const { options, selector } = props;
    const className = selector && selector.className
      ? selector.className
      : '';

    return <div
      className="radial-menu-select-selector-wrapper"
      style={{ width: size * 2, height: size * 2 }}>
      <Radial
        className={className}
        stroke="#ddd"
        fill="#fff"
        strokeWidth={1}
        data={options.map(x => getItem(size, x))}
        cx={size}
        cy={size}
        updateData={() => { }}
        innerRadius={0}
        outerRadius={size} />
    </div>;
  }

  const renderIcon = (option: RadialMenuSelectOption<TValue>, onClick: () => void) =>
    <div className="clickable icon-container mt-2" onClick={onClick}>
      {option.getIcon()}
      <h3>
        {typeof option.display === 'object'
          ? option.display.fullName
          : option.display}
      </h3>
    </div>

  const renderReadonlyIcon = (option: RadialMenuSelectOption<TValue>) =>
    <div className="icon-container mt-2">
      {option.getIcon()}
      <h3>
        {typeof option.display === 'object'
          ? option.display.fullName
          : option.display}
      </h3>
    </div>

  const getItem = (size: number, option: RadialMenuSelectOption<TValue>): ItemProps => {
    const { currentValue } = props;

    const coords = option.getCoords(size);

    const isSelected = currentValue === option.value;
    return {
      name: `radial-menu.${option.value}`,
      fill: isSelected ? '#8ef592' : undefined,
      buttonFunctions: () => selectValue(option.value),
      icon: <g>
        {option.getIcon({
          ...coords.icon,
          textAnchor: 'middle'
        })}
        <text {...{
          fill: '#000',
          fillOpacity: isSelected ? 1 : 'inherit',
          style: {
            textAnchor: 'middle'
          },
          ...coords.text
        }}>
          {typeof option.display === 'object'
            ? option.display.shortName
            : option.display}
        </text>
      </g>
    };
  }

  const selectValue = (value: TValue) => {
    props.selectValue(value);
    setShowSelector(false);
  }

  const { options, currentValue, readonly } = props;
  const currentlySelectedOption = options.find(x => x.value === currentValue);
  const wrapResult = (item: React.ReactNode | null) => <div ref={cardRef} className="radial-menu-select">
    {item}
  </div>;

  if (readonly)
    return wrapResult(currentlySelectedOption
      ? renderReadonlyIcon(currentlySelectedOption)
      : null);

  if (showSelector || !currentlySelectedOption)
    return wrapResult(renderSelector());

  return wrapResult(
    renderIcon(currentlySelectedOption, () => setShowSelector(true))
  );
}

export default observer(RadialMenuSelect);