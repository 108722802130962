import User from '../../base/actions/User';

const user: User = {
  delete: {
    title: 'Smazat uživatele',
    submit: 'Smazat uživatele',
    message: 'Opravdu chcete smazat uživatele {username}?'
  },
  unlock: {
    title: 'Odemknout uživatele',
    submit: 'Odemknout uživatele',
    message: 'Opravdu chcete odemknout uživatele {username}?'
  },
  lock: {
    title: 'Zamknout uživatele',
    submit: 'Zamknout uživatele',
    message: 'Opravdu chcete zamknout uživatele {username}?'
  }
};

export default user;
