import React from 'react';
import AirdomeListItemModel from '../../models/AirdomeListItemModel';
import AirdomeCountryFlag from '../basic/AirdomeCountryFlag';
import Translate from '../../localization/Translate';

const countryCell = ({ translate, item }: { translate: Translate, item: AirdomeListItemModel }) => {
  const languageCode = item.country.shortcut.toLowerCase();
  return <span className="airdome-country-span">
    <AirdomeCountryFlag {...{
      languageCode,
      translate
    }} />
  </span>;
};

export default React.memo(countryCell);
