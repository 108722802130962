import NotificationChannel from '../../base/enums/NotificationChannel';

const notificationChannel: NotificationChannel = {
  both: 'Oboje',
  emails: 'Email',
  mobilenotifications: 'Push notifikace',
  none: 'Žádné'
};

export default notificationChannel;
