import { inject, observer } from 'mobx-react';

import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import RootStore from '../stores/RootStore';
import { withRouter } from 'react-router';

/**
 * Wrapper of mobx inject with observer and withRouter included. Implicitly injects airdomeStore and errorStore
 * @deprecated Use basePageTypedInject() instead
 * @param {string[]} injects Array of injects to add
 * @returns Wrapped component
 */
const basePageInject = (...injects: string[]) =>
  <TComponent extends IReactComponent>(component: TComponent): TComponent =>
    // @ts-ignore
    withRouter(inject('airdomeStore', 'errorStore', ...injects)(observer(component)));

/**
 * Wrapper of mobx inject with observer and withRouter included. Implicitly injects airdomeStore and errorStore
 * @param {Function} getStores Select stores from rootstore to inject
 * @returns Wrapped component
 */
const basePageTypedInject = (getStores: (x: RootStore) => Object) =>
  <TComponent extends IReactComponent>(component: TComponent): TComponent =>
    // @ts-ignore
    withRouter(inject((x: RootStore) => ({
      errorStore: x.errorStore,
      airdomeStore: x.airdomeStore,
      ...getStores(x)
    }))(observer(component)));

export {
  basePageInject,
  basePageTypedInject
};
