import { AirdomeListItemExtendedDto } from '../../dto/Container';
import PersistedHubConnection from './base/PersistedHubConnection';
import SecurityStore from 'stores/SecurityStore';
import { getJoinedEndpoint } from '../../utils/Settings';

type RegistryMultiAirdomeEvents = 'AirdomeCreated';

export default class RegistryMultiAirdomeHubConnection extends PersistedHubConnection<RegistryMultiAirdomeEvents, ''> {
  constructor(securityStore: SecurityStore) {
    super(getJoinedEndpoint(
      x => x.signalr.baseUri,
      x => x.signalr.registry
    ), securityStore);
  }

  public onAirdomeCreated = (callback: (createdAirdome: AirdomeListItemExtendedDto) => void) => {
    super.on('AirdomeCreated', callback);
  }
}
