import * as yup from 'yup';

export function getBasicStringSchema(options: {
  min?: number;
  max?: number;
  required?: boolean;
  strict?: boolean;
}): yup.StringSchema {
  let schema = yup.string();

  schema = options.required
    ? schema.required()
    : schema.notRequired();

  schema = schema.strict(!!options.strict);
  if (options.min) schema = schema.min(options.min);
  if (options.max) schema = schema.max(options.max);

  return schema;
}

export function getBasicNumberSchema(
  options: {
    min?: number;
    max?: number;
    required?: boolean;
    strict?: boolean;
  } = {}
): yup.NumberSchema {
  let schema = yup.number();

  if (typeof options.min === 'number') schema = schema.min(options.min);
  if (typeof options.max === 'number') schema = schema.max(options.max);
  if (options.required) schema = schema.required();
  if (options.strict) schema = schema.strict(true);

  return schema;
}

export function getUsernameValidationSchema(
  label: string = '',
  noWhitespaceValidationMessage: string = '',
  usernameValidationMessage: string = ''): yup.StringSchema {
  return getBasicStringSchema({
    min: 5,
    max: 100,
    required: true,
    strict: true
  })
    // @ts-ignore
    .noWhitespace(noWhitespaceValidationMessage)
    // @ts-ignore
    .username(usernameValidationMessage)
    .label(label);
}

export function getOptionalPasswordValidationSchema(minLabel: string = '', maxLabel: string = ''): yup.StringSchema {
  return yup
    .string()
    .notRequired()
    .test({
      message: minLabel,
      name: 'min',
      params: { min: 5 },
      test: x => !x || x.length >= 5
    })
    .test({
      message: maxLabel,
      name: 'max',
      params: { max: 100 },
      test: x => !x || x.length <= 100
    })
}

export function getPasswordValidationSchema(): yup.StringSchema {
  return getBasicStringSchema({
    min: 5,
    max: 100,
    required: true,
    strict: true
  });
}

export function getEmailValidationSchema(): yup.StringSchema {
  return getBasicStringSchema({
    min: 5,
    max: 100,
    strict: true,
    required: true
  }).email();
}

export function getMacAddressValidationSchema(
  options: {
    required: boolean;
    macAddress?: String;
    label?: String;
  } = { required: false }): yup.StringSchema {
  let schema = getBasicStringSchema({
    required: options.required,
    strict: true
  })
    // @ts-ignore
    .macAddress(options.macAddress);

  if (options.label)
    schema = schema.label(options.label);

  return schema;
}
