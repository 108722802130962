import Columns from '../../../base/pages/airdomelist/Columns';

const columns: Columns = {
  name: 'Name',
  availability: 'Availability',
  notifications: 'Notifications',
  country: 'Country',
  projectNumber: 'Project number',
  template: 'Template'
};

export default columns;
