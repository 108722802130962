import { CheckboxModel } from '../../../../../models';
import { FormCheckbox } from '../../../..';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface HasDoorLockingFieldProps {
  hasDoorLocking: boolean;
  setHasDoorLocking: (hasDoorLocking: boolean) => void;
}

const HasDoorLockingField = ({ hasDoorLocking, setHasDoorLocking }: HasDoorLockingFieldProps) =>
  {
    const translate = useLocalization();
    return <FormCheckbox {...{
      translate,
      model: new CheckboxModel({
        inputId: 'AirdomeDoorLocking',
        label: translate(x => x.forms.airdome.create.hasDoorLocking),
        setValue: setHasDoorLocking,
        value: hasDoorLocking
      })
    }} />;
  };

export default observer(HasDoorLockingField);
