import pressure from './Pressure';
import temperature from './Temperature';
import Restrictions from '../../../../base/forms/airdome/restrictions';

const restrictions: Restrictions = {
  pressure,
  temperature
};

export default restrictions;
