export default class ApiError extends Error {
  // tslint:disable-next-line: naming-convention
  private __proto__: any;
  public readonly messageKey: string;
  public readonly data: any;

  constructor(error?: {
    data?: any;
    message?: string;
    localizationKey?: string;
  }) {
    super(error && error.message);

    this.messageKey = error && error.localizationKey
      ? error.localizationKey
      : 'GeneralError';

    if (error)
      this.data = error.data;

    const actualPrototype = new.target.prototype;
    if (Object.setPrototypeOf)
      Object.setPrototypeOf(this, actualPrototype);
    else
      this.__proto__ = actualPrototype;
  }
}
