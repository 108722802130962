import { FormSelect } from '../../..';
import InfoFormProps from '../InfoFormProps';
import React from 'react';
import objectHelper from '../../../../utils/ObjectHelper';
import { observer } from 'mobx-react';

const TemplateField = ({ translate, templateOptions, infoModel, disabled }: InfoFormProps) =>
  <FormSelect {...{
    readOnly: disabled,
    options: templateOptions,
    setValue: x => infoModel.templateId.setValue(Number(x)),
    selectedValue: objectHelper.executeIfNumber(infoModel.templateId.Value, x => x.toString()),
    label: translate(x => x.general.airdome.template),
    selectId: 'TemplateField'
  }} />;

export default observer(TemplateField);
