import BacnetObjects from "../../../base/pages/bacnetObjects";

const calendar: BacnetObjects = {
  readTitle: "Viewing bacnet objects",
  writeTitle: "Editing bacnet objects",
  selectObjects: "Select objects",
  bacnetObject: "Bacnet object",
  addToSelection: "Add to selection",
  selectedObjects: "Selected objects",
  bacnetType: "Type",
  bacnetName: "Name",
  objectValues: "Object values",
  sendRequest: "Send request",
  noSelectedObjects: "No objects selected",
  bacnetValue: "Value"
};

export default calendar;
