import Columns from '../../../base/pages/airdomelist/Columns';

const columns: Columns = {
  name: 'Hala',
  availability: 'Dostupnost',
  notifications: 'Notifikace',
  country: 'Země',
  projectNumber: 'Číslo projektu',
  template: 'Šablona'
};

export default columns;
