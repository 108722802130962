import PreheatingSettings from 'localization/base/pages/PreheatingSettings';

const preheatingSettings: PreheatingSettings = {
  header: 'Předzátop',
  historyHeader: 'Historie předzátopu',
  maximalPreheatingMinutes: 'Maximální doba předzátopu (min.)',
  minimumPreheatingMinutes: 'Minimální doba předzátopu (min.)',
  isPreheatingEnabled: "Povolení předzátopu",
  timestamp: 'Naposledy aktualizováno',
  updatedBy: 'Upravil',
};

export default preheatingSettings;
