import React from 'react';
import Col from 'reactstrap/lib/Col';
import { TranslateProps } from '../../../interfaces/Props';
import { Card, CardBody } from 'reactstrap';
import Translation from '../../../localization/base';
import objectHelper from '../../../utils/ObjectHelper';

export const iconSize = 48;

const grayedOutColor = '#666';

export const getColorIfDefined = (value: any, color: string): string =>
  objectHelper.isNumber(value) || typeof value === 'object'
    ? color
    : grayedOutColor;

export const chartStyle = {
  innerRadius: 110,
  outerRadius: 120
};

export const ValuesUnits = {
  pressure: 'Pa',
  voltage: 'V',
  height: 'm',
  windSpeed: 'm/s'
};

export const wrapGauge = (element: JSX.Element, className?: string, onClick?: () => void) =>
  <Col xl={4} lg={6} sm={12} className={`gauge-card col-xxl-3 col-4xl-2 ${className || ''}`}>
    <Card>
      <CardBody onClick={onClick}>
        {element}
      </CardBody>
    </Card>
  </Col>;

export const isTranslateSame = (oldProps: TranslateProps, newProps: TranslateProps,
  getTranslation: (translation: Translation) => string) =>
  oldProps.translate
  && newProps.translate
  && oldProps.translate(getTranslation) === newProps.translate(getTranslation);
