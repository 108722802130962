import AirdomeState from '../../base/enums/AirdomeState';

const airdomeState: AirdomeState = {
  Alive: 'Hala je dostupná',
  HalfDead: 'Hala neodpovídá',
  Dead: 'Hala není dostupná',
  PlcUnavailable: 'PLC haly je nedostupné',
  NotInflated: 'Hala není nafouknutá'
};

export default airdomeState;
