import { AxiosError, AxiosInstance } from "axios";
import PreheatingSettingsDto from "dto/settings/PreheatingSettingsDto";
import { getBackendException } from "../../helpers/ErrorHelper";
import { getJoinedEndpoint } from "../../utils/Settings";
import stringFormat from 'string-format';
import PagingDto from "services/shared/dtos/PagingDto";

export default class PreheatingService {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    public fetchPreheatingSettings = async (airdomeId: number, trackingId: string)
        : Promise<PreheatingSettingsDto> => {
        try {
            const url = stringFormat(
                getJoinedEndpoint(
                    x => x.settings.baseUri,
                    x => x.settings.preheatingSettings.get),
                airdomeId.toString());

            const result = await this.axios.get<PreheatingSettingsDto>(url,
                { headers: { trackingId } });

            return result.data;
        } catch (error) {
            throw getBackendException(error as AxiosError);
        }
    }

    public fetchPreheatingSettingsHistory = async (airdomeId: number, skip: number, take: number, trackingId: string)
        : Promise<PagingDto<PreheatingSettingsDto>> => {
        try {
        const url = stringFormat(
            getJoinedEndpoint(
                x => x.settings.baseUri,
                x => x.settings.preheatingSettings.getHistory),
            airdomeId.toString(),
            skip.toString(),
            take.toString());

            const result = await this.axios.get<PagingDto<PreheatingSettingsDto>>(url, { headers: { trackingId } });

            return result.data;
        } catch (error) {
        throw getBackendException(error as AxiosError);
        }
    }

    public updatePreheatingSettings = async (
        updatedPreheatingSettings: Omit<PreheatingSettingsDto, 'userId' | 'timestamp' | 'airdomeId'>,
        airdomeId: number,
        trackingId: string): Promise<number> => {
        try {
            const url = stringFormat(
                getJoinedEndpoint(
                    x => x.settings.baseUri,
                    x => x.settings.preheatingSettings.update),
                airdomeId.toString());
            const result = await this.axios.put<number>(url, updatedPreheatingSettings, { headers: { trackingId } })

            return result.data;
        } catch (error) {
            throw getBackendException(error as AxiosError);
        }
    }
}