import BaseStore, { Params } from './base/BaseStore';
import { PressureRestrictionsModel, TemperatureRestrictionsModel } from '../models';
import { action, reaction } from 'mobx';
import { catchError, processingState } from './decorators';

import AirdomeStore from './AirdomeStore';
import RestrictionsService from '../services/settings/RestrictionsService';

interface RestrictionsStoreParams {
  airdomeStore: AirdomeStore;
  restrictionsService: RestrictionsService;
}

export default class RestrictionsStore extends BaseStore<RestrictionsStoreParams> {
  public temperatureRestrictionsModel = new TemperatureRestrictionsModel();
  public pressureRestrictionsModel = new PressureRestrictionsModel();

  constructor(params: Params<RestrictionsStoreParams>) {
    super(params);
    reaction(
      () => params.airdomeStore.SelectedAirdomeId,
      (airdomeId) => {
        if (!airdomeId)
          return;
        this.fetchRestrictions(airdomeId);
      }
    );
  }

  @catchError
  @action
  public fetchRestrictions = async (airdomeId: number, trackingId: string = '') => {
    const restrictions =
      await this.params.restrictionsService.getRestrictions(airdomeId, trackingId);

    this.temperatureRestrictionsModel.importFromDto(restrictions);
    this.pressureRestrictionsModel.importFromDto(restrictions);
  }

  @processingState
  @catchError
  @action
  public updatePressureRestrictions = async (trackingId: string = '') => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    const pressuresDto = this.pressureRestrictionsModel.getDto();
    if (!airdomeId || !pressuresDto)
      return;

    const savedPressureRestrictions = await this.params.restrictionsService.updatePressureRestrictions(pressuresDto, airdomeId, trackingId);
    this.pressureRestrictionsModel.importFromDto(savedPressureRestrictions);
  }

  @processingState
  @catchError
  @action
  public updateTemperatureRestrictions = async (trackingId: string = '') => {
    const airdomeId = this.params.airdomeStore.SelectedAirdomeId;
    const temperaturesDto = this.temperatureRestrictionsModel.getDto();
    if (!airdomeId || !temperaturesDto)
      return;

    const savedTemperatureRestrictions = await this.params.restrictionsService.updateTemperatureRestrictions(temperaturesDto, airdomeId, trackingId);
    this.temperatureRestrictionsModel.importFromDto(savedTemperatureRestrictions);
  }
}
