export default class AIConstantsDto {
  public inputTemperatureIntegrationRegulation: number;
  public inputTemperatureAmplificationRegulation: number;
  public innerTemperatureIntegrationRegulation: number;
  public innerTemperatureAmplificationRegulation: number;

  constructor(model: {
    inputTemperatureIntegrationRegulation: number,
    inputTemperatureAmplificationRegulation: number,
    innerTemperatureIntegrationRegulation: number,
    innerTemperatureAmplificationRegulation: number
  }) {
    this.inputTemperatureIntegrationRegulation = model.inputTemperatureIntegrationRegulation;
    this.inputTemperatureAmplificationRegulation = model.inputTemperatureAmplificationRegulation;
    this.innerTemperatureIntegrationRegulation = model.innerTemperatureIntegrationRegulation;
    this.innerTemperatureAmplificationRegulation = model.innerTemperatureAmplificationRegulation;
  }
}
