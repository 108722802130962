import { action, observable } from 'mobx';

import AddUserAccessModel from './AddUserAccessModel';
import { UserWithRoleDto } from '../../dto/Container';

export default class UsersWithAccessModel {
  @observable
  public users: UserWithRoleDto[] = [];

  @observable
  public addUserAccessModel: AddUserAccessModel;

  constructor() {
    this.addUserAccessModel = new AddUserAccessModel(this);
  }

  @action public importOptions = (userNameOptions: string[]) =>
    this.addUserAccessModel.import(userNameOptions)

  @action public importUsers = (users: UserWithRoleDto[]) =>
    this.users = users

  @action public clear = () =>
    this.users = []

  @action
  public removeUser = (id: string) =>
    this.users = this.users.filter(x => x.id !== id)

  @action
  public addUser = (user: UserWithRoleDto) =>
    this.users.push(user)
}
