import { action, computed, observable, reaction } from 'mobx';
import { catchError, processingState } from '../decorators';

import { AirdomeModel } from 'models';
import AirdomeStore from '../AirdomeStore';
import ErrorStore from '../errors/ErrorStore';
import { Moment } from 'moment-timezone';
import StatusDto from '../../dto/status/StatusDto';
import StatusService from '../../services/status/StatusService';
import moment from 'moment';

interface StatusChartStoreParams {
  airdomeStore: AirdomeStore;
  statusService: StatusService;
  errorStore: ErrorStore;
  getTrackingId: () => string;
}

export default class StatusChartStore {
  @observable public statuses: {
    readonly timestamp: Moment;
    readonly temperatureOutside?: number;
    readonly temperatureInside?: number;
    readonly temperatureInput?: number;
    readonly pressure: number;
    readonly windSpeed: number;
    readonly airdomeHeight: number;
  }[] = [];
  @observable public fromDate: moment.Moment = this.defaultFromDate();
  @observable public toDate: moment.Moment = this.defaultToDate();
  @observable public showWarningModal: boolean = false;

  private params: StatusChartStoreParams;
  constructor(params: StatusChartStoreParams) {
    this.params = params;
  }

  private defaultToDate(): moment.Moment {
    return moment();
  }

  private defaultFromDate(): moment.Moment {
    return moment().add(-1, 'd');
  }

  @action public setFromDate = (value: moment.Moment) =>
    this.fromDate = value

  @action public setToDate = (value: moment.Moment) =>
    this.toDate = value

  @action private setStatuses = (statuses: StatusDto[]) =>
    this.statuses = statuses.map(x => ({
      timestamp: moment(x.timestamp),
      temperatureInside: x.temperatureInside && Number(x.temperatureInside.toFixed(2)),
      temperatureInput: x.temperatureInput && Number(x.temperatureInput.toFixed(2)),
      temperatureOutside: x.temperatureOutside && Number(x.temperatureOutside.toFixed(2)),
      pressure: Number(x.pressure.toFixed(2)),
      windSpeed: Number(x.windSpeed.toFixed(2)),
      airdomeHeight: Number(x.height.toFixed(2))
    }));

  @action public clearStatuses = () =>
    this.statuses = []

  @action public closeWarningModal = () =>
    this.setShowWarningModal(false)

  @action public confirmWarningModal = async () => {
    await this.fetchStatuses();
    this.setShowWarningModal(false);
  }

  @action private setShowWarningModal = (value: boolean) =>
    this.showWarningModal = value

  @catchError
  @processingState
  @action
  public async fetchStatuses(trackingId: string = '') {
    const dateDifference = this.toDate.diff(this.fromDate, 'month', true);
    if (!this.showWarningModal && dateDifference >= 1) {
      this.setShowWarningModal(true);
      return;
    }

    const airdome = this.params.airdomeStore.SelectedAirdome;
    if (!airdome)
      return;

    await this.fetchStatusesInner(airdome, trackingId);
  }

  @catchError
  @action
  private fetchStatusesInner = async (airdome: AirdomeModel, trackingId: string = '') => {
    const fromDate = moment.tz(this.fromDate.clone(), airdome.timeZone.iana).utc().toDate();
    const toDate = moment.tz(this.toDate.clone(), airdome.timeZone.iana).utc().toDate();

    await this.params.statusService
      .getStatusesForInterval(airdome.id, fromDate, toDate, trackingId)
      .then(this.setStatuses);
  }

  @catchError
  @action
  public resetDates = () => {
    this.fromDate = this.defaultFromDate();
    this.toDate = this.defaultToDate();
  }
}
