import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import ArcModal from "shared/components/ArcModal";
import CalendarTable from "./CalendarTable";
import { ChangeRequestTypeEnum } from "dto/changeRequest/ChangeRequestTypeEnum";
import FormNewChangesDialog from "components/FormNewChangesDialog";
import { Prompt } from "react-router";
import ScheduleIntervalComparer from "utils/ScheduleIntervalComparer";
import ScheduleIntervalForm from "./ScheduleIntervalForm";
import { ScheduleTypeEnum } from "dto/settings/enums";
import classnames from 'classnames';
import { observer } from "mobx-react";
import useLocalWeekdays from "common/WeekdayHooks";
import { useLocalization } from "localization/LocalizationProvider";
import { useState } from "react";
import { useStore } from "stores/MobXTypedStoreHook";

export const getCalendarTypeByScheduleType = (scheduleType: ScheduleTypeEnum) => {
  switch (scheduleType) {
    case ScheduleTypeEnum.Heating:
      return 'heating';
    case ScheduleTypeEnum.MainDoorLock:
      return 'door-lock';
    default:
      return 'light';
  }
}

const CalendarTablesContainer = () => {
  const wholeWeekTabId = 1;
  const { weekdays, toLocalDayOrder, toIsoDayOrder } = useLocalWeekdays();
  const weekDaysCapitalized = weekdays.map(x => capitalizeFirstLetter(x));

  const calendarStore = useStore(x => x.calendarStore);
  const translate = useLocalization();

  const [activeTab, setActiveTab] = useState<number>(wholeWeekTabId);
  const [isIntervalModalOpen, setIsIntervalModalOpen] = useState<boolean>(false);
  const selectedScheduleType = calendarStore.intervalFormModel.selectedScheduleType;

  return (
    <div className="tabs tabs--bordered-top">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === wholeWeekTabId })}
              onClick={() => {
                setActiveTab(wholeWeekTabId);
              }}
            >
              {translate(x => x.pages.calendar.fullWeek)}
            </NavLink>
          </NavItem>
          {weekDaysCapitalized.map((dayOfWeek, index) => {
            const tabId = wholeWeekTabId + index + 1;
            return (
              <NavItem key={tabId}>
                <NavLink
                  className={classnames({ active: activeTab === tabId })}
                  onClick={() => setActiveTab(tabId)}
                >
                  {dayOfWeek}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={wholeWeekTabId}>
          <CalendarTable
            categories={
              weekDaysCapitalized.map((dayOfWeek, index) => (
                {
                  id: index,
                  name: dayOfWeek
                }))
            }
            intervals={
              calendarStore.weekIntervalSummary?.map(x => (
                {
                  categoryId: toLocalDayOrder(x.dayOfWeek),
                  start: x.timeRange.start,
                  end: x.timeRange.end,
                  type: "summary",
                  isSaved: x.isSaved
                }))
            }
            tableId={wholeWeekTabId.toString()}
            intervalSelected={selection => {
              calendarStore.intervalFormModel.setFormType("multiple");
              calendarStore.intervalFormModel.prefillMultiple(
                toIsoDayOrder(selection.category.id),
                selection.timeRange.start,
                selection.timeRange.end);
              setIsIntervalModalOpen(true);
            }}
            intervalClicked={selection => {
              calendarStore.intervalFormModel.setFormType("multiple");
              const dayOfWeek = toIsoDayOrder(selection.category.id);
              const selectedSummaryInterval = calendarStore.weekIntervalSummary.find(x => x.timeRange.equals(selection.timeRange) && x.dayOfWeek === dayOfWeek)!;
              calendarStore.intervalFormModel.prefillMultiple(
                dayOfWeek,
                selection.timeRange.start,
                selection.timeRange.end,
                selectedSummaryInterval.intervalsGrouped);
              setIsIntervalModalOpen(true);
            }}
            categoryClick={category => setActiveTab((wholeWeekTabId + category.id + 1))}
          />
        </TabPane>
        {
          weekdays.map((_, dayIndex) => {
            const tabId = wholeWeekTabId + dayIndex + 1;
            return (
              <TabPane tabId={tabId} key={dayIndex}>
                <CalendarTable
                  categories={
                    calendarStore.scheduleTypes
                      ?.map(item => ({
                        id: item.scheduleTypeId,
                        name: item.name ?? translate(x => x.pages.calendar.scheduleTypes[item.scheduleTypeId])
                      }))}
                  intervals={
                    calendarStore.intervals
                      ?.filter(x => toLocalDayOrder(x.dayOfWeek) === dayIndex)
                      .map(x => ({
                        categoryId: x.scheduleTypeId,
                        start: x.start,
                        end: x.end,
                        type: getCalendarTypeByScheduleType(x.scheduleTypeId),
                        isSaved: x.isSaved
                      }))}
                  tableId={tabId.toString()}
                  intervalSelected={selection => {
                    calendarStore.intervalFormModel.setFormType("single");
                    calendarStore.intervalFormModel.prefillSingle(
                      toIsoDayOrder(dayIndex),
                      selection.timeRange.start,
                      selection.timeRange.end,
                      selection.category.id);
                    setIsIntervalModalOpen(true);
                  }}
                  intervalClicked={selection => {
                    calendarStore.intervalFormModel.setFormType("single");
                    const selectedInterval = calendarStore.intervals.find(x =>
                      ScheduleIntervalComparer.areEqual(x, {
                        start: selection.timeRange.start,
                        end: selection.timeRange.end,
                        dayOfWeek: toIsoDayOrder(dayIndex),
                        scheduleTypeId: selection.category.id
                      }))!;
                    calendarStore.intervalFormModel.prefillSingle(
                      toIsoDayOrder(dayIndex),
                      selection.timeRange.start,
                      selection.timeRange.end,
                      selection.category.id,
                      selectedInterval);
                    setIsIntervalModalOpen(true);
                  }}
                />
              </TabPane>
            )
          })
        }
      </TabContent>

      <ArcModal
        isOpen={isIntervalModalOpen}
        title={activeTab === wholeWeekTabId
          ? weekDaysCapitalized[toLocalDayOrder(calendarStore.intervalFormModel.dayOfWeek ?? 0)]
          : selectedScheduleType
            ? selectedScheduleType.name ?? translate(x => x.pages.calendar.scheduleTypes[selectedScheduleType.scheduleTypeId])
            : ''}
        close={() => {
          calendarStore.intervalFormModel.clear();
          setIsIntervalModalOpen(false);
        }}
      >
        <ScheduleIntervalForm
          model={calendarStore.intervalFormModel}
          confirm={{
            content: translate(x => x.general.ok),
            enabled: calendarStore.intervalFormModel.isValid,
            onClick: () => {
              const newIntervals = calendarStore.intervalFormModel.createIntervalDtos();
              calendarStore.replaceIntervals(calendarStore.intervalFormModel.intervalsEdited, newIntervals);
              calendarStore.intervalFormModel.clear();
              setIsIntervalModalOpen(false);
            }
          }}
          cancel={{
            content: translate(x => x.general.cancel),
            onClick: () => {
              calendarStore.intervalFormModel.clear();
              setIsIntervalModalOpen(false);
            }
          }}
          delete={calendarStore.intervalFormModel.isEditting
            ? {
              content: translate(x => x.general.delete),
              onClick: () => {
                calendarStore.removeIntervals(calendarStore.intervalFormModel.intervalsEdited);
                setIsIntervalModalOpen(false);
              }
            }
            : undefined
          }
        />
      </ArcModal>

      <FormNewChangesDialog
        newChangesModel={calendarStore.newChangesModel}
        onConfirm={async () => await calendarStore.fetchAll()}
        onClose={() => {
          calendarStore.clearCurrentlyReceivingSchedules();
          calendarStore.newChangesModel.hideModals();
          calendarStore.newChangesModel.isUpdateExecutor = false;
        }}
        changeRequestType={ChangeRequestTypeEnum.Intervals}
      />

      <Prompt
        when={calendarStore.hasChanges}
        message={translate(x => x.pages.calendar.pageLeaveWarning)}
      />
    </div>
  )
};

const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export default observer(CalendarTablesContainer);