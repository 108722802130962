import ObservedNotifications from '../../../base/pages/status/ObservedNotifications';

const observedNotifications: ObservedNotifications = {
  level: {
    info: 'Information:',
    danger: 'Caution!',
    warning: 'Notice!'
  },
  internetOutage: 'Frequent internet outages.',
  wrongTemplate: 'Wrong template.',
  plcConnectivity: 'Frequent PLC connection outages.'
};

export default observedNotifications;
