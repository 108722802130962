import React from 'react';
const icon = `${process.env.PUBLIC_URL}/img/burgerWhite.svg`;

interface TopbarSidebarButtonProps {
  changeMobileSidebarVisibility: (...args: any[]) => any;
  changeSidebarVisibility: () => any;
}

const TopbarSidebarButton = ({ changeMobileSidebarVisibility, changeSidebarVisibility }: TopbarSidebarButtonProps) =>
  <div>
    <button className="topbar__button topbar__button--desktop" onClick={changeSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
    <button className="topbar__button topbar__button--mobile" onClick={changeMobileSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
  </div>;

export default React.memo(TopbarSidebarButton);
