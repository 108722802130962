import ErrorPages from '../../../base/pages/errorPages';
import forbidden from './Forbidden';
import notFound from './NotFound';

const errorPages: ErrorPages = {
  forbidden,
  notFound
};

export default errorPages;
