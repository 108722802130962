import ErrorStore from '../errors/ErrorStore';
import LoggerStore from '../LoggerStore';
import { action } from 'mobx';

export interface BaseStoreParams {
  errorStore: ErrorStore;
  getTrackingId: () => string;
  loggerStore: LoggerStore;
}

export type Params<TParams> = TParams & BaseStoreParams;

export default abstract class BaseStore<TParams> {
  public loadPromise?: Promise<void>;
  protected params: Params<TParams>;

  constructor(params: Params<TParams>) {
    this.params = params;
  }

  @action
  protected setLoadPromise(execute: () => Promise<void>) {
    this.loadPromise = execute();
  }
}
