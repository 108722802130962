import { Card, CardBody, Col } from 'reactstrap';

import AiOffIcon from '../../../content/images/artificialIntelligenceOff.svg';
import AiOnIcon from '../../../content/images/artificialIntelligenceOn.svg';
import RadioboxBlankIcon from 'mdi-react/RadioboxBlankIcon';
import RadioboxMarkedIcon from 'mdi-react/RadioboxMarkedIcon';
import React from 'react';
import Translate from '../../../localization/Translate';
import { observer } from 'mobx-react';

interface ArtificialIntelligenceIconProps{
  artificialIntelligenceEnabled: boolean;
}

interface ArtificialIntelligenceProps {
  translate : Translate;
  colClassName?: string;
  artificialIntelligenceEnabled?: boolean;
  isInitialized?: boolean;
  isInflated?: boolean;
}

const ArtificialIntelligenceIcon = ({ artificialIntelligenceEnabled }: ArtificialIntelligenceIconProps) =>
  <img width={128} height={128} className="p-2" src={artificialIntelligenceEnabled ? AiOnIcon : AiOffIcon} />;

const ArtificialIntelligence = ({ translate,
                                  colClassName,
                                  isInflated,
                                  isInitialized,
                                  artificialIntelligenceEnabled }: ArtificialIntelligenceProps) =>
 <Col xl={3} lg={6} sm={12} className={`gauge-card col-xxl-3 col-4xl-2 ${colClassName || ''}`}>
  <Card>
    <CardBody><div className="ai-box gauge-value-box w-100">
      <div className="card__title mb-0">
        <h5 className="bold-text">
          Airdome
        </h5>
      </div>
      <div className="w-100">
        <Col className="d-inline-block pl-0 pr-0 col-xxs-6">
          <p>{translate(x => x.pages.status.inflated)}</p>
          {isInflated
            ? <RadioboxMarkedIcon className="mb-1 d-inline-block inflated-icon-on" />
            : <RadioboxBlankIcon className="mb-1 d-inline-block inflated-icon-off" />}
        </Col>
        <Col className="d-inline-block pl-0 pr-0 col-xxs-6">
          <p>{translate(x => x.pages.status.initialized)}</p>
          {isInitialized
            ? <RadioboxMarkedIcon className="mb-1 d-inline-block initialized-icon-on" />
            : <RadioboxBlankIcon className="mb-1 d-inline-block initialized-icon-off" />}
        </Col>
      </div>
      <div className="card__title border-top border-dark pt-2">
        <h5 className="bold-text">
          {translate(x => x.pages.status.artificialIntelligence)}
        </h5>
      </div>
      <div className="gauge-value-box-chart">
        <ArtificialIntelligenceIcon artificialIntelligenceEnabled={!!artificialIntelligenceEnabled} />
      </div>
    </div>
    </CardBody>
  </Card>
 </Col>;

export default observer(ArtificialIntelligence);
