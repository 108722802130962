import ActiveNotificationsDto from 'dto/notification/ActiveNotificationsDto';
import { AxiosInstance } from 'axios';
import NotificationChannelDto from 'dto/notifications/NotificationChannelDto';
import NotificationDto from 'dto/notification/NotificationDto';
import NotificationIdentifierDto from 'dto/notification/NotificationIdentifierDto';
import NotificationsHistoryDto from 'dto/notification/NotificationsHistoryDto';
import { getBackendException } from '../../helpers/ErrorHelper';
import { getJoinedEndpoint } from '../../utils/Settings';
import stringFormat from 'string-format';

export default class NotificationsService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  public getNotificationsHistory = async (airdomeId: number, start: Date, end: Date, skip: number, take: number, trackingId: string): Promise<NotificationsHistoryDto> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.status.baseUri,
          x => x.status.notifications.getHistory),
        airdomeId.toString());

      const config = {
        headers: { trackingId },
        params: { start, end, skip, take }
      };

      const result = await this.axios.get<NotificationsHistoryDto>(url, config);

      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public getNotificationsForAirdome = async (airdomeId: number, trackingId: string): Promise<ActiveNotificationsDto> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.status.baseUri,
          x => x.status.notifications.getActive),
        airdomeId.toString());

      const result = await this.axios.get<ActiveNotificationsDto>(url, { headers: { trackingId } });
      return result.data;
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public resolveNotificationForAirdome = async (airdomeId: number, notificationId: NotificationIdentifierDto, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.status.baseUri,
          x => x.status.notifications.resolve),
        airdomeId.toString());

      await this.axios.post(url, notificationId, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public resolveAllNotificationsForAirdome = async (airdomeId: number, trackingId: string): Promise<void> => {
    try {
      const url = stringFormat(
        getJoinedEndpoint(
          x => x.status.baseUri,
          x => x.status.notifications.resolveAll),
        airdomeId.toString());

      await this.axios.post(url, undefined, { headers: { trackingId } });
    } catch (error) {
      throw getBackendException(error);
    }
  }

  public async getCurrentUserNotificationChannel(trackingId: string): Promise<NotificationChannelDto> {
    try {
      const url = getJoinedEndpoint(x => x.notifications.baseUri,
        x => x.notifications.notifications.getCurrentUsersNotificationChannel);
      const response = await this.axios.get<NotificationChannelDto>(url);
      return response.data;
    } catch (exception) {
      throw getBackendException(exception);
    }
  }

  public async updateCurrentUserNotificationChannel(notificationChannelId: number, trackingId: string): Promise<NotificationChannelDto> {
    try {
      const url = stringFormat(getJoinedEndpoint(x => x.notifications.baseUri,
        x => x.notifications.notifications.updateCurrentUsersNotificationChannel), notificationChannelId.toString());
      const response = await this.axios.put<NotificationChannelDto>(url);
      return response.data;
    } catch (exception) {
      throw getBackendException(exception);
    }
  }
}
