import 'bootstrap/dist/css/bootstrap.css';
import './scss/app.scss';
import './validations/yupMethods';
import 'common/ArrayExtension';

import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Router from './containers/app/Router';
import ScrollToTop from './containers/app/ScrollToTop';

const App = () => 
  <BrowserRouter basename="/">
    <ScrollToTop>
      <Router />
    </ScrollToTop>
  </BrowserRouter>;

export default App;
