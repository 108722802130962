import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import stringFormat from 'string-format';
import objectHelper from '../../utils/ObjectHelper';

interface GaugeValueBoxProps {
  titles: {
    top?: string;
    bottom?: string;
  };

  icon: JSX.Element;
  value?: number;
  unit: string;
  minValue: number;
  maxValue: number;
  valueTextFormat?: string;
  valueFontSizeMultiplier?: number;
  lineFill: {
    foreground: string;
    background: string;
  };

  angles: {
    start: number;
    end: number;
  };

  chartStyle: {
    outerRadius: number;
    innerRadius: number;
  };
}

const emptyValueString = '-';

const getPercentage = (minValue: number, maxValue: number, value?: number) =>
  objectHelper.executeIfNumber(value, x => (x - minValue) * 100 / (maxValue - minValue))
  || 0;

const trimPercentage = (value: number) =>
  Math.max(0,
    Math.min(100,
      value));

const resonsiveText = (value: string) => {

  if (value.length > 9)
    return 30;
  if (value.length > 8)
    return 35;
  if (value.length > 7)
    return 40;
  if (value.length > 6)
    return 45;

  return 50;
};

const GaugeValueBox = (props: GaugeValueBoxProps) => {
  const fillPercentage = trimPercentage(getPercentage(props.minValue, props.maxValue, props.value));

  const multiplier = props.valueFontSizeMultiplier || 1;

  const data = [];
  const fontSize = props.value
    ? resonsiveText(props.value.toString()) * multiplier
    : resonsiveText(emptyValueString) * multiplier;

  if (fillPercentage > 0)
    data.push({ value: fillPercentage, fill: props.lineFill.foreground });

  if (fillPercentage < 100)
    data.push({ value: 100 - fillPercentage, fill: props.lineFill.background });

  const styleWidth = {
    width: `${props.chartStyle.outerRadius * 2}px`
  };

  return <div className="gauge-value-box" style={styleWidth}>
    {props.titles.top ? <div className="card__title" style={styleWidth}>
      <h5 className="bold-text">{props.titles.top}</h5>
    </div> : null}
    <div className="gauge-value-box-chart" style={styleWidth}>
      <ResponsiveContainer width={props.chartStyle.outerRadius * 2} height={props.chartStyle.outerRadius * 2}>
        <PieChart width={props.chartStyle.outerRadius * 2}>
          <Pie data={data}
            dataKey="value"
            width={props.chartStyle.outerRadius * 2}
            startAngle={props.angles.start}
            endAngle={props.angles.end}
            cy={(props.chartStyle.outerRadius + props.chartStyle.innerRadius) / 2}
            innerRadius={props.chartStyle.innerRadius}
            outerRadius={props.chartStyle.outerRadius}
            isAnimationActive={true} />
        </PieChart>
      </ResponsiveContainer>
      <div className="gauge-value-box-chart-info" style={styleWidth}>
        {props.icon}
        <p className="gauge-value-box-chart-number" style={{ fontSize }}>
          {typeof props.value !== 'number' || Number.isNaN(props.value)
            ? emptyValueString
            : props.valueTextFormat
              ? stringFormat(props.valueTextFormat,
                props.value.toString(),
                props.maxValue.toString(),
                props.minValue.toString())
              : props.value}
        </p>
        <p className="gauge-value-box-chart-units">{props.unit}</p>
      </div>
    </div>
    {props.titles.bottom ? <div className="card__title">
      <h5 className="bold-text">{props.titles.bottom}</h5>
    </div> : null}
  </div>;
};

export default React.memo(GaugeValueBox);
