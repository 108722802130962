import Datatables from '../../../base/general/datatables';

const datatables: Datatables = {
  previousPage: 'Previous page',
  nextPage: 'Next page',
  search: 'Search',
  numberOfRowsShown: ' ',
  displayedRows: 'Page {page}'
};

export default datatables;
