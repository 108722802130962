import * as React from 'react';

import { observer } from 'mobx-react';

const SwitchboxLight = ({
  topLabel,
  bottomLabel,
  lightColor,
  state
}: {
  state: 'on' | 'off' | 'failure',
  lightColor: 'dark-red' | 'white' | 'green',
  topLabel?: string, 
  bottomLabel?: string
}) => <div className="switchbox-control">
  {topLabel ? <div className="txt-label">{topLabel}</div> : null}
  <div className={`switchbox-light ${lightColor} ${state}`}></div>
  {bottomLabel ? <div className="txt-label">{bottomLabel}</div> : null}
</div>;

export default observer(SwitchboxLight);