import { action, computed, observable } from 'mobx';

export default class FormNewChangesModel<TSignalRDto> {
    @observable
    public isUpdateExecutor = false;
  
    @observable
    public displayCRRefreshModal = false;
  
    @observable
    public displayPlcRefreshModal = false;
  
    readonly onFormUpdate = (dto: TSignalRDto) => {};
  
    constructor(bindForm: (dto: TSignalRDto) => void) {
      this.onFormUpdate = bindForm;
    }
  
    @action
    public updateForm = (dto: TSignalRDto, isFromPlc: boolean, hasAnyChanges: boolean) => {
      if (this.isUpdateExecutor || !hasAnyChanges) {
        this.onFormUpdate(dto);
        this.isUpdateExecutor = false;
      } else {
        if(isFromPlc)
          this.displayPlcRefreshModal = true;
        else
          this.displayCRRefreshModal = true;
      }
    }
  
    @computed
    public get isUpdatePending(): boolean {
      return !!this.isUpdateExecutor;
    }
  
    @computed 
    public get isModalOpened(): boolean {
      return this.displayCRRefreshModal || this.displayPlcRefreshModal;
    }
  
    @action
    public hideModals = () => {
      this.displayCRRefreshModal = false;
      this.displayPlcRefreshModal = false;
    }
  }