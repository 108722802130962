import { action, observable } from 'mobx';

import AirdomeModel from './AirdomeModel';
import StatusDto from '../../dto/status/StatusDto';
import moment from 'moment';

export default class StatusModel {
  @observable public timestamp?: Date;
  @observable public temperatureOutside?: number;
  @observable public temperatureInside?: number;
  @observable public temperatureInput?: number;
  @observable public pressure?: number;
  @observable public height?: number;
  @observable public voltage?: number;
  @observable public windSpeed?: number;

  @observable public isInflated?: boolean;
  @observable public isInitialized?: boolean;
  @observable public artificialIntelligenceEnabled?: boolean;

  @action public importFromStatusDto = (dto: StatusDto) => {
    this.timestamp = moment(dto.timestamp).toDate();
    this.temperatureOutside = dto.temperatureOutside;
    this.temperatureInside = dto.temperatureInside;
    this.temperatureInput = dto.temperatureInput;
    this.pressure = dto.pressure;
    this.height = dto.height;
    this.voltage = dto.voltage;
    this.windSpeed = dto.windSpeed;
  }

  @action public importFromAirdomeDto = (dto: AirdomeModel) => {
    this.isInflated = dto.isInflated;
    this.isInitialized = dto.isInitialized;
  }

  @action public setIsInitialized = (isInitialized: boolean) => {
    this.isInitialized = isInitialized;
  }

  @action public setIsInflated = (isInflated: boolean) => {
    this.isInflated = isInflated;
  }

  @action public setIsAIEnabled = (isAIEnabled: boolean) => {
    this.artificialIntelligenceEnabled = isAIEnabled;
  }

  @action public clear = () => {
    this.timestamp = undefined;
    this.temperatureOutside = undefined;
    this.temperatureInside = undefined;
    this.temperatureInput = undefined;
    this.pressure = undefined;
    this.height = undefined;
    this.voltage = undefined;
    this.windSpeed = undefined;
    this.isInflated = undefined;
    this.isInitialized = undefined;
    this.artificialIntelligenceEnabled = undefined;
  }
}
