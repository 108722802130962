import './StatusBox.scss';

import { Card, CardBody, Col } from 'reactstrap';

import AirdomeHeight from './values/AirdomeHeight';
import ArtificialIntelligence from './values/ArtificialIntelligence';
import Lighting from './values/Lighting';
import Pressure from './values/Pressure';
import TemperatureInput from './temperatures/TemperatureInput';
import TemperatureInside from './temperatures/TemperatureInside';
import TemperatureOutside from './temperatures/TemperatureOutside';
import Translate from '../../localization/Translate';
import Voltage from './values/Voltage';
import WindSpeed from './values/WindSpeed';
import { observer } from 'mobx-react';

interface StatusBoxProps {
  translate: Translate;
  statusModel: {
    isInflated?: boolean;
    isInitialized?: boolean;
    temperatureOutside?: number;
    temperatureInside?: number;
    temperatureInput?: number;
    pressure?: number;
    height?: number;
    voltage?: number;
    windSpeed?: number;
    artificialIntelligenceEnabled?: boolean;
  };
  lights?: {
    total: number;
    active: number;
  };
  onLightingClick: () => void;
}

const StatusBox = ({ translate, statusModel, lights, onLightingClick }: StatusBoxProps) => {
  const {
    temperatureInput,
    temperatureInside,
    temperatureOutside,
    height,
    pressure,
    voltage,
    windSpeed,
    artificialIntelligenceEnabled,
    isInflated,
    isInitialized
  } = statusModel;

  return <div className="w-100 status-gauge-box">
    <div className="d-flex flex-wrap">
      <Col xl={9} className="temp-box gauge-card col-xxs-12 col-xxxl-6 col-4xl-4">
        <Card>
          <CardBody className="pt-4">
            <TemperatureInside {... { translate, temperatureInside }} />
            <TemperatureOutside {... { translate, temperatureOutside }} />
            <TemperatureInput {... { translate, temperatureInput }} />
          </CardBody>
        </Card>
      </Col>
      <ArtificialIntelligence {... {
        translate,
        isInflated,
        isInitialized,
        artificialIntelligenceEnabled
      }} />
      <Lighting {... {
        translate,
        lights,
        onClick: onLightingClick,
        colClassName: 'order-2 lightingCard'
      }} />
      <Pressure {... { translate, pressure, colClassName: 'order-3' }} />
      <Voltage {... { translate, voltage, colClassName: 'order-4' }} />
      <WindSpeed {... { translate, windSpeed, colClassName: 'order-5' }} />
      <AirdomeHeight {... { translate, height, colClassName: 'order-6' }} />
    </div>
  </div>;
};

export default observer(StatusBox);
