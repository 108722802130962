import { ColClassName, TranslateProps } from '../../../interfaces/Props';
import { ValuesUnits, chartStyle, getColorIfDefined, iconSize, isTranslateSame, wrapGauge } from './Shared';

import React from 'react';
import StatusGauge from '../StatusGauge';
import WeatherWindyIcon from 'mdi-react/WeatherWindyIcon';

const WindSpeed = React.memo((props: TranslateProps & ColClassName & {
  windSpeed?: number;
}) => wrapGauge(
  <StatusGauge {...{
    chartStyle,
    title: props.translate(x => x.pages.status.windSpeed),
    value: props.windSpeed,
    minValue: 0,
    maxValue: 30,
    lineForeground: getColorIfDefined(props.windSpeed, '#15BF42'),
    unit: ValuesUnits.windSpeed,
    icon: <WeatherWindyIcon size={iconSize} color={getColorIfDefined(props.windSpeed, '#15BF42')} />
  }} />, props.colClassName),
  (a, b) => a.windSpeed === b.windSpeed
    && isTranslateSame(a, b, x => x.pages.status.windSpeed));

export default WindSpeed;
