import { Button, ButtonProps } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';

import { CancellablePromise } from 'mobx/lib/api/flow';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { flow } from 'mobx';

interface Props {
  content: string | JSX.Element;
  onClick: () => void | Promise<void>;
}

const ButtonLoading = (props: Props & ButtonProps) => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [onClickPromise, setOnClickPromise] = useState<CancellablePromise<void> | undefined>(undefined);

  useEffect(() => () => {
    isMounted.current = false;
    onClickPromise && onClickPromise.cancel();
  }, []);

  const onClickWrapped = flow(function* () {
    if (loading || !isMounted.current)
      return;

    setLoading(true);

    try {
      yield props.onClick();
    } finally {
      if (isMounted.current)
        setLoading(false);
    }
  });

  return (
    <Button
      {...{
        ...props,
        disabled: props.disabled || loading,
        onClick: () => setOnClickPromise(onClickWrapped()),
        className: `icon expand ${loading ? 'expand--load ' : ''}${props.className ?? ''}`
      }}
    >
      <div>
        <LoadingIcon className={loading ? '' : 'd-none'} />
        <span className="center">{props.content}</span>
      </div>
    </Button>
  )
}

export default ButtonLoading;