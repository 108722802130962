import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import { PressureModeMax } from '../icons';
import { Row } from 'reactstrap';
import SettingsFormProps from '../SettingsFormProps';
import { observer } from 'mobx-react';

const PressureMaxField = ({ translate, settingsModel, accessTable, disabled }: SettingsFormProps) => {
  const label = translate(x => x.forms.airdome.settings.pressureMax);

  return <Row>
    <div className="settings-field-icon">
      <PressureModeMax style={{ width: '100%', height: 40 }} />
    </div>
    <div className="settings-field-input">
      <FormInput {...{
        model: new FormInputModel({
          label,
          validationSchema: settingsModel.pressureMaxValidationSchema.label(label),
          placeholder: label,
          handleValueModel: new HandleValueModel({
            setValue: settingsModel.pressureMax.setValue,
            inputType: 'number'
          }),
          inputId: 'PressureMax',
          value: settingsModel.pressureMax.Value ? (Math.round(settingsModel.pressureMax.Value * 10) / 10).toString() : '',
          readonly: disabled || !accessTable.edit
        })
      }} />
    </div>
  </Row>;
};

export default observer(PressureMaxField);
