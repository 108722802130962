import { LightAuto, LightOff, LightOn } from './icons';

import { LightState } from '../../dto/settings/enums';
import SelectorCard from 'components/selectorCard/SelectorCard';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface LightCardProps {
  lightState: LightState;
  setLightState: (value: LightState) => void;
  name: string;
  setName: (value: string) => void;
  disabled?: boolean;
}

const LightCard = (props: LightCardProps) => {
  const translate = useLocalization();
  const { setLightState, lightState, name, setName, disabled } = props;

  return (
    <SelectorCard<LightState>
      currentValue={lightState}
      name={name}
      setName={setName}
      disabled={disabled}
      selectValue={setLightState}
      selections={[
        {
          value: LightState.Off,
          display: {
            shortName: translate(x => x.enums.lightState[LightState.Off].short),
            fullName: translate(x => x.enums.lightState[LightState.Off].full)
          },
          getIcon: props => <LightOff {...props} />
        },
        {
          value: LightState.Auto,
          display: {
            shortName: translate(x => x.enums.lightState[LightState.Auto].short),
            fullName: translate(x => x.enums.lightState[LightState.Auto].full)
          },
          getIcon: props => <LightAuto {...props} />
        },
        {
          value: LightState.On,
          display: {
            shortName: translate(x => x.enums.lightState[LightState.On].short),
            fullName: translate(x => x.enums.lightState[LightState.On].full)
          },
          getIcon: props => <LightOn {...props} />
        }
      ]}/>
  );
}

export default observer(LightCard);