import { CheckboxModel } from '../../../../../models';
import { FormCheckbox } from '../../../..';
import React from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';

interface HasPreheatingFieldProps {
  hasPreheating: boolean;
  setHasPreheating: (hasPreheating: boolean) => void;
}

const HasPreheatingField = ({ hasPreheating, setHasPreheating }: HasPreheatingFieldProps) =>
  {
    const translate = useLocalization();
    return <FormCheckbox {...{
      translate,
      model: new CheckboxModel({
        inputId: 'AirdomePreheating',
        label: translate(x => x.forms.airdome.create.hasPreheating),
        setValue: setHasPreheating,
        value: hasPreheating
      })
    }} />;
  };

export default observer(HasPreheatingField);