import { action, computed, isObservableArray, observable } from 'mobx';

import { AccessDefinition } from '../security/AccessDefinitions';
import { User } from 'oidc-client-ts';
import jwtDecode from 'jwt-decode';

export default class SecurityStore {
  @observable
  private accessToken?: string;

  @observable
  private decodedAccessToken?: {
    [key: string]: string | string[]
  };

  public isAccessAllowed(accessDefinition: AccessDefinition): boolean {
    if (!this.decodedAccessToken)
      return false;

    const claimValue = this.decodedAccessToken[accessDefinition.claimType];
    if (!claimValue)
      return false;

    if (isObservableArray(claimValue))
      return claimValue.includes(accessDefinition.claimValue);

    return claimValue === accessDefinition.claimValue;
  }

  @computed
  public get userId(): string {
    if (!this.decodedAccessToken)
      return '';

    const userId = this.decodedAccessToken['sub'];
    if (isObservableArray(userId))
      return userId[0];

    return userId?.toString() ?? "";
  }

  @computed
  public get userName(): string {
    if (!this.decodedAccessToken)
      return '';

    const userName = this.decodedAccessToken['name'];
    if (isObservableArray(userName))
      return userName[0];

    return userName?.toString() ?? "";
  }

  @computed
  public get email(): string {
    if (!this.decodedAccessToken)
      return '';

    const email = this.decodedAccessToken['email'];
    if (isObservableArray(email))
      return email[0];

    return email?.toString() ?? "";
  }

  @action
  public onUserLoaded(user: User) {
    this.accessToken = user.access_token;
    this.decodedAccessToken = jwtDecode(this.accessToken);
  }

  @computed
  public get AccessToken(): string | undefined {
    return this.accessToken;
  }

  @action
  public clear() {
    this.accessToken = undefined;
    this.decodedAccessToken = undefined;
  }

  @action
  public setUserName = (userName: string) => {
    if (this.decodedAccessToken)
      this.decodedAccessToken['name'] = userName;
  }

  @action
  public setEmail = (email: string) => {
    if (this.decodedAccessToken)
      this.decodedAccessToken['email'] = email;
  }
}
