import { Col, Row } from 'reactstrap';

import React from 'react';
import SiemensLogo from 'content/images/switchbox/siemens-logo-en-2x.png';
import SwitchBoxTablePropertyName from 'enums/SwitchBoxTablePropertyName';
import { observer } from 'mobx-react';
import { useLocalization } from 'localization/LocalizationProvider';
import { useStore } from 'stores/MobXTypedStoreHook';

const tableSwitchBoxTypes: { [key in SwitchBoxTablePropertyName]: string } = {
  [SwitchBoxTablePropertyName.DomeHeightUpperBound]: 'm',
  [SwitchBoxTablePropertyName.DomeHeightLowerBound]: 'm',
  [SwitchBoxTablePropertyName.DomeHeightAlarmHysteresis]: 'm',
  [SwitchBoxTablePropertyName.WindSpeedUpperBound]: 'm/s',
  [SwitchBoxTablePropertyName.WindSpeedLowerBound]: 'm/s',
  [SwitchBoxTablePropertyName.WindSpeedAlarmHysteresis]: 'm/s',
  [SwitchBoxTablePropertyName.ComputedPressureValue]: 'Pa',
  [SwitchBoxTablePropertyName.ZesileniRegulaceP]: '',
  [SwitchBoxTablePropertyName.IntegracniRegulaceP]: '',
  [SwitchBoxTablePropertyName.MaximalEmergencyPressure]: 'Pa',
  [SwitchBoxTablePropertyName.LastPreheatingDurationDeviation]: 'min',
  [SwitchBoxTablePropertyName.PredictedPreheatingDuration]: 'min'
};

const SwitchboxTable = () => {
  const translate = useLocalization();
  const {
    SwitchBoxTableData: tableData
  } = useStore(x => x.switchBoxStore);
  if(!tableData)
      return null;

  return <Row>
    <Col xs={12}>
      <Row className="switchbox-controller">
        <Col xs={3}>
          <img src={SiemensLogo} alt="Siemens"/>
        </Col>
        <Col xs={9} className="list-container">
          <table>
            <tbody>
              {tableData
                .map((item, index) => <tr key={index}>
                  <td>{translate(x => x.pages.switchbox.table[item.key])}</td>
                  {item.value !== undefined && item.value !== null
                    ? <td>{item.value.toFixed(1)}{tableSwitchBoxTypes[item.key] ? ` ${tableSwitchBoxTypes[item.key]}` : ''}</td>
                    : <td>Not available</td>}
                </tr>)}
            </tbody>
          </table>
        </Col>
      </Row>
    </Col>
  </Row>;
}

export default observer(SwitchboxTable);