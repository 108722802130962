import { action, computed, observable } from "mobx";

const defaultPage = 0;
const defaultRowsPerPage = 10;

export default class PagingModel {
  @observable
  public currentPage: number = defaultPage;

  @observable
  public rowsPerPage: number = defaultRowsPerPage;

  @action
  public setCurrentPage = (currentPage: number) =>
    this.currentPage = currentPage;

  @action
  public setRowsPerPage = (rowsPerPage: number) => {
    this.currentPage = defaultPage;
    this.rowsPerPage = rowsPerPage;
  }

  @action
  public clear = () => {
    this.setCurrentPage(defaultPage);
    this.setRowsPerPage(defaultRowsPerPage);
  }

  @computed
  public get Skip(): number {
    return this.currentPage * this.rowsPerPage;
  }

  @computed
  public get Take(): number {
    return this.rowsPerPage;
  }
}