import React from 'react';
import { FormInput } from '../../..';
import { FormInputModel } from '../../../../models';
import { HandleValueModel } from '../../../../models/component/HandleValueModel';
import Translate from '../../../../localization/Translate';
import { StringSchema } from 'yup';
import { observer } from 'mobx-react';

interface EmailFieldProps {
  validationSchema: StringSchema;
  translate: Translate;
  setEmail: (email?: string) => void;
  currentEmail?: string;
}

const EmailField = ({ validationSchema, translate, setEmail, currentEmail }: EmailFieldProps) => <FormInput {...{
  translate,
  model: new FormInputModel({
    validationSchema: validationSchema.label(translate(x => x.general.user.email)),
    handleValueModel: new HandleValueModel({
      setValue: setEmail,
      inputType: 'email'
    }),
    placeholder: translate(x => x.general.user.email),
    label: translate(x => x.general.user.email),
    inputId: 'Email',
    value: currentEmail
  })
}} />;

export default observer(EmailField);
